import React from 'react';
import { NSLabel, NSModal } from 'bricks';
import { useDeleteDeal } from 'views/Deals/hooks/useDeleteDeal';
import { useQueryClient } from '@tanstack/react-query';
import { Deal } from 'views/Deals/types';

export interface IDeleteDealModalProps {
    toggle: () => void;
    isOpen: boolean;
    deals: Deal[];
}

const DeleteDealModal = ({ toggle, isOpen, deals }: IDeleteDealModalProps) => {
    const queryClient = useQueryClient();
    const { mutateAsync: deleteDeal, error: deleteError } = useDeleteDeal();

    // TODO Remove hardcoded ORG ID
    const ORG_ID = '123e4567-e89b-12d3-a456-426614174000';

    const handleDelete = async () => {
        if (!deals || deals.length === 0) {
            window.alert('No deals selected to delete.');
            return;
        }

        try {
            await Promise.all(deals.map(deal => deleteDeal({ organizationId: ORG_ID, dealId: deal.id })));

            window.alert('Selected deals have been successfully deleted.');
            queryClient.invalidateQueries({ queryKey: ['deals', ORG_ID] });
            toggle();
        } catch (error: any) {
            window.alert(deleteError?.message || 'An error occurred while deleting the deals.');
        }
    };

    return (
        <NSModal
            toggle={toggle}
            isOpen={isOpen}
            modalHeaderClassName="bg-danger modal-colored-header"
            modalHeaderText="Delete deals"
            modalFooterButtonText="Confirm"
            modalFooterIsButtonFunctionDisabled={!deals}
            modalFooterIsShown
            modalFooterButtonFunction={handleDelete}
            modalFooterButtonColor="danger"
        >
            <div className="d-flex flex-column">
                <NSLabel className="text-dark font-weight-normal pt-1 mb-0">Are you sure you want to delete the selected deals?</NSLabel>
            </div>
        </NSModal>
    );
};

export default DeleteDealModal;
