import React from 'react';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IReportTitleProps {
    reportName: string;
    isEditing: boolean;
    setShowUpdateReportTitleAndDescriptionModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const ReportTitle = ({ reportName, isEditing, setShowUpdateReportTitleAndDescriptionModal }: IReportTitleProps) => {
    return (
        <div>
            <span>{reportName}</span>
            {isEditing && (
                <FontAwesomeIcon
                    icon={faPen}
                    size="xs"
                    className="mx-1"
                    style={{ fontSize: '0.8rem', cursor: 'pointer' }}
                    onClick={() => setShowUpdateReportTitleAndDescriptionModal(true)}
                    aria-label="Edit report name"
                />
            )}
        </div>
    );
};

export default ReportTitle;
