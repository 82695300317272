import { NSButton } from 'bricks';
import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import DeleteModalConfirmationModal from 'views/Reports/components/DeleteReportConfirmationModal/DeleteModalConfirmationModal';
import { getReportsQueryKey } from 'views/Reports/hooks/useGetReports';
import { useDeleteReports } from '../../hooks/useDeleteReports';

interface IActionRowProps {
    selectedRows: number[];
}

const ActionRow = ({ selectedRows }: IActionRowProps) => {
    const queryClient = useQueryClient();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);
    const toggle = () => setIsDeleteModalOpen(!isDeleteModalOpen);

    const { mutate: deleteReports } = useDeleteReports();

    const orgId = '123e4567-e89b-12d3-a456-426614174000';

    const handleDelete = () => {
        deleteReports(
            { orgId, reportIds: selectedRows },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: [getReportsQueryKey, orgId] });
                },
            },
        );
        toggle();
    };

    return (
        <>
            <div className="ActionRow p-2 rounded">
                <span className="text-white">{selectedRows.length} selected</span>
                <NSButton className="ml-2" color="danger" outline text="Delete" data-testid="delete-toggle" callback={() => setIsDeleteModalOpen(true)} />
            </div>
            <DeleteModalConfirmationModal
                isOpen={isDeleteModalOpen}
                toggle={toggle}
                numberOfReportsToDelete={selectedRows.length}
                handleDelete={handleDelete}
            />
        </>
    );
};

export default ActionRow;
