import axiosInstance from 'ns_libs/nsAxios';
import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';

export interface Organization {
    organizationCorrelationId: string;
    organizationName: string;
}

export interface UserClassData {
    id: number;
    name: string;
    description?: string;
}

export interface User {
    id: number;
    name: string;
    email: string;
    picture?: string;
    userClass: number;
    userClassData: UserClassData;
    function?: string;
    inviteStatus?: string;
    isDisabled: boolean;
}

export interface IAuthenticatedUser {
    user: User;
    scopes: Array<string>;
    primaryOrganization: Organization;
    secondaryOrganizations: Array<Organization>;
}

const getAuthenticatedUser = async (): Promise<IAuthenticatedUser> => {
    const response = await axiosInstance.get(API_ENDPOINTS.AUTHENTICATED_USER());
    return response.data;
};

const useGetAuthenticatedUser = () =>
    useQuery({
        queryKey: ['authenticatedUser'],
        queryFn: () => getAuthenticatedUser(),
        staleTime: 60000,
        refetchOnMount: true,
    });

export default useGetAuthenticatedUser;
