import { IOptionAnyValue } from 'bricks/types';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import ProFormaSelector from 'views/Reports/components/ProFormaSelector/ProFormaSelector';
import { IComparisonReportData } from 'views/Reports/types';

export const ColumnHeader: React.FC<{ data: IComparisonReportData }> = ({ data }) => {
    const { dealId } = useParams<{ dealId: string }>(); // undefined for now there is no dealId in the url, make sure this works when available
    const [selectedProForma, setSelectedProForma] = useState<IOptionAnyValue>();

    const handleProFormaSelection = (option: IOptionAnyValue) => {
        setSelectedProForma(option);
    };

    return (
        data && (
            <>
                <div className="text-white h4">{data.dealName}</div>
                <div>Rental apartment • Development</div>
                <ProFormaSelector dealId={Number(dealId) || 1} selectedProForma={selectedProForma} onChangeProFormaSelection={handleProFormaSelection} />
            </>
        )
    );
};

export default ColumnHeader;
