import React, { forwardRef, useState, useEffect } from 'react';
import { Formik, Form, FormikProps } from 'formik';

import { NSDropdown, NSInput, NSLabel } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollar } from '@fortawesome/free-solid-svg-icons';
import { IOptionAnyValue } from 'bricks/types';
import useToast from 'hooks/useToast';
import RenderIf from 'components/RenderIf/RenderIf';
import NSSwitch from 'bricks/NSSwitch/NSSwitch';
import { useNavigate } from 'react-router-dom';
import { useCreateDeal } from './hooks/useCreateDeal';
import { IFormValues } from './types';
import { assetTypeOptions, CreateDealSchema, dealTypeOptions, defaultValues } from './constants';
import { useUpdateDeal } from './hooks/useUpdateDeal';
import { useGetCoordinates } from '../../queries/Mapbox/useGetCoordinates';

interface DealFormProps {
    setMapCoordinates: React.Dispatch<React.SetStateAction<{ center: { lat: number; lng: number } }>>;
    initialValues?: IFormValues;
}

const DealForm = forwardRef<FormikProps<IFormValues>, DealFormProps>(({ setMapCoordinates, initialValues }, ref) => {
    const navigate = useNavigate();
    const { showSuccess, showError, ToastContainerElement } = useToast();
    const handleToggle = (id: string, checked: boolean) => {
        setSwitchStates(prev => ({ ...prev, [id]: checked }));
    };

    const modifiedInitialValues = initialValues && {
        dealName: initialValues?.dealName || '',
        address: initialValues?.address || '',
        city: initialValues?.city || '',
        county: initialValues?.county || '',
        state: initialValues?.state || '',
        zipCode: initialValues?.zipCode || '',
        dealTypeId: initialValues.dealTypeId || -1,
        assetTypeId: initialValues.assetTypeId || 'default',
        acquisitionPrice: initialValues?.acquisitionPrice || '',
        latitude: initialValues?.latitude || null,
        longitude: initialValues?.longitude || null,
        coordinatesSwitch: initialValues?.coordinatesSwitch || false,
    };

    const [switchStates, setSwitchStates] = useState<{ [key: string]: boolean }>({
        requiredSwitch: true,
        coordinatesSwitch: modifiedInitialValues?.coordinatesSwitch || false,
    });
    const [dropdownStates, setDropdownStates] = useState<{
        dealTypeId: IOptionAnyValue;
        assetTypeId: IOptionAnyValue;
        subType: IOptionAnyValue;
    }>(() => ({
        dealTypeId: dealTypeOptions.find(option => option.value === (modifiedInitialValues?.dealTypeId || -1)) || {
            label: 'Select option',
            value: 'default',
        },
        assetTypeId: assetTypeOptions.find(option => option.value === (modifiedInitialValues?.assetTypeId || 'default')) || {
            label: 'Select option',
            value: 'default',
        },
        subType: { label: 'Select option', value: 'default' },
    }));

    const handleDropdownChange = (fieldName: keyof typeof dropdownStates, value: IOptionAnyValue, setFieldValue: (field: string, value: any) => void) => {
        setFieldValue(fieldName, value.id || value.value);

        if (fieldName === 'assetTypeId') {
            const requiresSubtype = value.value === 'has_subtype';
            setFieldValue('has_subtype', requiresSubtype);
            if (!requiresSubtype) {
                setFieldValue('subType', 'default'); // Reset subType if no subtype is required
            }
        }

        setDropdownStates(prev => ({ ...prev, [fieldName]: value }));
    };

    const isEditing = !!initialValues;
    const { mutate: createDeal } = useCreateDeal();
    const { mutate: updateDeal } = useUpdateDeal();

    const handleSubmit = async (values: IFormValues) => {
        if (isEditing && initialValues?.id) {
            updateDeal(
                {
                    orgId: '123e4567-e89b-12d3-a456-426614174000', // TODO Remove hardcoded ORG ID
                    dealId: Number(initialValues.id),
                    postData: values,
                },
                {
                    onSuccess: data => {
                        console.log('Deal updated successfully:', data);
                        navigate(`/deals`);
                        setTimeout(() => showSuccess('Deal updated successfully!'), 500);
                    },
                    onError: error => {
                        console.error('Error updating deal:', error);
                        showError('Failed to update deal. Please try again.');
                    },
                },
            );
        } else {
            createDeal(
                {
                    orgId: '123e4567-e89b-12d3-a456-426614174000', // TODO Remove hardcoded ORG ID
                    postData: values,
                },
                {
                    onSuccess: data => {
                        showSuccess('Deal created successfully!');
                        setTimeout(() => navigate(-1), 2000);
                    },
                    onError: error => {
                        console.error('Error creating deal:', error);
                        showError('Failed to create deal. Please try again.');
                    },
                },
            );
        }
    };

    const getFullAddress = (values: IFormValues) => {
        const { address, city, county, state, zipCode } = values;
        if (!address || !city || !state || !zipCode) return '';
        return `${address}, ${city}, ${county ? `${county}, ` : ''}${state} ${zipCode}`;
    };

    return (
        <div className="w-50 p-3 mr-2 CreateDeal__form">
            <div className="d-flex justify-content-between text-white mb-1">
                <div className="text-uppercase text-primary">General</div>
                <NSSwitch
                    id="requiredSwitch"
                    name="requiredSwitch"
                    checked={switchStates.requiredSwitch}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleToggle(e.target.id, e.target.checked)}
                    label="Only show required fields"
                />
            </div>
            <Formik<IFormValues>
                initialValues={(modifiedInitialValues as IFormValues) || defaultValues}
                validationSchema={CreateDealSchema}
                onSubmit={handleSubmit}
                innerRef={ref}
            >
                {({ errors, touched, values, setFieldValue, handleBlur, isSubmitting }) => {
                    const { data: coordinates } = useGetCoordinates(getFullAddress(values));

                    useEffect(() => {
                        if (coordinates && !switchStates.coordinatesSwitch) {
                            setMapCoordinates({
                                center: coordinates,
                            });
                        }
                    }, [coordinates]);

                    return (
                        <Form>
                            <NSInput
                                id="dealName"
                                type="text"
                                label="Deal name"
                                name="dealName"
                                placeholder="Enter deal name"
                                value={values.dealName}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('dealName', e.target.value)}
                                onBlur={handleBlur}
                                errorMsg={touched.dealName && errors.dealName ? errors.dealName : null}
                                showErrorMsg={!!(touched.dealName && errors.dealName)}
                                required
                            />
                            <RenderIf isTrue={!switchStates.coordinatesSwitch}>
                                <NSInput
                                    type="text"
                                    label="Address"
                                    name="address"
                                    placeholder="Enter address"
                                    value={values.address}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('address', e.target.value)}
                                    onBlur={handleBlur}
                                    errorMsg={touched.address && errors.address ? errors.address : null}
                                    showErrorMsg={!!(touched.address && errors.address)}
                                    required={!switchStates.coordinatesSwitch}
                                    labelClassName="mt-2"
                                />
                                <div className="d-flex my-2">
                                    <div className="w-50 mr-2">
                                        <NSInput
                                            type="text"
                                            label="City"
                                            name="city"
                                            placeholder="Enter city"
                                            value={values.city}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('city', e.target.value)}
                                            onBlur={handleBlur}
                                            className={touched.city && errors.city ? 'is-invalid' : ''}
                                            errorMsg={touched.city && errors.city ? errors.city : null}
                                            showErrorMsg={!!(touched.city && errors.city)}
                                            required={!switchStates.coordinatesSwitch}
                                        />
                                    </div>
                                    <div className="w-50 mr-2">
                                        <NSInput
                                            type="text"
                                            label="County"
                                            name="county"
                                            placeholder="Enter county"
                                            value={values.county}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('county', e.target.value)}
                                            onBlur={handleBlur}
                                            className={touched.county && errors.county ? 'is-invalid' : ''}
                                            errorMsg={touched.county && errors.county ? errors.county : null}
                                            showErrorMsg={!!(touched.county && errors.county)}
                                            required={!switchStates.coordinatesSwitch}
                                        />
                                    </div>
                                    <div className="mr-2">
                                        <NSInput
                                            type="text"
                                            label="State"
                                            name="state"
                                            placeholder="Enter state"
                                            value={values.state}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('state', e.target.value)}
                                            onBlur={handleBlur}
                                            className={touched.state && errors.state ? 'is-invalid' : ''}
                                            errorMsg={touched.state && errors.state ? errors.state : null}
                                            showErrorMsg={!!(touched.state && errors.state)}
                                            required={!switchStates.coordinatesSwitch}
                                        />
                                    </div>
                                    <div>
                                        <NSInput
                                            type="text"
                                            label="Zip"
                                            name="zipCode"
                                            placeholder="Enter zip"
                                            value={values.zipCode}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('zipCode', e.target.value)}
                                            onBlur={handleBlur}
                                            className={touched.zipCode && errors.zipCode ? 'is-invalid' : ''}
                                            errorMsg={touched.zipCode && errors.zipCode ? errors.zipCode : null}
                                            showErrorMsg={!!(touched.zipCode && errors.zipCode)}
                                            required={!switchStates.coordinatesSwitch}
                                        />
                                    </div>
                                </div>
                            </RenderIf>
                            <RenderIf isTrue={switchStates.coordinatesSwitch}>
                                <div className="d-flex my-2">
                                    <div className="w-50 mr-2">
                                        <NSInput
                                            type="number"
                                            label="Latitude"
                                            name="latitude"
                                            placeholder="Enter latitude"
                                            value={values.latitude}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue('latitude', e.target.value);
                                                if (setMapCoordinates && e.target.value && values.longitude) {
                                                    setMapCoordinates({
                                                        center: {
                                                            lat: Number(e.target.value),
                                                            lng: Number(values.longitude),
                                                        },
                                                    });
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            errorMsg={touched.latitude && errors.latitude ? errors.latitude : null}
                                            showErrorMsg={!!(touched.latitude && errors.latitude)}
                                            required={switchStates.coordinatesSwitch}
                                        />
                                    </div>
                                    <div className="w-50">
                                        <NSInput
                                            type="number"
                                            label="Longitude"
                                            name="longitude"
                                            placeholder="Enter longitude"
                                            value={values.longitude}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                setFieldValue('longitude', e.target.value);
                                                if (setMapCoordinates && values.latitude && e.target.value) {
                                                    setMapCoordinates({
                                                        center: {
                                                            lat: Number(values.latitude),
                                                            lng: Number(e.target.value),
                                                        },
                                                    });
                                                }
                                            }}
                                            onBlur={handleBlur}
                                            errorMsg={touched.longitude && errors.longitude ? errors.longitude : null}
                                            showErrorMsg={!!(touched.longitude && errors.longitude)}
                                            required={switchStates.coordinatesSwitch}
                                        />
                                    </div>
                                </div>
                            </RenderIf>
                            <NSSwitch
                                id="coordinatesSwitch"
                                name="coordinatesSwitch"
                                data-testid="coordinatesSwitch"
                                checked={switchStates.coordinatesSwitch}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    handleToggle(e.target.id, e.target.checked);
                                    setFieldValue('coordinatesSwitch', e.target.checked);
                                    // Clear address fields when switching to coordinates
                                    if (e.target.checked) {
                                        setFieldValue('address', '');
                                        setFieldValue('city', '');
                                        setFieldValue('county', '');
                                        setFieldValue('state', '');
                                        setFieldValue('zipCode', '');
                                    } else {
                                        setFieldValue('latitude', null);
                                        setFieldValue('longitude', null);
                                    }
                                }}
                                label="Use coordinates"
                                containerClassName="text-white"
                            />
                            <div className="CreateDeal__form__divider my-3" />
                            <div>
                                <div className="text-uppercase text-primary mb-1">Deal Information</div>
                                <div className="d-flex mt-2">
                                    <div className="w-50 mr-2">
                                        <NSLabel className="text-dark font-weight-normal">
                                            Deal type
                                            <span className="text-danger"> *</span>
                                        </NSLabel>
                                        <NSDropdown
                                            options={dealTypeOptions}
                                            selectedOption={dropdownStates.dealTypeId}
                                            handleSelectOption={option => handleDropdownChange('dealTypeId', option, setFieldValue)}
                                            isFullWidth
                                        />
                                        {touched.dealTypeId && errors.dealTypeId && <div className="text-danger">{errors.dealTypeId}</div>}
                                    </div>
                                    <div className="w-50">
                                        <NSLabel className="text-dark font-weight-normal">
                                            Asset class
                                            <span className="text-danger"> *</span>
                                        </NSLabel>
                                        <NSDropdown
                                            options={assetTypeOptions}
                                            selectedOption={dropdownStates.assetTypeId}
                                            handleSelectOption={option => {
                                                handleDropdownChange('assetTypeId', option, setFieldValue);
                                                setDropdownStates(prev => ({
                                                    ...prev,
                                                    subType: option.value === 'has_subtype' ? prev.subType : { label: '', value: '' },
                                                }));
                                            }}
                                            isFullWidth
                                        />
                                        {touched.assetTypeId && errors.assetTypeId && <div className="text-danger">{errors.assetTypeId}</div>}
                                    </div>
                                    <RenderIf isTrue={dropdownStates.assetTypeId.value === 'has_subtype'}>
                                        <div className="w-50 ml-2">
                                            <NSLabel className="text-dark font-weight-normal">
                                                Subtype
                                                <span className="text-danger"> *</span>
                                            </NSLabel>
                                            <NSDropdown
                                                options={[
                                                    { label: 'Subtype 1', value: '1' },
                                                    { label: 'Subtype 2', value: '2' },
                                                ]}
                                                selectedOption={
                                                    dropdownStates.subType.value ? dropdownStates.subType : { label: 'Select option', value: 'default' }
                                                }
                                                handleSelectOption={option => handleDropdownChange('subType', option, setFieldValue)}
                                                isFullWidth
                                            />
                                            {touched.subType && errors.subType && <div className="text-danger">{errors.subType}</div>}
                                        </div>
                                    </RenderIf>
                                </div>
                            </div>
                            <div className="CreateDeal__form__divider my-3" />
                            <div className="mb-3">
                                <div className="text-uppercase text-primary mb-1">Deal Metrics</div>
                                <NSInput
                                    label="Acquisition price"
                                    type="text"
                                    name="acquisitionPrice"
                                    placeholder="Enter amount"
                                    value={values.acquisitionPrice}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('acquisitionPrice', e.target.value)}
                                    prependInputAddon={<FontAwesomeIcon color="white" icon={faDollar} />}
                                    errorMsg={touched.acquisitionPrice && errors.acquisitionPrice ? errors.acquisitionPrice : null}
                                    showErrorMsg={!!(touched.acquisitionPrice && errors.acquisitionPrice)}
                                    onBlur={handleBlur}
                                    required
                                />
                                {ToastContainerElement}
                            </div>
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
});

export default DealForm;
