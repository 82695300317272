import React, { useState, useEffect } from 'react';
import { NSInput, NSModal } from 'bricks';
import useToast from 'hooks/useToast';
import { useRenameDealGroup } from 'views/Deals/hooks/useRenameDealGroup';
import { useQueryClient } from '@tanstack/react-query';

export interface IRenameDealGroupModalProps {
    toggle: () => void;
    isOpen: boolean;
    orgId: string;
    dealGroupId?: number;
    currentGroupName: string | null;
}

const RenameDealGroupModal = ({ toggle, isOpen, orgId, dealGroupId, currentGroupName }: IRenameDealGroupModalProps) => {
    const [newGroupName, setNewGroupName] = useState<string>('');

    const queryClient = useQueryClient();
    const { showSuccess, showError } = useToast();

    useEffect(() => {
        setNewGroupName(currentGroupName || '');
    }, [currentGroupName]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewGroupName(e.target.value);
    };

    const { mutate: renameDealGroup, error: renameError } = useRenameDealGroup();

    const handleRenameDealGroup = () => {
        if (!dealGroupId) {
            window.alert('Please select a group.');
            return;
        }
        renameDealGroup(
            {
                dealGroupId,
                orgId,
                postData: { name: newGroupName },
            },
            {
                onSuccess: () => {
                    showSuccess('Deal group renamed successfully!');
                    queryClient.invalidateQueries({ queryKey: ['dealGroups', orgId] });
                    toggle();
                },
                onError: () => {
                    showError(renameError?.message || 'Failed to rename deal group. Please try again.');
                },
            },
        );
    };

    return (
        <NSModal
            toggle={toggle}
            isOpen={isOpen}
            modalHeaderClassName="bg-primary modal-colored-header"
            modalHeaderText="Rename group"
            modalFooterButtonText="Rename"
            modalFooterIsButtonFunctionDisabled={newGroupName.trim().length === 0 || newGroupName === currentGroupName}
            modalFooterIsShown
            size="lg"
            modalFooterButtonFunction={handleRenameDealGroup}
            modalFooterCancelButtonFunction={() => setNewGroupName(currentGroupName || '')}
        >
            <NSInput
                id="newGroupName"
                type="text"
                label="Group name"
                name="newGroupName"
                placeholder="Enter new group name"
                value={newGroupName}
                onChange={handleInputChange}
                required
            />
        </NSModal>
    );
};

export default RenameDealGroupModal;
