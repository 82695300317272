import React, { createContext, useContext, useEffect, useState, useRef } from 'react';
import { io } from 'socket.io-client';
import { IProFormaData, IProFormaSourceTableRow, IProFormaUses } from 'views/ProFormaTable/types';
import { PRO_FORMA_TABS } from 'views/ProFormaTable/constants';
import { useSearchParams } from 'react-router-dom';
import { useAuth0TokenContext } from '../../../../../contexts/Auth0TokenContext';
import { config } from '../../../../../config';

const joinRoom = (socket: any, roomId: string) => {
    return new Promise((resolve, reject) => {
        socket.emit('join_room', roomId, (response: any) => {
            if (response && response.error) {
                console.error('Error joining room:', response.error);
                reject(response.error);
            } else {
                console.log('Successfully joined room:', roomId);
                resolve(response);
            }
        });
    });
};

const getProForma = (socket: any, roomId: string, proFormaId: number) => {
    return new Promise((resolve, reject) => {
        socket.emit('get_pro_forma', { room: roomId, pro_forma_id: proFormaId });

        socket.on('pro_forma:fetched', (data: any) => {
            if (data && data.error) {
                console.error('Error getting pro forma data:', data.error);
                reject(data.error);
            }
            resolve(data.pro_forma);
        });
    });
};

const getProFormaUses = (socket: any, roomId: string, proFormaId: number) => {
    return new Promise((resolve, reject) => {
        socket.emit('get_pro_forma_uses', { room: roomId, pro_forma_id: proFormaId });

        socket.on('pro_forma:uses:fetched', (data: any) => {
            if (data && data.error) {
                console.error('Error getting pro forma uses:', data.error);
                reject(data.error);
            }
            resolve(data.uses);
        });
    });
};

const getProFormaSources = (socket: any, roomId: string, proFormaId: number) => {
    return new Promise((resolve, reject) => {
        socket.emit('get_pro_forma_sources', { room: roomId, pro_forma_id: proFormaId });

        socket.on('pro_forma:sources:fetched', (data: any) => {
            if (data && data.error) {
                console.error('Error getting pro forma sources:', data.error);
                reject(data.error);
            }
            resolve(data.sources);
        });
    });
};

const defaultValues: IProFormaSocketContext = {
    handleUpdateProForma: () => {},
};

const ProFormaSocketContext = createContext<IProFormaSocketContext>(defaultValues);

interface IProFormaSocketProviderProps {
    orgId: string;
    dealId: number;
    proFormaId: number;
    children: React.ReactNode;
}

const ProFormaSocketProvider = ({ orgId, dealId, proFormaId, children }: IProFormaSocketProviderProps) => {
    const roomId = `${orgId}/deal/${dealId}/pro_forma/${proFormaId}`;
    const socketRef = useRef<any>(null);

    const [searchParams] = useSearchParams();
    const currentTab = searchParams.get('tab') || '';

    const [proForma, setProForma] = useState({});
    const [uses, setUses] = useState<IProFormaUses[]>([]);
    const [sources, setSources] = useState<IProFormaSourceTableRow[]>([]);

    const { isAuthenticated, accessToken } = useAuth0TokenContext();

    const fetchUses = async () => {
        try {
            const updatedUses = await getProFormaUses(socketRef.current, roomId, proFormaId);
            setUses(updatedUses as IProFormaUses[]);
        } catch (error) {
            console.error('Error fetching uses:', error);
        }
    };

    const fetchSources = async () => {
        try {
            const updatedSources = await getProFormaSources(socketRef.current, roomId, proFormaId);
            setSources(updatedSources as IProFormaSourceTableRow[]);
        } catch (error) {
            console.error('Error fetching sources:', error);
        }
    };

    useEffect(() => {
        if (isAuthenticated && accessToken) {
            const socket = io(config.webSocketBaseUrl, {
                transports: ['websocket'],
                path: '/api/socket.io',
                auth: {
                    token: accessToken,
                },
                rejectUnauthorized: config.rejectUnauthorizedWebsocket,
            });

            socket.on('connect', async () => {
                console.log('Socket ID:', socket.id);
                console.log('Joining room:', roomId);

                try {
                    await joinRoom(socket, roomId);
                    console.log('Getting pro forma data:', proFormaId);
                    const updatedProForma = await getProForma(socket, roomId, proFormaId);
                    console.log('Pro forma data:', updatedProForma);
                    setProForma(updatedProForma as IProFormaData);
                } catch (error) {
                    console.error('Error during connection workflow:', error);
                }
            });

            socket.on('pro_forma:updated', data => {
                console.log('The pro forma was updated:', data);

                const responseData = data as IProFormaData;

                setProForma(prevState => ({ ...prevState, ...responseData }));
            });

            socket.on('connect_error', error => {
                console.error('WebSocket connection error:', error);
            });

            socketRef.current = socket;

            return () => {
                console.log('Cleaning up WebSocket connection');
                if (socket) {
                    socket.disconnect();
                    socketRef.current = null;
                }
            };
        }

        return () => {};
    }, [isAuthenticated, accessToken, config.webSocketBaseUrl]);

    useEffect(() => {
        if (currentTab === PRO_FORMA_TABS.DEVELOPMENT.name && socketRef.current) {
            fetchUses();
            fetchSources();
        }
    }, [currentTab, socketRef.current]);

    const handleUpdateProForma = (key: string, response: string | number) => {
        if (socketRef.current) {
            socketRef.current.emit('update_pro_forma', {
                room: roomId,
                pro_forma_id: proFormaId,
                data: { [key]: response },
            });
        } else {
            console.error('WebSocket connection is not established');
        }
    };

    return <ProFormaSocketContext.Provider value={{ proForma, handleUpdateProForma, uses, sources }}>{children}</ProFormaSocketContext.Provider>;
};

const useProFormaSocketContext = () => useContext(ProFormaSocketContext);

export { ProFormaSocketContext, ProFormaSocketProvider, useProFormaSocketContext };

interface IProFormaSocketContext {
    proForma?: any;
    handleUpdateProForma: (key: string, value: string | number) => void;
    uses?: IProFormaUses[];
    sources?: IProFormaSourceTableRow[];
}
