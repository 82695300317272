import React, { useEffect } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import './PageTitle.scss';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import RenderIf from '../RenderIf/RenderIf';
import { NSBreadcrumbBackLink } from '../../bricks';
import { URLS } from '../../services/urls';

export interface IPageTitleProps {
    title: string | React.ReactNode;
    subtitle?: string | React.ReactNode;
    displayBreadcrumb?: boolean;
    boldTitle?: boolean;
    dealName?: string | null;
    pageMetaDataTitle?: string;
    children?: React.ReactNode;
    breadcrumbBackLinkText?: string;
    breadcrumbBackLinkCallback?: () => void;
}

const PageTitle = ({
    title = '',
    subtitle,
    displayBreadcrumb = false,
    boldTitle = false,
    dealName = '',
    pageMetaDataTitle = '',
    breadcrumbBackLinkText = '',
    breadcrumbBackLinkCallback = undefined,
    children = undefined,
}: IPageTitleProps) => {
    const { dealId } = useParams<{ dealId: string }>();

    const pageName = pageMetaDataTitle || String(title);
    const dealPagePath = `/deal/${dealId}`;
    const isDealPagePath = window.location.pathname === dealPagePath;

    useEffect(() => {
        window.document.title = dealName && pageName ? `${dealName} | ${pageName}` : pageName;
        return () => {
            window.document.title = 'NSDeal';
        };
    }, [dealName, pageName]);

    return (
        <div className="pageTitle d-sm-flex justify-content-between align-items-end pb-2">
            <div>
                <RenderIf isTrue={displayBreadcrumb}>
                    <Breadcrumb
                        cssModule={{
                            breadcrumb: 'breadcrumb d-flex align-items-center m-0 p-0',
                        }}
                    >
                        <BreadcrumbItem>
                            <Link to={URLS.DEALS.HOME}>Deal Pipeline</Link>
                        </BreadcrumbItem>
                        <RenderIf isTrue={dealName}>
                            <FontAwesomeIcon icon={faChevronRight} className="px-2 font-10" />
                            <BreadcrumbItem>{isDealPagePath ? dealName : <Link to={dealPagePath}>{dealName}</Link>}</BreadcrumbItem>
                        </RenderIf>
                        <RenderIf isTrue={!isDealPagePath}>
                            <FontAwesomeIcon icon={faChevronRight} className="px-2 font-10" />
                            <BreadcrumbItem>{title}</BreadcrumbItem>
                        </RenderIf>
                    </Breadcrumb>
                </RenderIf>
                <RenderIf isTrue={breadcrumbBackLinkCallback && !displayBreadcrumb}>
                    <NSBreadcrumbBackLink message={breadcrumbBackLinkText} callback={breadcrumbBackLinkCallback!} isSmall={false} />
                </RenderIf>
                <div className="d-flex align-items-center mt-1">
                    <div>
                        <h3 className={`text-white my-0 ${boldTitle ? 'font-weight-bold' : ''}`}>{title}</h3>
                        {subtitle && <div>{subtitle}</div>}
                    </div>
                </div>
            </div>
            <div className="mt-1">{children}</div>
        </div>
    );
};

export default PageTitle;
