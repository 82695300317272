import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { IBackOfEnvelopeResponse, IBackOfEnvelopeQueryParams } from '../types';

const getBackOfEnvelopeScenarios = async ({ orgId, dealId }: IBackOfEnvelopeQueryParams & { dealId?: number }): Promise<IBackOfEnvelopeResponse> => {
    const queryParams = dealId ? `?deal_id=${dealId}` : '';
    const response = await axiosInstance.get(`${API_ENDPOINTS.BACK_OF_ENVELOPE_SCENARIOS(orgId)}${queryParams}`);
    return response.data;
};

export const useGetBackOfEnvelopeScenarios = ({ orgId, dealId }: IBackOfEnvelopeQueryParams & { dealId?: number }) =>
    useQuery<IBackOfEnvelopeResponse>({
        queryKey: ['back-of-envelope-scenarios', orgId, dealId],
        queryFn: () => getBackOfEnvelopeScenarios({ orgId, dealId }),
        staleTime: 5000,
        refetchOnMount: true,
    });
