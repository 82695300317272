import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import useToast from 'hooks/useToast';
import React, { useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { URLS } from 'services/urls';
import { useDeleteReports } from 'views/Reports/hooks';
import { getReportsQueryKey } from 'views/Reports/hooks/useGetReports';
import DeleteModalConfirmationModal from '../DeleteReportConfirmationModal/DeleteModalConfirmationModal';

export interface IReportActionsMenuProps {
    reportId: number;
    orgId: string;
}

const ReportActionsMenu = ({ reportId, orgId }: IReportActionsMenuProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const navigate = useNavigate();
    const { showSuccess, showError } = useToast();
    const queryClient = useQueryClient();
    const { mutate: deleteReports } = useDeleteReports();

    const handleClickView = () => {
        const path = generatePath(URLS.REPORTS.REPORT_PAGE, { reportId });
        navigate(path);
    };

    const handleClickDelete = () => {
        deleteReports(
            { orgId, reportIds: [reportId] },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: [getReportsQueryKey, orgId] });
                    showSuccess('Report deleted successfully');
                },
                onError: () => {
                    showError('Failed to delete report. Please try again.');
                },
            },
        );
    };

    const handleEdit = () => {
        const path = generatePath(URLS.REPORTS.REPORT_PAGE, { reportId });
        navigate(path, {
            state: { isEdit: true },
        });
    };

    return (
        <>
            <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} direction="down">
                <DropdownToggle tag="div" className="cursor--pointer">
                    <FontAwesomeIcon data-testid="ellipsis-icon" icon={faEllipsisVertical} />
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={handleClickView} data-testid="view-action">
                        View
                    </DropdownItem>
                    <DropdownItem onClick={handleEdit} data-testid="edit-action">
                        Edit
                    </DropdownItem>
                    <DropdownItem onClick={() => setIsDeleteModalOpen(true)} data-testid="delete-action">
                        Delete
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
            <DeleteModalConfirmationModal
                isOpen={isDeleteModalOpen}
                toggle={() => setIsDeleteModalOpen(!isDeleteModalOpen)}
                handleDelete={handleClickDelete}
                title="Delete report"
            />
        </>
    );
};
export default ReportActionsMenu;
