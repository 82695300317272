import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { IDealsQueryParams, IDealsResponse } from '../types';

const getDeals = async ({ pageSize, currentPage, orgId, searchValue, sortBy, sortOrder, groupId }: IDealsQueryParams): Promise<IDealsResponse> => {
    const includeSearch = searchValue ? `&search=${searchValue}` : '';
    const includeSort = sortBy && sortOrder ? `&${sortBy}=${sortOrder}` : '';
    const includeGroupId = groupId ? `&deal_group_id=${groupId}` : '';
    const response = await axiosInstance.get(
        `${API_ENDPOINTS.DEALS(orgId)}?page=${currentPage - 1}&page_size=${pageSize}${includeSearch}${includeSort}${includeGroupId}`,
    );
    return response.data;
};

export const useGetDeals = ({ pageSize, currentPage, orgId, searchValue, sortBy, sortOrder, groupId }: IDealsQueryParams) =>
    useQuery({
        queryKey: ['deals', orgId, currentPage, pageSize, searchValue, sortBy, sortOrder, groupId],
        queryFn: () => getDeals({ pageSize, currentPage, orgId, searchValue, sortBy, sortOrder, groupId }),
        staleTime: 5000,
        refetchOnWindowFocus: true,
        refetchOnMount: true,
    });
