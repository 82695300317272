import { NSSelect } from 'bricks';
import { customStyles } from 'bricks/NSSelect/NSSelect';
import { IOptionAnyValue } from 'bricks/types';
import classNames from 'classnames';
import { formatUTCtoLocal } from 'ns_libs/formatter';
import React, { useState } from 'react';
import { generateDateRange } from 'views/ProFormaTable/helpers';

export interface IPeriodsSelectProps {
    dateRange: string[];
    className?: string;
}

// all local date formatting is done assuming dates are passed in UTC
const PeriodsSelect = ({ className, dateRange }: IPeriodsSelectProps) => {
    const [selectedDate, setSelectedDate] = useState<IOptionAnyValue>({
        value: formatUTCtoLocal(dateRange[0], 'yyyy-MM-dd'),
        label: formatUTCtoLocal(dateRange[1], 'MMM yyyy'),
    });

    const dateOptions = generateDateRange(dateRange);

    return (
        <NSSelect
            name="periodsSelect"
            className={className}
            options={dateOptions}
            value={dateOptions.find(option => option.value === selectedDate.value)}
            onChange={option => setSelectedDate(option?.value)}
            isClearable={false}
            isSearchable={false}
            formatOptionLabel={(option: { label: string; value: string }, context: { context: string }) => {
                const index = dateOptions.findIndex(opt => opt.value === option.value);

                if (['menu', 'value'].includes(context.context)) {
                    return (
                        <div className={classNames('d-flex flex-column', { 'py-1': context.context === 'value' })}>
                            <small>Period {index + 1}</small>
                            <div className={classNames({ 'text-primary': context.context === 'value' })}>{option.label}</div>
                        </div>
                    );
                }

                return option.label;
            }}
            styles={{
                ...customStyles,
                control: provided => ({
                    ...provided,
                    color: '#aab8c5',
                    backgroundColor: '#30343e',
                    borderRadius: 4,
                    cursor: 'pointer',
                    border: 'none',
                    width: '120px',
                    height: '52px',
                    '&:hover': {
                        border: 'none',
                        backgroundColor: '#464e5b',
                    },
                }),
                dropdownIndicator: (provided, state) => ({
                    ...provided,
                    color: state.isFocused ? '#F1F1F1' : '#30343e',
                    '&:hover': {
                        color: '#F1F1F1',
                        backgroundColor: '#464e5b',
                    },
                }),
            }}
        />
    );
};

export default PeriodsSelect;
