import React, { useCallback, useRef, useState } from 'react';
import DraggableTable from 'components/DraggableTable/DraggableTable';
import { useNavigate } from 'react-router-dom';
import useToast from 'hooks/useToast';
import { Deal } from '../types';
import { createColumns } from './helper';
import { COLUMN_OPTIONS } from '../constants';
import PageSizeDropdown from './PageSizeDropdown/PageSizeDropdown';
import PaginationButtons from './PaginationButtons/PaginationButtons';
import CreateGroupModal from './BulkActions/CreateGroupModal';
import AssociateDealsToGroupModal from './BulkActions/AssociateDealsToGroupModal';
import DeleteDealModal from './BulkActions/DeleteDealModal';

interface IDealsTableProps {
    deals: Deal[];
    visibleColumns: Record<string, boolean>;
    className?: string;
    pageSize: number;
    currentPage: number;
    totalItems: number;
    selectedDeal?: Deal;
    setPageSize: (size: number) => void;
    setCurrentPage: (page: number) => void;
    handleSelectDeal?: (deal: Deal) => void;
    onAscendingSort: (columnName: string) => void;
    onDescendingSort: (columnName: string) => void;
}

const DealsTable: React.FC<IDealsTableProps> = ({
    deals,
    visibleColumns,
    className,
    pageSize,
    currentPage,
    totalItems,
    selectedDeal,
    setPageSize,
    setCurrentPage,
    onAscendingSort,
    onDescendingSort,
    handleSelectDeal = _ => {},
}) => {
    const [openCreateGroupModal, setOpenCreateGroupModal] = useState(false);
    const [openAssociateDealsToGroupModal, setOpenAssociateDealsToGroupModal] = useState(false);
    const [openDeleteDealModal, setOpenDeleteDealModal] = useState(false);
    const [selectedDeals, setSelectedDeals] = useState<Deal[]>([]);
    const allColumns = createColumns(COLUMN_OPTIONS);
    const tableRef = useRef<{ resetRowSelection: () => void }>(null);
    const { ToastContainerElement } = useToast();

    const navigate = useNavigate();

    const handleAscendingSortPressed = (columnName: string | number | symbol) => {
        onAscendingSort(columnName as string);
    };

    const handleDescendingSortPressed = (columnName: string | number | symbol) => {
        onDescendingSort(columnName as string);
    };

    const handleRowClick = useCallback(
        (rowData: Deal) => {
            handleSelectDeal(rowData);
        },
        [handleSelectDeal],
    );

    const toggleOpenCreateGroupModal = () => setOpenCreateGroupModal(!openCreateGroupModal);
    const toggleOpenAssociateDealsToGroupModal = () => setOpenAssociateDealsToGroupModal(!openAssociateDealsToGroupModal);
    const toggleDeleteDealModal = () => setOpenDeleteDealModal(!openDeleteDealModal);
    const handleDealGroupsActionsSuccess = () => tableRef.current?.resetRowSelection();

    return (
        <div className={className}>
            <DraggableTable
                data={deals}
                ref={tableRef}
                columns={allColumns}
                columnVisibility={visibleColumns}
                selectedItem={selectedDeal}
                onAscendingSortPressed={handleAscendingSortPressed}
                onDescendingSortPressed={handleDescendingSortPressed}
                onAddToExistingGroup={(selectedRows: Deal[]) => {
                    setSelectedDeals(selectedRows);
                    toggleOpenAssociateDealsToGroupModal();
                }}
                onCreateGroupFromSelection={(selectedRows: Deal[]) => {
                    setSelectedDeals(selectedRows);
                    toggleOpenCreateGroupModal();
                }}
                onCreateReport={(selectedRows: Deal[]) => {
                    const baseUrl = '/reports';
                    const params = new URLSearchParams();
                    params.set('create', 'true');

                    const selectedDealIds = selectedRows.map(deal => deal.id);
                    if (selectedDealIds && selectedDealIds.length > 0) {
                        params.set('dealIds', selectedDealIds.join(','));
                    }

                    navigate(`${baseUrl}?${params.toString()}`);
                }}
                onDelete={(selectedRows: Deal[]) => {
                    setSelectedDeals(selectedRows);
                    toggleDeleteDealModal();
                }}
                onRowClick={handleRowClick}
            />
            <div className="d-flex justify-content-between align-items-center pt-2">
                <PageSizeDropdown pageSize={pageSize} onPageSizeChange={setPageSize} pageSizeOptions={[5, 10, 20, 50]} />
                <PaginationButtons
                    currentPage={currentPage}
                    totalPages={Math.ceil(totalItems / pageSize)}
                    onPageChange={setCurrentPage}
                    itemsPerPage={pageSize}
                    totalItems={totalItems}
                />
            </div>
            <CreateGroupModal
                toggle={toggleOpenCreateGroupModal}
                deals={selectedDeals}
                isOpen={openCreateGroupModal}
                onCreationSuccess={handleDealGroupsActionsSuccess}
            />
            <AssociateDealsToGroupModal
                toggle={toggleOpenAssociateDealsToGroupModal}
                deals={selectedDeals}
                isOpen={openAssociateDealsToGroupModal}
                onAsociationSuccess={handleDealGroupsActionsSuccess}
            />
            <DeleteDealModal toggle={toggleDeleteDealModal} isOpen={openDeleteDealModal} deals={selectedDeals} />
            {ToastContainerElement}
        </div>
    );
};

export default DealsTable;
