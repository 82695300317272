/* eslint-disable operator-linebreak */
import { ASSET_TYPE_MAPPING, COST_BASIS_TYPES, LOAN_CALCULATION_TYPES, UNIT_OF_TIME_TYPES } from './constants';
import { BOESlideoutFormValues, IBackOfEnvelopePayload } from './types';

export const prepareBackOfEnvelopePayload = (formikValues: BOESlideoutFormValues): IBackOfEnvelopePayload => {
    const normalizeValue = (value: number | null | undefined): number | undefined => (value !== null && value !== undefined ? value : undefined);

    const loanCalculationField = (() => {
        switch (formikValues.selectedLoanCalculationOptions?.value) {
            case LOAN_CALCULATION_TYPES.LTC:
                return { loanToCost: formikValues.contructionFinancing ? formikValues.contructionFinancing / 100 : undefined };
            case LOAN_CALCULATION_TYPES.LTV:
                return { loanToValue: formikValues.contructionFinancing ? formikValues.contructionFinancing / 100 : undefined };
            case LOAN_CALCULATION_TYPES.DY:
                return { debtYield: formikValues.contructionFinancing ? formikValues.contructionFinancing / 100 : undefined };
            default:
                return {};
        }
    })();

    return {
        name: formikValues.name,
        dealId: formikValues.dealId,
        propertyType: ASSET_TYPE_MAPPING[formikValues.selectedAssetType.value],
        builtSf: formikValues.grossSquareFootage!,
        buildingEfficiency: formikValues.efficiency ? formikValues.efficiency / 100 : 0,
        monthlyRentPricePerUnit: formikValues.rentPriceSubdiv === 'unit' ? normalizeValue(formikValues.rentPrice) : undefined,
        monthlyRentPricePerSf: formikValues.rentPriceSubdiv === 'sf' ? normalizeValue(formikValues.rentPrice) : undefined,
        rentPriceTime: formikValues.rentPriceTime,
        rentPriceSubdiv: formikValues.rentPriceSubdiv,
        numberOfUnits: formikValues.units!,
        monthlyExpenseCostPerUnit: formikValues.expenseCostSubdiv === 'unit' ? normalizeValue(formikValues.expenses) : undefined,
        monthlyExpenseCostPerSf: formikValues.expenseCostSubdiv === 'sf' ? normalizeValue(formikValues.expenses) : undefined,
        expenseCostTime: formikValues.expenseCostTime,
        expenseCostSubdiv: formikValues.expenseCostSubdiv,
        assumedExitCapRate: formikValues.exitCapRate ? formikValues.exitCapRate / 100 : 0,
        acquisition: formikValues.acquisitionCosts!,
        hardCostsPerUnit: formikValues.hardCostsSubdiv === 'unit' ? normalizeValue(formikValues.hardCosts) : undefined,
        hardCostsPerSf: formikValues.hardCostsSubdiv === 'sf' ? normalizeValue(formikValues.hardCosts) : undefined,
        hardCostsSubdiv: formikValues.hardCostsSubdiv,
        softCostsPerUnit: formikValues.softCostsSubdiv === 'unit' ? normalizeValue(formikValues.softCosts) : undefined,
        softCostsPerSf: formikValues.softCostsSubdiv === 'sf' ? normalizeValue(formikValues.softCosts) : undefined,
        softCostsSubdiv: formikValues.softCostsSubdiv,
        developerFeeRate: formikValues.developerFee ? formikValues.developerFee / 100 : 0,
        annualPercentageRate: formikValues.apr ? formikValues.apr / 100 : 0,
        averageLoanBalance: formikValues.avgLoanBalance ? formikValues.avgLoanBalance / 100 : 0,
        loanTermMonths: formikValues.loanTerm!,
        loanTermTime: formikValues.loanTermTime, // Backend is multiplying it
        ...loanCalculationField,
    };
};

export const calculateBOEValues = (values: BOESlideoutFormValues) => {
    const rentTimeMultiplier = values.rentPriceTime === UNIT_OF_TIME_TYPES.MONTHLY ? 12 : 1;
    const expensesTimeMultiplier = values.expenseCostTime === UNIT_OF_TIME_TYPES.MONTHLY ? 12 : 1;

    const netRentable = (values.grossSquareFootage ?? 0) * ((values.efficiency ?? 0) / 100);
    const sfPerUnit = values.units ? netRentable / values.units : 0;

    const grossPotentialRent =
        values.rentPriceSubdiv === COST_BASIS_TYPES.PER_UNIT
            ? (values.rentPrice ?? 0) * (values.units ?? 0) * rentTimeMultiplier
            : (values.rentPrice ?? 0) * netRentable * rentTimeMultiplier;

    const expenseTotal =
        values.expenseCostSubdiv === COST_BASIS_TYPES.PER_UNIT
            ? (values.expenses ?? 0) * (values.units ?? 0) * expensesTimeMultiplier
            : (values.expenses ?? 0) * netRentable * expensesTimeMultiplier;

    const netOperatingIncome = grossPotentialRent - expenseTotal;
    const exitValuation = values.exitCapRate && values.exitCapRate !== 0 ? netOperatingIncome / ((values.exitCapRate ?? 1) / 100) : 0;

    const hardCostsTotal =
        values.hardCostsSubdiv === COST_BASIS_TYPES.PER_UNIT
            ? (values.hardCosts ?? 0) * (values.units ?? 0)
            : (values.hardCosts ?? 0) * (values.grossSquareFootage ?? 0);

    const softCostsTotal =
        values.softCostsSubdiv === COST_BASIS_TYPES.PER_UNIT
            ? (values.softCosts ?? 0) * (values.units ?? 0)
            : (values.softCosts ?? 0) * (values.grossSquareFootage ?? 0);

    const calculateLoanAmount = (totalDevelopmentCosts: number) => {
        const constructionFinancing = values.contructionFinancing ?? 0;
        const marketCapRate = values.marketCapRate ?? 0;

        switch (values.selectedLoanCalculationOptions?.value) {
            case LOAN_CALCULATION_TYPES.LTV:
                return marketCapRate === 0 ? 0 : (netOperatingIncome / (marketCapRate / 100)) * (constructionFinancing / 100);

            case LOAN_CALCULATION_TYPES.LTC:
                return (constructionFinancing / 100) * totalDevelopmentCosts;
            case LOAN_CALCULATION_TYPES.DY:
                return constructionFinancing === 0 ? 0 : netOperatingIncome / (constructionFinancing / 100);

            default:
                return 0;
        }
    };

    return {
        netRentable,
        sfPerUnit,
        grossPotentialRent,
        expenseTotal,
        netOperatingIncome,
        exitValuation,
        calculateLoanAmount,
        hardCostsTotal,
        softCostsTotal,
    };
};

export const calculateIterativeValues = (
    values: BOESlideoutFormValues,
    hardCostsTotal: number,
    softCostsTotal: number,
    netOperatingIncome: number,
    exitValuation: number,
    calculateLoanAmount: (totalDevelopmentCosts: number) => number,
) => {
    const maxIterations = 100;
    const convergenceThreshold = 0.01;
    let iterations = 0;

    let developerFeeTotal = 0;
    let prevDeveloperFeeTotal = 0;

    let interestReserveTotal = 0;
    let loanAmount = 0;
    let equityCost = 0;
    let returnOnCost = 0;
    let returnOnEquity = 0;
    let profitReturn = 0;
    let totalDevelopmentCosts = 0;

    while (iterations < maxIterations) {
        totalDevelopmentCosts = (values.acquisitionCosts ?? 0) + hardCostsTotal + softCostsTotal + developerFeeTotal + interestReserveTotal;
        developerFeeTotal = ((values.developerFee ?? 0) / 100) * totalDevelopmentCosts;

        loanAmount = calculateLoanAmount(totalDevelopmentCosts);

        const normalizedLoanTerm = values.loanTermTime === UNIT_OF_TIME_TYPES.MONTHLY ? (values.loanTerm ?? 0) / 12 : (values.loanTerm ?? 0);

        interestReserveTotal = loanAmount * ((values.apr ?? 0) / 100) * normalizedLoanTerm * ((values.avgLoanBalance ?? 0) / 100);

        equityCost = totalDevelopmentCosts - loanAmount;

        returnOnCost = totalDevelopmentCosts !== 0 ? netOperatingIncome / totalDevelopmentCosts : 0;

        returnOnEquity = equityCost !== 0 ? netOperatingIncome / equityCost : 0;

        profitReturn = exitValuation - totalDevelopmentCosts;

        if (Math.abs(developerFeeTotal - prevDeveloperFeeTotal) < convergenceThreshold) {
            break;
        }
        prevDeveloperFeeTotal = developerFeeTotal;
        iterations++;
    }

    return {
        developerFeeTotal,
        interestReserveTotal,
        loanAmount,
        equityCost,
        returnOnCost,
        returnOnEquity,
        profitReturn,
        totalDevelopmentCosts,
    };
};
