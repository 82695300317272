/* eslint-disable no-unused-vars */
export enum AggregateReportProperties {
    TOTAL_DEVELOPMENT_COST = 'Total development cost (before interest reserve)',
    TOTAL_INTEREST_RESERVE = 'Total interest reserve',
    TOTAL_CONSTRUCTION_LOAN_DRAWS = 'Total construction loan draws',
    PERMANENT_LOAN_FUNDING = 'Permanent loan funding',
    CASH_FLOW_FROM_OPERATIONS = 'Cash flow from operations',
    PERMANENT_LOAN_DEBT_SERVICE = 'Permanent loan debt service',
    NET_SALE_PROCEEDS = 'Net sale proceeds',
    CONSTRUCTION_LOAN_PAYOFF = 'Construction loan payoff',
    PERMANENET_LOAN_PAYOFF = 'Permanent loan payoff',
    EQUITY_PROCEEDS_FROM_CAPITAL_EVENTS = 'Equity proceeds from capital event(s)',
    NET_UNLEVERED_CASH_FLOW = 'Net unlevered cash flow',
    NET_LEVERED_CASH_FLOW = 'Net levered cash flow',
}

export enum Periods {
    MONTHLY = 'Monthly',
    QUARTERLY = 'Quarterly',
    ANNUALLY = 'Annually',
}

export const PERIOD_OPTIONS = [
    { label: Periods.MONTHLY, value: Periods.MONTHLY },
    { label: Periods.QUARTERLY, value: Periods.QUARTERLY },
    { label: Periods.ANNUALLY, value: Periods.ANNUALLY },
];
