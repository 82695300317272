/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { createBrowserRouter, RouterProvider, createRoutesFromElements, Route, Outlet } from 'react-router-dom';
import LayoutWithNavbar from 'components/LayoutWithNavbar/LayoutWithNavbar';
import DealDashboard from 'views/DealDashboard/DealDashboard';
import CreateDeal from 'views/Deals/CreateDeal/CreateDeal';
import ScenarioComparison from 'views/ScenarioComparison/ScenarioComparison';
import ProFormaWizard from 'views/ProFormaWizard/ProFormaWizard';
import ReportBuilder from 'views/ReportBuilder/ReportBuilder';
import ProFormaTable from './views/ProFormaTable/ProFormaTable';
import Reports from './views/Reports/Reports';
import Counter from './views/Counter/Counter.jsx';
import Tiff from './views/Tiff/Tiff';
import NSRoute from './components/NSRoute/NSRoute';
import Deals from './views/Deals/Deals';
import Utilities from './views/Utilities/Utilities';
import { URLS } from './services/urls';
import ProFormaHome from './views/ProFormaHome/ProFormaHome';
import { ReportPageRouter } from './views/Reports/ReportPageRouter';
import { BackOfEnvelopeProvider } from './contexts/BackOfEnvelopeContext';
import { ReportPreview } from './views/Reports/ReportTypes/ReportPreview';

const NotFound = () => <div>404 - Page Not Found</div>;

// Needed so that navbar and deals dashboard have acces to router params (dealId)
const BackOfEnvelopeWrapper = () => {
    return (
        <BackOfEnvelopeProvider>
            <Outlet />
        </BackOfEnvelopeProvider>
    );
};

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route
                element={
                    <BackOfEnvelopeProvider>
                        <LayoutWithNavbar />
                    </BackOfEnvelopeProvider>
                }
            >
                <Route path="/" element={<Counter />} />
                <Route element={<NSRoute />}>
                    <Route path={URLS.TIFF} element={<Tiff />} />
                    <Route path={URLS.REPORTS.HOME} element={<Reports />} />
                    <Route path={URLS.REPORTS.NEW} element={<ReportPageRouter />} />
                    <Route path={URLS.REPORTS.REPORT_PAGE} element={<ReportPageRouter />} />
                    <Route path={URLS.REPORTS.REPORT_PREVIEW} element={<ReportPreview />} />
                    <Route path={URLS.REPORT_BUILDER} element={<ReportBuilder />} />
                    <Route path={URLS.DEALS.HOME} element={<Deals />} />
                    <Route element={<BackOfEnvelopeWrapper />}>
                        <Route path={URLS.DEAL_DASHBOARD.HOME} element={<DealDashboard />} />
                        <Route path={URLS.PRO_FORMA.HOME} element={<ProFormaHome />} />
                        <Route path={URLS.PRO_FORMA.TABLE} element={<ProFormaTable />} />
                    </Route>
                    <Route path={URLS.DEALS.COMPARISON} element={<ScenarioComparison />} />
                    <Route path={URLS.DEALS.FORM} element={<CreateDeal />} />
                    <Route path={URLS.UTILITIES} element={<Utilities />} />
                    <Route path={URLS.PRO_FORMA.HOME} element={<ProFormaHome />} />
                    <Route path={URLS.PRO_FORMA.TABLE} element={<ProFormaTable />} />
                    <Route path={URLS.PRO_FORMA.WIZARD} element={<ProFormaWizard />} />
                </Route>
                <Route path="*" element={<NotFound />} />
            </Route>
        </>,
    ),
);

const AppRouter = () => <RouterProvider router={router} />;

export default AppRouter;
