import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';

// TODO move this out of the codebase
// eslint-disable-next-line import/prefer-default-export
export const mapboxAccessToken = 'pk.eyJ1Ijoibm9ydGhzcHlyZSIsImEiOiJja2g2Z2IxaWMwM25wMnhxa20zeWNrYmNoIn0.R7ba3gfNaKgq5u04q7JUdQ';

interface IGeocodeQueryParams {
    address: string;
}

const getMapboxGeocode = async ({ address }: IGeocodeQueryParams): Promise<any> => {
    const response = await axiosInstance.get(`${API_ENDPOINTS.MAPBOX}${encodeURIComponent(address)}.json`, {
        params: {
            access_token: mapboxAccessToken,
        },
    });

    const bestMatch = response.data.features[0];
    if (!bestMatch || !bestMatch.center || bestMatch.center.length < 2) throw new Error('No valid geocode data returned');

    return {
        lat: bestMatch.center[1],
        lng: bestMatch.center[0],
    };
};

export const useGetCoordinates = (address: string) =>
    useQuery({
        queryKey: ['mapCoordinates', address],
        queryFn: () => getMapboxGeocode({ address }),
        enabled: !!address,
    });

export default useGetCoordinates;
