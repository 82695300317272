import React from 'react';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSCard, NSInput, NSSelect, NSSwitch } from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import { PRO_FORMA_SIZE_UNIT_OPTIONS } from 'constants/unitTypes';
import { CALCULATE_SIZE_HEADER_CLASS_NAME, FORM_ROW_CLASS_NAME } from 'views/ProFormaTable/constants';
import { customStyles } from 'bricks/NSSelect/NSSelect';
import { numeralFormatter } from 'ns_libs/formatter';
import './CalculateSizeCard.scss';
import { IGrossBuildableAreaLot } from './types';

interface ICalculateSizeCardProps {
    shouldCalculate: boolean;
    toggleCalculate: () => void;
    lots: IGrossBuildableAreaLot[];
    isLoading: boolean;
    onCreateLot: () => void;
    onDeleteLot: (id: number) => void;
    onUpdateLot: (id: number, dataKey: keyof IGrossBuildableAreaLot, value: string | number) => void;
    totalGrossBuildableArea: number;
}

const CalculateSizeCard = ({
    shouldCalculate,
    toggleCalculate,
    lots,
    isLoading,
    onCreateLot,
    onDeleteLot,
    onUpdateLot,
    totalGrossBuildableArea,
}: ICalculateSizeCardProps) => {
    return (
        <>
            <RenderIf isTrue={shouldCalculate} isLoading={isLoading}>
                <NSCard className="NSCard--level-3 p-3 mb-2 CalculateSizeCard">
                    <table>
                        <thead>
                            <tr>
                                <th className={CALCULATE_SIZE_HEADER_CLASS_NAME}>Lot</th>
                                <th className={CALCULATE_SIZE_HEADER_CLASS_NAME}>Land area</th>
                                <th className={CALCULATE_SIZE_HEADER_CLASS_NAME}>FAR</th>
                                <th className={`${CALCULATE_SIZE_HEADER_CLASS_NAME} text-right`}>Gross buildable area</th>
                                <th className="p-0 pb-1" />
                            </tr>
                        </thead>
                        <tbody>
                            {lots.map((lot, index) => (
                                <tr key={lot.id} className="pt-1">
                                    <td className="p-0 pb-1 pr-1">{index + 1}</td>
                                    <td className="p-0 pb-1 pr-1">
                                        <NSInput
                                            type="number"
                                            name="landArea"
                                            customClassName="NSInput__appended-child--with-select"
                                            value={lot.landArea}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onUpdateLot(lot.id, 'landArea', event.target.value)}
                                            placeholder="Enter land area"
                                            inputGroupClassName="p-0"
                                            appendInputAddon={
                                                <NSSelect
                                                    name="landAreaUnitTypeId"
                                                    options={PRO_FORMA_SIZE_UNIT_OPTIONS}
                                                    value={PRO_FORMA_SIZE_UNIT_OPTIONS.find(opt => opt.value === lot.landAreaUnitTypeId)}
                                                    onChange={option => onUpdateLot(lot.id, 'landAreaUnitTypeId', option.value)}
                                                    isClearable={false}
                                                    isSearchable={false}
                                                    styles={{
                                                        ...customStyles,
                                                        control: styles => ({
                                                            ...styles,
                                                            border: 'none',
                                                            backgroundColor: '#47515d',
                                                            width: '80px',
                                                        }),
                                                    }}
                                                />
                                            }
                                        />
                                    </td>
                                    <td className="p-0 pb-1 pr-1">
                                        <NSInput
                                            type="number"
                                            name="floorAreaRatio"
                                            customClassName="CalculateSizeCard__input NSInput__appended-child--with-select"
                                            value={lot.floorAreaRatio}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                                onUpdateLot(lot.id, 'floorAreaRatio', event.target.value)
                                            }
                                            placeholder="Enter FAR"
                                            inputGroupClassName="p-0"
                                            step={0.1}
                                        />
                                    </td>
                                    <td className="text-right CalculateSizeCard__bordered-cell d-flex align-items-center justify-content-end">
                                        <span className="text-dark">{numeralFormatter(lot.grossBuildableArea)}&nbsp;</span>
                                        <span className="text-muted">ft&sup2;</span>
                                    </td>
                                    <td className="p-0 pb-1 pl-1 text-center">
                                        <FontAwesomeIcon
                                            icon={faTrash}
                                            size="sm"
                                            className="text-dark cursor-pointer"
                                            onClick={() => onDeleteLot(lot.id)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div role="presentation" className="text-primary mt-1 mb-0 cursor--pointer" onClick={onCreateLot}>
                        <FontAwesomeIcon icon={faPlus} size="sm" className="mr-1" />
                        Add lot
                    </div>
                    <hr className="mt-1 mb-2" />

                    <div className="d-flex justify-content-between">
                        <div className="text-dark">Total gross buildable area:</div>
                        <div>
                            <span className="text-dark">{numeralFormatter(totalGrossBuildableArea, '0,0')} </span>
                            <span>ft&sup2;</span>
                        </div>
                    </div>
                </NSCard>
            </RenderIf>
            <div className={FORM_ROW_CLASS_NAME}>
                <NSSwitch
                    id="calculateToggle"
                    name="calculateToggle"
                    checked={shouldCalculate}
                    onChange={toggleCalculate}
                    label="Calculate"
                    containerClassName="text-dark"
                    disabled={!!lots.length && shouldCalculate}
                />
            </div>
        </>
    );
};

export default CalculateSizeCard;
