/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import { IProFormaUses, DevelopmentUnitOfMeasureTypes } from 'views/ProFormaTable/types';
import { NSCheckbox } from 'bricks';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import NSCellInput from 'bricks/NSCellInput/NSCellInput';
import RenderIf from 'components/RenderIf/RenderIf';
import { PRO_FORMA_UNIT_TO_FIELD_VALUES } from 'constants/unitTypes';
import classNames from 'classnames';
import { useCreateBudgetClassification } from 'queries/BudgetClassification';
import { DEFAULT_BUDGET_LINE_NAME, mockOrgId } from 'views/ProFormaTable/constants';
import { useUsesTableContext } from '../../context/UsesTableProvider';
import UsesTableBudgetLine from './UsesTableBudgetLine/UsesTableBudgetLine';

export interface IUsesTableSubcategory {
    subcategory: IProFormaUses;
    unitType: DevelopmentUnitOfMeasureTypes;
    budgetClassificationIds: number[];
}

const UsesTableSubcategory = ({ subcategory, unitType, budgetClassificationIds }: IUsesTableSubcategory) => {
    const { selectedCategories, expandedCategories, updateSelectedCategories, updateExpandedCategories } = useUsesTableContext();
    const [isHovered, setIsHovered] = useState(false);

    const handleBudgetLineUpdate = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            // create();
        } else if (event.key === 'Escape') {
            // cancel();
        }
    };

    const { mutate: createBudgetClassifications } = useCreateBudgetClassification();
    const createBudgetLine = () => {
        const postBody = {
            nodeName: DEFAULT_BUDGET_LINE_NAME,
            description: DEFAULT_BUDGET_LINE_NAME,
            budget_line_nickname: DEFAULT_BUDGET_LINE_NAME,
            parentId: subcategory.id,
        };
        createBudgetClassifications(
            // TO DO: Update hardcoded organizationId
            { organizationId: mockOrgId, proformaId: subcategory.proFormaId, postBody },
            {
                onSuccess: () => {
                    // call fetchUses() in the provider to refetch the uses table data
                    if (!expandedCategories.subcategories.includes(subcategory.id)) {
                        updateExpandedCategories(null, subcategory.id);
                    }
                },
                onError: error => console.error('Error creating budget line:', error),
            },
        );
    };

    const deleteSubcategory = () => {};

    const subcategoryAmount = subcategory.totals && subcategory.totals?.amount !== null ? subcategory.totals?.amount : null;

    const subcategoryUnitAmount =
        subcategory.totals && subcategory.totals[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType]] !== null
            ? subcategory.totals[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType]]
            : null;

    const hasNoSelectedCategories = Object.values(selectedCategories).every(categoryLevel => !categoryLevel.length);

    return (
        <>
            <tr
                className="NSTable__tbody__tr NSTable__tbody__tr--level-2"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <td
                    className="NSTable__tbody__tr__td NSTable__tbody__tr__td--right-border text-dark
                    d-flex justify-content-between align-items-center flex-nowrap"
                >
                    <div className="d-flex align-items-center">
                        <NSCheckbox
                            id={`selectSubcategory-${subcategory.id}`}
                            checked={selectedCategories.subcategories.includes(subcategory.id)}
                            callback={() => updateSelectedCategories(null, [subcategory.id], budgetClassificationIds)}
                            containerClassName="custom-control-inline align-middle"
                        />
                        <div className="d-flex align-items-center">
                            <RenderIf isTrue={Boolean(subcategory.children?.length)}>
                                <FontAwesomeIcon
                                    className="icon ml-1 mr-2"
                                    role="presentation"
                                    onClick={() => updateExpandedCategories(null, subcategory.id)}
                                    icon={expandedCategories.subcategories.includes(subcategory.id) ? faCaretUp : faCaretDown}
                                    fixedWidth
                                />
                            </RenderIf>
                            <NSCellInput
                                value={subcategory.description}
                                onChange={() => {}}
                                onBlur={() => {}}
                                placeholder="Enter subcategory name"
                                customClassName={subcategory.children?.length ? '' : 'ml-4'}
                                onEnter={handleBudgetLineUpdate}
                            />
                        </div>
                    </div>
                    <div className="d-flex flex-row flex-nowrap">
                        <RenderIf isTrue={hasNoSelectedCategories}>
                            <FontAwesomeIcon
                                icon={faTrash}
                                onClick={() => isHovered && deleteSubcategory()}
                                className={classNames('text-muted', { invisible: !isHovered })}
                            />
                        </RenderIf>
                        <FontAwesomeIcon
                            icon={faPlus}
                            onClick={() => isHovered && createBudgetLine()}
                            className={classNames('pl-2 text-muted', { invisible: !isHovered })}
                        />
                    </div>
                </td>
                <td className="NSTable__tbody__tr__td" />
                <td className="NSTable__tbody__tr__td" />
                <td className="NSTable__tbody__tr__td" />
                <td className="NSTable__tbody__tr__td">
                    <div className="d-flex justify-content-end text-dark">
                        {subcategoryUnitAmount !== null ? numeralFormatterCurrency(subcategoryUnitAmount) : '—'}
                    </div>
                </td>
                <td className="NSTable__tbody__tr__td">
                    <div className="d-flex justify-content-end text-dark">
                        {subcategoryAmount !== null ? numeralFormatterCurrency(subcategoryAmount) : '—'}
                    </div>
                </td>
            </tr>

            <RenderIf isTrue={expandedCategories.subcategories.includes(subcategory.id)}>
                {(subcategory.children || []).map(budgetClassification => (
                    <UsesTableBudgetLine key={budgetClassification.id} budgetClassification={budgetClassification} unitType={unitType} />
                ))}
            </RenderIf>
        </>
    );
};

export default UsesTableSubcategory;
