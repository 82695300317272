import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';

export interface IResponse {
    url: string;
}

export const getComparisonPdf = async (orgId: string, dealId: number): Promise<IResponse> => {
    const response = await axiosInstance.get(API_ENDPOINTS.BOE_SCENARIO_COMP_PDF(orgId, dealId));
    return response.data;
};

export const useGetComparisonPdf = (orgId: string, dealId: number, options: { enabled: boolean }) =>
    useQuery({
        queryKey: ['boeScenarioComparisonPdf', orgId, dealId],
        queryFn: () => getComparisonPdf(orgId, dealId),
        ...options,
    });

export default useGetComparisonPdf;
