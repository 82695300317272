import React from 'react';

const EmptyState = () => {
    return (
        <div className="NewCombinedReport__EmptyState d-flex align-items-center justify-content-center mt-2 h-100">
            <p className="p-0">Added report will be shown here.</p>
        </div>
    );
};

export default EmptyState;
