/* eslint-disable operator-linebreak */
import React, { useMemo } from 'react';
import { NSTable } from 'bricks';
import { numeralFormatterCurrency, percentFormatter } from 'ns_libs/formatter';
import { IOptionAnyValue } from 'bricks/types';
import { AggregateReportProperties, Periods } from './constant';

export interface IProps {
    data: any;
    cashFlowData: any;
    selectedPeriod: IOptionAnyValue;
}

export const ProFormaReturnsTable = ({ data, cashFlowData, selectedPeriod }: IProps) => {
    const dateKeys = data.length > 0 ? Object.keys(data[0]).filter(key => key !== 'Property' && key !== 'Amount') : [];

    const periodLabel = useMemo(() => {
        if (selectedPeriod.value === Periods.MONTHLY) {
            return 'MONTH';
        }
        if (selectedPeriod.value === Periods.QUARTERLY) {
            return 'QUARTER';
        }
        if (selectedPeriod.value === Periods.ANNUALLY) {
            return 'YEAR';
        }
        return '';
    }, [selectedPeriod]);

    return (
        <div className="NSTable--sticky-headers__table-container">
            <NSTable className="NSTable NSTable--sticky-headers--two-sticky-columns ProFormaReturnsTable mb-1">
                <thead>
                    <tr>
                        <th className="align-middle text-white NSTable__thead__tr__th">Property</th>
                        <th className="align-middle text-white">Amount</th>
                        {dateKeys.map((date, index) => (
                            <th key={`header-${date}`} className="NSTable__thead__tr text-nowrap text-white">
                                <span className="text-muted font-weight-light h6">{`${periodLabel} ${index + 1}`}</span>
                                <br />
                                {date}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row: any) => (
                        <>
                            <tr key={row.Property} className="NSTable__tbody__tr">
                                <td className="text-white">{row.Property}</td>
                                <td className="text-white">{numeralFormatterCurrency(row.Amount)}</td>
                                {Object.keys(row)
                                    .filter(key => key !== 'Property' && key !== 'Amount')
                                    .map(period => (
                                        <td key={period} className="text-white">
                                            {numeralFormatterCurrency(row[period])}
                                        </td>
                                    ))}
                            </tr>
                            {(row.Property === AggregateReportProperties.TOTAL_INTEREST_RESERVE ||
                                row.Property === AggregateReportProperties.PERMANENT_LOAN_FUNDING ||
                                row.Property === AggregateReportProperties.PERMANENT_LOAN_DEBT_SERVICE) && (
                                <tr className="NSTable__spacer-row">
                                    <td colSpan={dateKeys.length + 4} className="pt-0 pb-1" />
                                </tr>
                            )}
                        </>
                    ))}
                </tbody>
            </NSTable>
            <NSTable className="NSTable NSTable--sticky-headers--four-sticky-columns ProFormaReturnsTable">
                <thead>
                    <tr>
                        <th className="align-middle"> </th>
                        <th className="align-middle text-white">Equity multiple</th>
                        <th className="align-middle text-white">IRR</th>
                        <th className="align-middle text-white">Net profit</th>
                        {dateKeys.map(date => (
                            <th key={`header-${date}`} className="NSTable__thead__tr text-nowrap">
                                {' '}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {cashFlowData.map((row: any) => (
                        <tr key={row.Property} className="NSTable__tbody__tr text-end">
                            <td className="text-white ">{row.Property}</td>
                            <td className="text-white">{percentFormatter(row.EquityMultiple)}</td>
                            <td className="text-white">{numeralFormatterCurrency(row.IRR)}</td>
                            <td className="text-white">{numeralFormatterCurrency(row.NetProfit)}</td>
                            {Object.keys(row)
                                .filter(key => key !== 'Property' && key !== 'EquityMultiple' && key !== 'IRR' && key !== 'NetProfit')
                                .map(period => (
                                    <td key={period} className="text-white">
                                        {numeralFormatterCurrency(row[period])}
                                    </td>
                                ))}
                        </tr>
                    ))}
                </tbody>
            </NSTable>
        </div>
    );
};

export default ProFormaReturnsTable;
