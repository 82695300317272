import React, { useEffect } from 'react';
import { NSButton, NSCard, NSInput, NSLabel, NSSwitch } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollar, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import './ExpensesDetail.scss';
import { DEAL_TYPES } from 'views/ProFormaTable/constants';
import { IExpense, useExpenses } from 'views/ProFormaWizard/BasicInformation/hooks/useExpenses';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import RenderIf from 'components/RenderIf/RenderIf';

export interface IExpensesDetailProps {
    initialValues: IExpense[];
    assetType: string;
    showExpenseDetail: boolean;
    setShowExpenseDetail: (show: boolean) => void;
    onChange: (values: IExpense[]) => void;
}

const ExpensesDetail = ({ initialValues, assetType, showExpenseDetail, setShowExpenseDetail, onChange }: IExpensesDetailProps) => {
    const { expenses, totalAmount, createExpense, updateExpense, deleteExpense } = useExpenses(initialValues);

    useEffect(() => {
        onChange(expenses);
    }, [expenses, onChange]);

    return (
        <NSCard className="p-2">
            <div className="d-flex flex-row align-items-center justify-content-between mb-1">
                <div>
                    <div className="ExpensesDetail__title">Expense Detail</div>
                    <div className="ExpensesDetail__subtitle">Add recommended expenses (e.g. utilities, payroll, repairs)</div>
                </div>
                <NSSwitch
                    id="showDetailExpenses"
                    name="showDetailExpenses"
                    checked={showExpenseDetail}
                    onChange={e => setShowExpenseDetail(e.target.checked)}
                />
            </div>
            <RenderIf isTrue={showExpenseDetail}>
                {expenses.map(expense => (
                    <NSCard className="NSCard--level-4 ExpensesDetail__card d-flex flex-row p-2 mb-2 align-items-end" key={expense.id}>
                        <div className="flex-grow-1">
                            <NSLabel className="text-dark font-weight-normal" htmlFor="hardCosts">
                                Name
                            </NSLabel>
                            <NSInput
                                value={expense.name}
                                onChange={(e: { target: { value: string | number } }) => updateExpense(expense.id, 'name', e.target.value)}
                                placeholder="Name"
                                name="name"
                                type="text"
                            />
                        </div>
                        <div className="flex-grow-1">
                            <NSLabel className="text-dark font-weight-normal" htmlFor="hardCosts">
                                Growth
                            </NSLabel>
                            <NSInput
                                value={expense.growthPercentage}
                                onChange={(e: { target: { value: string } }) => updateExpense(expense.id, 'growthPercentage', parseFloat(e.target.value))}
                                type="number"
                                placeholder="Growth"
                                name="growthPercentage"
                                appendInputAddon={<div>%</div>}
                            />
                        </div>
                        <div className="d-flex flex-row flex-grow-1 align-items-center">
                            <div className="flex-grow-1">
                                <NSLabel className="text-dark font-weight-normal" htmlFor="hardCosts">
                                    {assetType === DEAL_TYPES.APARTMENT ? 'Amount/year' : '$/SF/year'}
                                </NSLabel>
                                <NSInput
                                    value={expense.amount}
                                    onChange={(e: { target: { value: string } }) => updateExpense(expense.id, 'amount', parseFloat(e.target.value))}
                                    type="number"
                                    placeholder="Amount/Year"
                                    name="amount"
                                    prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                                />
                            </div>
                        </div>
                        <NSButton callback={() => deleteExpense(expense.id)} color="danger" outline data-testid={`delete-expense-btn-${expense.id}`}>
                            <FontAwesomeIcon icon={faTrash} className="text-danger" />
                        </NSButton>
                    </NSCard>
                ))}
                <NSCard
                    onClick={createExpense}
                    className="ExpensesDetail__add-expense cursor--pointer text-white align-items-center justify-content-center mb-2"
                >
                    <div className="py-3">
                        <FontAwesomeIcon icon={faPlus} className="pr-2" />
                        Add other income
                    </div>
                </NSCard>
                <NSCard className="NSCard--level-4">
                    <div className="d-flex flex-row justify-content-between align-items-center p-2">
                        <span className="font-weight-bold text-white">Total expenses:</span>
                        <div className="d-flex flex-row align-items-center justify-content-center">
                            <span className="font-weight-bold text-white">{numeralFormatterCurrency(totalAmount)}</span>
                        </div>
                    </div>
                </NSCard>
            </RenderIf>
        </NSCard>
    );
};

export default ExpensesDetail;
