import axiosInstance from 'ns_libs/nsAxios';
import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import { Deal } from '../types';

export const getDealById = async ({ orgId, dealId }: { orgId: string; dealId: number }) => {
    const response = await axiosInstance.get(API_ENDPOINTS.DEALS_BY_ID(orgId, dealId));
    return response.data;
};

interface UseGetDealByIdProps {
    orgId: string;
    dealId: number;
    shouldFetch: boolean;
}

export const useGetDealById = ({ orgId, dealId, shouldFetch }: UseGetDealByIdProps) => {
    return useQuery<Deal, Error>({
        queryKey: ['deal', orgId, dealId],
        queryFn: async () => getDealById({ orgId, dealId }),
        enabled: shouldFetch,
    });
};
