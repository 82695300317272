import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';

interface IDeleteVariables {
    organizationId: string;
    proFormaId: string;
    budgetClassificationIds?: number[];
    deleteAll?: boolean;
}

interface IResponse {
    success: boolean;
    data: any; // won't return anything apart from status 204
}

const deleteBudgetClassifications = async ({
    organizationId,
    proFormaId,
    budgetClassificationIds = [],
    deleteAll = false,
}: IDeleteVariables): Promise<IResponse> => {
    const params = new URLSearchParams();
    if (budgetClassificationIds) {
        budgetClassificationIds.forEach(id => params.append('budgetClassificationIds', String(id)));
    }
    if (deleteAll) {
        params.append('deleteAll', String(deleteAll));
    }

    return axiosInstance.delete(`${API_ENDPOINTS.BUDGET_CLASSIFICATIONS(organizationId, proFormaId)}`, { params });
};

export const useDeleteBudgetClassifications = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<IResponse, TError, IDeleteVariables, TContext>,
) => {
    return useMutation({
        mutationFn: deleteBudgetClassifications,
        ...options,
    });
};

export default useDeleteBudgetClassifications;
