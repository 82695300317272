import React from 'react';
import { NSModal } from 'bricks';
import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getMissingDateText } from 'views/ProFormaTable/helpers';

export interface IMissingDateModalProps {
    isOpen: boolean;
    toggle: () => void;
    startDate: string | null;
    saleDate: string | null;
    redirectToGeneralInformation: () => void;
}

const MissingDateModal = ({ isOpen, toggle, startDate, saleDate, redirectToGeneralInformation }: IMissingDateModalProps) => {
    const { title, prependedSubtitle, boldSubtitleText } = getMissingDateText(startDate, saleDate);

    return (
        <NSModal
            isOpen={isOpen}
            toggle={toggle}
            modalHeaderText={title as string}
            modalHeaderClassName="text-dark bg-primary"
            modalFooterButtonText="Go to General Information"
            modalFooterButtonFunction={redirectToGeneralInformation}
        >
            <div className="d-flex">
                <FontAwesomeIcon className="icon text-warning my-auto" icon={faWarning} fixedWidth />
                <div className="text-dark ml-1">{title} for bulk update</div>
            </div>
            <small className="d-flex text-dark ml-3">
                <div>
                    Before you can bulk update these budget lines, {prependedSubtitle?.toLowerCase()}
                    <span role="presentation" onClick={redirectToGeneralInformation} className="text-primary px-1 cursor--pointer">
                        General Information
                    </span>
                    <span>
                        to populate <b>{boldSubtitleText}</b> on each budget line.
                    </span>
                </div>
            </small>
        </NSModal>
    );
};

export default MissingDateModal;
