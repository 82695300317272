/* eslint-disable no-unused-vars */
import { stateListUS } from 'constants/statesList';
import { UNIT_OF_MEASURE_TYPES, UNIT_TYPE_NAMES } from 'constants/unitTypes';
import { IAdditionalField } from './types';
import { Attributes } from '../constants';

export enum ConfigurationSidebarTabs {
    Attributes = 'Attributes',
    Filters = 'Filters',
}

export const longValueAttributes: Attributes[] = [Attributes.Address, Attributes.Description];

export enum FilterType {
    ContainsExactly = 'contains exactly',
    DoesNotContainExactly = "doesn't contain exactly",
    IsEqualTo = 'is equal to',
    IsNotEqualTo = 'is not equal to',
    IsAnyOf = 'is any of',
    IsNoneOf = 'is not none of',
    IsGreaterThan = 'is greater than',
    IsGreaterThanOrEqualTo = 'is greater than or equal to',
    IsLessThan = 'is less than',
    IsLessThanOrEqualTo = 'is less than or equal to',
    IsBetween = 'is between',
    IsKnown = 'is known',
    IsUnknown = 'is unknown',
    IsBefore = 'is before',
    IsAfter = 'is after',
    IsAllTime = 'is all times',
    IsThis = 'is this',
    IsNext = 'is next',
    IsLast = 'is last',
}

enum DealType {
    Acquisition = 'Acquisition',
    Development = 'Development',
}

enum Phase {
    Origination = 'Origination',
    SiteSelection = 'Site selection',
    Underwriting = 'Underwriting',
    DueDiligence = 'Due diligence',
    Closing = 'Closing',
}

enum LeadSource {
    Broker = 'Broker',
    DirectOwnerRelationship = 'Direct owner relationship',
    DirectMarketing = 'Direct marketing',
    OnlinePlatform = 'Online platform',
    Networking = 'Networking',
    Wholesaler = 'Wholesaler',
    MLS = 'MLS',
    PublicRecordsAuction = 'Public records/auction',
    DriveBy = 'Drive-by',
    Other = 'Other',
}

enum LeasingStrategy {
    Speculative = 'Speculative',
    BuildToSuit = 'Build-to-Suit',
}

enum Rank {
    High = 'High',
    Medium = 'Medium',
    Low = 'Low',
}

enum Period {
    Day = 'Day',
    Week = 'Week',
    WeekSoFar = 'Week so far',
    Month = 'Month',
    MonthSoFar = 'Month so far',
    Quarter = 'Quarter',
    QuarterSoFar = 'Quarter so far',
    Year = 'Year',
    YearSoFar = 'Year so far',
}

export const unitTypeSelectorOptions = [
    { label: UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.ACRE].dropdownItemName, value: UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.ACRE].dropdownItemName },
    { label: UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.GROSS_SF].dropdownItemName, value: UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.GROSS_SF].dropdownItemName },
    {
        label: UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.RENTABLE_SF].dropdownItemName,
        value: UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.RENTABLE_SF].dropdownItemName,
    },
    {
        label: UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.RESIDENTIAL_UNIT].dropdownItemName,
        value: UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.RESIDENTIAL_UNIT].dropdownItemName,
    },
    { label: UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.LOT].dropdownItemName, value: UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.LOT].dropdownItemName },
    { label: UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.BED].dropdownItemName, value: UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.BED].dropdownItemName },
    { label: UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.MEGAWATT].dropdownItemName, value: UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.MEGAWATT].dropdownItemName },
];

export const filterOptionGroups = [
    {
        appliedToAttributes: [
            Attributes.County,
            Attributes.State,
            Attributes.Market,
            Attributes.Submarket,
            Attributes.DealType,
            Attributes.AssetClass,
            Attributes.ConstructionType,
            Attributes.Phase,
            Attributes.LeadSource,
            Attributes.LeasingStrategy,
            Attributes.Rank,
            Attributes.DealCreator,
            Attributes.DealOwner,
            Attributes.DevelopmentManager,
            Attributes.EquityPartner,
            Attributes.Lender,
            Attributes.LegalCounsel,
            Attributes.Architect,
            Attributes.Engineer,
            Attributes.Surveyor,
            Attributes.EnvironmentalConsultant,
        ],
        filterOptions: [FilterType.IsAnyOf, FilterType.IsNoneOf, FilterType.IsKnown, FilterType.IsUnknown],
    },
    {
        appliedToAttributes: [Attributes.Name, Attributes.Address, Attributes.City, Attributes.Description],
        filterOptions: [FilterType.ContainsExactly, FilterType.DoesNotContainExactly, FilterType.IsKnown, FilterType.IsUnknown],
    },
    {
        appliedToAttributes: [
            Attributes.ZipCode,
            Attributes.Units,
            Attributes.Progress,
            Attributes.AcquisitionPrice,
            Attributes.TargetReturnOnCost,
            Attributes.TargetReturnOnEquity,
            Attributes.TargetProfitReturn,
            Attributes.IRR,
            Attributes.EquityMultiple,
        ],
        filterOptions: [
            FilterType.IsEqualTo,
            FilterType.IsNotEqualTo,
            FilterType.IsGreaterThan,
            FilterType.IsGreaterThanOrEqualTo,
            FilterType.IsLessThan,
            FilterType.IsLessThanOrEqualTo,
            FilterType.IsBetween,
            FilterType.IsKnown,
            FilterType.IsUnknown,
        ],
    },
    {
        appliedToAttributes: [Attributes.DateCreated],
        filterOptions: [
            FilterType.IsEqualTo,
            FilterType.IsNotEqualTo,
            FilterType.IsBefore,
            FilterType.IsAfter,
            FilterType.IsBetween,
            FilterType.IsAllTime,
            FilterType.IsThis,
            FilterType.IsNext,
            FilterType.IsLast,
        ],
    },
];

export enum AdditionalFieldType {
    Text = 'text',
    Textarea = 'textarea',
    Selector = 'selector',
    Number = 'number',
    Date = 'date',
    Range = 'range',
    DateRange = 'date range',
}

export const additionalFields: IAdditionalField[] = [
    {
        appliedToAttributes: [Attributes.Name, Attributes.Address, Attributes.City, Attributes.County],
        appliedToFilters: [FilterType.ContainsExactly, FilterType.DoesNotContainExactly],
        type: AdditionalFieldType.Text,
    },
    {
        appliedToAttributes: [Attributes.Description],
        appliedToFilters: [FilterType.ContainsExactly, FilterType.DoesNotContainExactly],
        type: AdditionalFieldType.Textarea,
    },
    {
        appliedToAttributes: [Attributes.State],
        appliedToFilters: [FilterType.IsAnyOf, FilterType.IsNoneOf],
        type: AdditionalFieldType.Selector,
        options: stateListUS.map(state => ({ label: state.abbreviation, value: state.abbreviation })),
    },
    {
        appliedToAttributes: [
            Attributes.ZipCode,
            Attributes.Units,
            Attributes.Progress,
            Attributes.AcquisitionPrice,
            Attributes.TargetReturnOnCost,
            Attributes.TargetReturnOnEquity,
            Attributes.TargetProfitReturn,
            Attributes.IRR,
            Attributes.EquityMultiple,
        ],
        appliedToFilters: [
            FilterType.IsEqualTo,
            FilterType.IsNotEqualTo,
            FilterType.IsGreaterThan,
            FilterType.IsGreaterThanOrEqualTo,
            FilterType.IsLessThan,
            FilterType.IsLessThanOrEqualTo,
        ],
        type: AdditionalFieldType.Number,
    },
    {
        appliedToAttributes: [Attributes.DateCreated],
        appliedToFilters: [FilterType.IsEqualTo, FilterType.IsBefore, FilterType.IsAfter],
        type: AdditionalFieldType.Date,
    },
    {
        appliedToAttributes: [
            Attributes.ZipCode,
            Attributes.Units,
            Attributes.Progress,
            Attributes.AcquisitionPrice,
            Attributes.TargetReturnOnCost,
            Attributes.TargetReturnOnEquity,
            Attributes.TargetProfitReturn,
            Attributes.IRR,
            Attributes.EquityMultiple,
        ],
        appliedToFilters: [FilterType.IsBetween],
        type: AdditionalFieldType.Range,
    },
    {
        appliedToAttributes: [Attributes.DateCreated],
        appliedToFilters: [FilterType.IsBetween],
        type: AdditionalFieldType.DateRange,
    },
    {
        appliedToAttributes: [Attributes.DateCreated],
        appliedToFilters: [FilterType.IsThis, FilterType.IsNext, FilterType.IsLast],
        type: AdditionalFieldType.Selector,
        options: [
            { label: Period.Day, value: Period.Day },
            { label: Period.Week, value: Period.Week },
            { label: Period.WeekSoFar, value: Period.WeekSoFar },
            { label: Period.Month, value: Period.Month },
            { label: Period.MonthSoFar, value: Period.MonthSoFar },
            { label: Period.Quarter, value: Period.Quarter },
            { label: Period.QuarterSoFar, value: Period.QuarterSoFar },
            { label: Period.Year, value: Period.Year },
            { label: Period.YearSoFar, value: Period.YearSoFar },
        ],
    },
    {
        appliedToAttributes: [Attributes.DealType],
        appliedToFilters: [FilterType.IsAnyOf, FilterType.IsNoneOf],
        type: AdditionalFieldType.Selector,
        options: [
            { label: DealType.Acquisition, value: DealType.Acquisition },
            { label: DealType.Development, value: DealType.Development },
        ],
    },
    {
        appliedToAttributes: [Attributes.Phase],
        appliedToFilters: [FilterType.IsAnyOf, FilterType.IsNoneOf],
        type: AdditionalFieldType.Selector,
        options: [
            { label: Phase.Origination, value: Phase.Origination },
            { label: Phase.SiteSelection, value: Phase.SiteSelection },
            { label: Phase.Underwriting, value: Phase.Underwriting },
            { label: Phase.DueDiligence, value: Phase.DueDiligence },
            { label: Phase.Closing, value: Phase.Closing },
        ],
    },
    {
        appliedToAttributes: [Attributes.LeadSource],
        appliedToFilters: [FilterType.IsAnyOf, FilterType.IsNoneOf],
        type: AdditionalFieldType.Selector,
        options: [
            { label: LeadSource.Broker, value: LeadSource.Broker },
            { label: LeadSource.DirectOwnerRelationship, value: LeadSource.DirectOwnerRelationship },
            { label: LeadSource.DirectMarketing, value: LeadSource.DirectMarketing },
            { label: LeadSource.OnlinePlatform, value: LeadSource.OnlinePlatform },
            { label: LeadSource.Networking, value: LeadSource.Networking },
            { label: LeadSource.Wholesaler, value: LeadSource.Wholesaler },
            { label: LeadSource.MLS, value: LeadSource.MLS },
            { label: LeadSource.PublicRecordsAuction, value: LeadSource.PublicRecordsAuction },
            { label: LeadSource.DriveBy, value: LeadSource.DriveBy },
            { label: LeadSource.Other, value: LeadSource.Other },
        ],
    },
    {
        appliedToAttributes: [Attributes.LeasingStrategy],
        appliedToFilters: [FilterType.IsAnyOf, FilterType.IsNoneOf],
        type: AdditionalFieldType.Selector,
        options: [
            { label: LeasingStrategy.Speculative, value: LeasingStrategy.Speculative },
            { label: LeasingStrategy.BuildToSuit, value: LeasingStrategy.BuildToSuit },
        ],
    },
    {
        appliedToAttributes: [Attributes.Rank],
        appliedToFilters: [FilterType.IsAnyOf, FilterType.IsNoneOf],
        type: AdditionalFieldType.Selector,
        options: [
            { label: Rank.High, value: Rank.High },
            { label: Rank.Medium, value: Rank.Medium },
            { label: Rank.Low, value: Rank.Low },
        ],
    },
    {
        appliedToAttributes: [Attributes.Market],
        appliedToFilters: [FilterType.IsAnyOf, FilterType.IsNoneOf],
        type: AdditionalFieldType.Selector,
        options: [
            // TODO: Replace with values from zip_code_to_msa table
            { label: 'Market 1', value: 'Market 1' },
            { label: 'Market 2', value: 'Market 2' },
            { label: 'Market 3', value: 'Market 3' },
        ],
    },
    {
        appliedToAttributes: [Attributes.Submarket],
        appliedToFilters: [FilterType.IsAnyOf, FilterType.IsNoneOf],
        type: AdditionalFieldType.Selector,
        options: [
            // TODO: Replace with list of submarkets
            { label: 'Submarket 1', value: 'Submarket 1' },
            { label: 'Submarket 2', value: 'Submarket 2' },
            { label: 'Submarket 3', value: 'Submarket 3' },
        ],
    },
    {
        appliedToAttributes: [Attributes.AssetClass],
        appliedToFilters: [FilterType.IsAnyOf, FilterType.IsNoneOf],
        type: AdditionalFieldType.Selector,
        options: [
            // TODO: Replace with list values of asset_type table
            { label: 'Asset class 1', value: 'Asset class 1' },
            { label: 'Asset class 2', value: 'Asset class 2' },
            { label: 'Asset class 3', value: 'Asset class 3' },
        ],
    },
    {
        appliedToAttributes: [Attributes.ConstructionType],
        appliedToFilters: [FilterType.IsAnyOf, FilterType.IsNoneOf],
        type: AdditionalFieldType.Selector,
        options: [
            // TODO: Replace with list values of project_type table
            { label: 'Construction type 1', value: 'Construction type 1' },
            { label: 'Construction type 2', value: 'Construction type 2' },
            { label: 'Construction type 3', value: 'Construction type 3' },
        ],
    },
    {
        appliedToAttributes: [
            Attributes.DealCreator,
            Attributes.DealOwner,
            Attributes.DevelopmentManager,
            Attributes.EquityPartner,
            Attributes.Lender,
            Attributes.LegalCounsel,
            Attributes.Architect,
            Attributes.Engineer,
            Attributes.Surveyor,
            Attributes.EnvironmentalConsultant,
        ],
        appliedToFilters: [FilterType.IsAnyOf, FilterType.IsNoneOf],
        type: AdditionalFieldType.Selector,
        options: [
            // TODO: Replace with list of organization's user list / contact list / company list
        ],
    },
];
