import React from 'react';

export const GroupLabelRow = ({ groupLabel, numDataCols }: { groupLabel: string; numDataCols: number }) => (
    <tr className="NSTable__tbody__subheader">
        <td className="">{groupLabel}</td>
        {Array.from({ length: numDataCols }).map((_, idx) => (
            <td key={`empty-${idx}`} /> // eslint-disable-line react/no-array-index-key
        ))}
    </tr>
);

export default GroupLabelRow;
