import React, { useEffect, useMemo, useRef, useState } from 'react';
import { NSButton, NSLoadingScreen } from 'bricks';
import EmptyState from 'components/EmptyState/EmptyState';
import classNames from 'classnames';
import PageTitle from 'components/PageTitle/PageTitle';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { URLS } from 'services/urls';
import { IOptionNumberValue } from 'bricks/types';
import { ColSortConfig } from 'types/sort';
import ReportsControl from './ReportsControl/ReportsControl';
import './Reports.scss';

import CreateReport from './CreateReport/CreateReport';
import { useGetReports } from './hooks/useGetReports';
import ReportTable from './ReportTable/ReportTable';
import { FILTERS_PARAM_KEYS } from './ReportsControl/constants';
import { ReportTypeIdToReportTypeMap } from './types';
import { useGetOrgDealSummaries } from './hooks';

const Reports = () => {
    const [colSortConfig, setColSortConfig] = useState<ColSortConfig<any> | undefined>();

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const isCreateReportShown = searchParams.get('create') === 'true';

    const [scrollPosition, setScrollPosition] = useState<number>(0);
    const scrollableDivRef = useRef<HTMLDivElement>(null);

    const { data: deals } = useGetOrgDealSummaries('123e4567-e89b-12d3-a456-426614174000', { enabled: true });

    const dealOptions = useMemo(() => {
        return (
            deals?.map(d => ({
                value: d.id,
                label: d.dealName,
            })) || []
        );
    }, [deals]);

    const reportTypeOptions = useMemo(() => {
        return Object.entries(ReportTypeIdToReportTypeMap).map(([id, name]) => ({
            label: name,
            value: id,
        }));
    }, [ReportTypeIdToReportTypeMap]);

    const [filterSettings, setFilterSettings] = useState<Partial<Record<FILTERS_PARAM_KEYS, IOptionNumberValue[]>>>();

    const orgId = '123e4567-e89b-12d3-a456-426614174000';
    const { data: reportData, isLoading } = useGetReports({
        pageSize: 10,
        currentPage: 1,
        orgId,
        configured_filters: {
            deal_ids: filterSettings?.deals?.map(filter => filter.value),
            report_types: filterSettings?.reportType?.map(filter => filter.value),
        },
        ...(colSortConfig && { sorting: { [colSortConfig.key]: colSortConfig.direction } }),
    });
    const isEmptyResult = reportData?.itemCount === 0 && filterSettings;

    // Save scroll position before showing the overlay
    const handleShowCreateReport = () => {
        if (scrollableDivRef.current) {
            setScrollPosition(scrollableDivRef.current.scrollTop);
        }
        setSearchParams({ create: 'true' }); // Add the "create=true" query parameter
    };

    // Restore scroll position when the overlay is closed
    useEffect(() => {
        if (!isCreateReportShown && scrollableDivRef.current) {
            scrollableDivRef.current.scrollTop = scrollPosition;
        }
    }, [isCreateReportShown, scrollPosition]);

    return (
        <>
            <div
                ref={scrollableDivRef}
                className={classNames([
                    'flex-column m-1 vh-100 overflow-y--auto',
                    {
                        'd-none': isCreateReportShown,
                        'd-flex': !isCreateReportShown,
                    },
                ])}
            >
                <PageTitle title="Reports" breadcrumbBackLinkCallback={() => navigate(URLS.DEALS.HOME)} breadcrumbBackLinkText="Back">
                    <NSButton className="NSButton pt-1" text="Create report" color="primary" outline={false} callback={handleShowCreateReport} />
                </PageTitle>
                {isLoading ? (
                    <NSLoadingScreen />
                ) : (
                    <div>
                        {!reportData || (reportData.items.length === 0 && !isEmptyResult) ? (
                            <EmptyState
                                title="No reports created yet!"
                                subtitle="Quickly build powerful reports from your deals using one of our report templates."
                                className="mt-2 vh-100"
                            >
                                <NSButton text="Create report" color="primary" outline={false} className="NSButton" callback={handleShowCreateReport} />
                            </EmptyState>
                        ) : (
                            <div>
                                <ReportsControl
                                    filterSettings={filterSettings}
                                    setFilterSettings={setFilterSettings}
                                    reportTypeOptions={reportTypeOptions}
                                    dealOptions={dealOptions}
                                />
                                {isEmptyResult ? (
                                    <EmptyState
                                        title="No results available."
                                        subtitle="Adjust your search or applied filters to view reports"
                                        className="mt-2 vh-100"
                                    />
                                ) : (
                                    <ReportTable reportData={reportData.items} colSortConfig={colSortConfig} setColSortConfig={setColSortConfig} />
                                )}
                            </div>
                        )}
                    </div>
                )}
            </div>
            {isCreateReportShown && <CreateReport />}
        </>
    );
};

export default Reports;
