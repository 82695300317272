import { useQuery } from '@tanstack/react-query';
import axiosInstance from '../../../../ns_libs/nsAxios';
import API_ENDPOINTS from '../../../../services/urls';

export const getTimelines = async ({ orgId, dealId }: { orgId: string; dealId: number }) => {
    const response = await axiosInstance.get(API_ENDPOINTS.TIMELINES(orgId, dealId));
    return response.data;
};

// // Get a timeline by ID
// export const getTimelineById = async ({ orgId, dealId, timelineId }: { orgId: string; dealId: string; timelineId: string }) => {
//     const response = await axiosInstance.get(API_ENDPOINTS.GET_TIMELINE_BY_ID(orgId, dealId, timelineId));
//     return response.data;
// };

export const useGetTimelines = ({ orgId, dealId }: { orgId: string; dealId: number }) =>
    useQuery({
        queryKey: ['timelines', orgId, dealId],
        queryFn: () => getTimelines({ orgId, dealId }),
    });

// // Hook to fetch a single timeline by ID
// export const useGetTimelineById = ({ orgId, dealId, timelineId }: { orgId: string; dealId: string; timelineId: string }) =>
//     useQuery({
//         queryKey: ['timeline', orgId, dealId, timelineId],
//         queryFn: () => getTimelineById({ orgId, dealId, timelineId }),
//     });
