import { IOptionAnyValue } from 'bricks/types';

// TODO: Remove this when the backend is ready.
export const dealGroupsTypes: IOptionAnyValue[] = [
    { id: 1, label: 'Team member', value: 'Team member' },
    { id: 2, label: 'Geography', value: 'Geography' },
    { id: 3, label: 'Real estate asset type', value: 'Real estate asset type' },
    { id: 4, label: 'Level of Prioriy', value: 'Level of Prioriy' },
    { id: 5, label: 'Project type', value: 'Project type' },
    { id: 6, label: 'Department or Business Unit', value: 'Department or Business Unit' },
    { id: 7, label: 'Anticipated Completion Date', value: 'Anticipated Completion Date' },
    { id: 8, label: 'Active / Inactive', value: 'Active / Inactive' },
    { id: 9, label: 'Other / Custom', value: 'Other / Custom' },
];
