import { NSCard } from 'bricks';
import React from 'react';
import { Row } from 'reactstrap';
import NSDropdown from 'bricks/NSDropdown/NSDropdown';
import { IOptionAnyValue } from 'bricks/types';

export interface IDealSelectorProps {
    dealOptions: IOptionAnyValue[];
    addSelectedDeal: (newSelection: IOptionAnyValue) => void;
}

const DealSelector = ({ dealOptions, addSelectedDeal }: IDealSelectorProps) => {
    return (
        <NSCard className="NSCard--level-4 p-2 px-3 m-0 Deal-selector--unselected bg-transparent">
            <Row className="d-flex m-0 justify-content-between align-items-center">
                <span>Select deal to add:</span>
                <div className="d-flex m-0 align-items-center py-1 pl-3 flex-fill">
                    <NSDropdown
                        options={dealOptions}
                        handleSelectOption={option => addSelectedDeal(option as IOptionAnyValue)}
                        placeholder="Select"
                        containerClassName="w-100"
                        toggleClassName="Deal-selector__dropdown w-100"
                    />
                </div>
            </Row>
        </NSCard>
    );
};

export default DealSelector;
