import React from 'react';
import { Input } from 'reactstrap';
import { InputTypes } from '../constants';
import NSLabel from '../NSLabel/NSLabel';

export interface IProps {
    id: string;
    label: React.ReactNode;
    name: string;
    checked: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => any;
    containerClassName?: string;
    inputClassName?: string;
    labelClassName?: string;
    disabled?: boolean;
    value?: any;
    containerStyle?: React.CSSProperties;
}

export const NSRadioOption = ({
    checked,
    onChange,
    id,
    label,
    name,
    containerClassName,
    inputClassName,
    labelClassName,
    disabled,
    value,
    containerStyle,
    ...rest
}: IProps) => (
    <div className={`custom-control custom-radio ${containerClassName}`} style={containerStyle}>
        <Input
            type={InputTypes.radio}
            className={`custom-control-input cursor--pointer ${inputClassName}`}
            id={id}
            onChange={onChange}
            checked={checked}
            name={name}
            disabled={disabled}
            value={value}
            {...rest}
        />
        <NSLabel className={`custom-control-label font cursor--pointer ${labelClassName}`} for={id}>
            {label}
        </NSLabel>
    </div>
);

export default NSRadioOption;
