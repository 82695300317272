import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';

export interface IResponse {
    url: string;
}

export const getDealOverviewPdf = async (orgId: string, dealId: number): Promise<IResponse> => {
    const response = await axiosInstance.get(API_ENDPOINTS.DEAL_OVERVIEW_PDF(orgId, dealId));
    return response.data;
};

export const useGetDealOverviewPdf = (orgId: string, dealId: number, options: { enabled: boolean }) =>
    useQuery({
        queryKey: ['boeScenarioDealOverviewPdf', orgId, dealId],
        queryFn: () => getDealOverviewPdf(orgId, dealId),
        ...options,
    });

export default useGetDealOverviewPdf;
