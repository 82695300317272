import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { NSLoadingScreen, NSButton } from 'bricks';
import { downloadFileFromUrl } from 'helpers/file/download';
import { useGetReportPreview } from '../hooks';
import { getReportPdf } from '../hooks/useGetReportPdf';

type IRouterParams = {
    reportId: string;
};

export const ReportPreview = () => {
    const { reportId } = useParams<IRouterParams>();
    const numericReportId = reportId ? Number(reportId) : undefined;

    const [isPortrait, setIsPortrait] = useState(true);

    const {
        data: reportData,
        isLoading,
        refetch,
    } = useGetReportPreview(Number(numericReportId), {
        enabled: !!numericReportId,
    });

    const handlePdfExport = async () => {
        if (numericReportId) {
            try {
                const response = await getReportPdf(numericReportId);
                const pdfUrl = response.url;
                await downloadFileFromUrl(pdfUrl, `test-report-${reportId}`, 'pdf', true);
            } catch (error) {
                console.error('Failed to export report PDF:', error);
            }
        }
    };

    const toggleOrientation = () => {
        setIsPortrait(prev => !prev);
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                height: '100vh',
                padding: '20px',
                gap: '20px',
            }}
        >
            <div
                style={{
                    width: '200px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                }}
            >
                <NSButton className="w-100 mt-1 p-2 bg-primary rounded text-white" callback={() => refetch()} disabled={isLoading}>
                    Refetch
                </NSButton>
                <NSButton className="w-100 mt-1 p-2 bg-primary rounded text-white" callback={() => handlePdfExport()} disabled={isLoading}>
                    Download PDF
                </NSButton>
                <div>
                    <label htmlFor="orientation-toggle">
                        <input id="orientation-toggle" type="checkbox" checked={!isPortrait} onChange={toggleOrientation} />
                        <span className="pl-1 text-center">Landscape</span>
                    </label>
                </div>
            </div>
            <div
                style={{
                    width: isPortrait ? '21cm' : '29.7cm',
                    height: isPortrait ? '29.7cm' : '21cm',
                    border: '1px solid #ddd',
                    backgroundColor: 'white',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    overflow: 'auto',
                }}
            >
                {isLoading ? (
                    <NSLoadingScreen />
                ) : (
                    <>
                        {reportData && (
                            <iframe
                                srcDoc={reportData}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    border: 'none',
                                }}
                                title="Report Preview"
                            />
                        )}
                        {!reportData && <h1 className="text-center mt-4">Report not found</h1>}
                    </>
                )}
            </div>
        </div>
    );
};

export default ReportPreview;
