import { NSInput, NSLabel, NSModal, NSSelect } from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import React, { useState } from 'react';
import { SOURCE_TYPE_OPTIONS, mockSourceTypeId } from 'views/ProFormaTable/constants';

export interface IAddSourceModalProps {
    toggle: () => void;
    isOpen: boolean;
    isLoading: boolean;
    handleCreateProFormaSource: (sourceType: string, sourceName: string) => void;
}
const AddSourceModal = ({ toggle, isOpen, isLoading, handleCreateProFormaSource }: IAddSourceModalProps) => {
    const [type, setType] = useState<IOptionAnyValue | null>();
    const [name, setName] = useState<string>('');

    const handleCreate = () => {
        // TODO: update mockSourceTypeId with type?.value
        handleCreateProFormaSource(mockSourceTypeId, name);
    };

    return (
        <NSModal
            isOpen={isOpen}
            toggle={toggle}
            modalHeaderText="Add source"
            modalHeaderClassName="text-dark bg-primary"
            modalFooterButtonText="Confirm"
            modalFooterButtonFunction={handleCreate}
            modalFooterButtonIsLoading={isLoading}
            modalFooterIsButtonFunctionDisabled={isLoading || !type || !name}
        >
            <NSLabel required>Source type</NSLabel>
            <NSSelect name="type" options={SOURCE_TYPE_OPTIONS} value={type} onChange={option => setType(option)} isClearable={false} />

            <NSLabel required className="mt-2 text-dark font-weight-normal">
                Name
            </NSLabel>
            <NSInput
                type="text"
                name="name"
                placeholder="Enter name"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
            />
        </NSModal>
    );
};

export default AddSourceModal;
