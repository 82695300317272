import React, { useState } from 'react';
import './SlideOutReturns.scss';
import { useGetDeals } from 'views/Deals/hooks/useFetchDeals';
import { Collapse } from 'reactstrap';
import { NSSelect } from 'bricks';
import { Deal } from 'views/Deals/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullseyeArrow } from '@fortawesome/pro-regular-svg-icons';
import SlideOutReturnCard from './SlideOutReturnCard';

export interface IScenarioReturnsProps {
    containerClassName?: string;
    targetReturnOnCost?: number;
    targetReturnOnEquity?: number;
    targetProfitReturn?: number;
}

export interface IOptionAnyValue {
    label: string;
    value: any;
    id?: number | string;
    disabled?: boolean;
}

const SlideOutReturns = ({ containerClassName, targetReturnOnCost, targetReturnOnEquity, targetProfitReturn }: IScenarioReturnsProps) => {
    const [isExpandedState, setIsExpandedState] = useState(false);
    const [selectedOption, setSelectedOption] = useState<IOptionAnyValue | undefined>(undefined);
    const [selectedDeal, setSelectedDeal] = useState<Deal | null>(null);

    // TODO: Change this request and logic when the new endpoint is ready.
    const orgId = '123e4567-e89b-12d3-a456-426614174000';
    const { data } = useGetDeals({ pageSize: 100, currentPage: 1, orgId });

    const dealOptions: IOptionAnyValue[] = data?.items.map(deal => ({ label: deal.dealName, value: deal.id, id: deal.id })) || [];

    const handleSelectOption = (option: IOptionAnyValue) => {
        setSelectedOption(option);
        const deal = data?.items.find(item => item.id === option.value);
        setSelectedDeal(deal || null);
    };

    const textButton = isExpandedState ? 'Hide returns' : 'Show returns';

    return (
        <div className={containerClassName}>
            <div className="d-flex justify-content-end align-items-center">
                <button type="button" onClick={() => setIsExpandedState(!isExpandedState)} className="btn btn-outline-secondary">
                    <FontAwesomeIcon icon={faBullseyeArrow} className="text-primary pr-1" />
                    <span className="text-primary">{textButton}</span>
                </button>
            </div>
            <Collapse isOpen={isExpandedState} enter exit>
                <div className="NSCard--level-4 py-2 px-3 border border-secondary">
                    <div className="SlideOutReturns--header mb-2 d-flex flex-row align-items-center justify-content-between">
                        <div>
                            <h4 className="text-white font-weight-bold mb-0">Returns</h4>
                            <h5 className="mt-0 text-muted">Compare these back-of-envelope calculations to target returns on your deals.</h5>
                        </div>
                        {/* TODO: Change this select with the real component when */}
                        <NSSelect
                            options={dealOptions}
                            value={selectedOption}
                            onChange={handleSelectOption}
                            name="Deal"
                            menuPosition="absolute"
                            isClearable={false}
                        />
                    </div>
                    <div className="d-flex flex-row SlideOutReturns__CardsContainer">
                        <SlideOutReturnCard
                            title="Return on cost:"
                            returnValue={targetReturnOnCost || 0}
                            targetValue={selectedDeal?.targetReturnOnCost || 0}
                            formatter="formatPercentage"
                        />
                        <SlideOutReturnCard
                            title="Return on equity:"
                            returnValue={targetReturnOnEquity || 0}
                            targetValue={selectedDeal?.targetReturnOnEquity || 0}
                            formatter="formatPercentage"
                        />
                        <SlideOutReturnCard
                            title="Profit return:"
                            returnValue={targetProfitReturn || 0}
                            targetValue={selectedDeal?.targetProfitReturn || 0}
                            formatter="formatUSDCurrency"
                        />
                    </div>
                </div>
            </Collapse>
        </div>
    );
};

export default SlideOutReturns;
