import React from 'react';
import { NSButton } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { ActiveSections, IReportBuilderReport, ReportTypes } from 'views/ReportBuilder/constants';
import { useGetReports } from 'views/Reports/hooks';
import { IReport } from 'views/Reports/types';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export interface IStartNewReportFormProps {
    orgId: string;
    setActiveSection: React.Dispatch<React.SetStateAction<string>>;
    newReports: Array<IReportBuilderReport>;
    setNewReports: React.Dispatch<React.SetStateAction<IReportBuilderReport[]>>;
}

const StartNewReportForm = ({ orgId, setActiveSection, newReports, setNewReports }: IStartNewReportFormProps) => {
    const { data: reportData } = useGetReports({ pageSize: 30, currentPage: 1, orgId });
    const handleReportSelection = (report: IReport) => {
        setNewReports([...newReports, { savedReportId: report.id, reportType: ReportTypes.SAVED_REPORT, savedReportName: report.name }]);
    };

    const setToInitialState = () => {
        setActiveSection(ActiveSections.INITIAL);
        setNewReports([]);
    };

    const isReportInNewReports = (reportId: number) => {
        return newReports.some(report => report.savedReportId === reportId);
    };

    return (
        <>
            <div className="d-flex align-items-center mb-1">
                <h4 role="presentation" className="text-primary m-0 p-0" style={{ cursor: 'pointer' }} onClick={() => setToInitialState()}>
                    Create
                </h4>
                <FontAwesomeIcon icon={faAngleRight} className="mx-1" size="xs" />
                <h4 className="text-white m-0 p-0">Start new report</h4>
            </div>
            <p className="m-0 mt-1 p-0">Select from several report types to add to your combined report</p>
            <hr />
            {reportData?.items.map(
                (report: IReport) =>
                    !isReportInNewReports(report.id) && (
                        <NSButton
                            key={report.id}
                            callback={() => handleReportSelection(report)}
                            className="mt-2 py-2 w-100"
                            color="secondary"
                            outline={false}
                        >
                            <div className="text-white d-flex justify-content-between align-items-center">
                                <h5 className="my-0">{report.name}</h5>
                                <FontAwesomeIcon icon={faPlus} size="lg" />
                            </div>
                        </NSButton>
                    ),
            )}
        </>
    );
};

export default StartNewReportForm;
