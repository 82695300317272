import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useProFormaWizard } from 'views/ProFormaWizard/context/ProFormaWizardProvider';
import { faCalendar } from '@fortawesome/pro-solid-svg-icons';
import { NSButton, NSDropdown, NSInput, NSLabel, NSSelect } from 'bricks';
import './ProFormaWizardStepOne.scss';
import NSInputNumberStepper from 'bricks/NSInputNumberStepper/NSInputNumberStepper';
import { DEAL_TYPES, dealTypeProFormaParkingOptions, FORM_LABEL_CLASS_NAME } from 'views/ProFormaTable/constants';
import RenderIf from 'components/RenderIf/RenderIf';
import { useGrossBuildableArea } from 'views/ProFormaWizard/BasicInformation/hooks/useGrossBuildableArea';
import CalculateSizeCard from 'views/ProFormaTable/components/ProForma/ProFormaGeneralInformation/CalculateSizeCard/CalculateSizeCard';
import classNames from 'classnames';
import DealDetails from 'components/DealDetails/DealDetails';
import { useGetDealById } from 'views/Deals/hooks/useGetDealById';
import { useParams } from 'react-router-dom';
import { IOptionAnyValue } from 'bricks/types';

export interface StepOneValues {
    landArea: number;
    buildings: number;
    builtGrossSF: number;
    stories: number;
    sellingCosts: number;
    projectedCapRate: number;
    currentCapRate: number;
    parking: IOptionAnyValue[];
    structuredParkingSpaces: number;
    surfaceParkingSpaces: number;
    isFAR: boolean;
    farAmount: number;
    grossBuildableArea: number;
}

const LAND_AREA_UNITS = [
    { value: 'acres', label: 'acres' },
    { value: 'sqft', label: 'ft\u00B2' },
];

const ProFormaWizardStepOne = () => {
    const { registerStepHandler, getStepData, setStepData } = useProFormaWizard();
    const { lots, createLot, updateLot, deleteLot, totalGrossBuildableArea } = useGrossBuildableArea(getStepData(1)?.lots);
    const { dealId } = useParams<{ dealId: string }>();
    const ORG_ID = '123e4567-e89b-12d3-a456-426614174000'; // TODO Remove hardcoded ORG ID
    const { data: deal } = useGetDealById({
        orgId: ORG_ID,
        dealId: Number(dealId),
        shouldFetch: !!dealId,
    });

    const hasGrossBuildableLots = Boolean(lots.length);
    const [assetType] = useState<string>(DEAL_TYPES.APARTMENT); // TODO get from API

    const [startDatePicker, setStartDatePicker] = useState(false);
    const [saleMonthPicker, setSaleMonthPicker] = useState(false);
    const [calculatorToggle, setCalculatorToggle] = useState(hasGrossBuildableLots);
    const [startDate, setStartDate] = useState<Date | null>(() => getStepData(1)?.startDate || null);
    const [landAreaUnit, setLandAreaUnit] = useState<string>(() => getStepData(1)?.landAreaUnit || 'acres');
    const [grossBuildableUnit, setGrossBuildableUnit] = useState<string>('acres');
    const [saleDate, setSaleDate] = useState<Date | null>(getStepData(1)?.saleDate || null);

    const [values, setValues] = useState<StepOneValues>(
        () =>
            getStepData(1)?.values || {
                landArea: 0,
                buildings: 1,
                builtGrossSF: 0,
                stories: 1,
                sellingCosts: 6,
                projectedCapRate: 0,
                currentCapRate: 0,
                parking: [],
                structuredParkingSpaces: 0,
                surfaceParkingSpaces: 0,
                isFAR: !hasGrossBuildableLots,
                farAmount: 0,
                grossBuildableArea: 0,
            },
    );

    const validate = useCallback(() => {
        if (!startDate || !values.landArea) {
            alert('Please fill in all fields.');
            return false;
        }
        return true;
    }, [startDate, values.landArea]);

    const getData = useCallback(
        () => ({
            values,
            startDate,
            landAreaUnit,
            lots,
            saleDate,
        }),
        [values, startDate, landAreaUnit, lots, saleDate],
    );

    const formatDate = (date: Date) => date.toLocaleDateString();

    const handleSelect = (name: string, option: IOptionAnyValue) => {
        setValues({ ...values, [name]: option });
    };

    const toggleCalculate = () => {
        setCalculatorToggle(prev => {
            const newToggleValue = !prev;

            if (!prev && !hasGrossBuildableLots) {
                createLot();
            }

            return newToggleValue;
        });
    };

    const handleDeleteLot = (id: number) => {
        deleteLot(id);
        if (lots.length === 1) {
            toggleCalculate();
        }
    };

    useEffect(() => {
        registerStepHandler(1, {
            validate,
            getData,
        });

        // Save data to context on unmount
        return () => {
            setStepData(1, getData());
        };
    }, [getData, validate]);

    const renderParkingSpaces = (type: string, label: string, value: number, setValue: (count: number) => void, min: number) => (
        <RenderIf isTrue={values.parking?.find((park: IOptionAnyValue) => park.value === type)}>
            <div className="d-flex my-2 w-75">
                <NSLabel className={FORM_LABEL_CLASS_NAME}>{label}</NSLabel>
                <NSInputNumberStepper
                    name={`${type}ParkingSpaces`}
                    value={value}
                    onChange={(count: number) => setValue(count)}
                    min={min}
                    inputGroupClassName="ProFormaWizardStepOne__input-width"
                    step={1}
                />
            </div>
        </RenderIf>
    );

    if (!deal) {
        return <div>No deal selected</div>;
    }

    return (
        <div className="d-flex">
            <div className="mr-4">
                <DealDetails
                    isCompact
                    deal={{
                        ...deal,
                        description: '',
                    }}
                    detailsArray={[
                        { id: 1, label: 'Deal name', value: deal.dealName },
                        { id: 2, label: 'Address', value: deal.address },
                        { id: 3, label: 'City', value: deal.city },
                        { id: 4, label: 'County', value: deal.county },
                        { id: 5, label: 'State', value: deal.state },
                        { id: 6, label: 'Zip', value: deal.zipCode },
                    ]}
                />
            </div>
            <div className="w-100">
                {/* Start date */}
                <div className="w-50">
                    <small>1 of 3</small>
                    <div className="ProFormaWizardStepOne__title">Start Date</div>
                    <div className="ProFormaWizardStepOne__subtitle">
                        Determine start date for this analysis. Typically this is your acquisition closing. This date will be used as period 1 for cash
                        flows.
                    </div>
                    <NSInput
                        id="startDate"
                        name="startDate"
                        placeholder="Select date..."
                        inputClassName="w-75 pt-2"
                        appendInputAddon={
                            <div>
                                <FontAwesomeIcon icon={faCalendar} size="lg" onClick={() => setStartDatePicker(true)} style={{ cursor: 'pointer' }} />
                                {startDatePicker && (
                                    <div className="ProFormaWizardStepOne__datepicker">
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date: Date | null) => {
                                                if (date) {
                                                    setStartDate(date);
                                                }
                                                setStartDatePicker(false);
                                            }}
                                            onClickOutside={() => setStartDatePicker(false)}
                                            inline
                                        />
                                    </div>
                                )}
                            </div>
                        }
                        type="text"
                        value={startDate ? formatDate(startDate) : ''}
                        readOnly
                    />
                </div>
                <div className="ProFormaWizardStepOne__separator" />
                {/* Size */}
                <div>
                    <small>2 of 3</small>
                    <div className="ProFormaWizardStepOne__title">Size</div>
                    <div className="ProFormaWizardStepOne__subtitle">Details about this investment’s property size and building</div>
                    <div className="d-flex align-items-center mt-2 w-75">
                        <NSLabel className={FORM_LABEL_CLASS_NAME}>Land area</NSLabel>
                        <NSInput
                            id="landArea"
                            type="number"
                            label=""
                            name="landArea"
                            placeholder="Enter land area"
                            value={values.landArea || ''} // Allow '' for empty state
                            inputGroupClassName="p-0"
                            inputClassName="w-50"
                            appendInputAddon={
                                <NSDropdown
                                    options={LAND_AREA_UNITS}
                                    selectedOption={LAND_AREA_UNITS.find(option => option.value === landAreaUnit)}
                                    customSelectedOptionText={LAND_AREA_UNITS.find(option => option.value === landAreaUnit)?.label || ''}
                                    handleSelectOption={option => {
                                        const selectedUnit = option.value;

                                        setValues(prevValues => {
                                            const currentLandArea = prevValues.landArea;

                                            let convertedLandArea = currentLandArea;
                                            if (selectedUnit === 'sqft' && landAreaUnit === 'acres') {
                                                convertedLandArea = currentLandArea * 43560;
                                            } else if (selectedUnit === 'acres' && landAreaUnit === 'sqft') {
                                                convertedLandArea = currentLandArea / 43560;
                                            }

                                            return {
                                                ...prevValues,
                                                landArea: Number(convertedLandArea.toFixed(2)),
                                            };
                                        });

                                        setLandAreaUnit(selectedUnit);
                                    }}
                                    badgeColor=""
                                    isBadgeToggle
                                />
                            }
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                const inputValue = e.target.value === '' ? 0 : Number(e.target.value);
                                setValues(prevValues => ({
                                    ...prevValues,
                                    landArea: inputValue,
                                }));
                            }}
                        />
                    </div>
                    <div className="d-flex my-2 w-75">
                        <NSLabel className={`d-flex ${FORM_LABEL_CLASS_NAME}`}>
                            <NSButton
                                color="secondary"
                                outline={false}
                                callback={() => setValues({ ...values, isFAR: true })}
                                text="FAR"
                                className={classNames('px-3', { 'border border-primary bg-primary-lighten': values.isFAR })}
                            />
                            <RenderIf isTrue={assetType === DEAL_TYPES.APARTMENT || assetType === DEAL_TYPES.OFFICE}>
                                <NSButton
                                    color="secondary"
                                    outline={false}
                                    callback={() => setValues({ ...values, isFAR: false })}
                                    text="Gross build. area"
                                    className={classNames({ 'border border-primary bg-primary-lighten': !values.isFAR })}
                                />
                            </RenderIf>
                        </NSLabel>
                        <RenderIf isTrue={values.isFAR}>
                            <NSInputNumberStepper
                                name="farAmount"
                                value={values.farAmount}
                                onChange={(count: number) => setValues({ ...values, farAmount: count })}
                                min={0.1}
                                inputGroupClassName="ProFormaWizardStepOne__input-width"
                                step={0.1}
                            />
                        </RenderIf>

                        <RenderIf isTrue={!values.isFAR}>
                            <NSInput
                                type={hasGrossBuildableLots ? 'text' : 'number'}
                                name="grossBuildableArea"
                                value={totalGrossBuildableArea || (values.grossBuildableArea === 0 ? '' : values.grossBuildableArea)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setValues(prevValues => ({
                                        ...prevValues,
                                        grossBuildableArea: Number(e.target.value),
                                    }))
                                }
                                placeholder={hasGrossBuildableLots ? 'Calculate buildable area' : 'Enter gross buildable area'}
                                inputGroupClassName="p-0"
                                inputClassName="w-50"
                                disabled={hasGrossBuildableLots}
                                appendInputAddon={
                                    <NSDropdown
                                        disabled={hasGrossBuildableLots}
                                        options={LAND_AREA_UNITS}
                                        selectedOption={LAND_AREA_UNITS.find(option => option.value === grossBuildableUnit)}
                                        customSelectedOptionText={LAND_AREA_UNITS.find(option => option.value === grossBuildableUnit)?.label || ''}
                                        handleSelectOption={option => {
                                            const selectedUnit = option.value;

                                            setValues((prevValues: StepOneValues) => {
                                                const currentArea = prevValues.grossBuildableArea;

                                                let convertedArea = currentArea;
                                                if (selectedUnit === 'sqft' && grossBuildableUnit === 'acres') {
                                                    convertedArea = currentArea * 43560;
                                                } else if (selectedUnit === 'acres' && grossBuildableUnit === 'sqft') {
                                                    convertedArea = currentArea / 43560;
                                                }

                                                return {
                                                    ...prevValues,
                                                    grossBuildableArea: Number(convertedArea.toFixed(2)),
                                                };
                                            });

                                            setGrossBuildableUnit(selectedUnit);
                                        }}
                                        badgeColor=""
                                        isBadgeToggle
                                        toggleClassName="p-0 m-0"
                                    />
                                }
                            />
                        </RenderIf>
                    </div>

                    <RenderIf isTrue={!values.isFAR}>
                        <div className="w-75">
                            <CalculateSizeCard
                                isLoading={false}
                                lots={lots}
                                onCreateLot={createLot}
                                onDeleteLot={handleDeleteLot}
                                onUpdateLot={updateLot}
                                totalGrossBuildableArea={totalGrossBuildableArea}
                                toggleCalculate={toggleCalculate}
                                shouldCalculate={calculatorToggle}
                            />
                        </div>
                    </RenderIf>

                    <div className="d-flex align-items-center mt-2 w-75">
                        <NSLabel className={FORM_LABEL_CLASS_NAME}>Buildings</NSLabel>
                        <NSInputNumberStepper
                            name="buildings"
                            value={values.buildings}
                            onChange={(count: number) => setValues({ ...values, buildings: count })}
                            min={1}
                            step={1}
                            inputGroupClassName="ProFormaWizardStepOne__input-width"
                        />
                    </div>
                    <div className="d-flex align-items-center mt-2 w-75">
                        <NSLabel className={FORM_LABEL_CLASS_NAME}>Built gross SF</NSLabel>
                        <NSInput
                            id="builtGrossSF"
                            label=""
                            name="builtGrossSF"
                            placeholder="Enter built gross SF"
                            value={values.builtGrossSF === 0 ? '' : values.builtGrossSF}
                            inputClassName="w-50"
                            appendInputAddon={<div>ft&sup2;</div>}
                            type="number"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setValues(prevValues => ({
                                    ...prevValues,
                                    builtGrossSF: Number(e.target.value),
                                }))
                            }
                        />
                    </div>
                    <RenderIf isTrue={assetType === DEAL_TYPES.APARTMENT || assetType === DEAL_TYPES.OFFICE}>
                        <div className="d-flex align-items-center mt-2 w-75">
                            <NSLabel className={FORM_LABEL_CLASS_NAME}>{values.buildings <= 1 ? 'Stories' : 'Avg. # of stories'}</NSLabel>
                            <NSInputNumberStepper
                                name="stories"
                                value={values.stories}
                                onChange={(count: number) => setValues({ ...values, stories: count })}
                                min={1}
                                step={1}
                                inputGroupClassName="ProFormaWizardStepOne__input-width"
                            />
                        </div>
                    </RenderIf>
                    <div className="d-flex align-items-center mt-2 w-75">
                        <NSLabel className={FORM_LABEL_CLASS_NAME}>Parking</NSLabel>
                        <div className="w-100">
                            <NSSelect
                                isMulti
                                name="parking"
                                options={dealTypeProFormaParkingOptions[assetType]}
                                value={values.parking}
                                onChange={options => handleSelect('parking', options || [])}
                                className="w-75"
                            />
                        </div>
                    </div>

                    {assetType === DEAL_TYPES.APARTMENT ? (
                        <>
                            {renderParkingSpaces(
                                'structured',
                                'Structured parking spaces',
                                values.structuredParkingSpaces,
                                count => setValues({ ...values, structuredParkingSpaces: count }),
                                1,
                            )}
                            {renderParkingSpaces(
                                'surface',
                                'Surface parking spaces',
                                values.surfaceParkingSpaces,
                                count => setValues({ ...values, surfaceParkingSpaces: count }),
                                1,
                            )}
                        </>
                    ) : (
                        <>
                            {renderParkingSpaces(
                                'car',
                                'Car parking spaces',
                                values.structuredParkingSpaces,
                                count => setValues({ ...values, structuredParkingSpaces: count }),
                                1,
                            )}
                            {renderParkingSpaces(
                                'trailer',
                                'Trailer parking spaces',
                                values.surfaceParkingSpaces,
                                count => setValues({ ...values, surfaceParkingSpaces: count }),
                                1,
                            )}
                        </>
                    )}
                </div>
                {/* Assumptions */}
                <div className="ProFormaWizardStepOne__separator" />
                <small>3 of 3</small>
                <div className="ProFormaWizardStepOne__title">Assumptions</div>
                <div className="ProFormaWizardStepOne__subtitle">Assumptions about the sale of this investment</div>
                <div className="w-50">
                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className="ProFormaWizardStepOne__label my-auto text-white font-weight-normal">Sale month</NSLabel>
                        <NSInput
                            id="saleDate"
                            name="saleDate"
                            placeholder="Select date..."
                            inputClassName="w-50 pl-2"
                            appendInputAddon={
                                <div>
                                    <FontAwesomeIcon icon={faCalendar} size="lg" onClick={() => setSaleMonthPicker(true)} style={{ cursor: 'pointer' }} />
                                    {saleMonthPicker && (
                                        <div className="ProFormaWizardStepOne__datepicker">
                                            <DatePicker
                                                selected={saleDate}
                                                onChange={(date: Date | null) => {
                                                    setSaleDate(date);
                                                    setSaleMonthPicker(false);
                                                }}
                                                inline
                                                onClickOutside={() => setSaleMonthPicker(false)}
                                            />
                                        </div>
                                    )}
                                </div>
                            }
                            type="text"
                            value={saleDate ? formatDate(saleDate) : ''}
                        />
                    </div>

                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className="ProFormaWizardStepOne__label my-auto text-white font-weight-normal">Current market cap rate</NSLabel>
                        <NSInputNumberStepper
                            name="currentCapRate"
                            value={values.currentCapRate}
                            onChange={(count: number) => setValues({ ...values, currentCapRate: count })}
                            min={0.1}
                            step={0.1}
                            percentage
                            inputGroupClassName="ProFormaWizardStepOne__input-width ml-2"
                        />
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className="ProFormaWizardStepOne__label my-auto text-white font-weight-normal">Projected cap rate at sale</NSLabel>
                        <NSInputNumberStepper
                            name="projectedCapRate"
                            value={values.projectedCapRate}
                            onChange={(count: number) => setValues({ ...values, projectedCapRate: count })}
                            min={0.1}
                            step={0.1}
                            percentage
                            inputGroupClassName="ProFormaWizardStepOne__input-width ml-2"
                        />
                    </div>
                    <div className="d-flex align-items-center mt-2">
                        <NSLabel className="ProFormaWizardStepOne__label my-auto text-white font-weight-normal">Selling costs</NSLabel>
                        <NSInputNumberStepper
                            name="sellingCosts"
                            value={values.sellingCosts}
                            onChange={(count: number) => setValues({ ...values, sellingCosts: count })}
                            min={1}
                            step={1}
                            percentage
                            inputGroupClassName="ProFormaWizardStepOne__input-width ml-2"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProFormaWizardStepOne;
