import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSButton, NSCard, NSInput, NSLabel, NSSelect } from 'bricks';
import { faClone, faTrash, faDollar } from '@fortawesome/free-solid-svg-icons';
import './UnitMixOverviewCard.scss';
import { formatUSDCurrencyTwoDigits } from 'ns_libs/formatter';
import { IOptionAnyValue } from 'bricks/types';
import { IUnitMix } from '../../types';

interface UnitMixOverviewCardProps {
    values: IUnitMix;
    onDuplicate: () => void;
    onDelete: () => void;
    onUpdate: (updatedValues: UnitMixOverviewCardProps['values']) => void;
}

const baseOptions = [
    { value: 1, label: '1' },
    { value: 2, label: '2' },
    { value: 3, label: '3' },
    { value: 4, label: '4' },
    { value: 5, label: '5+' },
];

const UnitMixOverviewCard = ({ values, onDuplicate, onDelete, onUpdate }: UnitMixOverviewCardProps) => {
    const [selectedBedrooms, setSelectedBedrooms] = useState<IOptionAnyValue | null>(null);
    const [selectedBathrooms, setSelectedBathrooms] = useState<IOptionAnyValue | null>(null);
    const [averageSF, setAverageSF] = useState<number>(values.averageSF || 0);
    const [monthlyRent, setMonthlyRent] = useState<number>(values.monthlyRent || 0);
    const [totalUnits, setTotalUnits] = useState<number>(values.totalUnits || 0);
    const [editingName, setEditingName] = useState(false);
    const [editName, setEditName] = useState(values.name || 'Default name');

    const bedroomOptions = [{ value: 0, label: 'Studio' }, ...baseOptions];
    const bathroomOptions = [...baseOptions];

    useEffect(() => {
        onUpdate({
            id: values.id,
            name: editName,
            bedrooms: selectedBedrooms?.value,
            bathrooms: selectedBathrooms?.value,
            averageSF,
            monthlyRent,
            totalUnits,
            totalRent: monthlyRent * totalUnits * 12,
        });
    }, [editName, monthlyRent, selectedBedrooms, selectedBathrooms, averageSF, totalUnits]);

    return (
        <NSCard className="NSCard--level-4 mb-2 p-2">
            <div className="d-flex justify-content-between">
                {editingName ? (
                    <input
                        type="text"
                        value={editName}
                        onChange={e => setEditName(e.target.value)}
                        onBlur={() => setEditingName(false)}
                        onKeyDown={e => e.key === 'Enter' && setEditingName(false)}
                        className="form-control p-1 w-50"
                    />
                ) : (
                    <div
                        className="UnitMixOverviewCard__title cursor--pointer"
                        onClick={() => setEditingName(true)}
                        onKeyDown={e => e.key === 'Enter' && setEditingName(true)}
                        role="button"
                        tabIndex={0}
                    >
                        {editName || 'Default name'}
                    </div>
                )}
                <div>
                    <NSButton className="p-0 mr-2 UnitMixOverviewCard__noOutline" callback={onDuplicate} aria-label="clone">
                        <FontAwesomeIcon icon={faClone} className="NSNavbar__right__icon-list__icon cursor--pointer" />
                    </NSButton>
                    <NSButton className="p-0 UnitMixOverviewCard__noOutline" callback={onDelete} aria-label="trash">
                        <FontAwesomeIcon icon={faTrash} className="NSNavbar__right__icon-list__icon cursor--pointer" />
                    </NSButton>
                </div>
            </div>
            <div className="UnitMixOverviewCard__separator" />
            <div className="d-flex justify-content-between UnitMixOverviewCard__inputContainer">
                <div className="UnitMixOverviewCard__inputContainer__dropdown">
                    <NSLabel>Bedrooms</NSLabel>
                    <NSSelect name="bedroomOptions" options={bedroomOptions} value={selectedBedrooms} onChange={option => setSelectedBedrooms(option)} />
                </div>
                <div className="UnitMixOverviewCard__inputContainer__dropdown">
                    <NSLabel>Bathrooms</NSLabel>
                    <NSSelect
                        name="bathroomOptions"
                        options={bathroomOptions}
                        value={selectedBedrooms?.value === 0 ? bathroomOptions[0] : selectedBathrooms}
                        onChange={option => setSelectedBathrooms(option)}
                        isDisabled={selectedBedrooms?.value === 0}
                    />
                </div>
                <div className="UnitMixOverviewCard__inputContainer__input">
                    <NSLabel>Average SF</NSLabel>
                    <NSInput
                        id="averageSF"
                        name="averageSF"
                        placeholder="Enter avg. SF"
                        type="number"
                        value={averageSF || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAverageSF(Number(e.target.value))}
                    />
                </div>
            </div>
            <div className="d-flex justify-content-between UnitMixOverviewCard__inputContainer pt-2">
                <div className="UnitMixOverviewCard__inputContainer__largeInput">
                    <NSLabel>Monthly rent</NSLabel>
                    <NSInput
                        id="monthlyRent"
                        name="monthlyRent"
                        placeholder="Enter monthly rent"
                        type="number"
                        value={monthlyRent || ''}
                        prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMonthlyRent(Number(e.target.value))}
                    />
                </div>
                <div className="UnitMixOverviewCard__inputContainer__input">
                    <NSLabel>Total units</NSLabel>
                    <NSInput
                        id="totalUnits"
                        name="totalUnits"
                        placeholder="Enter total units"
                        type="number"
                        value={totalUnits || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTotalUnits(Number(e.target.value))}
                    />
                </div>
            </div>
            <div className="UnitMixOverviewCard__separator" />
            <div className="d-flex justify-content-between text-white font-weight-bold">
                <div>Total rent/year:</div>
                <div>{totalUnits ? formatUSDCurrencyTwoDigits(monthlyRent * totalUnits * 12) : '-'}</div>
            </div>
        </NSCard>
    );
};

export default UnitMixOverviewCard;
