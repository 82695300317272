import PageTitle from 'components/PageTitle/PageTitle';
import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { IOptionAnyValue } from 'bricks/types';
import CreateSection from './CreateSection/CreateSection';
import NewCombinedReportSection from './NewCombinedReportSection/NewCombinedReportSection';
import './ReportBuilder.scss';
import { IReportBuilderReport } from './constants';

const ReportBuilder = () => {
    const [selectedTemplate, setSelectedTemplate] = useState<IOptionAnyValue>();
    const [newReports, setNewReports] = useState<Array<IReportBuilderReport>>([]);
    const orgId = '123e4567-e89b-12d3-a456-426614174000';

    return (
        <>
            <PageTitle title="Report Builder" displayBreadcrumb dealName="4th Street Highrise" pageMetaDataTitle="Report Builder" />
            <Row className="mt-3 d-flex">
                <Col sm={11} md={9} lg={4} className="pb-3">
                    <CreateSection
                        orgId={orgId}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        newReports={newReports}
                        setNewReports={setNewReports}
                    />
                </Col>
                <Col sm={11} md={9} lg={8} className="pb-3">
                    <NewCombinedReportSection selectedTemplate={selectedTemplate} newReports={newReports} orgId={orgId} />
                </Col>
            </Row>
        </>
    );
};

export default ReportBuilder;
