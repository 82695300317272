import { NSButton, NSDropdown, NSInput, NSLabel } from 'bricks';
import NSModal, { INSModalProps } from 'bricks/NSModal/NSModal';
import { FormikHelpers } from 'formik';
import React, { useEffect, useState } from 'react';
import { IOptionAnyValue } from 'bricks/types';
import RenderIf from 'components/RenderIf/RenderIf';
import { useQueryClient } from '@tanstack/react-query';
import { BOESlideoutFormValues } from '../SlideoutForm/types';
import { useGetBackOfEnvelopeDeals } from '../../hooks/useGetBOEDeals';

export interface IImportUsesModalProps extends INSModalProps {
    dealId?: number;
    onConfirm: () => void;
    onCancel: () => void;
    setFieldValue?: FormikHelpers<BOESlideoutFormValues>['setFieldValue'];
    selectedScenarioName?: string;
}

const SaveModal = ({ onConfirm, onCancel, setFieldValue, children, dealId, selectedScenarioName = '', ...props }: IImportUsesModalProps) => {
    const [scenarioName, setScenarioName] = useState<string>(selectedScenarioName);
    const [selectedScenario, setSelectedScenario] = useState<IOptionAnyValue | undefined>(undefined);

    const orgId = '123e4567-e89b-12d3-a456-426614174000'; // TODO Remove this hardcoded orgId
    const { data: dropdownDeals } = useGetBackOfEnvelopeDeals({ orgId }); // DEALS WITH NESTED SCENARIOS
    const queryClient = useQueryClient();

    const mappedDeals = dropdownDeals?.items.map(item => ({
        value: item.id,
        label: item.name,
    }));

    const handleCancel = () => {
        onCancel();
        setSelectedScenario(undefined);
        setScenarioName('');
    };

    const handleSave = () => {
        const selectedDeal = mappedDeals?.find(deal => deal.value === selectedScenario?.value);
        if (setFieldValue) {
            setFieldValue('name', scenarioName || selectedScenarioName);
            setFieldValue('dealId', selectedDeal?.value);
        }

        onConfirm();
        setScenarioName('');
        queryClient.invalidateQueries({ queryKey: ['back-of-envelope', orgId] });
        onCancel();
    };

    useEffect(() => {
        if (!props.isOpen) {
            setScenarioName('');
        } else {
            setScenarioName(selectedScenarioName || '');
        }
    }, [props.isOpen, selectedScenarioName]);

    return (
        <NSModal
            {...props}
            modalHeaderText="Save scenario"
            modalHeaderClassName="bg-primary text-white"
            modalFooterButtonText="Save to deal"
            modalFooterCancelButtonText="Cancel"
            modalFooterButtonColor="primary"
            modalFooterCancelButtonFunction={handleCancel}
            modalFooterButtonFunction={handleSave}
            customFooter={
                selectedScenario ? undefined : (
                    <div>
                        <NSButton text="Cancel" callback={handleCancel} outline />
                        <NSButton className="mx-2" color="secondary" text="Save" callback={handleSave} />
                        <NSButton color="primary" text="Create new deal from scenario" callback={handleSave} />
                    </div>
                )
            }
        >
            <NSInput
                id="scenarioName"
                type="text"
                label="Scenario name"
                name="scenarioName"
                placeholder="Enter name"
                value={scenarioName}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setScenarioName(e.target.value)}
                inputClassName="mb-2"
                required
            />
            <NSLabel>Save to deal</NSLabel>
            <NSDropdown
                options={mappedDeals || []}
                selectedOption={mappedDeals?.find(deal => deal.value === selectedScenario?.value) || undefined}
                handleSelectOption={option => setSelectedScenario(option)}
                customSelectedOptionText={selectedScenario?.label || 'Select deal'}
                placeholder="Select deal"
                isFullWidth
            />
            <RenderIf isTrue={!selectedScenario}>
                <small>If no deal is selected, this scenario will be saved as Uncategorized.</small>
            </RenderIf>
        </NSModal>
    );
};

export default SaveModal;
