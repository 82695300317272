import { NSButton, NSCard } from 'bricks';
import React, { useState } from 'react';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IOptionAnyValue } from 'bricks/types';
import EmptyState from './EmptyState';
import { IReportBuilderReport } from '../constants';
import SaveTemplateModal from './SaveTemplateModal/SaveTemplateModal';

export interface INewCombinedReportSectionProps {
    selectedTemplate: IOptionAnyValue | undefined;
    newReports: Array<IReportBuilderReport>;
    orgId: string;
}
const NewCombinedReportSection = ({ selectedTemplate, newReports, orgId }: INewCombinedReportSectionProps) => {
    const today = new Date();
    const todayDate = `${today.getMonth() + 1}/${today.getDate()}/${today.getFullYear()}`;
    const [isSaveTemplateModalOpen, setIsSaveTemplateModalOpen] = useState<boolean>(false);

    const toggleSaveTemplateModal = () => {
        setIsSaveTemplateModalOpen(!isSaveTemplateModalOpen);
    };

    return (
        <NSCard className="NSCard--level-1 p-3 h-100" style={{ minHeight: '250px', maxHeight: '80vh' }}>
            <div className=" d-flex justify-content-between">
                <div>
                    <h5 className="text-white m-0 p-0">New combined report</h5>
                    <p className="m-0 p-0">Created {todayDate}</p>
                </div>
                <div>
                    <NSButton dataTestId="options-btn" color="secondary" className="mr-1" disabled>
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                    </NSButton>
                    <NSButton
                        dataTestId="save-as-template-btn"
                        color="secondary"
                        className="mr-1"
                        callback={() => toggleSaveTemplateModal()}
                        disabled={newReports.length === 0}
                    >
                        Save as template
                    </NSButton>
                    <NSButton dataTestId="generate-report-btn" color="primary" disabled>
                        Generate report
                    </NSButton>
                </div>
            </div>
            {selectedTemplate && <div>{selectedTemplate?.label}</div>}
            {newReports.map(report => (
                <div key={report.savedReportId}>{report.savedReportName}</div>
            ))}
            {!selectedTemplate && newReports.length === 0 && <EmptyState />}
            {isSaveTemplateModalOpen && (
                <SaveTemplateModal orgId={orgId} newReports={newReports} isOpen={isSaveTemplateModalOpen} toggle={toggleSaveTemplateModal} />
            )}
        </NSCard>
    );
};

export default NewCombinedReportSection;
