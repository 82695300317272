import { formatUTCtoLocal } from 'ns_libs/formatter';

export const downloadFileFromUrl = async (url: string, fileName: string, fileExtension: string, includeDate: boolean = false) => {
    try {
        const fullFileName = includeDate ? `${fileName}-${formatUTCtoLocal(new Date(), 'MM-dd-yy')}.${fileExtension}` : `${fileName}.${fileExtension}`;
        // Fetch the file as a Blob
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Failed to fetch file: ${response.statusText}`);
        }

        const blob = await response.blob();

        // Create a temporary URL for the Blob and trigger the download
        const downloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = fullFileName;
        document.body.appendChild(link);
        link.click();

        // Clean up
        URL.revokeObjectURL(downloadUrl);
        document.body.removeChild(link);
    } catch (error) {
        console.error('Failed to download the file:', error);
    }
};
