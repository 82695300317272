import React from 'react';
import './ScenarioComparison.scss';
import { ScenarioProvider } from './context/ScenarioComparisonProvider';
import ScenarioComparisonManager from './ScenarioComparisonManager';

const ScenarioComparison = () => {
    return (
        <ScenarioProvider>
            <ScenarioComparisonManager />
        </ScenarioProvider>
    );
};

export default ScenarioComparison;
