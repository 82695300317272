/* eslint-disable no-mixed-operators */
import { LOAN_CALCULATION_TYPES } from 'views/DealDashboard/BOE/components/SlideoutForm/constants';
import { TraunchOption, ISource } from './Source/types';

export function createTraunchOptions(numberOfElements: number): TraunchOption[] {
    const traunchOptions: TraunchOption[] = [];
    for (let i = 1; i <= numberOfElements; i++) {
        traunchOptions.push({ label: i.toString(), value: i });
    }
    return traunchOptions;
}

export const calculateAmountForLTC = (loanToCost: number, totalUses: number): number => {
    return totalUses * (loanToCost / 100) + totalUses;
};

export const updateSourceField = (sources: ISource[], id: string, field: string, value: any, totalUses: number): ISource[] => {
    return sources.map(source => {
        if (source.id === id) {
            const updatedSource = { ...source, [field]: value };

            // Reset loan calculation type fields if 'calculate' changes,
            // on initial state set to LTC by default
            if (field === 'calculate') {
                updatedSource.loanCalculationType = value ? LOAN_CALCULATION_TYPES.LTC : undefined;
                updatedSource.loanToCost = undefined;
            }

            // If loan calculation type changes from LTC to another, reset amount
            if (field === 'loanCalculationType' && value !== LOAN_CALCULATION_TYPES.LTC) {
                updatedSource.amount = 0;
            }

            // Recalculate amount for LTC
            if (updatedSource.calculate && updatedSource.loanCalculationType === LOAN_CALCULATION_TYPES.LTC) {
                const loanToCost = updatedSource.loanToCost || 0;
                updatedSource.amount = calculateAmountForLTC(loanToCost, totalUses);
            }

            return updatedSource;
        }
        return source;
    });
};

export const removeSourceById = (sources: ISource[], id: string): ISource[] => {
    return sources.filter(source => source.id !== id);
};

export const addNewSource = (sources: ISource[]): ISource[] => {
    return [
        ...sources,
        {
            id: new Date().toString(),
            amount: 0,
            trunch: sources.length + 1,
            name: 'New source',
            type: '',
            calculate: false,
        },
    ];
};
