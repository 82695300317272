import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCirclePlus } from '@fortawesome/pro-regular-svg-icons';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { NSButton, NSTable } from 'bricks';
import { formatDate } from 'ns_libs/formatter';
import capitalize from 'lodash/capitalize';
import { useQueryClient } from '@tanstack/react-query';
import { useGetTimelines } from '../hooks/useGetTimeline';
import { useUpdateTimeline } from '../hooks/useUpdateTimeline';
import { useDeleteTimeline } from '../hooks/useDeleteTimeline';
import ActionMenu from './components/ActionMenu/ActionMenu';
import './Timeline.scss';
import 'react-datepicker/dist/react-datepicker.css';

type TimelineItem = {
    id: string;
    dealId: number;
    name: string;
    target: string | null;
    completed: string | null;
    status: 'open' | 'complete' | 'overdue';
};

const Timeline = ({ dealId }: { dealId: number }) => {
    const orgId = '123e4567-e89b-12d3-a456-426614174000'; // TODO Remove hardcoded orgId

    const [timelineData, setTimelineData] = useState<TimelineItem[]>([]);
    const [editingId, setEditingId] = useState<string | null>(null);
    const [editName, setEditName] = useState<string>('');
    const [activePicker, setActivePicker] = useState<{ id: string; field: 'target' | 'completed' } | null>(null);

    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { data: timelines } = useGetTimelines({ orgId, dealId });
    const { mutate: deleteTimeline } = useDeleteTimeline();

    useEffect(() => {
        if (timelineData?.length === 0 && timelines) {
            setTimelineData(timelines);
        }
    }, [timelines]);

    const handleDateChange = (id: string, field: 'target' | 'completed', date: Date): void => {
        const updatedTimeline = timelineData?.map(item => (item.id === id ? { ...item, [field]: date.toISOString().split('T')[0] } : item));

        setTimelineData(updatedTimeline);
        setActivePicker(null);

        updateTimeline(
            {
                orgId,
                dealId,
                timelineId: id,
                patchData: {
                    [field]: date.toISOString().split('T')[0],
                },
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: ['timelines', orgId, dealId] });
                },
                onError: (error: any) => {
                    console.error(`Failed to update timeline with ID: ${id}`, error);
                },
            },
        );
    };

    const onUpdateSuccess = (updatedTimeline: any) => {
        setTimelineData(prevTimeline => prevTimeline.map(item => (item.id === updatedTimeline.id ? { ...item, ...updatedTimeline } : item)));
    };

    const { mutate: updateTimeline } = useUpdateTimeline(onUpdateSuccess);

    const handleDelete = (id: string) => {
        deleteTimeline(
            {
                orgId,
                dealId,
                timelineId: id,
            },
            {
                onSuccess: () => {
                    setTimelineData(prevTimeline => prevTimeline.filter(item => item.id !== id));
                    queryClient.invalidateQueries({ queryKey: ['timelines', orgId, dealId] });
                },
                onError: (error: any) => {
                    console.error(`Failed to delete timeline with ID: ${id}`, error);
                },
            },
        );
    };

    const handleEdit = (id: string) => {
        const timeline = timelineData.find(item => item.id === id);
        if (timeline) {
            setEditingId(id);
            setEditName(timeline.name);

            setTimeout(() => {
                const input = window.document.querySelector<HTMLInputElement>(`#timeline-input-${id}`);
                input?.focus();
            }, 0);
        }
    };

    const cancelEdit = () => {
        setEditingId(null);
        setEditName('');
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEditName(event.target.value);
    };

    const handleNameUpdate = (id: string) => {
        setTimelineData(prevTimeline => prevTimeline.map(item => (item.id === id ? { ...item, name: editName } : item)));
        setEditingId(null);

        updateTimeline(
            {
                orgId,
                dealId,
                timelineId: id,
                patchData: {
                    name: editName,
                },
            },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: ['timelines', orgId, dealId] });
                },
                onError: (error: any) => {
                    console.error(`Failed to update timeline with ID: ${id}`, error);
                },
            },
        );
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>, id: string) => {
        if (event.key === 'Enter') {
            handleNameUpdate(id);
        } else if (event.key === 'Escape') {
            cancelEdit();
        }
    };

    return (
        <div className="px-2">
            <div className="d-flex justify-content-between align-items-center mb-2">
                <h5>Timeline</h5>
                <div>
                    <NSButton color="link" className="text-primary" callback={() => navigate('/settings')}>
                        Manage template
                    </NSButton>
                </div>
            </div>
            <NSTable className="TimelineTable" hover>
                <thead>
                    <tr>
                        <th>Activity</th>
                        <th>Target</th>
                        <th>Date Completed</th>
                        <th>Status</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {timelineData?.map(item => (
                        <tr key={item.id}>
                            <td>
                                {editingId === item.id ? (
                                    <input
                                        id={`timeline-input-${item.id}`}
                                        type="text"
                                        value={editName}
                                        onChange={handleNameChange}
                                        onBlur={() => handleNameUpdate(item.id)}
                                        onKeyDown={event => handleKeyPress(event, item.id)}
                                        className="form-control"
                                    />
                                ) : (
                                    <span
                                        onClick={() => handleEdit(item.id)}
                                        onKeyDown={e => e.key === 'Enter' && handleEdit(item.id)}
                                        role="button"
                                        tabIndex={0}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {item.name}
                                    </span>
                                )}
                            </td>
                            <td>
                                {item.target ? (
                                    <span>{formatDate(item.target)}</span>
                                ) : (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faCalendarCirclePlus}
                                            size="lg"
                                            className="text-primary"
                                            onClick={() => setActivePicker({ id: item.id, field: 'target' })}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        {activePicker?.id === item.id && activePicker?.field === 'target' && (
                                            <div className="TimelineTable__datepicker">
                                                <DatePicker
                                                    selected={item.target ? new Date(item.target) : null}
                                                    onChange={date => date && handleDateChange(item.id, 'target', date)}
                                                    inline
                                                    onClickOutside={() => setActivePicker(null)}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </td>
                            <td>
                                {item.completed ? (
                                    <span>{formatDate(item.completed)}</span>
                                ) : (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faCalendarCirclePlus}
                                            size="lg"
                                            className="text-primary"
                                            onClick={() => setActivePicker({ id: item.id, field: 'completed' })}
                                            style={{ cursor: 'pointer' }}
                                        />
                                        {activePicker?.id === item.id && activePicker?.field === 'completed' && (
                                            <div className="TimelineTable__datepicker">
                                                <DatePicker
                                                    selected={item.completed ? new Date(item.completed) : null}
                                                    onChange={date => date && handleDateChange(item.id, 'completed', date)}
                                                    inline
                                                    onClickOutside={() => setActivePicker(null)}
                                                />
                                            </div>
                                        )}
                                    </>
                                )}
                            </td>
                            <td>
                                <p className={`badge-status--${item.status.toLowerCase()}`}>{capitalize(item.status)}</p>
                            </td>
                            <td>
                                <ActionMenu onView={() => handleEdit(item.id)} onDelete={() => handleDelete(item.id)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </NSTable>
        </div>
    );
};

export default Timeline;
