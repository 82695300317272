import React, { createContext, useContext, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

interface StepHandler {
    validate?: () => boolean;
    getData?: () => any;
}

interface ProFormaWizardContextType {
    currentStep: number;
    stepHandlers: Record<number, StepHandler>;
    stepData: Record<number, any>;
    registerStepHandler: (step: number, handler: StepHandler) => void;
    handleNextStep: () => void;
    handlePrevStep: () => void;
    getStepData: (step: number) => any;
    setStepData: (step: number, data: any) => void;
}

interface ProFormaWizardProviderProps {
    children: React.ReactNode;
}

const ProFormaWizardContext = createContext<ProFormaWizardContextType | null>(null);

export const ProFormaWizardProvider: React.FC<ProFormaWizardProviderProps> = ({ children }) => {
    const [currentStep, setCurrentStep] = useState<number>(1);
    const [stepData, setStepDataState] = useState<Record<number, any>>({});
    const stepHandlers = useRef<Record<number, StepHandler>>({});
    const navigate = useNavigate();

    const totalSteps = 3;
    const isLastStep = currentStep === totalSteps;

    const registerStepHandler = (step: number, handler: StepHandler) => {
        stepHandlers.current[step] = handler;
    };

    const handleNextStep = async () => {
        const handler = stepHandlers.current[currentStep];

        // Validate current step
        if (handler?.validate && !handler.validate()) {
            console.error(`Validation failed for step ${currentStep}. Cannot proceed.`);
            return;
        }

        try {
            // Submit data for the current step, if available
            if (handler?.getData) {
                const stepDataFromHandler = handler.getData();
                setStepData(currentStep, stepDataFromHandler);
                console.log(`Storing data for step ${currentStep}:`, stepDataFromHandler);
            }
        } catch (error) {
            console.error(`Error simulating API for step ${currentStep}:`, error);
            alert('An error occurred. Please try again.');
            return;
        }

        if (isLastStep) {
            console.log('Finalizing wizard simulation with all step data:', stepData);
            navigate('/');
        } else {
            setCurrentStep(prev => prev + 1);
        }
    };

    const handlePrevStep = () => {
        if (currentStep === 1) {
            navigate('/'); // Replace `/` with the desired exit route
        } else {
            setCurrentStep(prev => prev - 1);
        }
    };

    const setStepData = (step: number, data: any) => {
        setStepDataState(prev => ({ ...prev, [step]: data }));
    };

    const getStepData = (step: number) => stepData[step] || null;

    return (
        <ProFormaWizardContext.Provider
            value={{
                currentStep,
                stepHandlers: stepHandlers.current,
                stepData,
                registerStepHandler,
                handleNextStep,
                handlePrevStep,
                setStepData,
                getStepData,
            }}
        >
            {children}
        </ProFormaWizardContext.Provider>
    );
};

export const useProFormaWizard = (): ProFormaWizardContextType => {
    const context = useContext(ProFormaWizardContext);
    if (!context) {
        throw new Error('useProFormaWizard must be used within a ProFormaWizardProvider');
    }
    return context;
};
