import axiosInstance from 'ns_libs/nsAxios';
import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import { IProFormaData } from 'views/ProFormaTable/types';

interface ICreateProFormaProps {
    dealId: number;
    proFormaId: number;
}

const getGrossProForma = async ({ dealId, proFormaId }: ICreateProFormaProps): Promise<IProFormaData> => {
    const response = await axiosInstance.get(API_ENDPOINTS.PRO_FORMA(dealId, proFormaId));
    return response.data;
};

const useGetProForma = (dealId: number, proFormaId: number) =>
    useQuery({
        queryKey: ['proFormaData'],
        queryFn: () => getGrossProForma({ dealId, proFormaId }),
    });

export default useGetProForma;
