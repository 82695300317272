import { numeralFormatter } from 'ns_libs/formatter';

/* eslint-disable operator-linebreak */
interface DeveloperFeeValues {
    acquisitionCosts: number;
    totalHardCosts: number;
    reserveInterest: number;
    developerFeePercent: number;
}

export const calculateTotalDeveloperFee = (values: DeveloperFeeValues): number => {
    const maxIterations = 100;
    const convergenceThreshold = 0.01;
    let iterations = 0;
    let prevDeveloperFeeTotal = 0;

    let developerFeeTotal = 0;

    while (iterations < maxIterations) {
        const totalDevelopmentCosts =
            (values.acquisitionCosts ?? 0) + values.totalHardCosts + values.totalHardCosts + developerFeeTotal + values.reserveInterest;

        developerFeeTotal = ((values.developerFeePercent ?? 0) / 100) * totalDevelopmentCosts;

        if (Math.abs(developerFeeTotal - prevDeveloperFeeTotal) < convergenceThreshold) {
            break;
        }
        prevDeveloperFeeTotal = developerFeeTotal;
        iterations++;
    }

    return developerFeeTotal;
};

export const calculateDeveloperFeePercent = (newDeveloperFeeAmount: number, values: DeveloperFeeValues): number => {
    const maxIterations = 100;
    const convergenceThreshold = 0.01;
    let iterations = 0;

    let developerFeePercent = 0;
    let prevDeveloperFeePercent = 0;

    while (iterations < maxIterations) {
        const totalDevelopmentCosts =
            (values.acquisitionCosts ?? 0) + values.totalHardCosts + values.totalHardCosts + newDeveloperFeeAmount + values.reserveInterest;

        developerFeePercent = totalDevelopmentCosts > 0 ? (newDeveloperFeeAmount / totalDevelopmentCosts) * 100 : 0;

        if (Math.abs(developerFeePercent - prevDeveloperFeePercent) < convergenceThreshold) {
            break;
        }
        prevDeveloperFeePercent = developerFeePercent;
        iterations++;
    }

    return numeralFormatter(developerFeePercent);
};
