export const headerCellClassName = 'd-flex justify-content-between align-items-center text-dark';

export const NORTHSPYRE_PRO_FORMAS = 'Northspyre pro formas';
export const UPLOADED_PRO_FORMAS = 'Uploaded pro formas';

export const PRO_FORMA_HOME_TABS = [NORTHSPYRE_PRO_FORMAS, UPLOADED_PRO_FORMAS];

export const PRO_FORMA_STATUS_PROJECT_CREATED = 1;
export const PRO_FORMA_STATUS_SUBSTANTIALLY_COMPLETE = 2;
export const PRO_FORMA_STATUS_IN_PROGRESS = 3;
export const PRO_FORMA_STATUS_DRAFT = 4;

export const PRO_FORMA_STATUS_MAPPING: { [key: number]: { name: string; color: string } } = {
    [PRO_FORMA_STATUS_PROJECT_CREATED]: {
        name: 'Project created',
        color: 'primary-lighten',
    },
    [PRO_FORMA_STATUS_SUBSTANTIALLY_COMPLETE]: {
        name: 'Substantially complete',
        color: 'success-lighten',
    },
    [PRO_FORMA_STATUS_IN_PROGRESS]: {
        name: 'In progress',
        color: 'warning-lighten',
    },
    [PRO_FORMA_STATUS_DRAFT]: {
        name: 'Draft',
        color: 'dark-lighten',
    },
};

export const PRO_FORMA_DUPLICATE = 'Duplicate';
export const PRO_FORMA_SET_AS_ACTIVE = 'Set as active';
export const PRO_FORMA_DELETE = 'Delete';

export const PRO_FORMA_ELLIPSIS_OPTIONS: { [key: number]: string[] } = {
    [PRO_FORMA_STATUS_PROJECT_CREATED]: [PRO_FORMA_DUPLICATE],
    [PRO_FORMA_STATUS_SUBSTANTIALLY_COMPLETE]: [PRO_FORMA_DUPLICATE, PRO_FORMA_DELETE],
    [PRO_FORMA_STATUS_IN_PROGRESS]: [PRO_FORMA_DUPLICATE, PRO_FORMA_DELETE],
    [PRO_FORMA_STATUS_DRAFT]: [PRO_FORMA_DELETE],
};

export const PRO_FORMAS_TABLE_HEADERS = [
    {
        key: 'name',
        name: 'Name',
    },
    {
        key: 'boeScenarioId',
        name: 'Scenario',
    },
    {
        key: 'lastModified',
        name: 'Last modified',
    },
    {
        key: 'createdAt',
        name: 'Date created',
    },
    {
        key: 'createdBy',
        name: 'Created by',
    },
    {
        key: 'proFormaStatusId',
        name: 'Status',
    },
];
