/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import { IProFormaUses, DevelopmentUnitOfMeasureTypes } from 'views/ProFormaTable/types';
import { NSCheckbox } from 'bricks';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp, faPlus } from '@fortawesome/free-solid-svg-icons';
import RenderIf from 'components/RenderIf/RenderIf';
import classNames from 'classnames';
import { useCreateBudgetClassification } from 'queries/BudgetClassification';
import { PRO_FORMA_UNIT_TO_FIELD_VALUES } from 'constants/unitTypes';
import { DEFAULT_SUBCATEGORY_NAME, mockOrgId } from 'views/ProFormaTable/constants';
import { useUsesTableContext } from '../context/UsesTableProvider';
import UsesTableSubcategory from './UsesTableSubcategory/UsesTableSubcategory';

export interface IUsesTableHighLevel {
    highLevel: IProFormaUses;
    unitType: DevelopmentUnitOfMeasureTypes;
}

const UsesTableHighLevel = ({ highLevel, unitType }: IUsesTableHighLevel) => {
    const { selectedCategories, expandedCategories, updateSelectedCategories, updateExpandedCategories } = useUsesTableContext();
    const [isHovered, setIsHovered] = useState(false);

    const { mutate: createBudgetClassifications } = useCreateBudgetClassification();
    const createSubcategory = () => {
        const postBody = {
            nodeName: DEFAULT_SUBCATEGORY_NAME,
            description: DEFAULT_SUBCATEGORY_NAME,
            parentId: highLevel.id,
        };
        createBudgetClassifications(
            { organizationId: mockOrgId, proformaId: highLevel.proFormaId, postBody },
            {
                onSuccess: () => {
                    // call fetchUses() in the provider to refetch the uses table data
                    if (!expandedCategories.highLevels.includes(highLevel.id)) {
                        updateExpandedCategories(highLevel.id, null);
                    }
                },
                onError: error => console.error('Error creating subcategory:', error),
            },
        );
    };

    const subcategoryIds: number[] = [];
    const budgetClassificationIds: number[] = [];

    highLevel.children?.map(subcategory => {
        subcategoryIds.push(subcategory.id);
        subcategory.children?.map(budgetClassification => budgetClassificationIds.push(budgetClassification.id));

        return null;
    });

    const highLevelAmount = highLevel.totals && highLevel.totals?.amount !== null ? highLevel.totals?.amount : null;

    const highLevelUnitAmount =
        highLevel.totals && highLevel.totals[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType]] !== null
            ? highLevel.totals[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType]]
            : null;

    return (
        <>
            <tr
                className="NSTable__tbody__tr NSTable__tbody__tr--level-1"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                <td className="NSTable__tbody__tr__td--right-border text-dark d-flex align-items-center justify-content-between">
                    <div className="d-flex">
                        <NSCheckbox
                            id={`selectHighLevel-${highLevel.id}`}
                            checked={selectedCategories.highLevels.includes(highLevel.id)}
                            callback={() => updateSelectedCategories(highLevel.id, subcategoryIds, budgetClassificationIds)}
                            containerClassName="custom-control-inline align-middle"
                        />
                        <div
                            role="presentation"
                            onClick={() => {
                                updateExpandedCategories(highLevel.id, null);
                            }}
                        >
                            <RenderIf isTrue={Boolean(highLevel.children?.length)}>
                                <FontAwesomeIcon
                                    className="icon mr-1"
                                    icon={expandedCategories.highLevels.includes(highLevel.id) ? faCaretUp : faCaretDown}
                                    fixedWidth
                                />
                            </RenderIf>
                            <span className={classNames({ 'ml-3': !highLevel.children?.length })}>{highLevel.description}</span>
                        </div>
                    </div>
                    <RenderIf isTrue={isHovered}>
                        <FontAwesomeIcon icon={faPlus} onClick={createSubcategory} />
                    </RenderIf>
                </td>
                <td className="NSTable__tbody__tr__td" />
                <td className="NSTable__tbody__tr__td" />
                <td className="NSTable__tbody__tr__td" />
                <td className="NSTable__tbody__tr__td">
                    <div className="d-flex justify-content-end text-dark">
                        {highLevelUnitAmount !== null ? numeralFormatterCurrency(highLevelUnitAmount) : '—'}
                    </div>
                </td>
                <td className="NSTable__tbody__tr__td">
                    <div className="d-flex justify-content-end text-dark">
                        {highLevelAmount !== null ? numeralFormatterCurrency(highLevelAmount) : '—'}
                    </div>
                </td>
            </tr>

            <RenderIf isTrue={expandedCategories.highLevels.includes(highLevel.id)}>
                {(highLevel.children || []).map(subcategory => (
                    <UsesTableSubcategory
                        key={subcategory.id}
                        subcategory={subcategory}
                        unitType={unitType}
                        budgetClassificationIds={budgetClassificationIds}
                    />
                ))}
            </RenderIf>
        </>
    );
};

export default UsesTableHighLevel;
