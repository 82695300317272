import NSModal, { INSModalProps } from 'bricks/NSModal/NSModal';
import classNames from 'classnames';
import React from 'react';

export interface IConfirmationModalProps extends Omit<INSModalProps, 'modalFooterCancelButtonFunction' | 'modalFooterCancelButtonFunction'> {
    onConfirm?: () => void;
    onCancel?: () => void;
    danger?: boolean;
}

const ConfirmationModal = ({
    onConfirm,
    onCancel,
    danger,
    children,
    modalHeaderClassName,
    modalFooterButtonClassName,
    ...rest
}: IConfirmationModalProps) => {
    return (
        <NSModal
            {...rest}
            modalHeaderClassName={classNames(['bg-primary text-white', modalHeaderClassName, { 'bg-danger': danger }])}
            modalFooterCancelButtonFunction={onCancel}
            modalFooterButtonFunction={onConfirm}
            modalFooterButtonClassName={classNames([modalFooterButtonClassName, { 'bg-danger': danger, 'border-0': danger }])}
        >
            {children}
        </NSModal>
    );
};

export default ConfirmationModal;
