import { useQueryClient, useMutation } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';
import { IProFormaData } from 'views/ProFormaTable/types';

interface IDuplicateProFormaProps {
    organizationId: string;
    proFormaId: number;
}

const duplicateProForma = async ({ organizationId, proFormaId }: IDuplicateProFormaProps): Promise<IProFormaData> => {
    const response = await axiosInstance.post(API_ENDPOINTS.DUPLICATE_PRO_FORMA(organizationId, proFormaId));
    return response.data;
};

const useDuplicateProForma = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: duplicateProForma,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getProFormas'] });
        },
        onError: error => {
            console.error('Error duplicating pro forma:', error);
        },
    });
};

export default useDuplicateProForma;
