/* eslint-disable no-unused-vars */
export enum TABS {
    ATTRIBUTES = 'Attributes',
    FILTERS = 'Filters',
}

export enum Attributes {
    Name = 'Name',
    Address = 'Address',
    City = 'City',
    County = 'County',
    State = 'State',
    ZipCode = 'Zip code',
    Market = 'Market',
    Submarket = 'Submarket',
    DateCreated = 'Date created',
    Description = 'Description',
    DealType = 'Deal type',
    AssetClass = 'Asset class',
    ConstructionType = 'Construction type',
    Units = 'Units',
    Phase = 'Phase',
    Progress = 'Progress',
    LeadSource = 'Lead source',
    LeasingStrategy = 'Leasing strategy',
    Rank = 'Rank',
    AcquisitionPrice = 'Acquisition price',
    TargetReturnOnCost = 'Target return on cost',
    TargetReturnOnEquity = 'Target return on equity',
    TargetProfitReturn = 'Target profit return',
    IRR = 'IRR',
    EquityMultiple = 'Equity multiple',
    DealCreator = 'Deal creator',
    DealOwner = 'Deal owner',
    DevelopmentManager = 'Development manager',
    EquityPartner = 'Equity partner',
    Lender = 'Lender',
    LegalCounsel = 'Legal counsel',
    Architect = 'Architect',
    Engineer = 'Engineer',
    Surveyor = 'Surveyor',
    EnvironmentalConsultant = 'Environmental Consultant',
    UserDefined = 'User defined',
}

export const AttributeKeysMap: Record<string, Attributes> = {
    deal_name: Attributes.Name,
    address: Attributes.Address,
    city: Attributes.City,
    county: Attributes.County,
    state: Attributes.State,
    zip_code: Attributes.ZipCode,
    market: Attributes.Market,
    submarket: Attributes.Submarket,
    created_at: Attributes.DateCreated,
    description: Attributes.Description,
    deal_type: Attributes.DealType,
    asset_type_id: Attributes.AssetClass,
    construction_type_id: Attributes.ConstructionType,
    deal_unit_types: Attributes.Units,
    subproject_stage_id: Attributes.Phase,
    progress: Attributes.Progress,
    lead_source: Attributes.LeadSource,
    leasing_strategy: Attributes.LeasingStrategy,
    rank: Attributes.Rank,
    acquisition_price: Attributes.AcquisitionPrice,
    target_return_on_cost: Attributes.TargetReturnOnCost,
    target_return_on_equity: Attributes.TargetReturnOnEquity,
    target_profit_return: Attributes.TargetProfitReturn,
    irr: Attributes.IRR,
    equity_multiple: Attributes.EquityMultiple,
    deal_creator: Attributes.DealCreator,
    deal_owner: Attributes.DealOwner,
    development_manager: Attributes.DevelopmentManager,
    equity_partner: Attributes.EquityPartner,
    lender: Attributes.Lender,
    legal_counsel: Attributes.LegalCounsel,
    architect: Attributes.Architect,
    engineer: Attributes.Engineer,
    surveyor: Attributes.Surveyor,
    environmental_consultant: Attributes.EnvironmentalConsultant,
    user_defined: Attributes.UserDefined,
};

export const dollarValueAttributes = [
    Attributes.AcquisitionPrice,
    Attributes.TargetReturnOnCost,
    Attributes.TargetReturnOnEquity,
    Attributes.TargetProfitReturn,
];
export const percentageValueAttributes = [Attributes.Progress];
