import axiosInstance from 'ns_libs/nsAxios';
import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import { IProFormaSource } from 'types/db/ProFormaSource';
import { LoanRateType } from 'views/ProFormaTable/types';

interface ICreateProFormaSourcePostBody {
    sourceTypeId: string;
    name: string;
    traunch: number | null;
    amount: string;
    loanRateType?: LoanRateType | null;
    fixedLoanRate?: string | null;
    debtYield?: string | null;
    loanToCost?: string | null;
    loanToValue?: string | null;
}

interface IVariables {
    organizationId: string;
    proFormaId: string;
    postBody: ICreateProFormaSourcePostBody;
}

const createProFormaSource = async ({ organizationId, proFormaId, postBody }: IVariables): Promise<IProFormaSource> => {
    const response = await axiosInstance.post(API_ENDPOINTS.PRO_FORMA_SOURCE(organizationId, proFormaId), postBody);
    return response.data;
};

export const useCreateProFormaSource = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<IProFormaSource, TError, IVariables, TContext>,
) => {
    return useMutation<IProFormaSource, TError, IVariables, TContext>({
        mutationFn: createProFormaSource,
        ...options,
    });
};

export default useCreateProFormaSource;
