import { formatPercentage, formatUSDCurrency, formatUSDCurrencyTwoDigits, numeralFormatter } from 'ns_libs/formatter';
import { Deal } from 'views/Deals/types';
import { BOEScenario } from './types';
import { BOESlideoutFormValues } from './components/SlideoutForm/types';
import { ASSET_TYPE, ASSET_TYPE_OPTIONS, LOAN_CALCULATION_OPTIONS, LOAN_CALCULATION_TYPES } from './components/SlideoutForm/constants';

export type FormatterType = 'formatPercentage' | 'formatUSDCurrency' | 'numeralFormatter';

interface ScenarioRow {
    label: string;
    value: number | string | null;
    formatter?: FormatterType;
    placeholder?: string;
}

interface ScenarioSection {
    title: string[];
    value: (number | string)[];
    formatter: FormatterType[];
    rows: ScenarioRow[][];
    placeholder?: string;
}

interface ScenarioReturnsSection {
    title: string[];
    value: (number | string)[];
    target?: number;
    formatter: FormatterType[];
    displayMode: 'unstyled' | 'danger' | 'success';
}

export const formatBOEValues = (value: number, formatter?: string) => {
    switch (formatter) {
        case 'formatUSDCurrency':
            return formatUSDCurrency(value);
        case 'formatUSDCurrencyTwoDigits':
            return formatUSDCurrencyTwoDigits(value);
        case 'numeralFormatter':
            return numeralFormatter(value);
        case 'formatPercentage':
            return formatPercentage(value * 100, 100, 2);
        default:
            return value;
    }
};
const placeholders = {
    propertySize: {
        title: 'PROPERTY SIZE',
        placeholder: 'Built GSF',
        rows: {
            efficiencyRatio: 'Efficiency Ratio',
            netRental: 'Net Rental SF',
        },
    },
    grossPotentialRent: {
        title: 'GROSS POTENTIAL RENT',
        rows: {
            monthlyRentPricePerUnit: { title: 'Monthly rent price (per unit)', placeholder: '/ unit' },
            monthlyRentPricePerSf: { title: 'Monthly rent price (per SF)', placeholder: '/ SF' },
            numberOfUnits: 'Number of units',
            sfPerUnit: 'SF per unit',
        },
    },
    netOperatingIncome: {
        title: 'NET OPERATING INCOME',
        rows: {
            grossPotentialRent: 'Gross potent. rent',
            monthlyExpenseCostPerUnit: 'Monthly expense / unit',
            monthlyExpenseCostPerSf: 'Monthly expense / SF',
            expensesTotal: 'Total annual expenses',
        },
    },
    totalDevelopmentCosts: {
        title: 'TOTAL DEVELOPMENT COSTS',
        rows: {
            acquisitionCosts: 'Acquisition costs',
            hardCostsPerUnit: 'Hard costs (per unit)',
            hardCostsPerSf: 'Hard costs (per SF)',
            softCostsPerUnit: 'Soft costs (per unit)',
            softCostsPerSf: 'Soft costs (per SF)',
            financingCosts: 'Financing costs',
        },
    },
    loanAndEquity: {
        title: ['LOAN AMOUNT', 'REQUIRED EQUITY'],
        rows: {
            constructionFinancing: 'Construction Financing (LTC)',
        },
    },
    returns: {
        returnOnCost: 'RETURN ON COST',
        returnOnEquity: 'RETURN ON EQUITY',
        profitReturn: 'PROFIT RETURN',
    },
};

export const generateScenarioInformation = (BOEScenario: BOEScenario): ScenarioSection[][] => {
    return [
        [
            {
                title: [placeholders.propertySize.title],
                value: [Number(BOEScenario.builtSf || 0)],
                formatter: ['numeralFormatter'],
                placeholder: placeholders.propertySize.placeholder,
                rows: [
                    [
                        {
                            label: placeholders.propertySize.rows.efficiencyRatio,
                            value: BOEScenario.buildingEfficiency ? Number(BOEScenario.buildingEfficiency) : null,
                            formatter: 'formatPercentage',
                        },
                        {
                            label: placeholders.propertySize.rows.netRental,
                            value: BOEScenario.netRentableSf ? Number(BOEScenario.netRentableSf) : null,
                            formatter: 'numeralFormatter',
                        },
                    ],
                ],
            },
            {
                title: [placeholders.grossPotentialRent.title],
                value: [BOEScenario.grossPotentialRent ? Number(BOEScenario.grossPotentialRent) : 0],
                formatter: ['formatUSDCurrency'],
                rows: [
                    [
                        {
                            label: placeholders.grossPotentialRent.rows.monthlyRentPricePerUnit.title,
                            value: BOEScenario.monthlyRentPricePerUnit ? Number(BOEScenario.monthlyRentPricePerUnit) : null,
                            formatter: 'formatUSDCurrency',
                            placeholder: placeholders.grossPotentialRent.rows.monthlyRentPricePerUnit.placeholder,
                        },
                        {
                            label: placeholders.grossPotentialRent.rows.monthlyRentPricePerSf.title,
                            value: BOEScenario.monthlyRentPricePerSf ? Number(BOEScenario.monthlyRentPricePerSf) : null,
                            formatter: 'formatUSDCurrency',
                            placeholder: placeholders.grossPotentialRent.rows.monthlyRentPricePerSf.placeholder,
                        },
                        {
                            label: placeholders.grossPotentialRent.rows.numberOfUnits,
                            value: BOEScenario.numberOfUnits ? Number(BOEScenario.numberOfUnits) : null,
                        },
                        {
                            label: placeholders.grossPotentialRent.rows.sfPerUnit,
                            value: BOEScenario.sfPerUnit ? Number(BOEScenario.sfPerUnit) : null,
                        },
                    ],
                ],
            },
            {
                title: [placeholders.netOperatingIncome.title],
                value: [BOEScenario.netOperatingIncome ? Number(BOEScenario.netOperatingIncome) : 0],
                formatter: ['formatUSDCurrency'],
                rows: [
                    [
                        {
                            label: placeholders.netOperatingIncome.rows.grossPotentialRent,
                            value: BOEScenario.grossPotentialRent ? Number(BOEScenario.grossPotentialRent) : null,
                            formatter: 'formatUSDCurrency',
                        },
                        {
                            label: placeholders.netOperatingIncome.rows.monthlyExpenseCostPerUnit,
                            value: BOEScenario.monthlyExpenseCostPerUnit ? Number(BOEScenario.monthlyExpenseCostPerUnit) : null,
                            formatter: 'formatUSDCurrency',
                        },
                        {
                            label: placeholders.netOperatingIncome.rows.monthlyExpenseCostPerSf,
                            value: BOEScenario.monthlyExpenseCostPerSf ? Number(BOEScenario.monthlyExpenseCostPerSf) : null,
                            formatter: 'formatUSDCurrency',
                        },
                        {
                            label: placeholders.netOperatingIncome.rows.expensesTotal,
                            value: BOEScenario.expensesTotal ? Number(BOEScenario.expensesTotal) : null,
                            formatter: 'formatUSDCurrency',
                        },
                    ],
                ],
            },
        ],
        [
            {
                title: [placeholders.totalDevelopmentCosts.title],
                value: [BOEScenario.totalDevelopmentCosts ? Number(BOEScenario.totalDevelopmentCosts) : 0],
                formatter: ['formatUSDCurrency'],
                rows: [
                    [
                        {
                            label: placeholders.totalDevelopmentCosts.rows.acquisitionCosts,
                            value: BOEScenario.acquisition ? Number(BOEScenario.acquisition) : null,
                            formatter: 'formatUSDCurrency',
                        },
                        {
                            label: placeholders.totalDevelopmentCosts.rows.hardCostsPerUnit,
                            value: BOEScenario.hardCostsPerUnit ? Number(BOEScenario.hardCostsPerUnit) : null,
                            formatter: 'formatUSDCurrency',
                        },
                        {
                            label: placeholders.totalDevelopmentCosts.rows.hardCostsPerSf,
                            value: BOEScenario.hardCostsPerSf ? Number(BOEScenario.hardCostsPerSf) : null,
                            formatter: 'formatUSDCurrency',
                        },
                        {
                            label: placeholders.totalDevelopmentCosts.rows.softCostsPerUnit,
                            value: BOEScenario.softCostsPerUnit ? Number(BOEScenario.softCostsPerUnit) : null,
                            formatter: 'formatUSDCurrency',
                        },
                        {
                            label: placeholders.totalDevelopmentCosts.rows.softCostsPerSf,
                            value: BOEScenario.softCostsPerSf ? Number(BOEScenario.softCostsPerSf) : null,
                            formatter: 'formatUSDCurrency',
                        },
                        {
                            label: placeholders.totalDevelopmentCosts.rows.financingCosts,
                            value: BOEScenario.loanToCost ? Number(BOEScenario.loanToCost) : null,
                            formatter: 'formatUSDCurrency',
                        },
                    ],
                ],
            },
            {
                title: placeholders.loanAndEquity.title,
                value: [BOEScenario.loanAmount ? Number(BOEScenario.loanAmount) : 0, BOEScenario.requiredEquity ? Number(BOEScenario.requiredEquity) : 0],
                formatter: ['formatUSDCurrency', 'formatUSDCurrency'],
                rows: [
                    [
                        {
                            label: placeholders.loanAndEquity.rows.constructionFinancing,
                            value: BOEScenario.averageLoanBalance ? Number(BOEScenario.averageLoanBalance) : null,
                            formatter: 'formatPercentage',
                        },
                    ],
                ],
            },
        ],
    ];
};

export const getDisplayMode = (value: number | null, target: number | null): 'success' | 'danger' | 'unstyled' => {
    if (target !== null) {
        return value !== null && value > target ? 'success' : 'danger';
    }
    return 'unstyled';
};

export const generateScenarioReturns = (BOEScenario: BOEScenario, deal?: Deal): ScenarioReturnsSection[] => {
    return [
        {
            title: [placeholders.returns.returnOnCost],
            value: [BOEScenario.returnOnCost ? Number(BOEScenario.returnOnCost) : 0],
            target: deal?.targetReturnOnCost ? Number(deal.targetReturnOnCost) : 0,
            formatter: ['formatPercentage'],
            displayMode: getDisplayMode(Number(BOEScenario.returnOnCost), deal?.targetReturnOnCost ?? null),
        },
        {
            title: [placeholders.returns.returnOnEquity],
            value: [BOEScenario.returnOnEquity ? Number(BOEScenario.returnOnEquity) : 0],
            target: deal?.targetReturnOnEquity ? Number(deal.targetReturnOnEquity) : 0,
            formatter: ['formatPercentage'],
            displayMode: getDisplayMode(Number(BOEScenario.returnOnEquity), deal?.targetReturnOnEquity ?? null),
        },
        {
            title: [placeholders.returns.profitReturn],
            value: [BOEScenario.profitReturn ? Number(BOEScenario.profitReturn) : 0],
            target: deal?.targetProfitReturn ? Number(deal.targetProfitReturn) : 0,
            formatter: ['formatUSDCurrency'],
            displayMode: getDisplayMode(Number(BOEScenario.profitReturn), deal?.targetProfitReturn ?? null),
        },
    ];
};

export const mapScenarioToFormValues = (scenario: BOEScenario | null): BOESlideoutFormValues | null => {
    if (!scenario) return null;

    const selectedAssetType = (() => {
        if (!scenario?.propertyType || scenario.propertyType === 'apartments') {
            return ASSET_TYPE_OPTIONS.find(option => option.value === ASSET_TYPE.RENTAL_APARTMENT);
        }
        return (
            ASSET_TYPE_OPTIONS.find(option =>
                // eslint-disable-next-line prettier/prettier
                [ASSET_TYPE.OFFICE, ASSET_TYPE.RETAIL, ASSET_TYPE.INDUSTRIAL, ASSET_TYPE.DATA_CENTER].includes(option.value))
            // eslint-disable-next-line prettier/prettier
            || ASSET_TYPE_OPTIONS.find(option => option.value === ASSET_TYPE.RENTAL_APARTMENT)
        );
    })();

    const selectedLoanCalculationOption = (() => {
        if (scenario.loanToValue !== null) {
            return LOAN_CALCULATION_OPTIONS.find(option => option.value === LOAN_CALCULATION_TYPES.LTV) || null;
        }
        if (scenario.loanToCost !== null) {
            return LOAN_CALCULATION_OPTIONS.find(option => option.value === LOAN_CALCULATION_TYPES.LTC) || null;
        }
        if (scenario.debtYield !== null) {
            return LOAN_CALCULATION_OPTIONS.find(option => option.value === LOAN_CALCULATION_TYPES.DY) || null;
        }
        return null;
    })();

    const contructionFinancing = (() => {
        if (scenario.loanToValue !== null) {
            return parseFloat(scenario.loanToValue) * 100;
        }
        if (scenario.loanToCost !== null) {
            return parseFloat(scenario.loanToCost) * 100;
        }
        if (scenario.debtYield !== null) {
            return parseFloat(scenario.debtYield) * 100;
        }
        return null;
    })();

    const formValues: BOESlideoutFormValues = {
        id: scenario.id?.toString() || null,
        dealId: scenario.dealId || -1,
        name: scenario.name,
        selectedAssetType,
        selectedLoanCalculationOptions: selectedLoanCalculationOption,
        grossSquareFootage: scenario.builtSf ? parseFloat(scenario.builtSf) : null,
        efficiency: scenario.buildingEfficiency ? parseFloat(scenario.buildingEfficiency) * 100 : null,
        rentPrice: parseFloat(scenario.monthlyRentPricePerUnit ?? scenario.monthlyRentPricePerSf ?? '0') || null,
        expenses: parseFloat(scenario.monthlyExpenseCostPerUnit ?? scenario.monthlyExpenseCostPerSf ?? '0') || null,
        units: scenario.numberOfUnits ?? null,
        exitCapRate: scenario.assumedExitCapRate ? parseFloat(scenario.assumedExitCapRate) * 100 : null,
        acquisitionCosts: scenario.acquisition ? parseFloat(scenario.acquisition) : null,
        hardCosts: parseFloat(scenario.hardCostsPerUnit ?? scenario.hardCostsPerSf ?? '0') || null,
        softCosts: parseFloat(scenario.softCostsPerUnit ?? scenario.softCostsPerSf ?? '0') || null,
        developerFee: scenario.developerFeeRate ? parseFloat(scenario.developerFeeRate) * 100 : null,
        apr: scenario.annualPercentageRate ? parseFloat(scenario.annualPercentageRate) * 100 : null,
        avgLoanBalance: scenario.averageLoanBalance ? parseFloat(scenario.averageLoanBalance) * 100 : null,
        loanTerm: scenario.loanTermMonths ?? null,
        contructionFinancing,
        marketCapRate: scenario.loanToValue ? parseFloat(scenario.loanToValue) * 100 : null, // TODO Add market cap rate from API (not returned in GET)
        developerFeeTotal: scenario.developerFeeTotal ? parseFloat(scenario.developerFeeTotal) : null,
        interestReserveTotal: scenario.interestReserveTotal ? parseFloat(scenario.interestReserveTotal) : null,
        totalDevelopmentCosts: scenario.totalDevelopmentCosts ? parseFloat(scenario.totalDevelopmentCosts) : null,
        loanAmount: scenario.loanAmount ? parseFloat(scenario.loanAmount) : null,
        equityCost: scenario.requiredEquity ? parseFloat(scenario.requiredEquity) : null,
        rentPriceSubdiv: scenario.rentPriceSubdiv,
        expenseCostSubdiv: scenario.expenseCostSubdiv,
        softCostsSubdiv: scenario.softCostsSubdiv,
        hardCostsSubdiv: scenario.hardCostsSubdiv,
        rentPriceTime: scenario.rentPriceTime,
        expenseCostTime: scenario.expenseCostTime,
        loanTermTime: scenario.loanTermTime,
    };

    return formValues;
};
