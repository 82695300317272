/* eslint-disable operator-linebreak */
import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsFromLine } from '@fortawesome/pro-solid-svg-icons';
import { NSTable, NSCheckbox, NSDropdown, NSButton } from 'bricks';
import { PRO_FORMA_DEVELOPMENT_UNIT_OPTIONS, UNIT_OF_MEASURE_TYPES, UNIT_TYPE_NAMES } from 'constants/unitTypes';
import {
    IProFormaUses,
    IUsesExpandedCategories,
    IUsesSelectedCategories,
    IUnitTypeOption,
    DevelopmentUnitOfMeasureTypes,
    ProjectionType,
} from 'views/ProFormaTable/types';
import {
    BULK_ASSIGN_OPTIONS,
    BULK_OPTION,
    PROJECTION_TYPES,
    PRO_FORMA_DEVELOPMENT_UNIT_TYPE_KEY,
    PRO_FORMA_TABLE_NAV,
    PRO_FORMA_TABS,
    mockOrgId,
} from 'views/ProFormaTable/constants';
import { isEmpty } from 'lodash';
import RenderIf from 'components/RenderIf/RenderIf';
import './DevelopmentUsesTable.scss';
import { IOptionAnyValue } from 'bricks/types';
import { useDeleteBudgetClassifications, useUpdateBudgetClassifications } from 'queries/BudgetClassification';
import { AxiosError } from 'axios';
import { standardHandlerActionError } from 'ns_libs/utils';
import { useParams } from 'react-router-dom';
import { IBudgetClassificationPatchData } from 'queries/BudgetClassification/useUpdateBudgetClassifications';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { getMissingDateText, getUsesAmountTotal, getUsesCostPerUnitTotal } from 'views/ProFormaTable/helpers';
import NSInfoBanner from 'components/NSInfoBanner/NSInfoBanner';
import BudgetLineAmountModal from '../BudgetLineAmountModal/BudgetLineAmountModal';
import CurveFitModal from '../CurveFitModal/CurveFitModal';
import DateModal from '../DateModal/DateModal';
import { useUsesTableContext } from './context/UsesTableProvider';
import UsesTableHighLevel from './UsesTableHighLevel/UsesTableHighLevel';
import MissingDateModal from '../MissingDateModal/MissingDateModal';

export interface IDevelopmentUsesTableProps {
    uses: IProFormaUses[];
}

const DevelopmentUsesTable = ({ uses }: IDevelopmentUsesTableProps) => {
    const { proFormaId } = useParams();

    const usesLocalStorageData: UNIT_OF_MEASURE_TYPES = JSON.parse(localStorage.getItem(PRO_FORMA_DEVELOPMENT_UNIT_TYPE_KEY) ?? '{}');
    const { navigateToSection, proForma, deselectAllCategories, selectedCategories, selectAllCategories, hideAllCategories, showAllCategories } =
        useUsesTableContext();

    const existingUnitType = !isEmpty(usesLocalStorageData)
        ? PRO_FORMA_DEVELOPMENT_UNIT_OPTIONS.filter(option => option.value === usesLocalStorageData)[0]
        : PRO_FORMA_DEVELOPMENT_UNIT_OPTIONS?.[0];
    const [usesUnitType, setUsesUnitType] = useState<IUnitTypeOption>(existingUnitType);
    const [shouldSelectAllCategories, setShouldSelectAllCategories] = useState(false);
    const [expandAllCategories, setExpandAllCategories] = useState(false);
    const [bulkOption, setBulkOption] = useState<string | null>(null);
    const [openDateModal, setOpenDateModal] = useState(false);
    const [openCurveModal, setOpenCurveModal] = useState(false);
    const [openAmountModal, setOpenAmountModal] = useState(false);
    const [deleteAllSelectedCategories, setDeleteAllSelectedCategories] = useState(false);

    const toggleDateModal = () => setOpenDateModal(!openDateModal);
    const toggleCurveModal = () => setOpenCurveModal(!openCurveModal);
    const toggleAmountModal = () => setOpenAmountModal(!openAmountModal);
    const toggleDeleteSelectedCategories = () => setDeleteAllSelectedCategories(!deleteAllSelectedCategories);

    // bulk update is only for budget lines
    const { mutate: updateBudgetClassification, isPending: isUpdatingBudgetClassificationPending } = useUpdateBudgetClassifications({
        onSuccess: () => {
            // call fetchUses() in the provider to refetch the uses table data
            if (openDateModal) toggleDateModal();
            if (openCurveModal) toggleCurveModal();
            if (openAmountModal) toggleAmountModal();
        },
        onError: (error: AxiosError) => {
            standardHandlerActionError(error, 'Error updating default budget classifications - please try again.');
        },
    });

    const { mutate: deleteBudgetClassifications, isPending: isDeletingBudgetClassificationPending } = useDeleteBudgetClassifications({
        onSuccess: () => {
            // call fetchUses() in the provider to refetch the uses table data
            toggleDeleteSelectedCategories();
        },
        onError: (error: AxiosError) => {
            standardHandlerActionError(error, 'Error deleting default budget classifications - please try again.');
        },
    });

    const handleExpandAllCategories = () => {
        if (expandAllCategories) {
            hideAllCategories();
            setExpandAllCategories(false);
        } else {
            const updatedExpandedCategories: IUsesExpandedCategories = { highLevels: [], subcategories: [] };
            uses.forEach(highLevel => {
                updatedExpandedCategories.highLevels.push(highLevel.id);
                (highLevel.children || []).forEach(subcategory => {
                    updatedExpandedCategories.subcategories.push(subcategory.id);
                });
            });
            showAllCategories(updatedExpandedCategories);
            setExpandAllCategories(true);
        }
    };

    const handleUpdateUnitType = (option: IUnitTypeOption) => {
        setUsesUnitType(option);
        localStorage.setItem(PRO_FORMA_DEVELOPMENT_UNIT_TYPE_KEY, JSON.stringify(option.value));
    };

    const deselectAllBudgetCategories = () => {
        deselectAllCategories();
        setShouldSelectAllCategories(false);
    };

    const handleSelectAllCategories = () => {
        if (shouldSelectAllCategories) {
            deselectAllBudgetCategories();
        } else {
            const updatedSelectedCategories: IUsesSelectedCategories = { highLevels: [], subcategories: [], budgetClassifications: [] };
            uses.forEach(highLevel => {
                updatedSelectedCategories.highLevels.push(highLevel.id);
                (highLevel.children || []).forEach(subcategory => {
                    updatedSelectedCategories.subcategories.push(subcategory.id);
                    (subcategory.children || []).forEach(budgetClassification => {
                        updatedSelectedCategories.budgetClassifications.push(budgetClassification.id);
                    });
                });
            });
            selectAllCategories(updatedSelectedCategories);
            setShouldSelectAllCategories(true);
        }
    };

    // Bulk patch actions
    const handleSelectBulkOption = (option: IOptionAnyValue) => {
        setBulkOption(option.value as string);
        if (option.value === BULK_OPTION.START_DATE || option.value === BULK_OPTION.END_DATE) {
            toggleDateModal();
        }
        if (option.value === BULK_OPTION.CURVE) {
            toggleCurveModal();
        }
        if (option.value === BULK_OPTION.AMOUNT) {
            toggleAmountModal();
        }
    };

    const handleBulkUpdate = (updates: Partial<IBudgetClassificationPatchData>) => {
        if (selectedCategories.budgetClassifications.length === 0) return;

        const budgetClassificationsToUpdate: IBudgetClassificationPatchData[] = selectedCategories.budgetClassifications.map(budgetClassificationId => ({
            id: budgetClassificationId,
            ...updates,
        }));

        updateBudgetClassification({
            organizationId: mockOrgId,
            proFormaId: proFormaId!,
            patchData: { budgetClassifications: budgetClassificationsToUpdate },
        });
    };

    const handleBulkUpdateDate = (date: string, isStartDate: boolean) => {
        handleBulkUpdate(isStartDate ? { startDate: date } : { endDate: date });
    };

    const handleBulkUpdateCurveFit = (projectionType: ProjectionType, projectionVariable: number) => {
        const targetProjection = PROJECTION_TYPES.find(type => type.projectionType === projectionType);
        if (targetProjection) {
            handleBulkUpdate({ projectionType, projectionVariable: projectionVariable! });
        }
    };

    const handleBulkUpdateAmount = (amount: number | null) => {
        handleBulkUpdate({ amount: String(amount)! });
    };

    const handleBulkDelete = () => {
        deleteBudgetClassifications({
            organizationId: mockOrgId,
            proFormaId: proFormaId!,
            budgetClassificationIds: Object.values(selectedCategories).flat(),
            deleteAll: false,
        });
    };

    useEffect(() => {
        if (!openDateModal && !openCurveModal && !openAmountModal) {
            setBulkOption(null);
        }
    }, [openDateModal, openCurveModal, openAmountModal]);

    const costPerUnitTotal = getUsesCostPerUnitTotal(uses, usesUnitType);
    const amountTotal = getUsesAmountTotal(uses);
    const numberOfSelectedCategories = Object.values(selectedCategories).flat().length;

    const { title, prependedSubtitle, boldSubtitleText } = getMissingDateText(proForma.startDate, proForma.saleDate);
    const periodsDateRange = proForma.startDate && proForma.saleDate ? [proForma.startDate, proForma.saleDate] : null;

    const redirectToGeneralInformation = () => {
        navigateToSection(PRO_FORMA_TABLE_NAV.PRO_FORMA.name, PRO_FORMA_TABS.GENERAL_INFORMATION.name);
    };

    return (
        <>
            <RenderIf isTrue={!proForma.startDate || !proForma.saleDate}>
                <NSInfoBanner
                    title={title as string}
                    subtitle={
                        <small className="d-flex text-dark">
                            <div>
                                {prependedSubtitle}
                                <span role="presentation" onClick={redirectToGeneralInformation} className="text-primary px-1 cursor--pointer">
                                    General Information
                                </span>
                                <span>
                                    to populate <b>{boldSubtitleText}</b> on each budget line.
                                </span>
                            </div>
                        </small>
                    }
                    className="mt-3 NSCard--level-4 NSCard__left-border--warning bg-warning-lighten mb-3 py-1"
                    warning
                    customInfo={<NSButton text="Fix" color="primary" callback={redirectToGeneralInformation} className="mr-2" />}
                />
            </RenderIf>

            <RenderIf isTrue={Boolean(numberOfSelectedCategories)}>
                <div className="d-flex justify-content-between rounded DevelopmentUsesTable__bulk-actions-card p-2">
                    <div className="d-flex">
                        <div className="my-auto mr-2">{numberOfSelectedCategories} selected</div>
                        <RenderIf isTrue={Boolean(selectedCategories.budgetClassifications.length)}>
                            <NSDropdown
                                options={BULK_ASSIGN_OPTIONS}
                                selectedOption={{ label: 'Assign to budget lines', value: 'assign' }}
                                handleSelectOption={handleSelectBulkOption}
                                toggleClassName="bg-primary rounded border border-primary"
                            />
                        </RenderIf>
                        <NSButton text="Delete" className="ml-1" color="danger" callback={toggleDeleteSelectedCategories} outline />
                    </div>
                    <div role="presentation" onClick={deselectAllBudgetCategories} className="text-primary cursor--pointer my-auto">
                        Clear
                    </div>
                </div>
            </RenderIf>

            <NSTable>
                <thead className="NSTable__thead">
                    <tr className="NSTable__thead__tr">
                        <th className="NSTable__thead__tr__th NSTable__thead__tr__th--right-border align-middle">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                    <NSCheckbox
                                        id="usesTableSelectAll"
                                        checked={shouldSelectAllCategories}
                                        labelClassName="cursor--pointer"
                                        callback={handleSelectAllCategories}
                                    />
                                    <FontAwesomeIcon
                                        className="mr-2 text-muted cursor--pointer"
                                        icon={faArrowsFromLine}
                                        onClick={handleExpandAllCategories}
                                    />
                                    <span className="ml-0">Uses</span>
                                </div>
                                {/** TODO: show this button when implementing logic for user to create their own high levels */}
                                {/* <NSButton className="px-2 text-muted" size="sm" iconOnly icon={faPlus} callback={() => { }} /> */}
                            </div>
                        </th>
                        <th className="NSTable__thead__tr__th align-middle">Start</th>
                        <th className="NSTable__thead__tr__th align-middle">End</th>
                        <th className="NSTable__thead__tr__th align-middle">Curve</th>
                        <th className="NSTable__thead__tr__th align-middle">
                            <div className="text-right">
                                <span className="mr-1">Cost per</span>
                                <NSDropdown
                                    options={PRO_FORMA_DEVELOPMENT_UNIT_OPTIONS}
                                    selectedOption={{ label: UNIT_TYPE_NAMES[usesUnitType.value].abbreviated, value: usesUnitType.value }}
                                    handleSelectOption={option => handleUpdateUnitType(option)}
                                    badgeColor="dark-lighten"
                                    isBadgeToggle
                                />
                            </div>
                        </th>
                        <th className="NSTable__thead__tr__th text-right align-middle">Amount</th>
                    </tr>
                </thead>

                <tbody className="NSTable__tbody">
                    {uses.map(highLevel => (
                        <UsesTableHighLevel key={highLevel.id} highLevel={highLevel} unitType={usesUnitType.value as DevelopmentUnitOfMeasureTypes} />
                    ))}
                </tbody>

                <tfoot className="NSTable__tfoot">
                    <tr className="NSTable__tfoot__tr">
                        <td className="NSTable__tfoot__tr__td NSTable__tfoot__tr__td--right-border text-left" colSpan={4}>
                            Total
                        </td>
                        <td className="NSTable__tfoot__tr__td text-right">{costPerUnitTotal}</td>
                        <td className="NSTable__tfoot__tr__td text-right">{amountTotal}</td>
                    </tr>
                </tfoot>
            </NSTable>

            <RenderIf isTrue={bulkOption && periodsDateRange}>
                <DateModal
                    isOpen={openDateModal}
                    toggle={toggleDateModal}
                    headerText={proForma.startDate ? 'Start date' : 'End date'}
                    handleUpdateDate={handleBulkUpdateDate}
                    isStartDate={bulkOption === BULK_OPTION.START_DATE}
                    dateRange={periodsDateRange!}
                    isLoadingCallback={isUpdatingBudgetClassificationPending}
                />
            </RenderIf>

            <RenderIf isTrue={bulkOption && !periodsDateRange}>
                <MissingDateModal
                    isOpen={openDateModal}
                    toggle={toggleDateModal}
                    startDate={proForma.startDate}
                    saleDate={proForma.saleDate}
                    redirectToGeneralInformation={redirectToGeneralInformation}
                />
            </RenderIf>

            <RenderIf isTrue={bulkOption && bulkOption === BULK_OPTION.CURVE}>
                <CurveFitModal
                    isOpen={openCurveModal}
                    toggle={toggleCurveModal}
                    headerText="Curve"
                    handleUpdateProjection={handleBulkUpdateCurveFit}
                    isLoadingCallback={isUpdatingBudgetClassificationPending}
                />
            </RenderIf>

            <RenderIf isTrue={bulkOption && bulkOption === BULK_OPTION.AMOUNT}>
                <BudgetLineAmountModal
                    isOpen={openAmountModal}
                    toggle={toggleAmountModal}
                    headerText="Amount"
                    handleUpdateAmount={handleBulkUpdateAmount}
                    isLoadingCallback={isUpdatingBudgetClassificationPending}
                />
            </RenderIf>

            <RenderIf isTrue={deleteAllSelectedCategories}>
                <ConfirmationModal
                    toggle={toggleDeleteSelectedCategories}
                    isOpen={deleteAllSelectedCategories}
                    onConfirm={handleBulkDelete}
                    onCancel={toggleDeleteSelectedCategories}
                    modalHeaderText="Delete multiple selected"
                    modalFooterButtonText="Yes, delete"
                    modalFooterButtonIsLoading={isDeletingBudgetClassificationPending}
                    danger
                >
                    <div className="text-dark font-weight-bold mb-1">Are you sure you want to delete these multiple items?</div>
                    <div>Your work will not be able to be recovered. Any child content associated with this bulk selection will also be deleted.</div>
                    <div className="text-warning mt-1">Note: any selected high level categories (e.g. Hard Costs) will not be deleted.</div>
                </ConfirmationModal>
            </RenderIf>
        </>
    );
};

export default DevelopmentUsesTable;
