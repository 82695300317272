/* eslint-disable no-unused-vars */
export enum SortDirection {
    ASC = 'asc',
    DESC = 'desc',
}

export type ColSortConfig<T> = {
    key: T;
    direction: SortDirection;
};
