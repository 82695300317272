import React, { useState } from 'react';
import ProFormaGenericTable from 'views/ProFormaTable/components/ProFormaGenericTable/ProFormaGenericTable';
import { ProFormaGenericColumn, ProFormaGenericRow } from 'views/ProFormaTable/components/ProFormaGenericTable/types';
import { NSButton, NSInputNumberStepper, NSLabel } from 'bricks';
import NSCellInput from 'bricks/NSCellInput/NSCellInput';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { IOptionAnyValue } from 'bricks/types';
import {
    CALCULATION_OPTIONS,
    CAPITAL_EXPENDITURES_COLUMNS,
    CAPITAL_EXPENDITURES_INITIAL_STATE,
    CASH_FLOW_COLUMNS,
    NET_OPERATION_INCOME_COLUMNS,
    OPERATING_EXPENSES_COLUMNS,
    OPERATING_EXPENSES_INITIAL_STATE,
    TOTAL_CAPITAL_EXPENDITURES_COLUMNS,
} from './constants';
import './ProFormaExpenses.scss';

interface PropTaxItem {
    value: number;
}

const ProFormaExpenses = () => {
    const [operatingExpensesRows, setOperatingExpensesRows] = useState<ProFormaGenericRow[]>(OPERATING_EXPENSES_INITIAL_STATE);
    const [capitalExpendituresRows, setCapitalExpendituresRows] = useState<ProFormaGenericRow[]>(CAPITAL_EXPENDITURES_INITIAL_STATE);

    const [yearTaxes, setYearTaxes] = useState<PropTaxItem[]>([{ value: 0 }]);
    const [millRate, setMillRate] = useState<number>(0);

    const handleSelectCalculation = (rowId: string) => (option: IOptionAnyValue | null) => {
        if (option) {
            setOperatingExpensesRows(rows => rows.map(row => (row.id === rowId ? { ...row, selectedCalculation: option } : row)));
        }
    };

    const handleMillRateChange = (value: number) => {
        const newValue = Math.min(Math.max(value, 0), 100);
        setMillRate(newValue);
    };

    const handleAdd = () => {
        setYearTaxes([...yearTaxes, { value: 0 }]);
    };

    const handleChange = (index: number, newValue: number) => {
        const updatedItems = yearTaxes.map((item, idx) => (idx === index ? { ...item, value: newValue } : item));
        setYearTaxes(updatedItems);
    };

    const handleRemove = (index: number) => {
        const updatedItems = yearTaxes.filter((_, idx) => idx !== index);
        setYearTaxes(updatedItems);
    };

    function renderPropYearRow(index: number, value: number, handleChange: (index: number, value: number) => void, handleRemove: (index: number) => void) {
        return (
            <div className="d-flex align-items-center mt-2">
                <NSLabel className="pl-0 col-4 my-auto font-weight-normal text-dark">Prop tax: year {index + 1} anticipated % of full</NSLabel>
                <NSInputNumberStepper
                    name={`propTax_${index}`}
                    value={value}
                    onChange={(value: number) => handleChange(index, value)}
                    min={0}
                    step={0.1}
                    percentage
                    inputGroupClassName="ProFormaPermanentDebt__input-width"
                />
                <NSButton className="ml-2 cursor-pointer" callback={() => handleRemove(index)} icon={faTrash} />
            </div>
        );
    }

    const operatingExpenseColumn: ProFormaGenericColumn = {
        key: 'title',
        field: 'title',
        header: 'Operating expenses',
        format: 'none',
        isReadOnly: (row: ProFormaGenericRow) => ['capitalReserves', 'propertyTaxes'].includes(row.id),
        dropdown: {
            label: 'Calculated in:',
            options: CALCULATION_OPTIONS,
            getSelectedOption: (row: ProFormaGenericRow) => row.selectedCalculation,
            handleSelectOption: handleSelectCalculation,
            shouldShowDropdown: (row: ProFormaGenericRow) => row.id === 'capitalReserves',
        },
        customCell: {
            shouldShowCustomCell: (row: ProFormaGenericRow) => row.id === 'propertyTaxes',
            renderCell: () => (
                <div className="ProFormaExpenses__custom-cell d-flex flex-row aling-items-center py-1 px-2">
                    <div className="pr-1 text-white">Mill rate:</div>
                    <NSCellInput
                        type="number"
                        value={millRate || ''}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMillRateChange(e.target.valueAsNumber)}
                        placeholder="0"
                        rightAlign
                        onBlur={() => {}}
                    />
                </div>
            ),
        },
    };

    return (
        <div className="d-flex flex-column pb-3">
            <div className="ProFormaExpenses__inputs-container w-50 p-3">
                {yearTaxes.map((item, index) => renderPropYearRow(index, item.value, handleChange, handleRemove))}
                <NSButton className="ProFormaExpenses__add_year d-flex align-items-center mt-3" callback={handleAdd}>
                    <FontAwesomeIcon icon={faPlus} />
                    <p>Add year</p>
                </NSButton>
            </div>
            <div className="ProFormaExpenses__separator mx-3" />
            <div className="p-3">
                <ProFormaGenericTable
                    columns={[operatingExpenseColumn, ...OPERATING_EXPENSES_COLUMNS]}
                    rows={operatingExpensesRows}
                    setRows={setOperatingExpensesRows}
                />
                <div className="pb-1" />
                <ProFormaGenericTable columns={NET_OPERATION_INCOME_COLUMNS} rows={[]} setRows={() => {}} hideTotalFooter hideAddButton />
                <div className="pb-1" />
                <ProFormaGenericTable
                    columns={CAPITAL_EXPENDITURES_COLUMNS}
                    rows={capitalExpendituresRows}
                    setRows={setCapitalExpendituresRows}
                    hideTotalFooter
                />
                <div className="pb-1" />
                <ProFormaGenericTable columns={TOTAL_CAPITAL_EXPENDITURES_COLUMNS} rows={[]} setRows={() => {}} hideTotalFooter hideAddButton />
                <div className="pb-1" />
                <ProFormaGenericTable columns={CASH_FLOW_COLUMNS} rows={[]} setRows={() => {}} hideTotalFooter hideAddButton />
            </div>
        </div>
    );
};

export default ProFormaExpenses;
