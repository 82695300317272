/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import { IProFormaUses, ISelectedProjection, ProjectionType, DevelopmentUnitOfMeasureTypes } from 'views/ProFormaTable/types';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import RenderIf from 'components/RenderIf/RenderIf';
import { NSCheckbox } from 'bricks';
import { URLS } from 'services/urls';
import { mockOrgId, PROJECTION_TYPE, PROJECTION_TYPES } from 'views/ProFormaTable/constants';
import { PRO_FORMA_UNIT_TO_FIELD_VALUES } from 'constants/unitTypes';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import NSCellInput from 'bricks/NSCellInput/NSCellInput';
import { useUpdateBudgetClassifications } from 'queries/BudgetClassification';
import { AxiosError } from 'axios';
import { standardHandlerActionError } from 'ns_libs/utils';
import { useParams } from 'react-router-dom';
import PeriodsSelect from '../../../../PeriodsSelect/PeriodsSelect';
import CurveFitModal from '../../../../CurveFitModal/CurveFitModal';
import { useUsesTableContext } from '../../../context/UsesTableProvider';

export interface IUsesTableBudgetLine {
    budgetClassification: IProFormaUses;
    unitType: DevelopmentUnitOfMeasureTypes;
}

const UsesTableBudgetLine = ({ budgetClassification, unitType }: IUsesTableBudgetLine) => {
    const { proFormaId } = useParams();

    const { proForma, selectedCategories, updateSelectedCategories } = useUsesTableContext();
    const [isHovered, setIsHovered] = useState(false);
    const [curveFitModalOpen, setCurveFitModalOpen] = useState(false);

    const toggleCurveFitModal = () => setCurveFitModalOpen(!curveFitModalOpen);

    const selectedProjectionsCurveList = PROJECTION_TYPES.filter(projection => projection.projectionType === budgetClassification.projectionType);
    const selectedCurveByVariable = selectedProjectionsCurveList?.find(
        projection => projection.projectionVariable === Number(budgetClassification.projectionVariable),
    );

    let selectedCurve: ISelectedProjection = {
        name: selectedProjectionsCurveList[0]?.curveType,
        value: budgetClassification.projectionVariable || null,
        imgPrimary: selectedProjectionsCurveList[0]?.imgPrimary,
    };

    if (
        budgetClassification.projectionType &&
        [PROJECTION_TYPE.NORMAL, PROJECTION_TYPE.GAMMA].includes(budgetClassification.projectionType) &&
        selectedCurveByVariable
    ) {
        selectedCurve = {
            name: selectedCurveByVariable.curveType,
            value: budgetClassification.projectionVariable || null,
            imgPrimary: selectedCurveByVariable.imgPrimary,
        };
    }

    const { mutate: updateBudgetClassification, isPending: isUpdatingBudgetClassificationPending } = useUpdateBudgetClassifications({
        onSuccess: () => {
            // call fetchUses() in the provider to refetch the uses table data
            if (curveFitModalOpen) toggleCurveFitModal();
        },
        onError: (error: AxiosError) => {
            standardHandlerActionError(error, 'Error updating default budget classifications - please try again.');
        },
    });

    const handleUpdateProjection = (projectionType: ProjectionType, projectionVariable: number | null) => {
        const targetProjection = PROJECTION_TYPES.find(projection => projection.projectionType === projectionType);
        if (targetProjection) {
            updateBudgetClassification({
                organizationId: mockOrgId,
                proFormaId: proFormaId!,
                patchData: {
                    budgetClassifications: [
                        {
                            id: budgetClassification.id,
                            projectionType,
                            projectionVariable,
                        },
                    ],
                },
            });
        }
    };

    const deleteBudgetLine = () => {};

    const budgetClassificationAmount = budgetClassification.amount !== null ? budgetClassification.amount : null;

    const budgetClassificationUnitAmount =
        budgetClassification[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType]] !== null ? budgetClassification[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType]] : null;

    const hasNoSelectedCategories = Object.values(selectedCategories).every(categoryLevel => !categoryLevel.length);

    const periodsDateRange = proForma?.startDate && proForma?.saleDate ? [proForma.startDate, proForma.saleDate] : null;

    return (
        <>
            <tr className="NSTable__tbody__tr NSTable__tbody__tr--level-3">
                <td
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    className="NSTable__tbody__tr__td NSTable__tbody__tr__td--right-border text-dark align-middle
                    d-flex justify-content-between align-items-center flex-nowrap"
                >
                    <div className="d-flex align-items-center">
                        <NSCheckbox
                            id={`selectSubcategory-${budgetClassification.id}`}
                            checked={selectedCategories.budgetClassifications.includes(budgetClassification.id)}
                            callback={() => updateSelectedCategories(null, null, [budgetClassification.id])}
                            containerClassName="custom-control-inline align-middle"
                        />
                        <div className="d-flex align-items-center">
                            <NSCellInput
                                value={budgetClassification.budgetLineNickname}
                                onChange={() => {}}
                                onBlur={() => {}}
                                placeholder="Enter budget line name"
                                customClassName={budgetClassification.children?.length ? '' : 'ml-4'}
                                onEnter={() => {}}
                            />
                        </div>
                    </div>
                    <RenderIf isTrue={hasNoSelectedCategories}>
                        <div className="d-flex flex-row flex-nowrap">
                            <FontAwesomeIcon
                                icon={faTrash}
                                onClick={() => isHovered && deleteBudgetLine()}
                                className={classNames('text-muted', { invisible: !isHovered })}
                            />
                        </div>
                    </RenderIf>
                </td>
                <td className={classNames('NSTable__tbody__tr__td align-middle', { 'py-0 px-0': budgetClassification.startDate })}>
                    <RenderIf isTrue={periodsDateRange && budgetClassification.startDate && proForma?.startDate}>
                        <PeriodsSelect dateRange={periodsDateRange!} />
                    </RenderIf>
                    <RenderIf isTrue={!budgetClassification.startDate || !proForma?.startDate}>
                        <div className="text-primary text-nowrap font-italic">+ Add date</div>
                    </RenderIf>
                </td>
                <td className={classNames('NSTable__tbody__tr__td align-middle', { 'py-0 px-0': budgetClassification.endDate })}>
                    <RenderIf isTrue={periodsDateRange && budgetClassification.endDate && proForma?.saleDate}>
                        <PeriodsSelect dateRange={periodsDateRange!} />
                    </RenderIf>
                    <RenderIf isTrue={!budgetClassification.endDate || !proForma?.saleDate}>
                        <div className="text-primary text-nowrap font-italic">+ Add date</div>
                    </RenderIf>
                </td>
                <td className="NSTable__tbody__tr__td align-middle text-primary cursor--pointer text-primary text-nowrap" onClick={toggleCurveFitModal}>
                    <RenderIf isTrue={budgetClassification.projectionType}>
                        <img
                            src={URLS.IMAGE_CLOUDFRONT_URL(selectedCurve.imgPrimary)}
                            alt={`selectedCurve-${selectedCurve.name}`}
                            width="16px"
                            className="mx-auto"
                        />
                        <small className="mt-0 ml-1">{selectedCurve.name}</small>
                    </RenderIf>
                    <RenderIf isTrue={!budgetClassification.projectionType}>
                        <div className="text-primary text-nowrap font-italic">+ Add</div>
                    </RenderIf>
                </td>
                <td className="NSTable__tbody__tr__td">
                    <div className="d-flex justify-content-end text-dark">
                        {budgetClassificationUnitAmount !== null ? numeralFormatterCurrency(budgetClassificationUnitAmount) : '—'}
                    </div>
                </td>
                <td className="NSTable__tbody__tr__td">
                    <div className="d-flex justify-content-end text-primary">
                        {budgetClassificationAmount !== null ? numeralFormatterCurrency(budgetClassificationAmount) : '—'}
                    </div>
                </td>
            </tr>

            <RenderIf isTrue={curveFitModalOpen}>
                <CurveFitModal
                    toggle={toggleCurveFitModal}
                    isOpen={curveFitModalOpen}
                    headerText="Curve"
                    handleUpdateProjection={handleUpdateProjection}
                    isLoadingCallback={isUpdatingBudgetClassificationPending}
                    selectedCurve={selectedCurveByVariable}
                />
            </RenderIf>
        </>
    );
};

export default UsesTableBudgetLine;
