import React, { useMemo, useState } from 'react';
import { Dropdown, DropdownToggle } from 'reactstrap';
import md5 from 'md5';
import UserMenuItems from './UserMenuItem/UserMenuItems';
import './UserMenu.scss';
import useGetAuthenticatedUser from '../../../../queries/UserMenu/useGetAuthenticatedUser';

const UserMenu: React.FC = () => {
    const { data: authenticatedUser } = useGetAuthenticatedUser();

    const avatar = useMemo<string | undefined>(() => {
        if (!authenticatedUser?.user.email) {
            return undefined;
        }

        const picture = authenticatedUser?.user.picture;

        if (!picture) {
            return `https://www.gravatar.com/avatar/${md5(authenticatedUser.user.email)}?d=mp`;
        }

        if (picture.startsWith('https')) {
            return picture;
        }

        return `https://northspyre-images.s3.amazonaws.com/${picture}`;
    }, [authenticatedUser?.user.picture, authenticatedUser?.user.email]);

    const [isUserMenuOpen, setIsUserMenuOpen] = useState<boolean>(false);
    const toggleUserMenu = () => setIsUserMenuOpen(!isUserMenuOpen);

    return (
        <Dropdown isOpen={isUserMenuOpen} toggle={toggleUserMenu} className="UserMenu-dropdown" data-testid="user-menu-dropdown">
            <DropdownToggle tag="span" data-testid="user-menu-toggle">
                <div style={{ cursor: 'pointer' }}>
                    <img src={avatar} alt="user-icon" className="UserMenu-dropdown__avatar" data-testid="user-menu-avatar" />
                </div>
            </DropdownToggle>
            {isUserMenuOpen && <UserMenuItems />}
        </Dropdown>
    );
};

export default UserMenu;
