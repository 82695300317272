import React, { useMemo, useState } from 'react';
import { ComparisonGrid } from 'components/ComparisonGrid';
import { IOptionAnyValue } from 'bricks/types';
import { AddToComparisonSimpleSelect } from 'components/ComparisonGrid/components';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import { useCreateReport, useGetComparisonReport, useUpdateReport } from 'views/Reports/hooks';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { URLS } from 'services/urls';
import { useQueryClient } from '@tanstack/react-query';
import useToast from 'hooks/useToast';
import { ReportHeader } from '../../ReportHeader/ReportHeader';
import { IComparisonReportData, ICreateReportPostData, ReportTypeId, IReportDetails, IDealSelection } from '../../types';
import { BasicMetric, CostMetrics, InvestmentMetrics, ProjectionMetrics } from './metrics';
import { ColumnHeader } from './ColumnHeader';

export interface IProps {
    report?: IReportDetails;
    onClickBack: () => void;
    isEditing?: boolean;
}

export const ComparisonReport = ({ report, onClickBack, isEditing }: IProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();

    const [selectedOption, setSelectedOption] = useState<IOptionAnyValue>();
    const [isEditMode, setIsEditMode] = useState(!report || isEditing);
    const [reportName, setReportName] = useState(report?.name || 'New Comparison Report');
    const [reportDescription, setReportDescription] = useState(report?.description || '');
    // eslint-disable-next-line no-unused-vars
    const [selectedDeals, setSelectedDeals] = useState<IDealSelection[]>(report?.deals || location.state?.selectedDeals || []);

    const { showSuccess, showError, ToastContainerElement } = useToast();

    const orgId = '123e4567-e89b-12d3-a456-426614174000';

    const { data: comparisonReportData } = useGetComparisonReport({
        orgId,
        // proFormaIds: selectedDeals.map(deal => deal.proFormaId),
        // replace hard coded proFormaIds with above code.
        proFormaIds: [1, 2],
    });

    // TODO: Replace with data from API
    const selectionConfig = {
        label: 'Add to comparison',
        placeholder: 'Select a deal',
        options: [
            { label: '400 Highland', value: '400 Highland' },
            { label: 'Sunset Ridge Estates', value: 'Sunset Ridge Estates' },
        ],
        onSelectOption: setSelectedOption,
        selectedOption,
    };

    const gridConfig = useMemo(() => {
        if (!comparisonReportData) return undefined;

        return {
            headerRowFormatter: (data: IComparisonReportData) => <ColumnHeader data={data} />,
            groups: [
                {
                    groupLabel: 'Property Returns',
                    rows: [
                        { label: 'Unlevered', formatter: (data: IComparisonReportData) => <InvestmentMetrics data={data.propertyReturns.unlevered} /> },
                        { label: 'Levered', formatter: (data: IComparisonReportData) => <InvestmentMetrics data={data.propertyReturns.levered} /> },
                    ],
                },
                {
                    groupLabel: 'Partnership Returns',
                    rows: [
                        { label: 'Limited Partner', formatter: data => <InvestmentMetrics data={data.partnershipReturns.limitedPartner} /> },
                        { label: 'General Partner', formatter: data => <InvestmentMetrics data={data.partnershipReturns.generalPartner} /> },
                    ],
                },
                {
                    groupLabel: 'Development Returns',
                    rows: [
                        { label: 'Yield-on-cost', formatter: data => <ProjectionMetrics data={data.developmentReturns.yieldOnCost} /> },
                        { label: 'Market cap rate', formatter: data => <ProjectionMetrics data={data.developmentReturns.marketCapRate} /> },
                        { label: 'Development spread', formatter: data => <ProjectionMetrics data={data.developmentReturns.developmentSpread} /> },
                    ],
                },
                {
                    groupLabel: 'Details',
                    rows: [
                        { label: 'Units', formatter: data => <BasicMetric value={numeralFormatterCurrency(data.details.units)} /> },
                        { label: 'Net Rentable SF', formatter: data => <BasicMetric value={numeralFormatterCurrency(data.details.netRentableSf)} /> },
                        { label: 'Land area', formatter: data => <BasicMetric value={numeralFormatterCurrency(data.details.landArea)} /> },
                        { label: 'Building(s)', formatter: data => <BasicMetric value={numeralFormatterCurrency(data.details.buildings)} /> },
                        { label: 'Height', formatter: data => <BasicMetric value={numeralFormatterCurrency(data.details.height)} /> },
                        { label: 'Parking spaces', formatter: data => <BasicMetric value={numeralFormatterCurrency(data.details.parkingSpaces)} /> },
                        { label: 'Parking Ratio', formatter: data => <BasicMetric value={numeralFormatterCurrency(data.details.parkingRatio)} /> },
                    ],
                },
                {
                    groupLabel: 'Uses',
                    rows: [
                        { label: 'Acquisition costs', formatter: data => <CostMetrics data={data.uses.acquisitionCosts} /> },
                        { label: 'Hard costs', formatter: data => <CostMetrics data={data.uses.hardCosts} /> },
                        { label: 'Soft costs', formatter: data => <CostMetrics data={data.uses.softCosts} /> },
                        { label: 'Carry costs', formatter: data => <CostMetrics data={data.uses.carryCosts} /> },
                        { label: 'Total uses', formatter: data => <CostMetrics data={data.uses.totalUses} /> },
                    ],
                },
                {
                    groupLabel: 'Income',
                    rows: [
                        { label: 'Effective gross revenue', formatter: data => <ProjectionMetrics data={data.income.effectiveGrossRevenue} /> },
                        { label: 'Operating expenses', formatter: data => <ProjectionMetrics data={data.income.operatingExpenses} /> },
                        { label: 'Net operating income', formatter: data => <ProjectionMetrics data={data.income.netOperatingIncome} /> },
                        { label: 'Capital expenditures', formatter: data => <ProjectionMetrics data={data.income.capitalExpenditures} /> },
                        { label: 'Cash flow from operations', formatter: data => <ProjectionMetrics data={data.income.cashFlowFromOperations} /> },
                        { label: 'Cap rate', formatter: data => <ProjectionMetrics data={data.income.capRate} /> },
                        { label: 'Valuation', formatter: data => <ProjectionMetrics data={data.income.valuation} /> },
                        { label: '% of total project cost', formatter: data => <ProjectionMetrics data={data.income.percentOfTotalProjectCost} /> },
                        { label: 'Valuation / unit', formatter: data => <ProjectionMetrics data={data.income.valuationPerUnit} /> },
                    ],
                },
            ],
            data: comparisonReportData,
        };
    }, [comparisonReportData]);

    const { mutate: createReport } = useCreateReport();
    const { mutate: updateReport } = useUpdateReport();

    const handleSaveReport = () => {
        const postData: ICreateReportPostData = {
            name: reportName,
            description: reportDescription,
            type: ReportTypeId.Comparison,
            deals: selectedDeals.map(selection => ({
                dealId: selection.deal.id,
                proFormaId: selection.proForma?.value,
            })),
        };
        if (report) {
            updateReport(
                {
                    postData: {
                        report_id: report.id,
                        ...postData,
                    },
                    orgId,
                },
                {
                    onSuccess: data => {
                        queryClient.invalidateQueries({ queryKey: ['reportDetails', data.id] });
                        showSuccess('Report updated successfully');
                        setIsEditMode(false);
                    },
                    onError: () => {
                        showError('Failed to update report. Please try again.');
                    },
                },
            );
        } else {
            createReport(
                {
                    postData,
                    orgId,
                },
                {
                    onSuccess: data => {
                        const path = generatePath(URLS.REPORTS.REPORT_PAGE, { reportId: data.id });
                        showSuccess('Report created successfully');
                        navigate(path);
                    },
                    onError: () => {
                        showError('Failed to create report. Please try again.');
                    },
                },
            );
        }
    };

    return (
        <div data-testid="comparison-report-container">
            <ReportHeader
                reportName={reportName}
                reportDescription={reportDescription}
                setReportName={setReportName}
                setReportDescription={setReportDescription}
                onClickBack={onClickBack}
                onClickExport={() => console.log('export')}
                onClickSave={handleSaveReport}
                onClickEdit={() => setIsEditMode(true)}
                isEditing={isEditMode}
            />
            {gridConfig && (
                <ComparisonGrid
                    maxHeight="78vh"
                    gridConfig={gridConfig}
                    isEditMode={isEditMode}
                    renderAddToComparison={() => (
                        <AddToComparisonSimpleSelect
                            label={selectionConfig.label}
                            placehoder={selectionConfig.placeholder}
                            options={selectionConfig.options}
                            onSelectOption={selectionConfig.onSelectOption}
                            selectedOption={selectionConfig.selectedOption}
                        />
                    )}
                />
            )}
            {ToastContainerElement}
        </div>
    );
};

export default ComparisonReport;
