import RenderIf from 'components/RenderIf/RenderIf';
import React, { useState } from 'react';
import 'css/partials/_bricks.NSCellInput.scss';
import NSInput from 'bricks/NSInput/NSInput';
import { InputType } from 'reactstrap/es/Input';
import classNames from 'classnames';

interface NSCellInputProps {
    value: any;
    onChange: any;
    onBlur: any;
    type?: InputType | undefined;
    placeholder?: string;
    customClassName?: string;
    onEnter?: any;
    rightAlign?: boolean;
    setCellId?: (id: string | undefined) => void;
    cellId?: string;
}

const NSCellInput = ({ value, onChange, onBlur, type, placeholder, customClassName, onEnter, rightAlign, setCellId, cellId }: NSCellInputProps) => {
    const [isEditing, setIsEditing] = useState(false);

    const handleMouseEnter = () => {
        setIsEditing(true);
        if (setCellId) setCellId(cellId!);
    };

    const handleMouseLeave = () => {
        setIsEditing(false);
        if (setCellId) setCellId(undefined);
    };

    return (
        <div role="presentation" className={`NSCellInput text-primary ${customClassName}`} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <RenderIf isTrue={isEditing}>
                <NSInput
                    type={type || 'text'}
                    name="NSCellInput"
                    inputClassName={`NSCellInput__input text-primary ${rightAlign ? 'ml-0' : ''}`}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    onKeyDown={onEnter}
                    rightAlign={rightAlign}
                />
            </RenderIf>
            <RenderIf isTrue={!isEditing}>
                <span
                    className={classNames({
                        'text-right': rightAlign,
                        NSCellInput__label: !rightAlign,
                        'text-nowrap': true,
                    })}
                >
                    {value || placeholder}
                </span>
            </RenderIf>
        </div>
    );
};

export default NSCellInput;
