import React, { useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { NSTable, NSButton, NSDropdown } from 'bricks';
import { PRO_FORMA_DEVELOPMENT_UNIT_OPTIONS, UNIT_OF_MEASURE_TYPES, UNIT_TYPE_NAMES } from 'constants/unitTypes';
import { PRO_FORMA_DEVELOPMENT_SOURCES_OPTIONS } from 'constants/sources';
import { IOptionAnyValue } from 'bricks/types';
import { DevelopmentUnitOfMeasureTypes, IProFormaSourceTableRow, IUnitTypeOption } from 'views/ProFormaTable/types';
import { isEmpty } from 'lodash';
import { PRO_FORMA_DEVELOPMENT_UNIT_TYPE_KEY, mockOrgId } from 'views/ProFormaTable/constants';
import { getSourcesAmountTotal, getSourcesCostPerUnitTotal } from 'views/ProFormaTable/helpers';
import RenderIf from 'components/RenderIf/RenderIf';
import { useCreateProFormaSource } from 'queries/ProFormaSource';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { standardHandlerActionError } from 'ns_libs/utils';
import { AxiosError } from 'axios';
import AddSourceModal from './AddSourceModal/AddSourceModal';
import Source from './Source/Source';

export interface IDevelopmentSourcesTable {
    sources: IProFormaSourceTableRow[];
}
const DevelopmentSourcesTable = ({ sources }: IDevelopmentSourcesTable) => {
    const queryClient = useQueryClient();
    const { proFormaId } = useParams();

    const usesLocalStorageData: UNIT_OF_MEASURE_TYPES = JSON.parse(localStorage.getItem(PRO_FORMA_DEVELOPMENT_UNIT_TYPE_KEY) ?? '{}');

    const existingUnitType = !isEmpty(usesLocalStorageData)
        ? PRO_FORMA_DEVELOPMENT_UNIT_OPTIONS.filter(option => option.value === usesLocalStorageData)[0]
        : PRO_FORMA_DEVELOPMENT_UNIT_OPTIONS?.[0];

    const [sourcesUnitType, setSourcesUnitType] = useState<IUnitTypeOption>(existingUnitType);
    const [sourcesType, setSourcesType] = useState<IOptionAnyValue>(PRO_FORMA_DEVELOPMENT_SOURCES_OPTIONS?.[0]);
    const [openAddSourceModal, setOpenAddSourceModal] = useState(false);

    const toggleAddSourceModal = () => setOpenAddSourceModal(!openAddSourceModal);

    const { mutate: createProFormaSource, isPending: isCreateProFormaSourcePending } = useCreateProFormaSource({
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['proFormaSources'] });
            toggleAddSourceModal();
        },
        onError: (error: AxiosError) => standardHandlerActionError(error, 'Error creating pro forma sources - please try again.'),
    });

    const handleCreateProFormaSource = (sourceType: string, sourceName: string) => {
        const max = !sources?.length ? 0 : Math.max(...sources.map(source => source.traunch || 0));

        if (proFormaId) {
            createProFormaSource({
                organizationId: mockOrgId,
                proFormaId,
                postBody: {
                    sourceTypeId: sourceType,
                    name: sourceName,
                    traunch: max + 1,
                    amount: '0',
                },
            });
        }
    };

    const costPerUnitTotal = getSourcesCostPerUnitTotal(sources, sourcesUnitType);
    const amountTotal = getSourcesAmountTotal(sources);

    return (
        <>
            <NSTable>
                <thead className="NSTable__thead">
                    <tr className="NSTable__thead__tr">
                        <th className="NSTable__thead__tr__th NSTable__thead__tr__th--right-border align-middle" colSpan={4}>
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center ml-2">
                                    <span className="ml-0">Name</span>
                                </div>
                                <NSButton className="px-2 text-muted cursor--pointer" size="sm" iconOnly icon={faPlus} callback={toggleAddSourceModal} />
                            </div>
                        </th>
                        <th className="NSTable__thead__tr__th align-middle" colSpan={2}>
                            <div className="d-flex justify-content-end">
                                <NSDropdown
                                    options={PRO_FORMA_DEVELOPMENT_SOURCES_OPTIONS}
                                    selectedOption={sourcesType}
                                    handleSelectOption={setSourcesType}
                                    badgeColor="dark-lighten"
                                    isBadgeToggle
                                />
                            </div>
                        </th>
                        <th className="NSTable__thead__tr__th align-middle" colSpan={1}>
                            <div className="d-flex justify-content-end">Fund. traunch</div>
                        </th>
                        <th className="NSTable__thead__tr__th align-middle" colSpan={1}>
                            <div className="d-flex justify-content-end">% of sources</div>
                        </th>
                        <th className="NSTable__thead__tr__th align-middle" colSpan={2}>
                            <div className="text-right">
                                <span className="mr-1">Cost per</span>
                                <NSDropdown
                                    options={PRO_FORMA_DEVELOPMENT_UNIT_OPTIONS}
                                    selectedOption={{ label: UNIT_TYPE_NAMES[sourcesUnitType.value].abbreviated, value: sourcesUnitType.value }}
                                    handleSelectOption={setSourcesUnitType}
                                    badgeColor="dark-lighten"
                                    isBadgeToggle
                                />
                            </div>
                        </th>
                        <th className="NSTable__thead__tr__th align-middle text-right" colSpan={2}>
                            Amount
                        </th>
                    </tr>
                </thead>
                <tbody className="NSTable__tbody">
                    <RenderIf isTrue={sources?.length}>
                        {sources.map(source => (
                            <Source
                                key={source.id}
                                source={source}
                                totalSourcesAmount={sources.length}
                                unitType={sourcesUnitType.value as DevelopmentUnitOfMeasureTypes}
                            />
                        ))}
                    </RenderIf>

                    <RenderIf isTrue={!sources?.length}>
                        <tr className="NSTable__tbody_tr NSTable__tbody__tr--level-1">
                            <td className="NSTable__tbody__tr__td align-middle" colSpan={12}>
                                <div className="text-center text-dark mt-4">Your added sources will appear here.</div>
                                <div className="text-center mb-4">
                                    Click the plus <span className="text-dark">[+]</span> symbol in the <span className="text-dark">Name</span> header to
                                    add your first source.
                                </div>
                            </td>
                        </tr>
                    </RenderIf>
                </tbody>
                <tfoot className="NSTable__tfoot">
                    <tr className="NSTable__tfoot__tr">
                        <td className="NSTable__tfoot__tr__td NSTable__tfoot__tr__td--right-border text-left" colSpan={4}>
                            <div className="ml-2">Total</div>
                        </td>

                        <td className="NSTable__tfoot__tr__td" colSpan={2} />
                        <td className="NSTable__tfoot__tr__td" colSpan={1} />
                        <td className="NSTable__tfoot__tr__td text-right" colSpan={1}>
                            {sources?.length ? '100.00%' : '—'}
                        </td>
                        <td className="NSTable__tfoot__tr__td text-right" colSpan={2}>
                            {costPerUnitTotal}
                        </td>
                        <td className="NSTable__tfoot__tr__td text-right" colSpan={2}>
                            {amountTotal}
                        </td>
                    </tr>
                </tfoot>
            </NSTable>

            <RenderIf isTrue={openAddSourceModal}>
                <AddSourceModal
                    toggle={toggleAddSourceModal}
                    isOpen={openAddSourceModal}
                    isLoading={isCreateProFormaSourcePending}
                    handleCreateProFormaSource={handleCreateProFormaSource}
                />
            </RenderIf>
        </>
    );
};

export default DevelopmentSourcesTable;
