import React, { useState } from 'react';
import { NSInput, NSLabel, NSModal } from 'bricks';

export interface IBudgetLineAmountModalProps {
    isOpen: boolean;
    toggle: () => void;
    headerText: string;
    handleUpdateAmount: (amount: number | null) => void;
    isLoadingCallback: boolean;
}

const BudgetLineAmountModal = ({ isOpen, toggle, headerText, handleUpdateAmount, isLoadingCallback }: IBudgetLineAmountModalProps) => {
    const [amount, setAmount] = useState<number | null>(null);

    return (
        <NSModal
            isOpen={isOpen}
            toggle={toggle}
            modalHeaderText={headerText}
            modalHeaderClassName="DateModal text-dark bg-primary"
            modalFooterButtonText="Apply"
            modalFooterButtonFunction={() => handleUpdateAmount(amount)}
            modalFooterButtonIsLoading={isLoadingCallback}
            modalFooterIsButtonFunctionDisabled={isLoadingCallback || amount === null}
        >
            <NSLabel>Amount</NSLabel>

            <NSInput
                type="number"
                name="amount"
                placeholder="Enter amount"
                prependInputAddon="$"
                value={amount}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmount(e.target.value === '' ? null : parseFloat(e.target.value))}
            />
        </NSModal>
    );
};

export default BudgetLineAmountModal;
