if (process.env.REACT_APP_REJECT_UNAUTHORIZED_WEBSOCKET !== 'true' && process.env.REACT_APP_ENV !== 'development' && process.env.NODE_ENV !== 'test') {
    throw new Error(
        `Invalid configuration: REACT_APP_REJECT_UNAUTHORIZED_WEBSOCKET is set to "true" in a non-local environment (${process.env.REACT_APP_ENV}).`,
    );
}
export const config = {
    environment: process.env.REACT_APP_ENV!,
    auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN,
    auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
    auth0Audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    auth0AccessTokenRefreshIntervalMinutes: process.env.REACT_APP_AUTH0_ACCESS_TOKEN_REFRESH_INTERVAL_MINUTES,
    apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
    webSocketBaseUrl: process.env.REACT_APP_WEBSOCKET_BASE_URL,
    northspyreProjectsAppUrl: process.env.REACT_APP_NORTHSPYRE_PROJECTS_APP_URL!,
    mapboxGlApiKey: process.env.REACT_APP_MAPBOX_GL_API_KEY!,
    rejectUnauthorizedWebsocket: process.env.REACT_APP_REJECT_UNAUTHORIZED_WEBSOCKET === 'true',
};
