import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';

export interface IResponse {
    url: string;
}

export const getScenarioDashboardPdf = async (orgId: string, scenarioId: number): Promise<IResponse> => {
    const response = await axiosInstance.get(API_ENDPOINTS.BOE_SCENARIO_DASHBOARD_PDF(orgId, scenarioId));
    return response.data;
};

export const useGetScenarioDashboardPdf = (orgId: string, scenarioId: number, options: { enabled: boolean }) =>
    useQuery({
        queryKey: ['boeScenarioDashboardPdf', orgId, scenarioId],
        queryFn: () => getScenarioDashboardPdf(orgId, scenarioId),
        ...options,
    });

export default useGetScenarioDashboardPdf;
