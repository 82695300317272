import { NSCard } from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import React from 'react';
import { Row } from 'reactstrap';
import { IDeal } from 'views/Reports/types';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProFormaSelector from '../ProFormaSelector/ProFormaSelector';

export interface ISelectedDealProps {
    deal: IDeal;
    selectedProForma?: IOptionAnyValue;
    onChangeProFormaSelection: (newSelection: IOptionAnyValue) => void;
    onRemoveDealSelection: () => void;
}

const SelectedDeal = ({ deal, selectedProForma, onChangeProFormaSelection, onRemoveDealSelection }: ISelectedDealProps) => {
    return (
        <NSCard className="NSCard--level-4 p-2 px-3 m-0">
            <Row className="d-flex m-0 justify-content-between align-items-center">
                <div className="m-0">
                    <h4 className="text-white mb-0">{deal.project}</h4>
                    <div>
                        <span>{`${deal.projectType} • ${deal.dealType}`}</span>
                    </div>
                </div>
                <div className="d-flex m-0 align-items-center py-1 pl-3">
                    <ProFormaSelector
                        dealId={deal.id}
                        selectedProForma={selectedProForma}
                        onChangeProFormaSelection={(option: IOptionAnyValue) => onChangeProFormaSelection(option)}
                        dropdownClassName="Deal-selector__dropdown w-100"
                        showDefaultOption
                    />
                    <FontAwesomeIcon icon={faTrash} data-testid="trash-icon" size="lg" className="pl-2 cursor--pointer" onClick={onRemoveDealSelection} />
                </div>
            </Row>
        </NSCard>
    );
};

export default SelectedDeal;
