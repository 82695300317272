import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DEAL_TYPES } from 'views/ProFormaTable/constants';
import { useProFormaWizard } from 'views/ProFormaWizard/context/ProFormaWizardProvider';
import Uses, { IUses } from './Uses/Uses';
import { ISource } from './Sources/Source/types';
import { sourcesInitialState } from './Sources/Source/constants';
import Sources from './Sources/Sources';

const ProFormaWizardStepTwo = () => {
    const { registerStepHandler, getStepData, setStepData } = useProFormaWizard();
    const [sources, setSources] = useState<ISource[]>(() => getStepData(2)?.sources || sourcesInitialState);
    const [usesValues, setUsesValues] = useState<IUses>(
        () =>
            getStepData(2)?.usesValues || {
                acquisitionCosts: 0,
                hardCosts: 0,
                totalHardCosts: 0,
                hardCostType: 'sf',
                softCosts: 0,
                totalSoftCosts: 0,
                softCostType: 'sf',
                developerFeePercent: 0,
                reserveInterest: 0,
            },
    );

    const [assetType] = useState<string>(DEAL_TYPES.APARTMENT);

    const validate = useCallback(() => true, []);

    const getData = useCallback(
        () => ({
            sources,
            usesValues,
        }),
        [sources, usesValues],
    );

    useEffect(() => {
        registerStepHandler(2, { validate, getData });

        // Save data when sources or usesValues change
        setStepData(2, getData());
    }, [getData, validate]);

    const handleInputChange = (key: string, value: number | string) => {
        setUsesValues(prevValues => ({
            ...prevValues,
            [key]: value,
        }));
    };

    // TODO: Remove this when this data comes from the API.
    const dealUnits = 50;
    const dealSF = 10;

    // eslint-disable-next-line max-len, prettier/prettier
    const totalUses = usesValues.acquisitionCosts + usesValues.totalHardCosts + usesValues.totalHardCosts + usesValues.reserveInterest + usesValues.developerFeePercent;
    const totalSources = useMemo(() => sources.reduce((acc, curr) => acc + Number(curr.amount), 0), [sources]);

    return (
        <div>
            <Uses
                values={usesValues}
                onChange={handleInputChange}
                dealSF={dealSF}
                dealUnits={dealUnits}
                assetType={assetType}
                totalSources={totalSources}
            />
            <Sources sources={sources} onChange={setSources} totalUses={totalUses} totalSources={totalSources} />
        </div>
    );
};

export default ProFormaWizardStepTwo;
