import { config } from 'config';

export interface GeocodingResult {
    lat: number;
    lng: number;
}

export const geocodeAddress = async (address: string, city: string, state: string, zipCode?: string): Promise<GeocodingResult | null> => {
    try {
        const searchQuery = `${address}, ${city}, ${state} ${zipCode || ''}`.trim();
        const encodedQuery = encodeURIComponent(searchQuery);
        const response = await fetch(
            `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedQuery}.json?access_token=${config.mapboxGlApiKey}&limit=1`,
        );
        const data = await response.json();

        if (data.features && data.features.length > 0) {
            const [lng, lat] = data.features[0].center;
            return { lat, lng };
        }
        return null;
    } catch (error) {
        console.error('Geocoding error:', error);
        return null;
    }
};
