import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';
import { IDealGroup } from '../types';

const getDealGroups = async (orgId: string): Promise<IDealGroup[]> => {
    const response = await axiosInstance.get<IDealGroup[]>(API_ENDPOINTS.DEALS_GROUPS(orgId));
    return response.data;
};

export const useGetDealGroups = (orgId: string) => {
    return useQuery<IDealGroup[], Error>({
        queryKey: ['dealGroups', orgId],
        queryFn: () => getDealGroups(orgId),
        enabled: !!orgId,
    });
};
