/* eslint-disable prettier/prettier */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { NSCard, NSDropdown, NSInput, NSLabel } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLeftRight, faDollar, faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import './Uses.scss';
import { numeralFormatterCurrency } from 'ns_libs/formatter';
import { Tooltip } from 'reactstrap';
import classNames from 'classnames';
import { DEAL_TYPES } from 'views/ProFormaTable/constants';
import { calculateDeveloperFeePercent, calculateTotalDeveloperFee } from 'views/ProFormaWizard/helpers/calculateTotalDeveloperFeeHelper';

const HARD_COSTS_UNITS = [
    { value: 'sf', label: 'per SF' },
    { value: 'units', label: 'per Unit' },
];

export interface IUses {
    acquisitionCosts: number;
    hardCosts: number;
    totalHardCosts: number;
    hardCostType: string;
    softCosts: number;
    totalSoftCosts: number;
    softCostType: string;
    developerFeePercent: number;
    reserveInterest: number;
}

export interface IUsesProps {
    dealUnits?: number;
    dealSF?: number;
    assetType?: string;
    values: IUses;
    totalSources: number;
    onChange: (key: string, value: number | string) => void;
}

const Uses = ({ dealUnits, dealSF, assetType, values, totalSources, onChange }: IUsesProps) => {
    const [totalDeveloperFee, setTotalDeveloperFee] = useState(0);
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
    const filteredUnits = assetType === DEAL_TYPES.APARTMENT ? HARD_COSTS_UNITS : HARD_COSTS_UNITS.filter(unit => unit.value === 'sf');

    const updateCosts = (
        type: 'hardCosts' | 'totalHardCosts' | 'softCosts' | 'totalSoftCosts',
        newValue: number,
        relatedType: 'totalHardCosts' | 'hardCosts' | 'totalSoftCosts' | 'softCosts',
        multiplierType: 'hardCostType' | 'softCostType'
    ) => {
        const multiplier = values[multiplierType] === 'units' ? dealUnits : dealSF;
        if (type.includes('total')) {
            onChange(type, newValue);
            onChange(relatedType, newValue / (multiplier ?? 1));
        } else {
            onChange(type, newValue);
            onChange(relatedType, newValue * (multiplier ?? 1));
        }
    };

    useEffect(() => {
        updateCosts('hardCosts', values.hardCosts, 'totalHardCosts', 'hardCostType');
    }, [values.hardCostType]);

    useEffect(() => {
        updateCosts('softCosts', values.softCosts, 'totalSoftCosts', 'softCostType');
    }, [values.softCostType]);

    useEffect(() => {
        const newTotalDeveloperFee = calculateTotalDeveloperFee(values);
        setTotalDeveloperFee(newTotalDeveloperFee);
    }, [values.acquisitionCosts, values.totalHardCosts, values.reserveInterest, values.developerFeePercent]);

    const handleDeveloperFeeChange = (newDeveloperFeeAmount: number) => {
        const newDeveloperFeePercent = calculateDeveloperFeePercent(newDeveloperFeeAmount, values);
        onChange('developerFeePercent', newDeveloperFeePercent);
        setTotalDeveloperFee(newDeveloperFeeAmount);
    };
    const totalUses = values.acquisitionCosts + values.totalHardCosts + values.totalHardCosts + values.reserveInterest + totalDeveloperFee;
    const totalUsesFormatted = numeralFormatterCurrency(totalUses);

    return (
        <div className="w-100">
            <small>1 of 2</small>
            <div className="Uses__title">Uses</div>
            <div className="Uses__subtitle pb-2">
                Add any information about this investment’s uses. You will be able to add more detailed information later.
            </div>
            <div className="d-flex flex-row pb-2">
                <NSCard className="NSCard--level-2 p-2 mr-2 w-50 ml-0 mr-0">
                    <NSInput
                        id="acquisitionCosts"
                        type="number"
                        label="Acquisition costs"
                        name="acquisitionCosts"
                        placeholder="Enter amount"
                        prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                        value={values.acquisitionCosts}
                        onChange={(e: { target: { value: number } }) => onChange('acquisitionCosts', Number(e.target.value))}
                    />
                </NSCard>
                <NSCard className="NSCard--level-2 p-2 w-50 ml-0 mr-0">
                    <NSLabel className="text-dark font-weight-normal" htmlFor="hardCosts">
                        Hard costs
                    </NSLabel>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <NSInput
                            id="hardCosts"
                            dataTestId="hardCosts"
                            type="number"
                            name="hardCosts"
                            placeholder="Enter amount"
                            prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                            value={values.hardCosts}
                            onChange={(e: { target: { value: number } }) => updateCosts('hardCosts', Number(e.target.value), 'totalHardCosts', 'hardCostType')}
                            appendInputAddon={
                                <NSDropdown
                                    options={filteredUnits}
                                    selectedOption={filteredUnits.find(option => option.value === values.hardCostType)}
                                    customSelectedOptionText={filteredUnits.find(option => option.value === values.hardCostType)?.label}
                                    handleSelectOption={option => onChange('hardCostType', option.value)}
                                    badgeColor=""
                                    isBadgeToggle
                                    toggleClassName="p-0 m-0"
                                />
                            }
                        />
                        <FontAwesomeIcon icon={faLeftRight} className="mx-2" />
                        <NSInput
                            id="totalHardCosts"
                            type="number"
                            name="totalHardCosts"
                            value={values.totalHardCosts}
                            onChange={(e: { target: { value: number } }) => updateCosts('totalHardCosts', Number(e.target.value), 'hardCosts', 'hardCostType')}
                            prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                            appendInputAddon={<div>Total</div>}
                        />
                    </div>
                </NSCard>
            </div>
            <div className="d-flex flex-row pb-2">
                <NSCard className="NSCard--level-2 p-2 mr-2 w-50 ml-0 mr-0">
                    <NSLabel className="text-dark font-weight-normal">Soft costs</NSLabel>
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <NSInput
                            id="softCosts"
                            dataTestId="softCosts"
                            type="number"
                            name="softCosts"
                            placeholder="Enter amount"
                            prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                            value={values.softCosts}
                            onChange={(e: { target: { value: number } }) => updateCosts('softCosts', Number(e.target.value), 'totalSoftCosts', 'softCostType')}
                            appendInputAddon={
                                <NSDropdown
                                    options={filteredUnits}
                                    selectedOption={filteredUnits.find(option => option.value === values.softCostType)}
                                    customSelectedOptionText={filteredUnits.find(option => option.value === values.softCostType)?.label}
                                    handleSelectOption={option => onChange('softCostType', option.value)}
                                    badgeColor=""
                                    isBadgeToggle
                                    toggleClassName="p-0 m-0"
                                />
                            }
                        />
                        <FontAwesomeIcon icon={faLeftRight} className="mx-2" />
                        <NSInput
                            id="totalSoftCosts"
                            type="number"
                            name="totalSoftCosts"
                            value={values.totalSoftCosts}
                            onChange={(e: { target: { value: number } }) => updateCosts('totalSoftCosts', Number(e.target.value), 'softCosts', 'softCostType')}
                            prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                            appendInputAddon={<div>Total</div>}
                        />
                    </div>
                </NSCard>
                <div className="d-flex flex-row w-50">
                    <NSCard className="NSCard--level-2 px-2">
                        <div className="d-flex flex-row pt-2">
                            <NSLabel className="text-dark font-weight-normal">Developer fee</NSLabel>
                            <FontAwesomeIcon className="px-1 text-white" id="developer-fee-tooltip" icon={faCircleInfo} />
                            <Tooltip target="developer-fee-tooltip" isOpen={tooltipOpen} toggle={toggleTooltip} placement="top">
                                Developer fee is calculated on total uses.
                            </Tooltip>
                        </div>
                        <div className="d-flex flex-row justify-content-center align-items-center">
                            <div className="Uses__developer-fee-percent">
                                <NSInput
                                    id="developerFeePercent"
                                    dataTestId="developerFeePercent"
                                    inputClassName="pr-2"
                                    type="number"
                                    name="developerFeePercent"
                                    placeholder="Enter percentage"
                                    value={values.developerFeePercent}
                                    onChange={(e: { target: { value: number } }) => onChange('developerFeePercent', Number(e.target.value))}
                                    appendInputAddon={<div>%</div>}
                                />
                            </div>
                            <div className="Uses__developer-fee-amount">
                                <NSInput
                                    id="developerFeeAmount"
                                    type="number"
                                    name="developerFeeAmount"
                                    value={totalDeveloperFee}
                                    onChange={(e: { target: { value: number } }) => handleDeveloperFeeChange(Number(e.target.value))}
                                    prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                                />
                            </div>
                        </div>
                    </NSCard>
                    <NSCard className="NSCard--level-2 flex-grow-1 ml-2 p-2">
                        <NSInput
                            id="reserveInterest"
                            dataTestId="reserveInterest"
                            type="number"
                            label="Reserve interest"
                            name="reserveInterest"
                            placeholder="Enter amount"
                            prependInputAddon={<FontAwesomeIcon icon={faDollar} />}
                            value={values.reserveInterest}
                            onChange={(e: { target: { value: number } }) => onChange('reserveInterest', Number(e.target.value))}
                        />
                    </NSCard>
                </div>
            </div>
            <NSCard
                className={classNames('p-2 mt-1 justify-content-center', {
                    Uses__total: totalSources >= totalUses,
                    'Uses__total--highlight': totalSources < totalUses,
                })}
            >
                <div className="d-flex flex-row justify-content-between align-items-center">
                    <span className="font-weight-bold text-white">Total uses:</span>
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        {totalSources < totalUses ? <NSLabel className="Uses__label-warning Uses__total-label">Uses exceed sources</NSLabel> : null}
                        <span className="font-weight-bold text-white">{totalUsesFormatted}</span>
                    </div>
                </div>
            </NSCard>
        </div>
    );
};

export default Uses;
