import React, { useState } from 'react';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Row } from 'reactstrap';

import { mockOrgId, POPULATE_USES_METHOD } from 'views/ProFormaTable/constants';
import RenderIf from 'components/RenderIf/RenderIf';
import ExpandableSection from 'components/ExpandableSection/ExpandableSection';
import { NSCard } from 'bricks';
import NSInfoBanner from 'components/NSInfoBanner/NSInfoBanner';
import useCreateDefaultBudgetClassifications from 'queries/BudgetClassification/useCreateDefaultBudgetClassifications';
import { AxiosError } from 'axios';
import { useParams } from 'react-router-dom';
import { standardHandlerActionError } from 'ns_libs/utils';
import { useDeleteBudgetClassifications } from 'queries/BudgetClassification';
import { IProFormaData } from 'views/ProFormaTable/types';
import { formatUTCtoLocal } from 'ns_libs/formatter';
import UsesVsSourcesCard from './UsesVsSourcesCard/UsesVsSourcesCard';
import DevelopmentUsesTable from './DevelopmentUsesTable/DevelopmentUsesTable';
import DevelopmentSourcesTable from './DevelopmentSourcesTable/DevelopmentSourcesTable';
import ImportUsesModal from './ImportUsesModal/ImportUsesModal';
import { UsesTableProvider } from './DevelopmentUsesTable/context/UsesTableProvider';
import { useProFormaSocketContext } from '../socketContext/ProFormaSocketProvider';

export interface IProFormaDevelopmentProps {
    proForma: IProFormaData;
    navigateToSection: (sideNavName: string, tabName?: string) => void;
}

const ProFormaDevelopment = ({ proForma, navigateToSection }: IProFormaDevelopmentProps) => {
    const { uses, sources } = useProFormaSocketContext();
    const [usesMethod, setUsesMethod] = useState<string | null>(null);
    const [isImportModalOpen, setIsImportModalOpen] = useState(false);
    const { proFormaId } = useParams();

    const toggleImportModal = () => setIsImportModalOpen(!isImportModalOpen);

    const { mutate: createBudgetClassifications, isPending: isCreateDefaultBudgetClassificationPending } = useCreateDefaultBudgetClassifications({
        onSuccess: () => {
            setUsesMethod(POPULATE_USES_METHOD.START_FROM_SCRATCH.title);
            // call fetchUses() in the provider to refetch the uses table data
        },
        onError: (error: AxiosError) => {
            standardHandlerActionError(error, 'Error creating default budget classifications - please try again.');
        },
    });

    const { mutate: deleteBudgetClassifications, isPending: isDeletingBudgetClassificationPending } = useDeleteBudgetClassifications({
        onSuccess: () => {
            // call fetchUses() in the provider to refetch the uses table data
            setUsesMethod(null);
            toggleImportModal();
        },
        onError: (error: AxiosError) => {
            standardHandlerActionError(error, 'Error deleting default budget classifications - please try again.');
        },
    });

    const handleStartFromScratch = () => {
        if (!uses?.length && proFormaId) {
            createBudgetClassifications({ organizationId: mockOrgId, proFormaId });
        } else {
            setUsesMethod(POPULATE_USES_METHOD.START_FROM_SCRATCH.title);
        }
    };

    const handleDeleteBudgetCategories = () => {
        deleteBudgetClassifications({
            organizationId: mockOrgId,
            proFormaId: proFormaId!,
            deleteAll: true,
        });
    };

    return (
        <>
            <ExpandableSection
                number={1}
                title="Development Timeline"
                subtitle="Determine development timeline for this investment"
                className="NSCard--level-2"
                isExpanded
                animate
            >
                <div className="d-flex m-3">
                    <NSCard className="NSCard--level-4 w-100 py-2 px-3 mr-3">
                        <div>
                            <h5 className="my-0 text-muted font-14">Development start</h5>
                            <RenderIf isTrue={proForma.startDate !== null}>
                                <small className="text-dark font-14">{proForma.startDate && formatUTCtoLocal(proForma.startDate, 'MMMM yyyy')}</small>
                            </RenderIf>
                            <RenderIf isTrue={!proForma.startDate}>
                                <small className="text-dark font-italic font-14">In progress</small>
                            </RenderIf>
                        </div>
                    </NSCard>
                    <NSCard className="NSCard--level-4 w-100 py-2 px-3">
                        <div>
                            <h5 className="my-0 text-muted font-14">Development end</h5>
                            <RenderIf isTrue={proForma.saleDate !== null}>
                                <small className="text-dark font-14">{proForma.saleDate && formatUTCtoLocal(proForma.saleDate, 'MMMM yyyy')}</small>
                            </RenderIf>
                            <RenderIf isTrue={!proForma.saleDate}>
                                <small className="text-dark font-italic font-14">In progress</small>
                            </RenderIf>
                        </div>
                    </NSCard>
                </div>
            </ExpandableSection>

            <NSInfoBanner
                title="Warning: Uses exceed sources"
                subtitle="Ensure funding is sufficient to cover uses."
                className="mt-3 NSCard--level-4 NSCard__left-border--warning"
                warning
                customInfo={<UsesVsSourcesCard />}
            />

            <ExpandableSection
                number={2}
                title="Uses"
                subtitle={`Information about the investment's allocation of funds`}
                className="mt-3 NSCard--level-2"
                displayButton={Boolean(usesMethod || uses?.length)}
                displayButtonText="Import Uses via different method"
                displayButtonCallback={toggleImportModal}
                isExpanded
                animate
            >
                <RenderIf isTrue={!usesMethod && !uses?.length}>
                    <Row className="m-3">
                        <Col m={12} xl={4} className="px-0">
                            <div className="h3 mt-0 text-dark mb-3">
                                Select a method to populate <b>Uses</b>:
                            </div>
                            <NSCard
                                className="NSCard--level-4 mb-2 p-2 cursor--pointer"
                                onClick={() => setUsesMethod(POPULATE_USES_METHOD.IMPORT_FROM_PREVIOUS_PROJECT.title)}
                            >
                                <div className="text-dark h5 mt-1 mb-0">{POPULATE_USES_METHOD.IMPORT_FROM_PREVIOUS_PROJECT.title}</div>
                                <div className="mb-0">{POPULATE_USES_METHOD.IMPORT_FROM_PREVIOUS_PROJECT.subtitle}</div>
                            </NSCard>

                            <NSCard
                                className="NSCard--level-4 mb-2 p-2 cursor--pointer"
                                onClick={() => setUsesMethod(POPULATE_USES_METHOD.START_WITH_BACK_OF_ENVELOPE.title)}
                            >
                                <div className="text-dark h5 mt-1 mb-0">{POPULATE_USES_METHOD.START_WITH_BACK_OF_ENVELOPE.title}</div>
                                <div className="mb-0">{POPULATE_USES_METHOD.START_WITH_BACK_OF_ENVELOPE.subtitle}</div>
                            </NSCard>

                            <NSCard
                                className="NSCard--level-4 mb-2 p-2 cursor--pointer"
                                onClick={() => setUsesMethod(POPULATE_USES_METHOD.USE_RECOMMENDED_CATEGORIES.title)}
                            >
                                <div className="text-dark h5 mt-1 mb-0">{POPULATE_USES_METHOD.USE_RECOMMENDED_CATEGORIES.title}</div>
                                <div className="mb-0">{POPULATE_USES_METHOD.USE_RECOMMENDED_CATEGORIES.subtitle}</div>
                            </NSCard>

                            <div role="presentation" onClick={handleStartFromScratch} className="text-primary d-flex mt-3 cursor--pointer">
                                <RenderIf isTrue={isCreateDefaultBudgetClassificationPending}>
                                    <span className="spinner-border-sm" style={{ height: '20px', width: '20px' }} />
                                </RenderIf>
                                <RenderIf isTrue={!isCreateDefaultBudgetClassificationPending}>
                                    <div>{POPULATE_USES_METHOD.START_FROM_SCRATCH.title}</div>
                                    <FontAwesomeIcon icon={faArrowRight} className="ml-1 my-auto" />
                                </RenderIf>
                            </div>
                        </Col>
                    </Row>
                </RenderIf>

                <RenderIf isTrue={usesMethod || uses?.length}>
                    <div className="m-3">
                        <RenderIf isTrue={usesMethod === POPULATE_USES_METHOD.IMPORT_FROM_PREVIOUS_PROJECT.title}>
                            {/** IMPORT FROM PREV COMPONENT */}
                        </RenderIf>

                        <RenderIf isTrue={usesMethod === POPULATE_USES_METHOD.START_WITH_BACK_OF_ENVELOPE.title}>
                            {/** START WITH BOE COMPONENT */}
                        </RenderIf>
                        <RenderIf isTrue={usesMethod === POPULATE_USES_METHOD.USE_RECOMMENDED_CATEGORIES.title}>
                            {/** USE RECOMMENDED COMPONENT */}
                        </RenderIf>
                        <RenderIf isTrue={usesMethod === POPULATE_USES_METHOD.START_FROM_SCRATCH.title || uses?.length} isLoading={uses === undefined}>
                            <UsesTableProvider proForma={proForma} navigateToSection={navigateToSection}>
                                <DevelopmentUsesTable uses={uses || []} />
                            </UsesTableProvider>
                        </RenderIf>
                    </div>
                </RenderIf>
            </ExpandableSection>
            <ExpandableSection
                number={3}
                title="Sources"
                subtitle={`Information about the investment's sources of funds`}
                className="mt-3 NSCard--level-2"
                isExpanded
                animate
            >
                <RenderIf isTrue={sources} isLoading={sources === undefined}>
                    <div className="d-flex m-3">
                        <DevelopmentSourcesTable sources={sources || []} />
                    </div>
                </RenderIf>
            </ExpandableSection>

            <RenderIf isTrue={isImportModalOpen}>
                <ImportUsesModal
                    isOpen={isImportModalOpen}
                    toggle={toggleImportModal}
                    handleDeleteBudgetCategories={handleDeleteBudgetCategories}
                    isLoading={isDeletingBudgetClassificationPending}
                />
            </RenderIf>
        </>
    );
};

export default ProFormaDevelopment;
