import React, { Dispatch, SetStateAction, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import classNames from 'classnames';

import { NSButton, NSInput, NSLabel, NSSelect, NSCard, NSInputNumberStepper } from 'bricks';
import { customStyles } from 'bricks/NSSelect/NSSelect';
import { IOptionAnyValue } from 'bricks/types';
import {
    FORM_ROW_CLASS_NAME,
    FORM_LABEL_CLASS_NAME,
    dealTypeProFormaParkingOptions,
    IN_PROGRESS_TEXT,
    mockProFormaUnits,
    MockRentableSquareFootage,
    STRUCTURED_PARKING,
    SURFACE_PARKING,
} from 'views/ProFormaTable/constants';
import RenderIf from 'components/RenderIf/RenderIf';
import { useCreateBuildableLot, useDeleteBuildableLot, useGetBuildableLots, useUpdateBuildableLot } from 'queries/ProForma';
import { numeralFormatter } from 'ns_libs/formatter';
import { PRO_FORMA_SIZE_UNIT_OPTIONS } from 'constants/unitTypes';
import { getProFormaApartmentDisplayCards } from 'views/ProFormaTable/helpers';
import CalculateSizeCard from '../CalculateSizeCard/CalculateSizeCard';
import { IGrossBuildableAreaLot } from '../CalculateSizeCard/types';

export interface IApartmentSizeDetailsProps {
    values: any;
    setValues: Dispatch<SetStateAction<any>>;
    deal: any;
    handleInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleSelect: (name: string, option: string | IOptionAnyValue | IOptionAnyValue[]) => void;
    updateProForma: (key: string, value: any) => void;
}

const ApartmentSizeDetails = ({ values, setValues, deal, handleInputChange, handleSelect, updateProForma }: IApartmentSizeDetailsProps) => {
    const [shouldCalculate, setShouldCalculate] = useState(true);
    const toggleCalculate = () => setShouldCalculate(!shouldCalculate);
    const displayCards = getProFormaApartmentDisplayCards(values, mockProFormaUnits, MockRentableSquareFootage);

    const { dealId: stringDealId, proFormaId: stringProFormaId } = useParams();
    const { data: grossBuildableLotsData, isLoading: isLoadingGrossBuildableLotsData } = useGetBuildableLots(
        Number(stringDealId),
        Number(stringProFormaId),
    );
    const hasGrossBuildableLots = Boolean(grossBuildableLotsData?.grossBuildableAreaLots?.length);
    const totalGrossBuildableArea = hasGrossBuildableLots ? numeralFormatter(grossBuildableLotsData?.totalGrossBuildableArea) : values.grossBuildableArea;

    const grossBuildableLots = grossBuildableLotsData?.grossBuildableAreaLots || [];

    const { mutate: createBuildableLot } = useCreateBuildableLot();
    const handleCreateBuildableLot = async () => createBuildableLot({ dealId: Number(stringDealId), proFormaId: Number(stringProFormaId) });

    const { mutate: deleteBuildableLot } = useDeleteBuildableLot();
    const handleDeleteBuildableLot = async (lotId: number) =>
        deleteBuildableLot({ dealId: Number(stringDealId), proFormaId: Number(stringProFormaId), lotId });

    const { mutate: updateBuildableLot } = useUpdateBuildableLot();
    const handleUpdateBuildableLot = async (id: number, dataKey: keyof IGrossBuildableAreaLot, value: string | number) => {
        updateBuildableLot({ postData: { [dataKey]: value }, dealId: Number(stringDealId), proFormaId: Number(stringProFormaId), lotId: id });
    };

    const updateProFormaParkingTypes = (parkingTypes: IOptionAnyValue[]) => {
        const isStructuredParkingSelected = parkingTypes.find((type: any) => type.value === STRUCTURED_PARKING);
        const isSurfaceParkingSelected = parkingTypes.find((type: any) => type.value === SURFACE_PARKING);

        // Removing parking types
        if (!isStructuredParkingSelected) updateProForma('structuredParkingSpaces', null);
        if (!isSurfaceParkingSelected) updateProForma('surfaceParkingSpaces', null);

        // Adding selected parking types
        if (isStructuredParkingSelected && !values.structuredParkingSpaces) updateProForma('structuredParkingSpaces', 0);
        if (isSurfaceParkingSelected && !values.surfaceParkingSpaces) updateProForma('surfaceParkingSpaces', 0);
    };

    return (
        <Row>
            <Col md={12} lg={8}>
                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>Land area</NSLabel>
                    <NSInput
                        type="number"
                        name="landArea"
                        customClassName="NSInput__appended-child--with-select"
                        value={values.landArea}
                        onChange={handleInputChange}
                        onBlur={() => updateProForma('landArea', values.landArea)}
                        placeholder="Enter land area"
                        inputGroupClassName="p-0"
                        appendInputAddon={
                            <NSSelect
                                name="landAreaUnitTypeId"
                                options={PRO_FORMA_SIZE_UNIT_OPTIONS}
                                value={PRO_FORMA_SIZE_UNIT_OPTIONS.find(opt => opt.value === values.landAreaUnitTypeId)}
                                onChange={option => handleSelect('landAreaUnitTypeId', option.value)}
                                onBlur={() => updateProForma('landAreaUnitTypeId', values.landAreaUnitTypeId)}
                                isClearable={false}
                                styles={{
                                    ...customStyles,
                                    control: styles => ({
                                        ...styles,
                                        border: 'none',
                                        backgroundColor: '#47515d',
                                        width: '80px',
                                    }),
                                }}
                            />
                        }
                    />
                </div>
                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>
                        <NSButton
                            color="secondary"
                            outline={false}
                            callback={() => setValues({ ...values, isFAR: true })}
                            text="FAR"
                            className={classNames('px-3', { 'border border-primary bg-primary-lighten': values.isFAR })}
                        />
                        <NSButton
                            color="secondary"
                            outline={false}
                            callback={() => setValues({ ...values, isFAR: false })}
                            text="Gross build. area"
                            className={classNames({ 'border border-primary bg-primary-lighten': !values.isFAR })}
                        />
                    </NSLabel>
                    <RenderIf isTrue={values.isFAR}>
                        <NSInputNumberStepper
                            name="floorAreaRatio"
                            value={values.floorAreaRatio}
                            onChange={(count: number) => {
                                setValues({ ...values, floorAreaRatio: count });
                                updateProForma('floorAreaRatio', String(count));
                            }}
                            min={0.1}
                            inputGroupClassName="w-25"
                            step={0.1}
                        />
                    </RenderIf>

                    <RenderIf isTrue={!values.isFAR}>
                        <NSInput
                            type={hasGrossBuildableLots ? 'text' : 'number'}
                            name="grossBuildableArea"
                            value={totalGrossBuildableArea}
                            onChange={handleInputChange}
                            onBlur={() => updateProForma('grossBuildableArea', values.grossBuildableArea)}
                            placeholder="Enter gross buildable area"
                            inputGroupClassName="p-0"
                            disabled={hasGrossBuildableLots}
                            appendInputAddon={<div className="px-2">ft&sup2;</div>}
                        />
                    </RenderIf>
                </div>

                <RenderIf isTrue={!values.isFAR}>
                    <CalculateSizeCard
                        isLoading={isLoadingGrossBuildableLotsData}
                        onCreateLot={handleCreateBuildableLot}
                        onDeleteLot={handleDeleteBuildableLot}
                        onUpdateLot={handleUpdateBuildableLot}
                        lots={grossBuildableLots}
                        totalGrossBuildableArea={totalGrossBuildableArea}
                        shouldCalculate={shouldCalculate}
                        toggleCalculate={toggleCalculate}
                    />
                </RenderIf>

                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>Buildings</NSLabel>
                    <NSInputNumberStepper
                        name="buildings"
                        value={values.buildings}
                        onChange={(count: number) => {
                            setValues({ ...values, buildings: count });
                            updateProForma('buildings', String(count));
                        }}
                        min={1}
                        inputGroupClassName="w-25"
                        step={1}
                    />
                </div>

                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>{values.buildings <= 1 ? 'Stories' : 'Avg. # of stories'}</NSLabel>
                    <NSInputNumberStepper
                        name="averageStories"
                        value={values.averageStories}
                        onChange={(count: number) => {
                            setValues({ ...values, averageStories: count });
                            updateProForma('averageStories', count);
                        }}
                        min={1}
                        inputGroupClassName="w-25"
                        step={1}
                    />
                </div>

                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>Built gross SF</NSLabel>
                    <NSInput
                        type="number"
                        name="builtGrossSquareFootage"
                        value={values.builtGrossSquareFootage}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                        onBlur={() => updateProForma('builtGrossSquareFootage', values.builtGrossSquareFootage)}
                        placeholder="Enter built gross SF"
                        inputGroupClassName="p-0"
                        appendInputAddon={<div className="px-2">ft&sup2;</div>}
                    />
                </div>

                <div className={FORM_ROW_CLASS_NAME}>
                    <NSLabel className={FORM_LABEL_CLASS_NAME}>Parking</NSLabel>
                    <div className="w-100">
                        <NSSelect
                            isMulti
                            name="parking"
                            options={dealTypeProFormaParkingOptions[deal.assetType.name]}
                            value={values.parking}
                            onChange={options => handleSelect('parking', options || [])}
                            onBlur={() => updateProFormaParkingTypes(values.parking)}
                        />
                    </div>
                </div>

                <RenderIf isTrue={values.parking?.find((park: any) => park.value === 'structured')}>
                    <div className={FORM_ROW_CLASS_NAME}>
                        <NSLabel className={FORM_LABEL_CLASS_NAME}>Structured parking spaces</NSLabel>
                        <NSInputNumberStepper
                            name="structuredParkingSpaces"
                            value={values.structuredParkingSpaces}
                            onChange={(count: number) => {
                                setValues({ ...values, structuredParkingSpaces: count });
                                updateProForma('structuredParkingSpaces', count);
                            }}
                            min={0.1}
                            inputGroupClassName="w-25"
                            step={1}
                        />
                    </div>
                </RenderIf>

                <RenderIf isTrue={values.parking?.find((park: any) => park.value === 'surface')}>
                    <div className={FORM_ROW_CLASS_NAME}>
                        <NSLabel className={FORM_LABEL_CLASS_NAME}>Surface parking spaces</NSLabel>
                        <NSInputNumberStepper
                            name="surfaceParkingSpaces"
                            value={values.surfaceParkingSpaces}
                            onChange={(count: number) => {
                                setValues({ ...values, surfaceParkingSpaces: count });
                                updateProForma('surfaceParkingSpaces', count);
                            }}
                            min={1}
                            inputGroupClassName="w-25"
                            step={1}
                        />
                    </div>
                </RenderIf>
            </Col>
            <Col md={12} lg={4}>
                <Row className="mx-n1">
                    {displayCards.map(({ label, value, format, valueSuffix }) => (
                        <Col className="px-1 mb-2" md={12} lg={6} key={label}>
                            <NSCard className="NSCard--level-3 p-2 h-100">
                                <div>{label}</div>
                                <RenderIf isTrue={!value}>
                                    <div className="text-dark font-italic">{IN_PROGRESS_TEXT}</div>
                                </RenderIf>
                                <RenderIf isTrue={!!value}>
                                    <div className="text-dark">
                                        {numeralFormatter(value, format)}
                                        {valueSuffix && <span>&nbsp;{valueSuffix}</span>}
                                    </div>
                                </RenderIf>
                            </NSCard>
                        </Col>
                    ))}
                </Row>
            </Col>
        </Row>
    );
};

export default ApartmentSizeDetails;
