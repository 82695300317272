export const INITIAL_EXPENSES = [
    { id: 1, name: 'Capital reserves', growthPercentage: 3.0, amount: 0 },
    { id: 2, name: 'Contract services', growthPercentage: 3.0, amount: 0 },
    { id: 3, name: 'General and administrative', growthPercentage: 3.0, amount: 0 },
    { id: 4, name: 'Insurance', growthPercentage: 3.0, amount: 0 },
    { id: 5, name: 'Make ready cost', growthPercentage: 3.0, amount: 0 },
    { id: 6, name: 'Management fee', growthPercentage: 3.0, amount: 0 },
    { id: 7, name: 'Marketing', growthPercentage: 3.0, amount: 0 },
    { id: 8, name: 'Other capital expenditures', growthPercentage: 3.0, amount: 0 },
    { id: 9, name: 'Payroll', growthPercentage: 3.0, amount: 0 },
    { id: 10, name: 'Property taxes', growthPercentage: 3.0, amount: 0 },
    { id: 11, name: 'Repairs and maintenance', growthPercentage: 3.0, amount: 0 },
    { id: 12, name: 'Retail leasing cost reserves', growthPercentage: 3.0, amount: 0 },
    { id: 13, name: 'Utilities', growthPercentage: 3.0, amount: 0 },
];
