import { DEAL_TYPES } from 'views/ProFormaTable/constants';

export const getDefaultVacancy = (assetType: string): number => {
    switch (assetType) {
        case DEAL_TYPES.RETAIL:
            return 8;
        case DEAL_TYPES.INDUSTRIAL:
            return 6;
        case DEAL_TYPES.OFFICE:
            return 10;
        case DEAL_TYPES.APARTMENT:
            return 6;
        default:
            return 0;
    }
};

export const getDefaultCreditLoss = (assetType: string): number => {
    switch (assetType) {
        case DEAL_TYPES.RETAIL:
            return 3;
        case DEAL_TYPES.INDUSTRIAL:
            return 2;
        case DEAL_TYPES.OFFICE:
            return 2;
        case DEAL_TYPES.APARTMENT:
            return 1;
        default:
            return 0;
    }
};
