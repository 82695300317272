import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import './DealGroupMenu.scss';

interface DealGroupMenuProps {
    onRename: () => void;
    onDelete: () => void;
}

const DealGroupMenu: React.FC<DealGroupMenuProps> = ({ onRename, onDelete }) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    return (
        <Dropdown isOpen={isMenuOpen} toggle={toggleMenu} className="DealGroupMenu" direction="down">
            <DropdownToggle tag="div" className="DealGroupMenu__toggle p-2 m-1" data-testid="deal-group-menu-toggle">
                <FontAwesomeIcon icon={faGear} size="sm" style={{ cursor: 'pointer' }} />
            </DropdownToggle>
            <DropdownMenu className="DealGroupMenu--dropdown-menu text-white" data-testid="deal-group-menu">
                <DropdownItem onClick={onRename}>Rename group</DropdownItem>
                <DropdownItem onClick={onDelete}>Delete group</DropdownItem>
            </DropdownMenu>
        </Dropdown>
    );
};

export default DealGroupMenu;
