import React, { useState } from 'react';
import { NSNavTabs, NSDropdown } from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import { useSlideout } from 'contexts/BackOfEnvelopeContext';
import RenderIf from 'components/RenderIf/RenderIf';
import PageTitle from 'components/PageTitle/PageTitle';
import { TabContent, TabPane } from 'reactstrap';
import { useGetDealById } from 'views/Deals/hooks/useGetDealById';
import { useParams } from 'react-router-dom';
import useToast from 'hooks/useToast';
import { downloadFileFromUrl } from 'helpers/file/download';
import { Deal } from 'views/Deals/types';
import { DEAL_OVERVIEW, DEAL_OVERVIEW_TABS, BACK_OF_ENVELOPE, DEAL_ACTIVITY, ExportOptions } from './constants';
import Overview from './Overview/Overview';
import BackOfEnvelope from './BOE/BackOfEnvelope';
import './DealDashboard.scss';
import { getDealOverviewPdf } from './Overview/hooks/useGetDealOverviewPdf';
import { getScenarioDashboardPdf } from './Overview/hooks/useGetScenarioDashboardPdf';
import { useGetBackOfEnvelopeScenarios } from './BOE/hooks/useGetBOEScenarios';

const DealDashboard = () => {
    const [activeTabName, setActiveTabName] = useState(DEAL_OVERVIEW);
    const { ToastContainerElement } = useToast();
    const { selectedScenarioId } = useSlideout();
    const orgId = '123e4567-e89b-12d3-a456-426614174000'; // TODO remove orgId (to be replaced with API logic)

    const { dealId } = useParams<{ dealId: string }>();

    const { data: fetchedDeal, isLoading } = useGetDealById({
        orgId,
        dealId: Number(dealId),
        shouldFetch: !!dealId,
    });

    const { data: { items: boeScenarios } = {} } = useGetBackOfEnvelopeScenarios({ orgId, dealId: fetchedDeal?.id });

    const handlePdfExport = async (pdfUrl: string, fileName: string) => {
        if (pdfUrl) {
            await downloadFileFromUrl(pdfUrl, fileName, 'pdf', true);
        } else {
            console.error('Failed to export report PDF: No URL returned');
        }
    };

    const exportDealOverviewPdf = async (deal?: Deal) => {
        if (deal) {
            const response = await getDealOverviewPdf(deal.organizationId, deal.id);
            const pdfUrl = response.url;
            handlePdfExport(pdfUrl, `${deal.dealName}-deal-overview`);
        }
    };

    const exportScenarioDashboardPdf = async (deal?: Deal) => {
        const scenarioId = selectedScenarioId || boeScenarios?.[0]?.id;
        if (deal && scenarioId) {
            const response = await getScenarioDashboardPdf(deal.organizationId, Number(scenarioId));
            const pdfUrl = response.url;
            handlePdfExport(pdfUrl, `${deal.dealName}-scenario-dashboard`);
        }
    };

    const exportOptions = Object.values(ExportOptions).map(option => ({
        value: option,
        label: option,
    }));

    const handleSelectExportOption = async (option: IOptionAnyValue) => {
        try {
            switch (option.value) {
                case ExportOptions.SCENARIO_DASHBOARD:
                    exportScenarioDashboardPdf(fetchedDeal);
                    break;
                case ExportOptions.DEAL_OVERVIEW:
                    exportDealOverviewPdf(fetchedDeal);
                    break;
                default:
                    break;
            }
        } catch (error) {
            console.error('Failed to export report PDF:', error);
        }
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!fetchedDeal) {
        return <div>No deal selected</div>;
    }

    return (
        <div className="DealDashboard">
            <PageTitle
                title={fetchedDeal.dealName}
                dealName={fetchedDeal.dealName}
                pageMetaDataTitle="Deal dashboard"
                subtitle={`${fetchedDeal.address} ${fetchedDeal.city} ${fetchedDeal.state} ${fetchedDeal.zipCode}`}
                displayBreadcrumb
                boldTitle
            >
                <NSDropdown
                    containerClassName="mr-2"
                    options={exportOptions}
                    customSelectedOptionText="Export"
                    handleSelectOption={handleSelectExportOption}
                    disabled={!fetchedDeal}
                    menuRight
                />
            </PageTitle>

            <NSNavTabs
                containerClassName="d-flex mt-2 DealDashboard__separator"
                navItems={DEAL_OVERVIEW_TABS.map((navName, index) => ({
                    callback: () => setActiveTabName(navName),
                    name: navName,
                    isActive: activeTabName === navName,
                    id: `deal-overview-${index}`,
                    className: 'text-dark text-nowrap px-4 mr-2',
                }))}
            />

            <TabContent activeTab={activeTabName} className="DealDashboard__tab-content d-flex w-100">
                <RenderIf isTrue={activeTabName === DEAL_OVERVIEW}>
                    <TabPane className="d-flex flex-column w-100" tabId={DEAL_OVERVIEW}>
                        <Overview deal={fetchedDeal} />
                    </TabPane>
                </RenderIf>
                <RenderIf isTrue={activeTabName === BACK_OF_ENVELOPE}>
                    <TabPane className="d-flex flex-column w-100" tabId={BACK_OF_ENVELOPE}>
                        <BackOfEnvelope orgId={orgId} dealId={Number(dealId)} boeScenarios={boeScenarios} />
                    </TabPane>
                </RenderIf>
                <RenderIf isTrue={activeTabName === DEAL_ACTIVITY}>
                    <TabPane className="d-flex flex-column w-100 pt-2" tabId={DEAL_ACTIVITY}>
                        testing zone
                    </TabPane>
                </RenderIf>
            </TabContent>
            {ToastContainerElement}
        </div>
    );
};

export default DealDashboard;
