import { useQueryClient, useMutation } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';
import { IGrossBuildableAreaLot } from 'views/ProFormaTable/components/ProForma/ProFormaGeneralInformation/CalculateSizeCard/types';

interface ICreateBuildableLotProps {
    dealId: number;
    proFormaId: number;
}

const createBuildableLot = async ({ dealId, proFormaId }: ICreateBuildableLotProps): Promise<IGrossBuildableAreaLot> => {
    const response = await axiosInstance.post(API_ENDPOINTS.PRO_FORMA_BUILDABLE_LOT(dealId, proFormaId));
    return response.data;
};

const useCreateBuildableLot = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: createBuildableLot,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['proFormaGrossBuildableLots'] });
        },
        onError: error => {
            console.error('Error creating new lot:', error);
        },
    });
};

export default useCreateBuildableLot;
