import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useUpdateProForma } from 'queries/ProForma';
import { NSInput, NSModal } from '../../../../bricks';

interface IEditProFormaModalProps {
    showEditProFormaModal: boolean;
    toggleEditProFormaModal: () => void;
}

const EditProFormaModal = ({ showEditProFormaModal, toggleEditProFormaModal }: IEditProFormaModalProps) => {
    const queryClient = useQueryClient();
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const { dealId: dealIdString, proFormaId: proFormaIdString } = useParams<{ dealId: string; proFormaId: string }>();
    const dealId = Number(dealIdString);
    const proFormaId = Number(proFormaIdString);

    const { mutate: updateProForma } = useUpdateProForma();
    const handleSave = () => {
        updateProForma(
            { dealId, proFormaId, patchData: { name, description } },
            {
                onSuccess: () => {
                    queryClient.invalidateQueries({ queryKey: ['proFormaData'] });
                    toggleEditProFormaModal();
                },
            },
        );
    };

    return (
        <NSModal
            isOpen={showEditProFormaModal}
            toggle={toggleEditProFormaModal}
            modalHeaderText="Edit pro forma"
            modalHeaderClassName="text-white bg-primary"
            modalFooterButtonText="Save"
            modalFooterButtonFunction={handleSave}
        >
            <NSInput
                label="Name"
                type="text"
                name="name"
                placeholder="Enter pro forma name"
                labelClassName="mb-0"
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)}
                required
            />
            <NSInput
                label="Description"
                type="textarea"
                name="description"
                placeholder="Enter optional description for this pro forma"
                labelClassName="mb-0 mt-2"
                value={description}
                onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value)}
            />
        </NSModal>
    );
};

export default EditProFormaModal;
