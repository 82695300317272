import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { faChartLineUp, faChartMixedUpCircleDollar, faGrid2 } from '@fortawesome/pro-solid-svg-icons';
import { PRO_FORMA_SIZE_UNIT_OPTIONS } from 'constants/unitTypes';
import { IOptionAnyValue } from 'bricks/types';
import { formatUSDCurrencyTwoDigits } from 'ns_libs/formatter';
import { IProjectionType, ProjectionType } from './types';
import { ProFormaGenericColumn, ProFormaGenericRow } from './components/ProFormaGenericTable/types';

export const PRO_FORMA_TABLE_NAV = {
    PRO_FORMA: { name: 'Pro Forma', icon: faChartLineUp },
    RETURNS_AND_WATERFALL: { name: 'Returns & Waterfall Analysis', icon: faChartBar },
    SENSITIVITY: { name: 'Sensitivity', icon: faChartMixedUpCircleDollar },
    EXECUTIVE_SUMMARY: { name: 'Executive Summary', icon: faGrid2 },
};
export const PRO_FORMA_TABLE_NAV_NAMES = Object.values(PRO_FORMA_TABLE_NAV).map(nav => nav.name);

export const PRO_FORMA_TABS = {
    GENERAL_INFORMATION: { prefix: 'A', name: 'General Information' },
    DEVELOPMENT: { prefix: 'B', name: 'Development' },
    OPERATIONS: { prefix: 'C', name: 'Operations' },
    DISPOSITION: { prefix: 'D', name: 'Disposition' },
};
export const PRO_FORMA_TAB_NAMES = Object.values(PRO_FORMA_TABS).map(tab => tab.name);

export const RETURNS_AND_WATERFALL_TABS = {
    RETURNS: 'Returns',
    WATERFALL_ANALYSIS: 'Waterfall Analysis',
};
export const RETURNS_AND_WATERFALL_TAB_NAMES = Object.values(RETURNS_AND_WATERFALL_TABS);

export const FORM_ROW_CLASS_NAME = 'd-flex mb-2';
export const FORM_LABEL_CLASS_NAME = 'pl-0 col-4 my-auto font-weight-normal text-dark text-nowrap';
export const CALCULATE_SIZE_HEADER_CLASS_NAME = 'p-0 pb-1 text-dark font-weight-normal';

export const GENERAL_INFORMATION_EXPANDABLE_SECTIONS = [
    {
        number: 1,
        title: 'Basic Details',
        subtitle: `Information about this investment's name and location`,
    },
    {
        number: 2,
        title: 'Pro forma start',
        subtitle: `Determine start date for this analysis. Typically this is your acquisition closing.
                    This date will be used as period 1 for cash flows.`,
    },
    {
        number: 3,
        title: 'Size',
        subtitle: `Details about this investment's property size and building`,
    },
];

export const DEAL_TYPES = {
    APARTMENT: 'Apartment',
    INDUSTRIAL: 'Industrial',
    RETAIL: 'Retail',
    OFFICE: 'Office',
};

export const STRUCTURED_PARKING = 'structured';
export const SURFACE_PARKING = 'surface';
export const CAR_PARKING = 'car';
export const TRAILER_PARKING = 'trailer';

export const parkingOptions = [
    { label: 'Structured', value: STRUCTURED_PARKING },
    { label: 'Surface', value: SURFACE_PARKING },
];

const industrialParkingOptions = [
    { label: 'Car', value: CAR_PARKING },
    { label: 'Trailer', value: TRAILER_PARKING },
];

export const dealTypeProFormaParkingOptions = {
    [DEAL_TYPES.APARTMENT]: parkingOptions,
    [DEAL_TYPES.INDUSTRIAL]: industrialParkingOptions,
    [DEAL_TYPES.RETAIL]: parkingOptions,
    [DEAL_TYPES.OFFICE]: parkingOptions,
};

export const dealTypeProFormaFormFields: { [key: string]: any } = {
    [DEAL_TYPES.APARTMENT]: {
        landArea: '',
        isFAR: false,
        farAmount: '',
        grossBuildableArea: '',
        grossBuildableAreaUnitType: PRO_FORMA_SIZE_UNIT_OPTIONS[1].value,
        buildings: 1,
        averageStories: 1,
        builtGrossSF: '',
        parking: [],
        structuredParkingSpaces: '',
        surfaceParkingSpaces: '',
    },
    [DEAL_TYPES.INDUSTRIAL]: {},
    [DEAL_TYPES.RETAIL]: {},
    [DEAL_TYPES.OFFICE]: {},
};

export const POPULATE_USES_METHOD = {
    IMPORT_FROM_PREVIOUS_PROJECT: {
        title: 'Import from a previous deal or project',
        subtitle: 'Start with budget categorizations, estimates, and curve fits used previously.',
    },
    START_WITH_BACK_OF_ENVELOPE: {
        title: 'Start with my Back-Of-Envelope estimates',
        subtitle: 'Start with high level assumptions established using Back-Of-Envelope.',
    },
    USE_RECOMMENDED_CATEGORIES: {
        title: 'Use recommended categories',
        subtitle: 'Start with our recommended budget line types for this project type.',
    },
    START_FROM_SCRATCH: {
        title: 'Start from scratch',
    },
};

export const PROJECTION_TYPE: { [key: string]: ProjectionType } = {
    LINEAR: 'linear',
    NORMAL: 'normal',
    GAMMA: 'gamma',
};

export const PROJECTION_TYPES: IProjectionType[] = [
    {
        projectionType: PROJECTION_TYPE.LINEAR,
        projectionVariable: null,
        curveType: 'Linear',
        img: 'linear_projection.png',
        imgPrimary: 'linear_projection_primary.png',
    },
    {
        projectionType: PROJECTION_TYPE.NORMAL,
        projectionVariable: 7,
        curveType: 'Wide',
        img: 'normal_wide_projection.png',
        imgPrimary: 'normal_wide_projection_primary.png',
    },
    {
        projectionType: PROJECTION_TYPE.NORMAL,
        projectionVariable: 5,
        curveType: 'Medium',
        img: 'normal_medium_projection.png',
        imgPrimary: 'normal_medium_projection_primary.png',
    },
    {
        projectionType: PROJECTION_TYPE.NORMAL,
        projectionVariable: 3,
        curveType: 'Narrow',
        img: 'normal_narrow_projection.png',
        imgPrimary: 'normal_narrow_projection_primary.png',
    },
    {
        projectionType: PROJECTION_TYPE.GAMMA,
        projectionVariable: -1,
        curveType: 'L Skew',
        img: 'gamma_leftskew_projection.png',
        imgPrimary: 'gamma_leftskew_projection_primary.png',
    },
    {
        projectionType: PROJECTION_TYPE.GAMMA,
        projectionVariable: 1,
        curveType: 'R Skew',
        img: 'gamma_rightskew_projection.png',
        imgPrimary: 'gamma_rightskew_projection_primary.png',
    },
];

export const PRO_FORMA_DEVELOPMENT_UNIT_TYPE_KEY = 'proFormaDevelopmentUnitType';

export const BULK_OPTION = {
    START_DATE: 'start_date',
    END_DATE: 'end_date',
    CURVE: 'curve',
    AMOUNT: 'amount',
};

export const BULK_ASSIGN_OPTIONS = [
    { label: 'Start date', value: BULK_OPTION.START_DATE },
    { label: 'End date', value: BULK_OPTION.END_DATE },
    { label: 'Curve', value: BULK_OPTION.CURVE },
    { label: 'Amount', value: BULK_OPTION.AMOUNT },
];

export const DEFAULT_SUBCATEGORY_NAME = 'New subcategory';
export const DEFAULT_BUDGET_LINE_NAME = 'New budget line';
export const IN_PROGRESS_TEXT = 'In progress';

// TO DO: Clean up mock data in https://app.clickup.com/2326533/v/l/6-181053511-1
export const mockProFormaUnits = 100; // TODO: fetch from C.2
export const MockRentableSquareFootage = 50000;
export const mockOrgId = '123e4567-e89b-12d3-a456-426614174000';
export const mockSourceTypeId = '123e4567-e89b-12d3-a456-426614174001';

export const DEBT_SOURCE_RATE = {
    FIXED: 'fixed',
    VARIABLE: 'variable',
};

export const PRO_FORMA_DEVELOPMENT_DEBT_RATE_OPTIONS = [
    { label: 'Fixed', value: DEBT_SOURCE_RATE.FIXED },
    { label: 'Variable', value: DEBT_SOURCE_RATE.VARIABLE },
];

export const DEBT_SOURCE_TYPES = {
    DEBT_PRIVATE: 'Debt (private)',
    DEBT_PUBLIC_LOCAL: 'Debt (public - local)',
    DEBT_PUBLIC_NATIONAL: 'Debt (public - national/federal)',
    DEBT_PUBLIC_STATE: 'Debt (public - state/province)',
};

// TODO: replace with real db data https://app.clickup.com/t/2326533/PRODUCT-3973
// this list ignores Bonds type per product for now
export const SOURCE_TYPE_OPTIONS = [
    { label: 'Company operation budgets', value: 2 },
    { label: 'Debt (private)', value: 3 },
    { label: 'Debt (public - local)', value: 4 },
    { label: 'Debt (public - national/federal)', value: 5 },
    { label: 'Debt (public - state/province)', value: 6 },
    { label: 'Equity', value: 7 },
    { label: 'Equity - GP', value: 8 },
    { label: 'Equity - LP', value: 9 },
    { label: 'Existing cash reserves', value: 10 },
    { label: 'Grant (private)', value: 11 },
    { label: 'Grant (public - local)', value: 12 },
    { label: 'Grant (public - national/federal)', value: 13 },
    { label: 'Grant (public - state/province)', value: 14 },
    { label: 'Other', value: 15 },
    { label: 'Philanthropy', value: 16 },
];

export const calculationOptions: IOptionAnyValue[] = [
    { value: 'total_potential_income', label: 'Total potential income' },
    { value: 'effective_gross_revenue', label: 'Effective gross revenue' },
    { value: 'net_rental_income', label: 'Net rental income' },
    { value: 'net_operating_income', label: 'Net operating income' },
];

export const INITIAL_INCOME_ROWS: ProFormaGenericRow[] = [];

// TODO: Remove this mock data when socket is implemented
export const INITIAL_GROSS_RENT_ADJUSTMENT_ROWS: ProFormaGenericRow[] = [
    {
        id: 'concessions',
        name: 'Concessions (free rent)',
        isReadOnly: true,
        isNegative: true,
        costPerSF: '0.16',
        costPerUnit: '125.00',
        totalRent: '37,500',
        column1: '0.16',
        column2: '0.16',
        column3: '0.16',
        column4: '0.16',
    },
    {
        id: 'gain-loss',
        name: 'Gain/loss-to-lease',
        costPerSF: '0.00',
        costPerUnit: '0.00',
        totalRent: '0',
        column1: '0.00',
        column2: '0.00',
        column3: '0.00',
        column4: '0.00',
    },
    {
        id: 'non-revenue',
        name: 'Non-revenue (model) units',
        costPerSF: '0.01',
        costPerUnit: '8.33',
        totalRent: '2,500',
        column1: '0.01',
        column2: '0.01',
        column3: '0.01',
        column4: '0.01',
    },
];

export const INITIAL_OTHER_INCOME_ROWS: ProFormaGenericRow[] = [
    {
        id: 'rubs',
        name: 'RUBS',
        annualGrowth: '3.0',
        percentTotalRent: '3.37',
        costPerSF: '0.07',
        costPerUnit: '56',
        totalRent: '200,000',
        column1: '31,368',
    },
    {
        id: 'parking',
        name: 'Parking income',
        annualGrowth: '3.0',
        percentTotalRent: '6.06',
        costPerSF: '0.12',
        costPerUnit: '100',
        totalRent: '360,000',
        column1: '31,368',
    },
    {
        id: 'other',
        name: 'Other income',
        annualGrowth: '3.0',
        percentTotalRent: '4.00',
        costPerSF: '0.08',
        costPerUnit: '66',
        totalRent: '237,534',
        column1: '31,368',
    },
];

export const INITIAL_INCOME_ADJUSTMENTS_ROWS: ProFormaGenericRow[] = [
    {
        id: 'vacancy',
        name: 'Vacancy',
        percentTotalRent: '5.00',
        costPerSF: '0.11',
        costPerUnit: '94',
        totalRent: '336,794',
        column1: '31,368',
        isNegative: true,
        selectedCalculation: { value: 'total_potential_income', label: 'Total potential income' },
    },
    {
        id: 'credit-loss',
        name: 'Credit loss',
        percentTotalRent: '1.00',
        costPerSF: '0.08',
        costPerUnit: '66',
        totalRent: '67,359',
        column1: '31,368',
        isNegative: true,
        selectedCalculation: { value: 'total_potential_income', label: 'Total potential income' },
    },
];

export const INCOME_COLUMNS: ProFormaGenericColumn[] = [
    { key: 'name', field: 'name', header: 'Unit mix', format: 'none', type: 'text' },
    { key: 'units', field: 'units', header: 'Units', format: 'none' },
    { key: 'averageSF', field: 'averageSF', header: 'Average SF', format: 'none' },
    { key: 'costPerSF', field: 'costPerSF', header: '$/SF/mo.' },
    { key: 'costPerUnit', field: 'costPerUnit', header: '$/unit/mo.' },
    { key: 'totalRent', field: 'totalRent', header: 'Total rent/year' },
    { key: 'column1', field: 'column1', header: 'Column 1', isFixed: false },
    { key: 'column2', field: 'column2', header: 'Column 2', isFixed: false },
    { key: 'column3', field: 'column3', header: 'Column 3', isFixed: false },
    { key: 'column4', field: 'column4', header: 'Column 4', isFixed: false },
];

export const GROSS_RENT_ADJUSTMENT_COLUMNS: ProFormaGenericColumn[] = [
    { key: 'name', field: 'name', header: 'Gross rent adjustments', type: 'text' },
    { key: 'invisibleUnits', field: 'invisibleUnits', header: '', isHidden: true },
    { key: 'invisibleSF', field: 'invisibleSF', header: '', isHidden: true },
    { key: 'costPerSF', field: 'costPerSF', header: '$/SF/mo.' },
    { key: 'costPerUnit', field: 'costPerUnit', header: '$/unit/mo.' },
    { key: 'totalRent', field: 'totalRent', header: 'Amount/year' },
    { key: 'column1', field: 'column1', header: 'MON 20XX', isFixed: false },
    { key: 'column2', field: 'column2', header: 'MON 20XX', isFixed: false },
    { key: 'column3', field: 'column3', header: 'MON 20XX', isFixed: false },
    { key: 'column4', field: 'column4', header: 'MON 20XX', isFixed: false },
];

export const OTHER_INCOME_COLUMNS: ProFormaGenericColumn[] = [
    { key: 'name', field: 'name', header: 'Other income', format: 'none', type: 'text' },
    { key: 'annualGrowth', field: 'annualGrowth', header: 'Annual growth', format: 'percentage' },
    { key: 'percentTotalRent', field: 'percentTotalRent', header: '% of total rent', format: 'percentage' },
    { key: 'costPerSF', field: 'costPerSF', header: '$/SF/Mo.' },
    { key: 'costPerUnit', field: 'costPerUnit', header: '$/Unit/month' },
    { key: 'totalRent', field: 'totalRent', header: 'Amount/year' },
    { key: 'column1', field: 'column1', header: 'MON 20XX', isFixed: false },
];

export const TOTAL_POTENTIAL_COLUMNS: ProFormaGenericColumn[] = [
    { key: 'name', field: 'name', header: 'Total potential income', format: 'none' },
    { key: 'invisibleUnits', field: 'invisibleUnits', header: '' },
    { key: 'invisibleUnits', field: 'invisibleUnits', header: '' },
    { key: 'costPerSF', field: 'costPerSF', header: formatUSDCurrencyTwoDigits(0.1), format: 'currency' },
    { key: 'costPerUnit', field: 'costPerUnit', header: formatUSDCurrencyTwoDigits(55), format: 'currency' },
    { key: 'totalRent', field: 'totalRent', header: formatUSDCurrencyTwoDigits(4310631), format: 'currency' },
    { key: 'column1', field: 'column1', header: formatUSDCurrencyTwoDigits(4310361), isFixed: false },
    { key: 'column2', field: 'column2', header: formatUSDCurrencyTwoDigits(1302361), isFixed: false },
];
