import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSInput, NSLabel } from 'bricks';
import React, { useState } from 'react';
import { FORM_LABEL_CLASS_NAME } from 'views/ProFormaTable/constants';
import { faCalendar } from '@fortawesome/pro-regular-svg-icons';
import DatePicker from 'react-datepicker';
import NSInputNumberStepper from 'bricks/NSInputNumberStepper/NSInputNumberStepper';

export interface OperatingInfoValues {
    operationStartDate: Date | null;
    rentalGrowth: number;
    preLeased: number;
    leaseUpPace: number;
    leaseLength: number;
    initialFreeRent: number;
    stabilizedFreeRent: number;
    vacancy: number;
    creditLoss: number;
}

interface OperatingInfoProps {
    onChange: (key: keyof OperatingInfoValues, value: number | Date | null) => void;
    values: OperatingInfoValues;
}

const OperatingInfo = ({ values, onChange }: OperatingInfoProps) => {
    const [startDatePicker, setStartDatePicker] = useState(false);
    const [startDate, setStartDate] = useState<Date | null>(null);

    const formatDate = (date: Date) => date.toLocaleDateString();

    return (
        <>
            <div className="ProFormaWizardStepOne__title">Operating information</div>
            <div className="ProFormaWizardStepOne__subtitle">Information about this investment’s incoming revenue</div>
            <div className="d-flex align-items-center mt-2 w-50">
                <NSLabel className={FORM_LABEL_CLASS_NAME}>Operation start</NSLabel>
                <NSInput
                    id="startDate"
                    name="startDate"
                    placeholder="Select date..."
                    inputClassName="w-75 pt-2"
                    appendInputAddon={
                        <div>
                            <FontAwesomeIcon icon={faCalendar} size="lg" onClick={() => setStartDatePicker(true)} style={{ cursor: 'pointer' }} />
                            {startDatePicker && (
                                <div className="ProFormaWizardStepOne__datepicker">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date: Date | null) => {
                                            setStartDate(date);
                                            onChange('operationStartDate', date);
                                            setStartDatePicker(false);
                                        }}
                                        onClickOutside={() => setStartDatePicker(false)}
                                        inline
                                    />
                                </div>
                            )}
                        </div>
                    }
                    type="text"
                    value={startDate ? formatDate(startDate) : ''}
                    readOnly
                />
            </div>
            <div className="d-flex align-items-center mt-2 w-50">
                <NSLabel className={FORM_LABEL_CLASS_NAME}>Rental growth</NSLabel>
                <NSInputNumberStepper
                    name="rentalGrowth"
                    value={values.rentalGrowth}
                    onChange={(count: number) => onChange('rentalGrowth', count)}
                    min={0.1}
                    step={0.1}
                    inputGroupClassName="ProFormaWizardStepOne__input-width"
                />
            </div>
            <div className="d-flex align-items-center mt-2 w-50">
                <NSLabel className={FORM_LABEL_CLASS_NAME}>% pre-leased</NSLabel>
                <NSInputNumberStepper
                    max={100}
                    percentage
                    name="preLeased"
                    value={values.preLeased}
                    onChange={(count: number) => onChange('preLeased', count)}
                    min={1}
                    step={1}
                    inputGroupClassName="ProFormaWizardStepOne__input-width"
                />
            </div>
            <div className="d-flex align-items-center mt-2 w-50">
                <NSLabel className={FORM_LABEL_CLASS_NAME}>Lease-up pace (units/month)</NSLabel>
                <NSInputNumberStepper
                    name="leaseUpPace"
                    value={values.leaseUpPace}
                    onChange={(count: number) => onChange('leaseUpPace', count)}
                    min={1}
                    step={1}
                    inputGroupClassName="ProFormaWizardStepOne__input-width"
                />
            </div>
            <div className="d-flex align-items-center mt-2 w-50">
                <NSLabel className={FORM_LABEL_CLASS_NAME}>Length of lease (months)</NSLabel>
                <NSInputNumberStepper
                    name="leaseLength"
                    value={values.leaseLength}
                    onChange={(count: number) => onChange('leaseLength', count)}
                    min={1}
                    step={1}
                    inputGroupClassName="ProFormaWizardStepOne__input-width"
                />
            </div>
            <div className="d-flex align-items-center mt-2 w-50">
                <NSLabel className={FORM_LABEL_CLASS_NAME}>Initial free rent</NSLabel>
                <NSInputNumberStepper
                    name="initialFreeRent"
                    value={values.initialFreeRent}
                    onChange={(count: number) => onChange('initialFreeRent', count)}
                    min={1}
                    step={1}
                    inputGroupClassName="ProFormaWizardStepOne__input-width"
                />
            </div>
            <div className="d-flex align-items-center mt-2 w-50">
                <NSLabel className={FORM_LABEL_CLASS_NAME}>Stabilized free rent</NSLabel>
                <NSInputNumberStepper
                    name="stabilizedFreeRent"
                    value={values.stabilizedFreeRent}
                    onChange={(count: number) => onChange('stabilizedFreeRent', count)}
                    min={1}
                    step={1}
                    inputGroupClassName="ProFormaWizardStepOne__input-width"
                />
            </div>
            <div className="d-flex align-items-center mt-2 w-50">
                <NSLabel className={FORM_LABEL_CLASS_NAME}>General vacancy</NSLabel>
                <NSInputNumberStepper
                    percentage
                    max={100}
                    name="vacancy"
                    value={values.vacancy}
                    onChange={(count: number) => onChange('vacancy', count)}
                    min={1}
                    step={1}
                    inputGroupClassName="ProFormaWizardStepOne__input-width"
                />
            </div>
            <div className="d-flex align-items-center mt-2 w-50">
                <NSLabel className={FORM_LABEL_CLASS_NAME}>Credit loss</NSLabel>
                <NSInputNumberStepper
                    percentage
                    max={100}
                    name="creditLoss"
                    value={values.creditLoss}
                    onChange={(count: number) => onChange('creditLoss', count)}
                    min={1}
                    step={1}
                    inputGroupClassName="ProFormaWizardStepOne__input-width"
                />
            </div>
        </>
    );
};

export default OperatingInfo;
