import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faSort, faSortUp, faSortDown } from '@fortawesome/free-solid-svg-icons';
import { ColSortConfig, SortDirection } from 'types/sort';

export const getSortIcon = <T>(key: T, config?: ColSortConfig<T>): IconDefinition => {
    if (!config || config.key !== key) {
        return faSort;
    }
    switch (config.direction) {
        case SortDirection.ASC:
            return faSortUp;
        case SortDirection.DESC:
            return faSortDown;
        default:
            return faSort;
    }
};

export const getColSortConfig = <T>(key: T, config?: ColSortConfig<T>): ColSortConfig<T> | undefined => {
    if (!config || config.key !== key) {
        return {
            key,
            direction: SortDirection.ASC,
        };
    }
    switch (config.direction) {
        case SortDirection.ASC:
            return {
                key,
                direction: SortDirection.DESC,
            };
        case SortDirection.DESC:
            return undefined;
        default:
            return {
                key,
                direction: SortDirection.ASC,
            };
    }
};
