import { Slide, toast, ToastContainer } from 'react-toastify';
import './useToast.scss';
import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';

const useToast = () => {
    const handleClose = useCallback(
        ({ closeToast }: { closeToast: Function }) =>
            () =>
                closeToast({}),
        [],
    );

    const showSuccess = (message: string) => {
        toast.success(message, {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            transition: Slide,
            type: 'success',
            className: 'ToastContainer success',
            closeButton: props => <FontAwesomeIcon className="ToastContainer__close-button" icon={faXmark} size="lg" onClick={handleClose(props)} />,
        });
    };

    const showError = (message: string) => {
        toast.error(message, {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            transition: Slide,
            type: 'error',
            className: 'ToastContainer error',
            closeButton: props => <FontAwesomeIcon className="ToastContainer__close-button" icon={faXmark} size="lg" onClick={handleClose(props)} />,
        });
    };

    const ToastContainerElement = React.useMemo(() => {
        return <ToastContainer />;
    }, []);

    return { showSuccess, showError, ToastContainerElement };
};

export default useToast;
