import React, { useState } from 'react';
import { excelExport } from 'bricks/utils';
import ReportHeader from 'views/Reports/ReportHeader/ReportHeader';
import { ColSortConfig } from 'types/sort';
import { AttributeKey, ICreateReportPostData, IReportDetails, ReportTypeId } from 'views/Reports/types';
import classNames from 'classnames';
import { useCreateReport, useUpdateReport } from 'views/Reports/hooks';
import { URLS } from 'services/urls';
import { generatePath, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useGetPipelineReportDeals } from 'views/Reports/hooks/useGetPipelineReportDeals';
import { NSLoadingScreen } from 'bricks';
import useToast from 'hooks/useToast';
import ConfigurationSidebar from './ConfigurationSidebar/ConfigurationSidebar';
import { formatPipelineDealsTableData, formatExcelExportData } from './helpers';
import DealsTable from './DealsTable/DealsTable';
import { IFilter } from './ConfigurationSidebar/Filters/types';

export interface IPipelineReportProps {
    report?: IReportDetails;
    onClickBack: () => void;
    isEditing?: boolean;
}

const PipelineReport = ({ report, onClickBack, isEditing = false }: IPipelineReportProps) => {
    const initializeColSortConfig = (): ColSortConfig<AttributeKey> | undefined => {
        if (report?.metadata?.sorting && Object.keys(report.metadata.sorting).length > 0) {
            const [key, direction] = Object.entries(report.metadata.sorting)[0];
            return { key, direction };
        }
        return undefined;
    };
    const [isEditMode, setIsEditMode] = useState(!report || isEditing);
    const [colSortConfig, setColSortConfig] = useState<ColSortConfig<AttributeKey> | undefined>(initializeColSortConfig);
    const [selectedColumns, setSelectedColumns] = useState<AttributeKey[]>(report?.metadata?.selectedColumns || []);
    const [configuredFilters, setConfiguredFilters] = useState<IFilter[]>(report?.metadata?.configuredFilters || []);
    const [reportName, setReportName] = useState(report?.name || 'New Pipeline Report');
    const [reportDescription, setReportDescription] = useState(report?.description || '');

    const { showSuccess, showError, ToastContainerElement } = useToast();

    const orgId = '123e4567-e89b-12d3-a456-426614174000';
    const { data: pipelineReportDealData, isLoading } = useGetPipelineReportDeals({
        pageSize: 10,
        currentPage: 1,
        orgId,
        selectedColumns,
        configuredFilters,
    });

    const dealRows = pipelineReportDealData ? formatPipelineDealsTableData(pipelineReportDealData.items, selectedColumns, colSortConfig) : [];

    const navigate = useNavigate();
    const queryClient = useQueryClient();

    const { mutate: createReport } = useCreateReport();
    const { mutate: updateReport } = useUpdateReport();

    const onClickExport = () => {
        if (report) {
            const formattedExcelExportData = formatExcelExportData(report.name, report.description, dealRows, selectedColumns);
            excelExport(report.name, true, formattedExcelExportData, true, []);
        }
    };

    const handleSaveReport = () => {
        const postData: ICreateReportPostData = {
            name: reportName,
            description: reportDescription,
            type: ReportTypeId.Pipeline,
            metadata: {
                sorting: colSortConfig ? { [colSortConfig.key]: colSortConfig.direction } : {},
                selectedColumns,
                configuredFilters,
            },
        };

        if (report) {
            updateReport(
                {
                    postData: {
                        report_id: report.id,
                        ...postData,
                    },
                    orgId,
                },
                {
                    onSuccess: data => {
                        queryClient.invalidateQueries({ queryKey: ['reportDetails', data.id] });
                        showSuccess('Report updated successfully');
                        setIsEditMode(false);
                    },
                    onError: () => {
                        showError('Failed to update report. Please try again.');
                    },
                },
            );
        } else {
            createReport(
                {
                    postData,
                    orgId,
                },
                {
                    onSuccess: data => {
                        const path = generatePath(URLS.REPORTS.REPORT_PAGE, { reportId: data.id });
                        showSuccess('Report created successfully');
                        navigate(path);
                    },
                    onError: () => {
                        showError('Failed to create report. Please try again.');
                    },
                },
            );
        }
    };

    return (
        <div className="m-3" data-testid="pipeline-report-container">
            <ReportHeader
                reportId={report?.id}
                reportName={reportName}
                setReportName={setReportName}
                reportDescription={reportDescription}
                setReportDescription={setReportDescription}
                onClickBack={onClickBack}
                onClickExport={onClickExport}
                onClickSave={handleSaveReport}
                onClickEdit={() => setIsEditMode(true)}
                isEditing={isEditMode}
                saveDisabled={selectedColumns.length === 0}
            />
            {isLoading ? (
                <NSLoadingScreen />
            ) : (
                <div className="pipelineReports d-flex mt-3 vh-100">
                    {isEditMode && (
                        <ConfigurationSidebar
                            selectedColumns={selectedColumns}
                            setSelectedColumns={setSelectedColumns}
                            filters={configuredFilters}
                            setFilters={setConfiguredFilters}
                        />
                    )}
                    {dealRows && (
                        <div className={classNames('dealTable', { 'ml-2': isEditMode, 'w-75': isEditMode, 'w-100': !isEditMode })}>
                            <DealsTable
                                selectedColumns={selectedColumns}
                                colSortConfig={colSortConfig}
                                setColSortConfig={setColSortConfig}
                                tableData={dealRows}
                                isEditMode={isEditMode}
                            />
                        </div>
                    )}
                </div>
            )}
            {ToastContainerElement}
        </div>
    );
};

export default PipelineReport;
