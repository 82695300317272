/* eslint-disable no-unused-vars */
import { UNIT_OF_MEASURE_TYPES, UNIT_TYPE_NAMES } from 'constants/unitTypes';
import { useState, useMemo, useRef } from 'react';
import { IGrossBuildableAreaLot } from 'views/ProFormaTable/components/ProForma/ProFormaGeneralInformation/CalculateSizeCard/types';

const SQUARE_FEET_PER_ACRE = 43560;

// Convert land area to square feet based on unit type ID
const convertToSquareFeet = (landArea: number, unitTypeId: number) => {
    if (unitTypeId === UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.ACRE].id) {
        return landArea * SQUARE_FEET_PER_ACRE;
    }
    return landArea;
};

// Calculate gross buildable area
const calculateGrossBuildableArea = (landArea: number, floorAreaRatio: number, unitTypeId: number) => {
    const landAreaInSquareFeet = convertToSquareFeet(landArea, unitTypeId);
    return landAreaInSquareFeet * floorAreaRatio;
};

export const useGrossBuildableArea = (initialLots: IGrossBuildableAreaLot[] = []) => {
    const [lots, setLots] = useState<IGrossBuildableAreaLot[]>(initialLots.length > 0 ? initialLots : []);
    const lastIdRef = useRef(initialLots.length > 0 ? Math.max(...initialLots.map(lot => lot.id)) : 1);

    // Create a new lot
    const createLot = () => {
        const newLot: IGrossBuildableAreaLot = {
            id: ++lastIdRef.current,
            landArea: 0,
            landAreaUnitTypeId: UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.GROSS_SF].id,
            floorAreaRatio: 0,
            grossBuildableArea: 0,
        };
        setLots([...lots, newLot]);
    };

    // Update an existing lot
    const updateLot = (id: number, field: keyof IGrossBuildableAreaLot, value: string | number) => {
        const updatedLots = lots.map(lot => {
            if (lot.id === id) {
                const updatedLot = { ...lot, [field]: value };

                if (['landArea', 'floorAreaRatio', 'landAreaUnitTypeId'].includes(field)) {
                    updatedLot.grossBuildableArea = calculateGrossBuildableArea(
                        updatedLot.landArea,
                        updatedLot.floorAreaRatio,
                        updatedLot.landAreaUnitTypeId,
                    );
                }
                return updatedLot;
            }
            return lot;
        });
        setLots(updatedLots);
    };

    // Delete a lot
    const deleteLot = (id: number) => {
        setLots(lots.filter(lot => lot.id !== id));
    };

    // Calculate total gross buildable area
    const totalGrossBuildableArea = useMemo(() => {
        return lots.reduce((total, lot) => total + lot.grossBuildableArea, 0);
    }, [lots]);

    return {
        lots,
        createLot,
        updateLot,
        deleteLot,
        totalGrossBuildableArea,
    };
};
