import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { IBackOfEnvelopeResponse, IBackOfEnvelopeQueryParams } from '../types';

const getBackOfEnvelopeDeals = async ({ orgId }: IBackOfEnvelopeQueryParams): Promise<IBackOfEnvelopeResponse> => {
    const response = await axiosInstance.get(API_ENDPOINTS.BACK_OF_ENVELOPE_DEALS(orgId));
    return response.data;
};

export const useGetBackOfEnvelopeDeals = ({ orgId }: IBackOfEnvelopeQueryParams) =>
    useQuery<IBackOfEnvelopeResponse>({
        queryKey: ['back-of-envelope-scenarios', orgId],
        queryFn: () => getBackOfEnvelopeDeals({ orgId }),
        staleTime: 5000,
        refetchOnMount: true,
    });
