import React, { createContext, useContext, useState } from 'react';
import { downloadFileFromUrl } from 'helpers/file/download';
import { Deal } from 'views/Deals/types';
import { highlightFields } from '../helpers/highlight';
import { highlightCriteria } from '../constants';
import { Option, Scenario } from '../types';
import { getComparisonPdf } from '../hooks/useGetComparisonPdf';

interface ScenarioContextType {
    scenarios: Scenario[];
    selectedScenarios: Option[];
    addScenario: (scenario: Scenario) => void;
    removeScenario: (id: string) => void;
    switchScenario: (currentId: string, newScenario: Scenario) => void;
    getAvailableOptions: () => Option[];
    getSelectedScenarios: () => Scenario[];
    initializeData: (scenarios: Scenario[], options: Option[]) => void;
    handlePdfExport: (deal: Deal) => void;
}

interface IScenarioProvider {
    children: React.ReactNode;
}

const ScenarioContext = createContext<ScenarioContextType | undefined>(undefined);

export const ScenarioProvider: React.FC<IScenarioProvider> = ({ children }: IScenarioProvider) => {
    const [scenarios, setScenarios] = useState<Scenario[]>([]);
    const [selectedScenarios, setSelectedScenarios] = useState<Option[]>([]);

    const addScenario = (scenario: Scenario) => {
        setSelectedScenarios(prev => [...prev, { label: scenario.name, value: scenario.id }]);
    };

    const removeScenario = (id: string) => {
        setSelectedScenarios(prev => prev.filter(option => option.value !== id));
    };

    const switchScenario = (currentId: string, newScenario: Scenario) => {
        setSelectedScenarios(prev => prev.map(option => (option.value === currentId ? { label: newScenario.name, value: newScenario.id } : option)));
    };

    const getAvailableOptions = (): Option[] => {
        const selectedIds = selectedScenarios.map(option => option.value);
        return scenarios
            .filter(scenario => !selectedIds.includes(scenario.id))
            .map(scenario => ({
                label: scenario.name,
                value: scenario.id,
            }));
    };

    const getSelectedScenarios = (): Scenario[] => {
        const selectedIds = selectedScenarios.map(option => option.value);
        const filteredScenarios = scenarios.filter(scenario => selectedIds.includes(scenario.id));

        return highlightFields(filteredScenarios, highlightCriteria);
    };

    const initializeData = (initialScenarios: Scenario[], initialOptions: Option[]) => {
        setScenarios(initialScenarios);
        setSelectedScenarios(initialOptions);
    };

    const handlePdfExport = async (deal: Deal) => {
        if (deal) {
            try {
                const response = await getComparisonPdf(deal.organizationId, deal.id);
                const pdfUrl = response.url;
                if (pdfUrl) {
                    await downloadFileFromUrl(pdfUrl, `${deal.dealName}-scenario-comparison`, 'pdf', true);
                } else {
                    console.error('Failed to export report PDF: No URL returned');
                }
            } catch (error) {
                console.error('Failed to export report PDF:', error);
            }
        }
    };

    return (
        <ScenarioContext.Provider
            value={{
                scenarios,
                selectedScenarios,
                addScenario,
                removeScenario,
                switchScenario,
                getAvailableOptions,
                getSelectedScenarios,
                initializeData,
                handlePdfExport,
            }}
        >
            {children}
        </ScenarioContext.Provider>
    );
};

export const useScenarioContext = (): ScenarioContextType => {
    const context = useContext(ScenarioContext);
    if (!context) {
        throw new Error('useScenarioContext must be used within a ScenarioProvider');
    }
    return context;
};
