import React, { useMemo, useState } from 'react';
import { IOptionAnyValue } from 'bricks/types';
import KeyStatsCard from 'components/KeyStatsCard/KeyStatsCard';
import { Col, Row } from 'reactstrap';
import { NSDropdown } from 'bricks';
import { PERIOD_OPTIONS } from 'components/ProFormaReturnsTable/constant';
import { ICreateReportPostData, IDeal, IDealSelection, IReportDetails, ReportTypeId } from 'views/Reports/types';
import { useCreateReport, useGetOrgDealSummaries, useUpdateReport } from 'views/Reports/hooks';
import ReportHeader from 'views/Reports/ReportHeader/ReportHeader';
import DealSelection from 'views/Reports/components/DealSelection/DealSelection';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import { URLS } from 'services/urls';
import { useQueryClient } from '@tanstack/react-query';
import useToast from 'hooks/useToast';
import AggregateProFormaReturnsTable from './AggregateProFormaReturnsTable/AggregateProFormaReturnsTable';

export interface IAggregateReportProps {
    report?: IReportDetails;
    onClickBack: () => void;
    isEditing?: boolean;
}

export const AggregateReport = ({ report, onClickBack, isEditing = false }: IAggregateReportProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryClient = useQueryClient();
    const { showSuccess, showError, ToastContainerElement } = useToast();

    const orgId = '123e4567-e89b-12d3-a456-426614174000';

    const [selectedPeriod, setSelectedPeriod] = React.useState<IOptionAnyValue>(PERIOD_OPTIONS[0]);
    const [isEditMode, setIsEditMode] = useState(!report || isEditing);
    const [reportName, setReportName] = useState(report?.name || 'New Aggregate Report');
    const [reportDescription, setReportDescription] = useState(report?.description || '');

    const { data: dealSummaries } = useGetOrgDealSummaries(orgId, { enabled: true });
    // mapping to add dummy project and projectType, update when all values are available in db is ready
    const deals: IDeal[] = useMemo(() => {
        return (
            dealSummaries?.map(deal => {
                return {
                    id: deal.id,
                    dealName: deal.dealName,
                    dealType: deal.dealType,
                    project: 'deal.project',
                    projectType: 'deal.projectType',
                };
            }) || []
        );
    }, [dealSummaries]);

    const reportDeals = report?.deals.map(deal => {
        return {
            deal: {
                id: deal.dealId,
                dealName: deal.dealName,
                dealType: deal.dealType,
                project: 'Project',
                projectType: 'Project Type',
            },
            proForma: { value: deal.proFormaId, label: deal.proFormaName },
        };
    });

    const [selectedDeals, setSelectedDeals] = useState<IDealSelection[]>(reportDeals || location.state?.selectedDeals || []);

    const handlePeriodSelection = (option: IOptionAnyValue) => {
        setSelectedPeriod(option);
    };

    const keyStatsMockData = {
        leveredEMx: 1.24,
        leveredIRR: 1.56,
        leveredProfitReturn: 1888.35,
    };

    const { mutate: createReport } = useCreateReport();
    const { mutate: updateReport } = useUpdateReport();

    const handleSaveReport = () => {
        const postData: ICreateReportPostData = {
            name: reportName,
            description: reportDescription,
            type: ReportTypeId.Aggregate,
            deals: selectedDeals.map(selection => ({
                dealId: selection.deal.id,
                proFormaId: selection.proForma?.value,
            })),
        };
        if (report) {
            updateReport(
                {
                    postData: {
                        report_id: report.id,
                        ...postData,
                    },
                    orgId,
                },
                {
                    onSuccess: data => {
                        queryClient.invalidateQueries({ queryKey: ['reportDetails', data.id] });
                        showSuccess('Report updated successfully');
                        setIsEditMode(false);
                    },
                    onError: () => {
                        showError('Failed to update report. Please try again.');
                    },
                },
            );
        } else {
            createReport(
                {
                    postData,
                    orgId,
                },
                {
                    onSuccess: data => {
                        const path = generatePath(URLS.REPORTS.REPORT_PAGE, { reportId: data.id });
                        showSuccess('Report created successfully');
                        navigate(path);
                    },
                    onError: () => {
                        showError('Failed to create report. Please try again.');
                    },
                },
            );
        }
    };

    return (
        <div data-testid="aggregate-report-container">
            <ReportHeader
                reportId={report?.id}
                reportName={reportName}
                setReportName={setReportName}
                reportDescription={reportDescription}
                setReportDescription={setReportDescription}
                onClickBack={onClickBack}
                onClickExport={() => console.log('Export')}
                onClickSave={handleSaveReport}
                onClickEdit={() => setIsEditMode(true)}
                isEditing={isEditMode}
            />
            {isEditMode && (
                <>
                    <DealSelection deals={deals} titleSuffix="DEALS SELECTED" selectedDeals={selectedDeals} setSelectedDeals={setSelectedDeals} />
                    <hr />
                </>
            )}

            <Row className="d-flex mt-1 justify-content-between">
                <Col xs="4" className="pr-0">
                    <KeyStatsCard title="LEVERED EMx" value={keyStatsMockData.leveredEMx} isPercentage />
                </Col>
                <Col xs="4" className="pr-0">
                    <KeyStatsCard title="LEVERED IRR" value={keyStatsMockData.leveredIRR} isPercentage />
                </Col>
                <Col xs="4">
                    <KeyStatsCard title="LEVERED PROFIT RETURN" value={keyStatsMockData.leveredProfitReturn} />
                </Col>
            </Row>
            <Row className="d-flex justify-content-end">
                <NSDropdown
                    containerClassName="mr-2"
                    options={PERIOD_OPTIONS}
                    selectedOption={selectedPeriod}
                    handleSelectOption={handlePeriodSelection}
                    placeholder="Select saved report"
                />
            </Row>
            <AggregateProFormaReturnsTable proFormaIds={[1, 2, 3, 4, 5, 6, 7, 8, 9]} selectedPeriod={selectedPeriod} />
            {ToastContainerElement}
        </div>
    );
};

export default AggregateReport;
