import React, { useState } from 'react';
import NSLabel from 'bricks/NSLabel/NSLabel';
import { Input, InputGroup, InputGroupAddon, InputGroupText, UncontrolledTooltip } from 'reactstrap';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RenderIf from 'components/RenderIf/RenderIf';
import 'css/partials/_bricks.NSInputNumberStepper.scss';

export interface INSInputNumberStepperProps {
    name: string;
    id?: string;
    labelClassName?: string;
    labelText?: string;
    value: number | null;
    inputGroupClassName?: string;
    min?: number;
    max?: number;
    onChange?: any;
    icon?: any;
    iconTooltipText?: string;
    required?: boolean;
    step: number;
    percentage?: boolean;
    customSuffix?: string;
}

const NSInputNumberStepper = ({
    name,
    id,
    labelClassName = '',
    labelText = '',
    value,
    inputGroupClassName,
    min,
    max,
    onChange,
    step,
    icon,
    iconTooltipText,
    customSuffix,
    required = false,
    percentage = false,
}: INSInputNumberStepperProps) => {
    const [isTypingDecimal, setIsTypingDecimal] = useState(false);
    const [intermediateValue, setIntermediateValue] = useState<string>('');
    const [isFocused, setIsFocused] = useState(false);

    const getDisplayValue = (val: number | null) => {
        if (isFocused || isTypingDecimal) return intermediateValue;
        if (val === null || Number.isNaN(val)) return '';
        if (val === 0) return '';
        if (percentage) return `${val}%`;
        if (customSuffix) return `${val} ${customSuffix}`;
        return val;
    };

    const updateCount = (newValue: number | null) => {
        if (newValue === null) {
            onChange(null);
            return;
        }

        let clampedValue = newValue;
        if (min !== undefined) {
            clampedValue = Math.max(min, clampedValue);
        }
        if (max !== undefined) {
            clampedValue = Math.min(max, clampedValue);
        }

        const formattedValue = Number.isInteger(step) ? Math.round(clampedValue) : parseFloat(clampedValue.toFixed(1));
        onChange(formattedValue);
    };

    const countDown = (prevValue: number | null) => {
        const newValue = (prevValue || 0) - step;
        if (min !== undefined && newValue < min) return;
        const formattedValue = Number.isInteger(step) ? Math.round(newValue) : parseFloat(newValue.toFixed(1));
        updateCount(formattedValue);
        setIntermediateValue(formattedValue.toString());
    };

    const countUp = (prevValue: number | null) => {
        const newValue = (prevValue || 0) + step;
        if (max !== undefined && newValue > max) return;
        const formattedValue = Number.isInteger(step) ? Math.round(newValue) : parseFloat(newValue.toFixed(1));
        updateCount(formattedValue);
        setIntermediateValue(formattedValue.toString());
    };

    const restoreCursorPosition = (target: HTMLInputElement, position: number | null) => {
        if (position === null) return;
        requestAnimationFrame(() => {
            try {
                if (document.activeElement === target) {
                    target.setSelectionRange(position, position);
                }
            } catch (error) {
                console.warn('Failed to restore cursor position:', error);
            }
        });
    };

    const handleEmptyInput = () => {
        setIsTypingDecimal(false);
        setIntermediateValue('');
        updateCount(null);
    };

    const handleDecimalPoint = (target: HTMLInputElement, cleanValue: string, cursorPosition: number | null) => {
        setIsTypingDecimal(true);
        setIntermediateValue(cleanValue);
        restoreCursorPosition(target, cursorPosition);
    };

    const handleDecimalNumber = (target: HTMLInputElement, cleanValue: string, cursorPosition: number | null) => {
        setIsTypingDecimal(true);
        setIntermediateValue(cleanValue);

        if (!/\.$/.test(cleanValue)) {
            const parsedValue = parseFloat(cleanValue);
            if (!Number.isNaN(parsedValue)) {
                updateCount(parsedValue);
            }
        }
        restoreCursorPosition(target, cursorPosition);
    };

    const handleRegularNumber = (target: HTMLInputElement, cleanValue: string, cursorPosition: number | null) => {
        setIsTypingDecimal(false);
        setIntermediateValue(cleanValue);
        const parsedValue = parseFloat(cleanValue);
        if (!Number.isNaN(parsedValue)) {
            updateCount(parsedValue);
        }
        restoreCursorPosition(target, cursorPosition);
    };

    const handleNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        // Only allow numbers and decimal point
        const cleanValue = inputValue.replace(/[^\d.]/g, '');

        // Prevent multiple decimal points
        const parts = cleanValue.split('.');
        const sanitizedValue = parts.length > 2 ? `${parts[0]}.${parts.slice(1).join('')}` : cleanValue;

        const cursorPosition = event.target.selectionStart;
        const { target } = event;

        if (!(target instanceof HTMLInputElement)) return;

        if (!sanitizedValue) {
            handleEmptyInput();
            return;
        }

        if (sanitizedValue === '.' || sanitizedValue === '0.') {
            handleDecimalPoint(target, sanitizedValue, cursorPosition);
            return;
        }

        if (sanitizedValue.includes('.')) {
            handleDecimalNumber(target, sanitizedValue, cursorPosition);
            return;
        }

        handleRegularNumber(target, sanitizedValue, cursorPosition);
    };

    const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsFocused(true);
        // Show empty string only if value is null or if value is 0 and min is greater than 0
        const shouldShowEmpty = value === null || (value === 0 && (min === undefined || min > 0));
        const rawValue = shouldShowEmpty ? '' : value.toString();
        setIntermediateValue(rawValue);

        const { target } = event;
        if (!(target instanceof HTMLInputElement)) return;
        restoreCursorPosition(target, rawValue.length);
    };

    const handleBlur = () => {
        setIsFocused(false);
        setIsTypingDecimal(false);
        setIntermediateValue('');

        // If we have a trailing decimal point, remove it
        if (value !== null && intermediateValue.endsWith('.')) {
            updateCount(parseFloat(intermediateValue));
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'ArrowUp') {
            event.preventDefault();
            countUp(value);
        } else if (event.key === 'ArrowDown') {
            event.preventDefault();
            countDown(value);
        }
    };

    return (
        <>
            <NSLabel for={name} className={labelClassName}>
                {labelText}
                <RenderIf isTrue={required}>
                    <span className="text-danger ml-1">*</span>
                </RenderIf>
                <RenderIf isTrue={icon}>
                    <FontAwesomeIcon icon={icon} className="pl-1 cursor-pointer" id="numberStepperIcon" />
                    <RenderIf isTrue={iconTooltipText}>
                        <UncontrolledTooltip target="numberStepperIcon">{iconTooltipText}</UncontrolledTooltip>
                    </RenderIf>
                </RenderIf>
            </NSLabel>
            <InputGroup className={`d-flex NSInputNumberStepper ${inputGroupClassName}`}>
                <InputGroupAddon addonType="prepend" className="NSInputNumberStepper__addon-prepend">
                    <InputGroupText className="py-0 NSInputNumberStepper__addon-prepend__text" onClick={() => countDown(value)}>
                        <FontAwesomeIcon icon={faMinus} data-testid="prependButton" />
                    </InputGroupText>
                </InputGroupAddon>
                <Input
                    type="text"
                    id={id}
                    name={name}
                    value={getDisplayValue(value)}
                    placeholder="Enter"
                    className="NSInputNumberStepper__input border-right-0 border-left-0 text-center"
                    onChange={handleNumberChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={handleKeyDown}
                    min={min}
                    max={max}
                />
                <InputGroupAddon addonType="append" className="NSInputNumberStepper__addon-append">
                    <InputGroupText className="py-0 NSInputNumberStepper__addon-append__text" onClick={() => countUp(value)}>
                        <FontAwesomeIcon icon={faPlus} data-testid="appendButton" />
                    </InputGroupText>
                </InputGroupAddon>
            </InputGroup>
        </>
    );
};

export default NSInputNumberStepper;
