import React from 'react';
import classNames from 'classnames';
import { NSCard, NSNavTabs } from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import { PRO_FORMA_TABS, PRO_FORMA_TABLE_NAV } from 'views/ProFormaTable/constants';
import { IProFormaData } from 'views/ProFormaTable/types';
import ProFormaDevelopment from './ProFormaDevelopment/ProFormaDevelopment';
import ProFormaGeneralInformation from './ProFormaGeneralInformation/ProFormaGeneralInformation';
import ProFormaOperations from './ProFormaOperations/ProFormaOperations';
import { ProFormaSocketProvider } from './socketContext/ProFormaSocketProvider';

export interface IProFormaProps {
    navigateToSection: (sideNavName: string, tabName?: string) => void;
    currentSection?: string;
    currentTab?: string;
    proForma: IProFormaData;
}

const ProForma = ({ navigateToSection, currentSection, currentTab, proForma }: IProFormaProps) => {
    const mockOrgId = 'f1423021-a183-473d-9282-4fd4097031f6';

    return proForma?.id ? (
        <ProFormaSocketProvider orgId={mockOrgId} dealId={proForma.dealId} proFormaId={proForma.id}>
            <NSNavTabs
                containerClassName="px-0"
                navItems={Object.entries(PRO_FORMA_TABS).map(([key, { prefix, name }], index) => ({
                    callback: e => {
                        e.preventDefault();
                        navigateToSection(currentSection || PRO_FORMA_TABLE_NAV.PRO_FORMA.name, name);
                    },
                    navPrefix: prefix,
                    className: classNames('text-nowrap', {
                        'mr-1': index !== Object.keys(PRO_FORMA_TABS).length - 1,
                    }),
                    name,
                    isActive: currentTab === name,
                    id: `proforma-tab-${index}`,
                }))}
            />
            <hr className="my-0" />
            <NSCard className="p-3">
                <RenderIf isTrue={currentTab === PRO_FORMA_TABS.GENERAL_INFORMATION.name && proForma} isLoading={!proForma}>
                    <ProFormaGeneralInformation />
                </RenderIf>
                <RenderIf isTrue={currentTab === PRO_FORMA_TABS.DEVELOPMENT.name}>
                    <ProFormaDevelopment proForma={proForma} navigateToSection={navigateToSection} />
                </RenderIf>
                <RenderIf isTrue={currentTab === PRO_FORMA_TABS.OPERATIONS.name}>
                    <ProFormaOperations />
                </RenderIf>
                <RenderIf isTrue={currentTab === PRO_FORMA_TABS.DISPOSITION.name}>
                    <p>Details go here.</p>
                </RenderIf>
            </NSCard>
        </ProFormaSocketProvider>
    ) : null;
};

export default ProForma;
