import axiosInstance from 'ns_libs/nsAxios';
import { useQuery } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import { IGrossBuildableAreaLotResponse } from 'views/ProFormaTable/components/ProForma/ProFormaGeneralInformation/CalculateSizeCard/types';

interface ICreateProFormaProps {
    dealId: number;
    proFormaId: number;
}

const getGrossBuildableLots = async ({ dealId, proFormaId }: ICreateProFormaProps): Promise<IGrossBuildableAreaLotResponse> => {
    const response = await axiosInstance.get(API_ENDPOINTS.PRO_FORMA_BUILDABLE_LOT(dealId, proFormaId));
    return response.data;
};

const useGetBuildableLots = (dealId: number, proFormaId: number) =>
    useQuery({
        queryKey: ['proFormaGrossBuildableLots'],
        queryFn: () => getGrossBuildableLots({ dealId, proFormaId }),
    });

export default useGetBuildableLots;
