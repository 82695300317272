import { NSInput } from 'bricks';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import React, { useState } from 'react';

export interface IUpdateReportTitleAndDescriptionModalProps {
    showModal: boolean;
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
    reportName: string;
    reportDescription: string;
    handleSaveReport: (values: { title: string; description: string }) => void;
}

const UpdateReportTitleAndDescriptionModal = ({
    handleSaveReport,
    showModal,
    setShowModal,
    reportName,
    reportDescription,
}: IUpdateReportTitleAndDescriptionModalProps) => {
    const [title, setTitle] = useState(reportName);
    const [description, setDescription] = useState(reportDescription);

    return (
        <ConfirmationModal
            toggle={() => setShowModal(!showModal)}
            isOpen={showModal}
            onConfirm={() => {
                handleSaveReport({
                    title,
                    description,
                });
            }}
            onCancel={() => setShowModal(false)}
            modalHeaderText="Edit title/description"
            modalFooterButtonText="Confirm"
            modalFooterCancelButtonText="Cancel"
            backdrop="static"
            modalFooterIsButtonFunctionDisabled={!title}
        >
            <NSInput
                id="title"
                type="text"
                label="Report title"
                name="title"
                placeholder="Enter title"
                value={title}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTitle(e.target.value)}
                required
                dataTestId="title-input"
            />
            <NSInput
                id="description"
                type="textarea"
                label="Description"
                name="description"
                placeholder="Enter description"
                value={description}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
                labelClassName="mt-3"
                dataTestId="description-input"
            />
        </ConfirmationModal>
    );
};

export default UpdateReportTitleAndDescriptionModal;
