import React from 'react';
import { percentFormatter } from 'ns_libs/formatter';
import { IInvestmentMetricsData } from '../../../types';

export const InvestmentMetrics: React.FC<{ data: IInvestmentMetricsData }> = ({ data }) => {
    return (
        data && (
            <div className="text-white">
                <div>ROI: {percentFormatter(data.roi)}</div>
                <div>EMx: {data.emx && `${data.emx}X`}</div>
                <div>IRR: {percentFormatter(data.irr)}</div>
            </div>
        )
    );
};

export default InvestmentMetrics;
