import { faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row } from '@tanstack/react-table';
import React, { useState, useEffect, useRef, useCallback } from 'react';
import './DraggableTableActionMenu.scss';
import { useNavigate } from 'react-router-dom';
import { Deal } from 'views/Deals/types';

interface DraggableTableActionMenuProps<TData> {
    row: Row<TData>;
}

export const DraggableTableActionMenu = <TData extends object>({ row }: DraggableTableActionMenuProps<TData>) => {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    const toggleMenu = () => setIsOpen(prev => !prev);

    const handleClickOutside = (event: MouseEvent) => {
        if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        if (isOpen) {
            window.document.addEventListener('mousedown', handleClickOutside);
        } else {
            window.document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            window.document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const handleViewDeal = useCallback(
        (deal: Deal) => () => {
            navigate(`/deal/${deal.id}`);
        },
        [navigate],
    );

    const handleDeleteDeal = () => {
        console.log(`Delete Deal clicked for row: ${JSON.stringify(row.original)}`);
    };

    return (
        <div ref={menuRef} className="DraggableTableActionMenu">
            <FontAwesomeIcon icon={faEllipsisH} className="cursor--pointer" onClick={toggleMenu} role="button" aria-label="Toggle menu" />
            {isOpen && (
                <div className="DraggableTableActionMenu__dropdown">
                    <ul className="DraggableTableActionMenu__list">
                        <li>
                            <button
                                type="button"
                                onClick={handleViewDeal(row.original as Deal)}
                                className="DraggableTableActionMenu__dropdown__item cursor--pointer"
                            >
                                View deal
                            </button>
                        </li>
                        <li>
                            <button type="button" onClick={handleDeleteDeal} className="DraggableTableActionMenu__dropdown__item cursor--pointer">
                                Delete deal
                            </button>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
};
