import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';
import { IBudgetClassification } from 'types/db/BudgetClassification';

interface IVariables {
    organizationId: string;
    proFormaId: string;
}

interface IResponse {
    success: boolean;
    data: IBudgetClassification;
}

const createDefaultBudgetClassifications = async ({ organizationId, proFormaId }: IVariables): Promise<IResponse> => {
    const response = await axiosInstance.post(API_ENDPOINTS.DEFAULT_BUDGET_CLASSIFICATIONS(organizationId, proFormaId));
    return response.data;
};

export const useCreateDefaultBudgetClassifications = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<IResponse, TError, IVariables, TContext>,
) => {
    return useMutation<IResponse, TError, IVariables, TContext>({
        mutationFn: createDefaultBudgetClassifications,
        ...options,
    });
};

export default useCreateDefaultBudgetClassifications;
