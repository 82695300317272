import { useMutation, useQueryClient } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';

interface IFormValues {
    landArea: number;
    landAreaUnitTypeId: number;
    floorAreaRatio: number;
    proFormaId: number;
}

interface IBuildableLotValues {
    landArea?: number;
    landAreaUnitTypeId?: number;
    floorAreaRatio?: number;
    proFormaId?: number;
}

interface IUpdateVariables {
    postData: IBuildableLotValues;
    dealId: number;
    proFormaId: number;
    lotId: number;
}

const updateBuildableLot = async ({ postData, dealId, proFormaId, lotId }: IUpdateVariables): Promise<IFormValues> => {
    const response = await axiosInstance.patch(`${API_ENDPOINTS.PRO_FORMA_BUILDABLE_LOT(dealId, proFormaId, lotId)}`, postData);
    return response.data;
};

const useUpdateBuildableLot = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: updateBuildableLot,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['proFormaGrossBuildableLots'] });
            queryClient.invalidateQueries({ queryKey: ['proFormaData'] });
        },
        onError: error => {
            console.error('Error updating lot:', error);
        },
    });
};

export default useUpdateBuildableLot;
