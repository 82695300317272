import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';
import { IUpdateReportPostData, IReportDetails } from '../types';

interface IUpdateReportParams {
    postData: IUpdateReportPostData;
    orgId: string;
}

const updateReport = async ({ postData, orgId }: IUpdateReportParams): Promise<IReportDetails> => {
    try {
        const response = await axiosInstance.put(`${API_ENDPOINTS.ORG_REPORTS(orgId)}`, postData);
        return response.data;
    } catch (error: any) {
        if (error.response?.status !== 500) {
            throw new Error(error.response.data);
        }
        throw new Error('An unexpected error occurred. Please try again.');
    }
};

export const useUpdateReport = () =>
    useMutation({
        mutationFn: updateReport,
    });

export default useUpdateReport;
