import React, { createContext, useContext, useState, useCallback, useRef, useEffect } from 'react';
import { NSSlideout, NSButton } from 'bricks';
import DealScenarioDropdown from 'components/DealScenarioDropdown/DealScenarioDropdown';
import { faTrashCan } from '@fortawesome/pro-solid-svg-icons';
import { useQueryClient } from '@tanstack/react-query';
import { BOESlideoutFormValues } from 'views/DealDashboard/BOE/components/SlideoutForm/types';
import { mapScenarioToFormValues } from 'views/DealDashboard/BOE/helper';
import BOESlideoutForm from 'views/DealDashboard/BOE/components/SlideoutForm/BOESlideoutForm';
import { defaultValues, LOAN_CALCULATION_TYPES } from 'views/DealDashboard/BOE/components/SlideoutForm/constants';
import { useDeleteBackOfEnvelope } from 'views/DealDashboard/BOE/components/SlideoutForm/hooks/useDeleteBOE';
import { IOptionAnyValue } from 'bricks/types';
import { useGetBackOfEnvelope } from 'views/DealDashboard/BOE/hooks/useGetBOE';
import { SimplifiedScenario } from 'components/DealScenarioDropdown/types';
import { useGetBackOfEnvelopeDeals } from 'views/DealDashboard/BOE/hooks/useGetBOEDeals';
import { useGetBackOfEnvelopeScenarios } from 'views/DealDashboard/BOE/hooks/useGetBOEScenarios';
import { useGetDealById } from 'views/Deals/hooks/useGetDealById';
import { useParams } from 'react-router-dom';
import { FormikProps } from 'formik';
import SaveModal from 'views/DealDashboard/BOE/components/SaveModal/SaveModal';
import LoanSizingModal from 'views/DealDashboard/BOE/components/LoanTestModal/LoanSizingModal';
import { ILoanSizingOption, ILoanSizingValue } from 'views/DealDashboard/BOE/components/LoanTestModal/types';

interface BackOfEnvelopeContextProps {
    formikRef: React.RefObject<any>;
    isOpen: boolean;
    initialValues: BOESlideoutFormValues | null;
    toggleSlideout: () => void;
    resetFormValues: () => void;
    setInitialValues: (values: BOESlideoutFormValues | null) => void;
    setHasEditingValues: (editing: boolean) => void;
    setSelectedScenarioId: (id: number | undefined) => void;
    setSelectedSlideoutScenarioId: (id: number | undefined) => void;
    setSelectedScenario: (scenario: IOptionAnyValue | undefined) => void;
    getSlideoutValues: any;
    selectedScenarioId: number | undefined;
    selectedScenario: IOptionAnyValue | undefined;
}

export const BackOfEnvelopeContext = createContext<BackOfEnvelopeContextProps | undefined>(undefined);

export const useSlideout = () => {
    const context = useContext(BackOfEnvelopeContext);
    if (!context) {
        throw new Error('useSlideout must be used within a SlideoutProvider');
    }
    return context;
};

function convertToSimplifiedScenario(data: SimplifiedScenario[] | null): SimplifiedScenario[] | null {
    if (!data) return null;

    return data.map(item => ({
        name: item.name,
        id: item.id,
        scenarios: [{ id: -1, name: 'default' }],
        hasNestedScenarios: true,
    }));
}

export const BackOfEnvelopeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const orgId = '123e4567-e89b-12d3-a456-426614174000'; // TODO Remove this hardcoded orgId

    const [showSaveModal, setShowSaveModal] = useState(false);
    const [showLoanSizingModal, setShowLoanSizingModal] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [hasEditingValues, setHasEditingValues] = useState(false);
    const [selectedSlideoutScenarioId, setSelectedSlideoutScenarioId] = useState<number | undefined>(undefined);
    const [initialValues, setInitialValues] = useState<BOESlideoutFormValues | null>(null);
    const [selectedScenarioId, setSelectedScenarioId] = useState<number | undefined>(undefined);
    const [selectedScenario, setSelectedScenario] = useState<IOptionAnyValue | undefined>(undefined);

    const { dealId } = useParams();
    const queryClient = useQueryClient();

    const { data: dropdownDeals } = useGetBackOfEnvelopeDeals({ orgId }); // DEALS WITH NESTED SCENARIOS
    const { data: dropdownScenarios } = useGetBackOfEnvelopeScenarios({ orgId }); // UNCATEGORIZED SCENARIOS
    const { mutate: deleteScenario } = useDeleteBackOfEnvelope();
    const { refetch: refetchSlideout } = useGetBackOfEnvelope({
        orgId,
        scenarioId: selectedSlideoutScenarioId,
        isForm: true,
    });
    const { data: overviewScenarioData } = useGetBackOfEnvelope({
        orgId,
        scenarioId: selectedScenarioId,
    });

    const { data: fetchedDeal } = useGetDealById({
        orgId,
        dealId: Number(dealId),
        shouldFetch: !!dealId,
    });

    const formikRef = useRef<FormikProps<BOESlideoutFormValues>>(null);

    const simplifiedScenarios = convertToSimplifiedScenario(dropdownDeals?.items || null);

    const combinedScenarios = [
        ...(simplifiedScenarios || []),
        ...(dropdownScenarios?.items.map(item => ({
            name: item.name,
            id: item.id,
        })) || []),
    ];

    const resetFormValues = () => {
        if (formikRef?.current) {
            formikRef.current.resetForm({ values: defaultValues });
            setHasEditingValues(false);
            setInitialValues(null);
        }
    };

    const toggleSlideout = useCallback(() => {
        setIsOpen(prev => {
            if (prev) {
                resetFormValues();
            }
            return !prev;
        });
    }, [resetFormValues]);

    const getSlideoutValues = () => {
        const formValues = mapScenarioToFormValues(overviewScenarioData || null);

        if (formikRef?.current && formValues) {
            formikRef.current.setValues(formValues);
        } else {
            setInitialValues(formValues);
        }

        setHasEditingValues(true);
    };

    const handleSave = async () => {
        if (formikRef.current) {
            if (hasEditingValues) {
                const selectedScenarioInUncategorized = dropdownScenarios?.items.find(scenario => scenario.id === selectedSlideoutScenarioId);
                if (selectedScenarioInUncategorized) {
                    setShowSaveModal(true);
                } else {
                    formikRef.current.submitForm();
                }
                return;
            }

            const errors = await formikRef.current.validateForm();
            if (Object.keys(errors).length === 0) {
                setShowSaveModal(true);
            } else {
                formikRef.current.setTouched(Object.keys(errors).reduce((acc, key) => ({ ...acc, [key]: true }), {}));
            }
        }
    };

    const handleDelete = useCallback(() => {
        if (!formikRef.current?.values.id) return;

        deleteScenario(
            { orgId, scenarioId: Number(formikRef.current?.values.id) },
            {
                onSuccess: () => {
                    window.alert('Scenario deleted successfully!');
                    queryClient.invalidateQueries({ queryKey: ['back-of-envelope', orgId] });
                },
                onError: error => {
                    console.error('Error deleting scenario:', error);
                },
            },
        );
    }, [selectedSlideoutScenarioId, selectedScenarioId]);

    const handleSelectScenarioInSlideout = useCallback(
        (option: IOptionAnyValue) => {
            if (option.label === 'SEARCH' || option.label === 'CREATE' || option.label === 'BACK') {
                return;
            }

            setSelectedSlideoutScenarioId(() => {
                const scenarioId = option.id || option.value;

                refetchSlideout().then(({ data }) => {
                    const formValues = mapScenarioToFormValues(data || null);

                    if (formikRef.current && formValues) {
                        formikRef.current.setValues(formValues);
                        setHasEditingValues(true);
                    }
                });
                return scenarioId;
            });
        },
        [mapScenarioToFormValues],
    );

    const handleCreateScenario = useCallback(() => {
        console.log('Create new scenario logic triggered');
    }, []);

    useEffect(() => {
        setHasEditingValues(!!formikRef?.current?.values.id);
    }, [formikRef.current?.values, isOpen]);

    return (
        <BackOfEnvelopeContext.Provider
            value={{
                formikRef,
                isOpen,
                initialValues,
                toggleSlideout,
                resetFormValues,
                setHasEditingValues,
                setInitialValues,
                setSelectedScenarioId,
                setSelectedSlideoutScenarioId,
                setSelectedScenario,
                selectedScenarioId,
                selectedScenario,
                getSlideoutValues,
            }}
        >
            {children}
            {isOpen && (
                <>
                    <NSSlideout
                        key={isOpen ? 'slideout-open' : 'slideout-closed'}
                        modalHeaderText="Back-Of-Envelope"
                        modalHeaderSubtitleText="Quickly assess the financial feasibility of this deal."
                        modalBodyClassName="pb-0"
                        customHeaderActions={
                            <div className="d-flex">
                                {combinedScenarios && (
                                    <DealScenarioDropdown
                                        preSelectScenario={{ label: initialValues?.name || '', value: initialValues?.id }}
                                        scenarios={combinedScenarios}
                                        handleCreateScenario={handleCreateScenario}
                                        handleSelectScenario={handleSelectScenarioInSlideout}
                                        setSelectedScenarioId={setSelectedSlideoutScenarioId}
                                        includeCreate
                                        includeLabels
                                        includeSearch
                                    />
                                )}
                                {hasEditingValues ? (
                                    <>
                                        <NSButton color="danger" icon={faTrashCan} size="sm" callback={handleDelete} className="mx-2" />
                                        <NSButton color="primary" text="Save" callback={handleSave} />
                                    </>
                                ) : (
                                    <>
                                        <NSButton color="secondary" text="Clear" className="mx-2" callback={resetFormValues} />
                                        <NSButton color="primary" text="Save" callback={handleSave} />
                                    </>
                                )}
                            </div>
                        }
                        isOpen={isOpen}
                        toggle={toggleSlideout}
                    >
                        <BOESlideoutForm
                            ref={formikRef}
                            initialValues={initialValues}
                            isEditing={hasEditingValues}
                            setShowModal={() => {}}
                            setShowLoanSizingModal={() => setShowLoanSizingModal(true)}
                            dealId={fetchedDeal?.id}
                            orgId={orgId}
                        />
                    </NSSlideout>
                    <SaveModal
                        selectedScenarioName={
                            selectedSlideoutScenarioId ? combinedScenarios?.find(scenario => scenario.id === selectedSlideoutScenarioId)?.name : ''
                        }
                        dealId={Number(fetchedDeal?.id)}
                        toggle={() => setShowSaveModal(!showSaveModal)}
                        isOpen={showSaveModal}
                        onConfirm={() => {
                            if (formikRef.current) {
                                formikRef.current.submitForm();
                            }
                        }}
                        onCancel={() => {
                            setShowSaveModal(false);
                        }}
                        setFieldValue={formikRef.current?.setFieldValue}
                        modalHeaderText="Save scenario"
                        modalFooterButtonText="Go back to selection"
                        modalFooterCancelButtonText="Cancel"
                        backdrop="static"
                    />
                    <LoanSizingModal
                        boeValues={formikRef.current?.values}
                        toggle={() => setShowLoanSizingModal(!showLoanSizingModal)}
                        isOpen={showLoanSizingModal}
                        onConfirm={(option: ILoanSizingOption, type: LOAN_CALCULATION_TYPES, values: ILoanSizingValue) => {
                            // Reset market cap rate
                            formikRef.current?.setFieldValue('marketCapRate', 0);

                            formikRef.current?.setFieldValue('selectedLoanCalculationOptions', option);
                            formikRef.current?.setFieldValue('contructionFinancing', values.contructionFinancing);
                            if (type === LOAN_CALCULATION_TYPES.LTV) {
                                formikRef.current?.setFieldValue('marketCapRate', values.marketCapRate);
                            }
                        }}
                        onCancel={() => {
                            setShowLoanSizingModal(false);
                        }}
                        backdrop="static"
                    />
                </>
            )}
        </BackOfEnvelopeContext.Provider>
    );
};
