import { useMutation, useQueryClient } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';

interface IFormValues {
    landArea: number;
    landAreaUnitTypeId: number;
    floorAreaRatio: number;
    proFormaId: number;
}

interface IUpdateVariables {
    dealId: number;
    proFormaId: number;
    lotId: number;
}

const deleteBuildableLot = async ({ dealId, proFormaId, lotId }: IUpdateVariables): Promise<IFormValues> => {
    const response = await axiosInstance.delete(`${API_ENDPOINTS.PRO_FORMA_BUILDABLE_LOT(dealId, proFormaId, lotId)}`);
    return response.data;
};

const useDeleteBuildableLot = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: deleteBuildableLot,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['proFormaGrossBuildableLots'] });
        },
        onError: error => {
            console.error('Error deleting lot:', error);
        },
    });
};

export default useDeleteBuildableLot;
