export const DEAL_OVERVIEW = 'Overview';
export const BACK_OF_ENVELOPE = 'Back-Of-Envelope';
export const DEAL_ACTIVITY = 'Activity';

export const DEAL_OVERVIEW_TABS = [DEAL_OVERVIEW, BACK_OF_ENVELOPE, DEAL_ACTIVITY];

/* eslint-disable no-unused-vars */
export enum ExportOptions {
    DEAL_OVERVIEW = 'Deal Overview',
    SCENARIO_DASHBOARD = 'Scenario Dashboard',
}
