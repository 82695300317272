import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';
import { IBudgetClassification } from 'types/db/BudgetClassification';
import { ProjectionType } from 'views/ProFormaTable/types';

export interface IBudgetClassificationPatchData {
    id: number;
    description?: string;
    budgetLineNickname?: string;
    amount?: string;
    projectionType?: ProjectionType | null;
    projectionVariable?: number | null;
    startDate?: string;
    endDate?: string;
}

interface IVariables {
    organizationId: string;
    proFormaId: string;
    patchData: {
        budgetClassifications: IBudgetClassificationPatchData[];
    };
}

interface IResponse {
    success: boolean;
    data: IBudgetClassification[];
}

const updateBudgetClassifications = async ({ organizationId, proFormaId, patchData }: IVariables): Promise<IResponse> => {
    const response = await axiosInstance.patch(API_ENDPOINTS.BUDGET_CLASSIFICATIONS(organizationId, proFormaId), patchData);
    return response.data;
};

export const useUpdateBudgetClassifications = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<IResponse, TError, IVariables, TContext>,
) => {
    return useMutation<IResponse, TError, IVariables, TContext>({
        mutationFn: updateBudgetClassifications,
        ...options,
    });
};

export default useUpdateBudgetClassifications;
