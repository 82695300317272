import { NSButton } from 'bricks';
import React, { useMemo, useState, useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import PageTitle from 'components/PageTitle/PageTitle';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { URLS } from 'services/urls';
import ReportTypeComponent from './ReportType';
import { REPORT_TYPES } from './constants';
import { IDeal, IDealSelection, IReportType, ReportType, ReportTypeToUrlParamMap } from '../types';
import DealSelection from '../components/DealSelection/DealSelection';
import { useGetOrgDealSummaries } from '../hooks';

const CreateReport = () => {
    const { data: dealSummaries } = useGetOrgDealSummaries('123e4567-e89b-12d3-a456-426614174000', { enabled: true });
    // mapping to add dummy project and projectType, update when all values are available in db is ready
    const deals: IDeal[] = useMemo(() => {
        return (
            dealSummaries?.map(deal => {
                return {
                    id: deal.id,
                    dealName: deal.dealName,
                    dealType: deal.dealType,
                    project: 'deal.project',
                    projectType: 'deal.projectType',
                };
            }) || []
        );
    }, [dealSummaries]);

    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const dealIdsParam = searchParams.get('dealIds');

    const initialDealsSelected = useMemo(() => {
        const dealIds = dealIdsParam ? dealIdsParam.split(',') : [];
        return deals.filter(d => dealIds.includes(d.id.toString())).map(d => ({ deal: d }));
    }, [dealIdsParam]);

    // Read selected report type and state from query params
    const [selectedReport, setSelectedReport] = useState<IReportType | null>(null);
    const [selectedDeals, setSelectedDeals] = useState<IDealSelection[]>(initialDealsSelected.length ? initialDealsSelected : []);
    const [showModal, setShowModal] = useState(false);

    const location = useLocation();

    useEffect(() => {
        const updatedState = {
            ...location.state,
            selectedDeals,
        };
        window.history.replaceState({ ...window.history.state, usr: updatedState }, '');
        if (location.state?.selectedDeals) setSelectedDeals(location.state.selectedDeals);
    }, [selectedDeals, location]);

    const handleNavigation = (reportType: ReportType) =>
        navigate(URLS.REPORTS.NEW.replace(':reportType', ReportTypeToUrlParamMap[reportType]), {
            state: {
                selectedDeals,
            },
        });

    const handleSelection = (report: IReportType) => {
        setSelectedReport(report);
        // Trigger warning if certain conditions are met
        if (selectedDeals.length < 2 && (report.type === ReportType.Comparison || report.type === ReportType.Aggregate)) {
            setShowModal(true);
        } else {
            handleNavigation(report.type);
        }
    };

    const handleExit = () => {
        navigate(URLS.REPORTS.HOME);
    };

    return (
        <>
            <div className="m-1">
                <PageTitle title="Create Report" subtitle="Select a report type for your new report">
                    <NSButton className="btn-secondary mr-2" callback={handleExit} text="Exit" />
                </PageTitle>
                <DealSelection deals={deals} titleSuffix="DEALS SELECTED" selectedDeals={selectedDeals} setSelectedDeals={setSelectedDeals} />
                <Row className="d-flex justify-content-between mt-1">
                    {REPORT_TYPES.map(reportType => (
                        <Col key={reportType.name} xs={12} md={6} className="p-0">
                            <ReportTypeComponent
                                typeName={reportType.name}
                                subtitle={reportType.description}
                                icon={reportType.icon}
                                isSelected={selectedReport === reportType}
                                disabledTooltip={reportType.disabledTooltip}
                                onClick={() => handleSelection(reportType)}
                            />
                        </Col>
                    ))}
                </Row>
            </div>
            <ConfirmationModal
                toggle={() => setShowModal(!showModal)}
                isOpen={showModal}
                onCancel={() => {
                    handleNavigation(selectedReport?.type as ReportType);
                    setShowModal(false);
                }}
                onConfirm={() => {
                    setSelectedReport(null);
                    setShowModal(false);
                }}
                modalHeaderText="Warning: report selection"
                modalFooterButtonText="Go back to selection"
                modalFooterCancelButtonText="Continue anyway"
                backdrop="static"
                danger
            >
                <h4 className="text-white font-weight-bold">We strongly recommend adding at least {2 - selectedDeals.length} more deal.</h4>
                <p className="text-white">
                    {`${selectedReport?.name} reports are intended for more than one selected deal. Are you sure you want to continue?`}
                </p>
            </ConfirmationModal>
        </>
    );
};

export default CreateReport;
