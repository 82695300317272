import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';
import { IReportDetails } from '../types';

const getReport = async (reportId: number): Promise<IReportDetails> => {
    const response = await axiosInstance.get(API_ENDPOINTS.REPORTS.REPORT(reportId));
    return response.data;
};

export const useGetReport = (reportId: number, options: { enabled: boolean }) => {
    return useQuery({
        queryKey: ['reportDetails', reportId],
        queryFn: () => getReport(reportId),
        staleTime: 5000,
        refetchOnMount: true,
        ...options,
    });
};

export default useGetReport;
