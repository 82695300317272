/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo } from 'react';
import { NSCard } from 'bricks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronCircleUp, faChevronCircleDown } from '@fortawesome/pro-regular-svg-icons';
import { IDeal, IDealSelection } from 'views/Reports/types';
import { IOptionAnyValue } from 'bricks/types';
import DealSelector from './DealSelector';
import SelectedDeal from './SelectedDeal';

interface IDealSelectionProps {
    deals: IDeal[];
    titlePrefix?: string;
    titleSuffix?: string;
    selectedDeals: IDealSelection[];
    setSelectedDeals: (deals: IDealSelection[]) => void;
}

const DealSelection = ({ deals, titlePrefix, titleSuffix, selectedDeals, setSelectedDeals }: IDealSelectionProps) => {
    const [showDeals, setShowDeals] = useState<boolean>(true);

    const dealOptions = useMemo(() => {
        return (
            deals?.map(d => ({
                value: d.id,
                label: d.dealName,
                disabled: selectedDeals.some(selected => selected.deal.id === d.id), // Disable if in selectedDeals
            })) || []
        );
    }, [deals, selectedDeals]);

    const addSelectedDeal = (dealSelection?: IOptionAnyValue) => {
        if (!dealSelection) return;

        const updatedDeals = [...selectedDeals, { deal: deals.find(i => i.id === dealSelection.value)!, proForma: undefined }];
        setSelectedDeals(updatedDeals);
    };

    const removeSelectedDeal = (deal: IDeal) => {
        const filteredDeals = selectedDeals.filter(d => d.deal.id !== deal.id);
        setSelectedDeals(filteredDeals);
    };

    const clearSelectedDeals = () => setSelectedDeals([]);

    const handleProFormaSelectionChange = (deal: IDeal, proFormaSelection?: IOptionAnyValue) => {
        const updatedDeals = selectedDeals.map(i => {
            if (i.deal.id === deal.id) {
                return { ...i, proForma: proFormaSelection };
            }
            return i;
        });
        setSelectedDeals(updatedDeals);
    };

    return (
        <NSCard className="NSCard--level-2 mt-2 p-2">
            <div className="d-flex justify-content-between p-1">
                <span>
                    {`${titlePrefix ? `${titlePrefix} ` : ''}${selectedDeals.length}${titleSuffix ? ` ${titleSuffix}` : ''}`}
                    {selectedDeals.length > 0 && (
                        <span onClick={clearSelectedDeals} className="text-primary cursor--pointer ml-1">
                            Clear
                        </span>
                    )}
                </span>
                <span onClick={() => setShowDeals(!showDeals)}>
                    <span className="text-primary cursor--pointer">
                        {showDeals ? 'Hide deals' : 'Show deals'}
                        <FontAwesomeIcon className="ml-1" icon={showDeals ? faChevronCircleUp : faChevronCircleDown} />
                    </span>
                </span>
            </div>

            {showDeals && (
                <div className="d-flex flex-wrap justify-content-between">
                    {selectedDeals.map((i: IDealSelection) => (
                        <div className="col-6 p-1" key={`selected-deal-selector-${i.deal.id}`}>
                            <SelectedDeal
                                deal={i.deal}
                                selectedProForma={i.proForma}
                                onChangeProFormaSelection={newSelection => handleProFormaSelectionChange(i.deal, newSelection)}
                                onRemoveDealSelection={() => removeSelectedDeal(i.deal)}
                            />
                        </div>
                    ))}
                    <div className="col-6 p-1">
                        <DealSelector dealOptions={dealOptions} addSelectedDeal={addSelectedDeal} />
                    </div>
                </div>
            )}
        </NSCard>
    );
};

export default DealSelection;
