import React from 'react';
import { NSCheckbox } from 'bricks';
import { Link } from 'react-router-dom';
import { URLS } from 'services/urls';
import ReportActionsMenu from 'views/Reports/components/ReportActionsMenu/ReportActionsMenu';
import { IReport, ReportTypeId, ReportTypeIdToNameMap } from '../../types';

interface IReportRowProps {
    reportRowData: IReport;
    isSelected: boolean;
    onSelect: (id: number) => void;
}

const ReportRow = ({ reportRowData, isSelected, onSelect }: IReportRowProps) => {
    const orgId = '123e4567-e89b-12d3-a456-426614174000';

    return (
        <tr className="NSTable__tbody__tr text-white">
            <td className="NSTable__tbody__tr__td align-middle">
                <div className="d-flex align-items-center">
                    <NSCheckbox id={`report-select-${reportRowData.id}`} checked={isSelected} callback={() => onSelect(reportRowData.id)} />
                    <Link to={URLS.REPORTS.REPORT_PAGE.replace(':reportId', reportRowData.id.toString())}>{reportRowData.name}</Link>
                </div>
            </td>
            <td className="NSTable__tbody__tr__td align-middle">{ReportTypeIdToNameMap[reportRowData.type]}</td>
            <td className="NSTable__tbody__tr__td align-middle">{reportRowData.description}</td>
            <td className="NSTable__tbody__tr__td align-middle">
                {reportRowData.type === ReportTypeId.Pipeline ? 'All' : reportRowData.deals.map(deal => <div key={deal}>{deal}</div>)}
            </td>
            <td className="NSTable__tbody__tr__td text-center">
                <ReportActionsMenu reportId={reportRowData.id} orgId={orgId} />
            </td>
        </tr>
    );
};

export default ReportRow;
