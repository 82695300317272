/* eslint-disable operator-linebreak */
import { formatUTCtoLocal, numeralFormatter, numeralFormatterCurrency } from 'ns_libs/formatter';
import { PRO_FORMA_UNIT_TO_FIELD_VALUES, UNIT_OF_MEASURE_TYPES, UNIT_TYPE_NAMES } from 'constants/unitTypes';
import { PRO_FORMA_TABS, PRO_FORMA_TABLE_NAV, RETURNS_AND_WATERFALL_TABS } from './constants';
import { DevelopmentUnitOfMeasureTypes, IProFormaSourceTableRow, IProFormaUses, IUnitTypeOption } from './types';
import { ProFormaGenericColumn } from './components/ProFormaGenericTable/types';

export const getProFormaUrl = (sideNavName: string, tabName?: string) => {
    const section = encodeURIComponent(sideNavName);
    let tab = '';

    if (!tabName) {
        if (sideNavName === PRO_FORMA_TABLE_NAV.PRO_FORMA.name) {
            tab = `&tab=${encodeURIComponent(PRO_FORMA_TABS.GENERAL_INFORMATION.name)}`;
        } else if (sideNavName === PRO_FORMA_TABLE_NAV.RETURNS_AND_WATERFALL.name) {
            tab = `&tab=${encodeURIComponent(RETURNS_AND_WATERFALL_TABS.RETURNS)}`;
        }
    } else {
        tab = `&tab=${encodeURIComponent(tabName)}`;
    }

    const url = `${window.location.pathname}?section=${section}${tab}`;

    return url;
};

export const generateDateRange = (dateRange: string[], labelFormat: string = 'MMM yyyy') => {
    const dates: Date[] = [];

    const startDate = new Date(dateRange[0]);
    const endDate = new Date(dateRange[1]);

    const currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        dates.push(new Date(currentDate));
        currentDate.setMonth(currentDate.getMonth() + 1);
    }
    const options = dates.map(date => ({
        value: formatUTCtoLocal(date, 'yyyy-MM-dd'), // 2024-01-01
        label: formatUTCtoLocal(date, labelFormat),
    }));
    return options;
};

export const getProFormaApartmentDisplayCards = (values: any, units: number = 0, rentableSquareFootage: number = 0) => {
    const landArea = values.landArea || 0;
    const landAreaInAcres = landArea && values.landAreaUnitTypeId === UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.GROSS_SF]?.id ? landArea / 43560 : landArea;
    const landAreaInFeet = landArea && values.landAreaUnitTypeId === UNIT_TYPE_NAMES[UNIT_OF_MEASURE_TYPES.ACRE]?.id ? landArea * 43560 : landArea;
    const averageSqftPerUnit = values.grossBuildableArea && units ? values.grossBuildableArea / units : 0;

    const netRentableArea = units && averageSqftPerUnit ? units * averageSqftPerUnit : undefined;
    const unitsPerAcre = units && landAreaInAcres ? (units / landAreaInAcres).toFixed(2) : undefined;
    const grossBuildableArea = values.isFAR ? values.farAmount * landAreaInFeet : values.grossBuildableArea;
    const grossBuildableAreLabel = values.isFAR ? 'FAR Gross buildable area' : 'Gross buildable area';
    const totalParkingSpots = (values.structuredParkingSpaces || 0) + (values.surfaceParkingSpaces || 0);
    const parkingRatio = totalParkingSpots && units ? (totalParkingSpots / units).toFixed(2) : undefined;
    const efficiencyRatio = rentableSquareFootage && grossBuildableArea ? (rentableSquareFootage / grossBuildableArea).toFixed(2) : null;

    return [
        { label: 'Units', value: units },
        { label: 'Net rentable area', value: netRentableArea, format: '0,0' },
        { label: 'Units/acre', value: unitsPerAcre, format: '0,0.00' },
        { label: grossBuildableAreLabel, value: grossBuildableArea, format: '0,0', valueSuffix: 'ft²' },
        { label: 'Total parking spots', value: totalParkingSpots, format: '0,0' },
        { label: 'Parking ratio', value: parkingRatio, format: '0,0.00' },
        { label: 'Efficiency ratio', value: efficiencyRatio, format: '0,0.00', valueSuffix: '%' },
    ];
};

export const getUsesCostPerUnitTotal = (items: IProFormaUses[], usesUnitType: IUnitTypeOption) => {
    const hasNoCostPerUnitData = items.every(
        highLevel =>
            !highLevel.totals ||
            (highLevel.totals && highLevel.totals?.[PRO_FORMA_UNIT_TO_FIELD_VALUES[usesUnitType.value as DevelopmentUnitOfMeasureTypes]] === null),
    );
    const costPerUnitTotals = items.reduce(
        (acc, curr) => acc + (parseFloat(String(curr.totals?.[PRO_FORMA_UNIT_TO_FIELD_VALUES[usesUnitType.value as DevelopmentUnitOfMeasureTypes]])) || 0),
        0,
    );

    return hasNoCostPerUnitData ? '—' : numeralFormatterCurrency(costPerUnitTotals);
};

export const getUsesAmountTotal = (items: IProFormaUses[]) => {
    const hasNoAmountData = items.every(highLevel => !highLevel.totals || highLevel.totals?.amount === null);
    const amountTotals = items.reduce((acc, curr) => acc + (parseFloat(String(curr.totals?.amount || '0')) || 0), 0);

    return hasNoAmountData ? '—' : numeralFormatterCurrency(amountTotals);
};

export const getSourcesCostPerUnitTotal = (sources: IProFormaSourceTableRow[], sourcesUnitType: IUnitTypeOption) => {
    const hasNoCostPerUnitData = sources.every(
        source =>
            !source.amount || (source.amount && source[PRO_FORMA_UNIT_TO_FIELD_VALUES[sourcesUnitType.value as DevelopmentUnitOfMeasureTypes]] === null),
    );
    const costPerUnitTotals = sources.reduce(
        (acc, curr) => acc + (parseFloat(String(curr[PRO_FORMA_UNIT_TO_FIELD_VALUES[sourcesUnitType.value as DevelopmentUnitOfMeasureTypes]])) || 0),
        0,
    );

    return hasNoCostPerUnitData ? '—' : numeralFormatterCurrency(costPerUnitTotals);
};

export const getSourcesAmountTotal = (sources: IProFormaSourceTableRow[]) => {
    const hasNoAmountData = sources.every(source => !source.amount || source.amount === null);
    const amountTotals = sources.reduce((acc, curr) => acc + (parseFloat(String(curr.amount || '0')) || 0), 0);

    return hasNoAmountData ? '—' : numeralFormatterCurrency(amountTotals);
};

export const formatValue = (value: string, isNegative = false, forDisplay = false, format?: ProFormaGenericColumn['format'], isHidden = false) => {
    if (isHidden) return '';

    const num = parseFloat(value);
    if (Number.isNaN(num)) return value;
    if (!forDisplay) return value;

    const absNum = Math.abs(num);
    let formatted: string;

    switch (format) {
        case 'percentage':
            formatted = `${numeralFormatter(absNum, '0.00')}%`;
            break;
        case 'none':
            formatted = numeralFormatter(absNum);
            break;
        default:
            formatted = numeralFormatterCurrency(absNum);
    }

    return isNegative ? `(${formatted})` : formatted;
};

export const getMissingDateText = (startDate: string | null, saleDate: string | null) => {
    if (!startDate && !saleDate) {
        return {
            title: 'Start and Sale dates required',
            prependedSubtitle: 'Provide start and sale dates on',
            boldSubtitleText: 'Start and End',
        };
    }
    if (!startDate) {
        return {
            title: 'Start date required',
            prependedSubtitle: 'Provide a start date on',
            boldSubtitleText: 'Start',
        };
    }
    if (!saleDate) {
        return {
            title: 'Sale date required',
            prependedSubtitle: 'Provide a sale date on',
            boldSubtitleText: 'End',
        };
    }
    return {
        title: null,
        prependedSubtitle: null,
        boldSubtitleText: null,
    };
};
