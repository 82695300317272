import React, { useState, useMemo, useCallback, forwardRef, useImperativeHandle, Ref } from 'react';
import { ColumnDef, flexRender, getCoreRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { arrayMove, SortableContext, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import './DraggableTable.scss';
import { NSButton } from 'bricks';
import NSTable from '../../bricks/NSTable/NSTable';
import { DraggableHeader } from './DraggableHeader/DraggableHeader';

interface DraggableTableProps<TData> {
    data: TData[];
    columns: (ColumnDef<TData> & { accessorKey: string })[];
    columnVisibility: Record<string, boolean>;
    selectedItem?: TData & { id: number };
    onAscendingSortPressed?: (columnName: keyof TData) => void;
    onDescendingSortPressed?: (columnName: keyof TData) => void;
    onRowClick?: (row: TData) => void;
    onCreateGroupFromSelection?: (selectedRows: TData[]) => void;
    onAddToExistingGroup?: (selectedRows: TData[]) => void;
    onCreateReport?: (selectedRows: TData[]) => void;
    onDelete?: (selectedRows: TData[]) => void;
}

interface DraggableTableRef {
    resetRowSelection: VoidFunction;
}

const DraggableTable = <TData extends object>(
    {
        data,
        columns,
        columnVisibility,
        onAscendingSortPressed,
        onDescendingSortPressed,
        selectedItem,
        onRowClick,
        onCreateGroupFromSelection,
        onAddToExistingGroup,
        onCreateReport,
        onDelete,
    }: DraggableTableProps<TData>,
    ref: Ref<DraggableTableRef>,
) => {
    const [columnOrder, setColumnOrder] = useState<string[]>(() => columns.map(col => col.accessorKey));
    const [sorting, setSorting] = useState<SortingState>([]);

    const table = useReactTable({
        data,
        columns,
        state: {
            columnOrder,
            sorting,
            columnVisibility,
        },
        manualSorting: true,
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        onColumnOrderChange: setColumnOrder,
    });

    useImperativeHandle(ref, () => ({
        resetRowSelection: () => {
            table.setRowSelection({});
        },
    }));

    const { rows } = table.getSelectedRowModel();
    const selectedRows = useMemo(() => rows.map(row => row.original), [rows]);
    const selectedRowsCount = selectedRows.length;

    const handleSort = (columnId: keyof TData, direction: 'asc' | 'desc') => {
        if (direction === 'asc' && onAscendingSortPressed) {
            onAscendingSortPressed(columnId);
        } else if (direction === 'desc' && onDescendingSortPressed) {
            onDescendingSortPressed(columnId);
        }
        setSorting([{ id: columnId as string, desc: direction === 'desc' }]);
    };

    const handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;

        if (active && over && active.id !== over.id) {
            setColumnOrder(prevOrder => {
                const oldIndex = prevOrder.indexOf(active.id as string);
                const newIndex = prevOrder.indexOf(over.id as string);

                if (oldIndex === 0 || newIndex === 0 || oldIndex === prevOrder.length - 1 || newIndex === prevOrder.length - 1) {
                    return prevOrder;
                }

                return arrayMove(prevOrder, oldIndex, newIndex);
            });
        }
    };

    const RowActions = (
        <div className="DraggableTable__row-actions p-3 d-flex align-items-center">
            <h5>{selectedRowsCount} selected</h5>
            <NSButton
                data-testid="create-group-button"
                className="btn btn-outline-primary m-2"
                callback={() => onCreateGroupFromSelection?.(selectedRows)}
            >
                Create group from selection
            </NSButton>
            <NSButton
                dataTestId="add-to-existing-group-button"
                className="btn btn-outline-primary m-2"
                callback={() => onAddToExistingGroup?.(selectedRows)}
            >
                Add to existing group
            </NSButton>
            <NSButton dataTestId="create-report-button" className="btn btn-outline-primary m-2" callback={() => onCreateReport?.(selectedRows)}>
                Create report
            </NSButton>
            <NSButton dataTestId="delete-button" className="btn btn-outline-danger m-2" callback={() => onDelete?.(selectedRows)}>
                Delete
            </NSButton>
        </div>
    );

    const getSort = useCallback((sorting: SortingState) => {
        return sorting[0]?.desc ? 'desc' : 'asc';
    }, []);

    return (
        <div className="DraggableTable">
            {selectedRowsCount > 0 && RowActions}
            <DndContext
                sensors={useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 8 } }), useSensor(KeyboardSensor))}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <NSTable className="DraggableTable__table">
                    <thead className="DraggableTable__table__table-header">
                        {table.getHeaderGroups().map(headerGroup => (
                            <tr key={headerGroup.id}>
                                <SortableContext
                                    items={headerGroup.headers.filter(header => header.column.getCanSort()).map(header => header.id)}
                                    strategy={horizontalListSortingStrategy}
                                >
                                    {headerGroup.headers.map(header => {
                                        return header.isPlaceholder ? null : (
                                            <DraggableHeader<TData>
                                                key={header.id}
                                                header={header}
                                                sortedColumnId={sorting[0]?.id ?? null}
                                                sortedDirection={sorting[0]?.id === header.column.id ? getSort(sorting) : null}
                                                onAscendingSortPressed={colId => handleSort(colId as keyof TData, 'asc')}
                                                onDescendingSortPressed={colId => handleSort(colId as keyof TData, 'desc')}
                                            />
                                        );
                                    })}
                                </SortableContext>
                            </tr>
                        ))}
                    </thead>
                    <tbody>
                        {table.getRowModel().rows.map(row => (
                            <tr
                                key={row.id}
                                className={`NSTable__tbody__tr--level-1 NSTable__tbody__tr--hoverable ${
                                    selectedItem?.id === (row.original as TData & { id: number }).id ? 'active' : ''
                                }`}
                            >
                                {row.getVisibleCells().map(cell => (
                                    <td key={cell.id} onClick={() => onRowClick?.(row.original)}>
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </NSTable>
            </DndContext>
        </div>
    );
};

export default forwardRef(DraggableTable) as <TData extends object>(
    props: DraggableTableProps<TData> & { ref?: Ref<DraggableTableRef> },
) => ReturnType<typeof DraggableTable>;
