import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';
import { useMutation } from '@tanstack/react-query';
import { IErrorResponse } from '../types';

interface IDeleteDealGroupVariables {
    organizationId: string;
    dealId: number;
}

const deleteDeal = async ({ organizationId, dealId }: IDeleteDealGroupVariables): Promise<string> => {
    try {
        const response = await axiosInstance.delete<string>(API_ENDPOINTS.DEALS_BY_ID(organizationId, dealId));
        return response.data;
    } catch (error: any) {
        if (error.response?.status !== 500) {
            const errorData: IErrorResponse = error.response.data;
            throw new Error(errorData.detail);
        }
        throw new Error('An unexpected error occurred. Please try again.');
    }
};

export const useDeleteDeal = () =>
    useMutation<string, Error, IDeleteDealGroupVariables>({
        mutationFn: deleteDeal,
    });
