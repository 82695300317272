import { useState } from 'react';
import { TUnit } from '../types';

interface UseUnitManagerProps {
    initialUnits?: TUnit[];
    assetType: string;
}

export const useUnitManager = ({ initialUnits = [] }: UseUnitManagerProps) => {
    const [unitArray, setUnitArray] = useState<TUnit[]>(initialUnits.length > 0 ? initialUnits : [{ id: Date.now(), name: '' }]);

    const handleDuplicateOrAdd = (unitId?: number) => {
        setUnitArray(prevArray => {
            if (!unitId) {
                return [...prevArray, { id: Date.now(), name: '' }];
            }

            const unitToDuplicate = prevArray.find(unit => unit.id === unitId);
            if (!unitToDuplicate) return prevArray;

            const duplicatedUnit = {
                ...unitToDuplicate,
                id: Date.now(),
            };
            return [...prevArray, duplicatedUnit];
        });
    };

    const handleDelete = (unitId: number) => {
        setUnitArray(prevArray => prevArray.filter(unit => unit.id !== unitId));
    };

    const handleUpdate = (updatedUnit: TUnit) => {
        setUnitArray(prevArray => prevArray.map(unit => (unit.id === updatedUnit.id ? { ...unit, ...updatedUnit } : unit)));
    };

    return {
        unitArray,
        handleDuplicateOrAdd,
        handleDelete,
        handleUpdate,
    };
};
