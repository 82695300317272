import { NSLabel, NSModal, NSSelect } from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import classNames from 'classnames';
import { formatLocalToUTC, formatUTCtoLocal } from 'ns_libs/formatter';
import React, { useState } from 'react';
import { generateDateRange } from 'views/ProFormaTable/helpers';
import './DateModal.scss';

export interface IDateModalProps {
    isOpen: boolean;
    toggle: () => void;
    headerText: string;
    handleUpdateDate: (date: string, isStartDate: boolean) => void;
    isStartDate?: boolean;
    dateRange: string[];
    isLoadingCallback: boolean;
}

// all local date formatting is done assuming dates are passed in UTC
const DateModal = ({ isOpen, toggle, headerText, handleUpdateDate, isStartDate = false, dateRange, isLoadingCallback }: IDateModalProps) => {
    const [selectedDate, setSelectedDate] = useState<IOptionAnyValue>({
        value: formatUTCtoLocal(dateRange[0], 'yyyy-MM-dd'),
        label: formatUTCtoLocal(dateRange[1], 'MMMM yyyy'),
    });

    const dateOptions = generateDateRange(dateRange, 'MMMM yyyy');

    return (
        <NSModal
            isOpen={isOpen}
            toggle={toggle}
            modalHeaderText={headerText}
            modalHeaderClassName="DateModal text-dark bg-primary"
            modalFooterButtonText="Apply"
            modalFooterButtonFunction={() => handleUpdateDate(formatLocalToUTC(selectedDate.value), isStartDate)}
            modalFooterButtonIsLoading={isLoadingCallback}
        >
            <NSLabel>{isStartDate ? 'Start' : 'End'}</NSLabel>

            <NSSelect
                name={isStartDate ? 'startDate' : 'endDate'}
                options={dateOptions}
                className="DateModal__select"
                value={dateOptions.find(option => option.value === selectedDate.value)}
                onChange={option => setSelectedDate(option)}
                isClearable={false}
                isSearchable={false}
                formatOptionLabel={(option: { label: string; value: string }, context: { context: string }) => {
                    const index = dateOptions.findIndex(opt => opt.value === option.value);

                    if (['menu', 'value'].includes(context.context)) {
                        return (
                            <div className={classNames('d-flex justify-content-between', { 'py-1': context.context === 'value' })}>
                                <div>{option.label}</div>
                                <div className={classNames('DateModal__select__month-count-text', { 'mr-3': context.context === 'menu' })}>
                                    Period {index + 1}
                                </div>
                            </div>
                        );
                    }

                    return option.label;
                }}
            />
        </NSModal>
    );
};

export default DateModal;
