/* eslint-disable no-unused-vars */
export enum FILTERS_PARAM_KEYS {
    reportType = 'reportType',
    deals = 'deals',
}

export const FILTER_CATEGORIES: Array<{
    label: string;
    value: FILTERS_PARAM_KEYS;
}> = [
    { label: 'Report Type', value: FILTERS_PARAM_KEYS.reportType },
    { label: 'Deal', value: FILTERS_PARAM_KEYS.deals },
];
