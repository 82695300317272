import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NSModal } from 'bricks';
import RenderIf from 'components/RenderIf/RenderIf';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Input, Row, UncontrolledTooltip } from 'reactstrap';
import { URLS } from 'services/urls';
import { PROJECTION_TYPES } from 'views/ProFormaTable/constants';
import './CurveFitModal.scss';
import classNames from 'classnames';
import { IOptionAnyValue } from 'bricks/types';
import { IProjectionType, ProjectionType } from 'views/ProFormaTable/types';

export interface ICurveFitModal {
    toggle: () => void;
    isOpen: boolean;
    headerText: string;
    handleUpdateProjection: (projectionType: ProjectionType, projectionVariable: number) => void;
    isLoadingCallback: boolean;
    selectedCurve?: IProjectionType;
}

const CurveFitModal = ({ toggle, isOpen, headerText, handleUpdateProjection, isLoadingCallback, selectedCurve }: ICurveFitModal) => {
    const [projectionType, setProjectionType] = useState<IOptionAnyValue | null>(
        selectedCurve ? { label: selectedCurve?.curveType, value: selectedCurve?.projectionType } : null,
    );
    const [projectionVariable, setProjectionVariable] = useState<number | null>(PROJECTION_TYPES[0].projectionVariable);

    const canvas = useRef<HTMLCanvasElement>(null);

    const curveCanvasClass = classNames('w-100 CurveFitModal__curve-canvas', {
        'CurveFitModal__curve-canvas--default': projectionVariable === null,
        'CurveFitModal__curve-canvas--highlight': projectionVariable !== null,
    });

    const handleChangeProjectionVariable = (e: React.ChangeEvent<HTMLInputElement>) => {
        setProjectionVariable(parseFloat(e.target.value));
    };

    const handleUpdateProjectionType = (projection: IProjectionType) => {
        setProjectionType({ value: projection.projectionType, label: projection.curveType });
        setProjectionVariable(projection.projectionVariable);
    };

    const handleResetLinearSlope = () => {
        setProjectionVariable(null);
    };

    useEffect(() => {
        if (canvas.current) {
            const canvasElement = canvas.current;
            canvasElement.width = canvasElement.clientWidth;
            canvasElement.height = canvasElement.clientHeight;

            const centerX = canvasElement.width / 2;
            const centerY = canvasElement.height / 2;

            const canvasContext = canvasElement.getContext('2d')!;
            canvasContext.beginPath();

            const slope = (projectionVariable === null ? -1 : projectionVariable) / 2;
            // starting coordinate of the line
            const startX = 20;
            // prettier-ignore
            const startY = centerY - (slope * (startX - centerX));
            canvasContext.moveTo(startX, startY);

            // ending coordinate of the line
            const endX = canvasElement.width - 20;
            // prettier-ignore
            const endY = startY - (slope * (endX - startX));
            canvasContext.lineTo(endX, endY);

            canvasContext.strokeStyle = getComputedStyle(canvasElement).getPropertyValue('stroke');
            canvasContext.lineWidth = 4;
            canvasContext.stroke();
        }
    }, [projectionType, projectionVariable]);

    useEffect(() => {
        if (!projectionType) {
            setProjectionType({ value: PROJECTION_TYPES[0].projectionType, label: PROJECTION_TYPES[0].curveType });
        }
    }, [projectionType]);

    return (
        <NSModal
            isOpen={isOpen}
            toggle={toggle}
            modalHeaderText={headerText}
            modalHeaderClassName="CurveFitModal text-dark bg-primary"
            modalFooterButtonText="Apply"
            modalFooterButtonFunction={() => handleUpdateProjection((projectionType?.value as ProjectionType) || null, projectionVariable!)}
            modalFooterButtonIsLoading={Boolean(isLoadingCallback)}
            modalFooterIsButtonFunctionDisabled={isLoadingCallback || projectionVariable === null}
        >
            <Row>
                <Col className="col-8 text-dark my-auto">
                    <div className="CurveFitModal__grid">
                        {PROJECTION_TYPES.map(projection => (
                            <div
                                key={projection.curveType}
                                className={classNames(
                                    `CurveFitModal__grid__item CurveFitModal__grid__item--bg-gray cursor--pointer
                                d-flex flex-column rounded align-items-center justify-content-center px-3 py-2`,
                                    {
                                        'border border-primary': projectionType?.label === projection.curveType,
                                    },
                                )}
                                role="presentation"
                                onClick={() => handleUpdateProjectionType(projection)}
                            >
                                <img src={URLS.IMAGE_CLOUDFRONT_URL(projection.img)} alt={`selectedCurve-${projection.curveType}`} />
                                <small className="mt-2">{projection.curveType}</small>
                            </div>
                        ))}
                    </div>
                </Col>

                <RenderIf isTrue={projectionType?.value === PROJECTION_TYPES[0].projectionType}>
                    <Col
                        className={`pl-0 d-flex flex-column justify-content-between 
                            CurveFitModal__curve-column CurveFitModal__curve-column--with-left-border`}
                    >
                        <div className="p-2 ml-2 CurveFitModal__curve-column__item">
                            <canvas ref={canvas} className={curveCanvasClass} />
                            <div>
                                <span className="m-0 d-block text-dark">Adjust curve</span>
                                <div className="d-flex align-items-center">
                                    <Input
                                        className="form-range d-inline-block p-0"
                                        type="range"
                                        min="-1"
                                        max="1"
                                        step="0.1"
                                        value={projectionVariable !== null ? projectionVariable : -1}
                                        onChange={handleChangeProjectionVariable}
                                    />
                                    <FontAwesomeIcon
                                        id="resetDefaultLinearSlope"
                                        icon={faSyncAlt}
                                        onClick={handleResetLinearSlope}
                                        className="ml-2 text-dark cursor-pointer"
                                    />
                                    <UncontrolledTooltip target="resetDefaultLinearSlope">Reset to default linear slope</UncontrolledTooltip>
                                </div>
                            </div>
                        </div>
                    </Col>
                </RenderIf>
            </Row>
        </NSModal>
    );
};

export default CurveFitModal;
