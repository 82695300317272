import React, { useState } from 'react';
import { NSInput, NSLabel, NSModal } from 'bricks';
import { IReportBuilderReport } from 'views/ReportBuilder/constants.js';
import { useCreateReportBuilderTemplate } from 'views/ReportBuilder/hooks';
import { useParams } from 'react-router-dom';

export interface ISaveTemplateModalProps {
    orgId: string;
    newReports: Array<IReportBuilderReport>;
    isOpen: boolean;
    toggle: () => void;
}

const SaveTemplateModal = ({ orgId, newReports, toggle, isOpen }: ISaveTemplateModalProps) => {
    const [templateName, setTemplateName] = useState<string>('');
    const { dealId: dealIdString } = useParams<{ dealId: string; proFormaId: string }>();
    const dealId = Number(dealIdString);

    const handleNameChange = (templateNameInput: string) => {
        setTemplateName(templateNameInput);
    };

    const { mutate: createReportBuilderTemplate } = useCreateReportBuilderTemplate();

    const saveNewTemplate = () => {
        createReportBuilderTemplate({
            dealId,
            postData: {
                orgId,
                templateName,
                reportBuilderReports: newReports.map(report => ({
                    reportType: report.reportType,
                    metaData: {
                        savedReportId: report.savedReportId,
                    },
                })),
            },
        });
        toggle();
    };

    return (
        <NSModal
            toggle={toggle}
            isOpen={isOpen}
            modalHeaderClassName="bg-primary text-white"
            modalHeaderText="Save template"
            footerButtonOutline={false}
            modalFooterButtonColor="primary"
            modalFooterButtonText="Save template"
            modalFooterButtonFunction={saveNewTemplate}
            modalFooterIsButtonFunctionDisabled={!templateName}
        >
            <NSLabel>Save new custom template as:</NSLabel>
            <NSInput
                dataTestId="template-name-input"
                type="text"
                name="template name"
                value={templateName}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    handleNameChange(event.target.value);
                }}
            />
        </NSModal>
    );
};

export default SaveTemplateModal;
