import React from 'react';
import { Row, CardBody, Card } from 'reactstrap';
import { numeralFormatterCurrency } from 'ns_libs/formatter';

export interface IProps {
    title?: string;
    customizedTitle?: React.JSX.Element;
    value: number | string;
    isPercentage?: boolean;
    children?: React.ReactNode;
    titleChildren?: React.ReactNode;
    cardHighlightColor?: string;
    customFormat?: string;
    showPlaceholder?: boolean;
    valueId?: string;
    cardClassName?: string;
    titleClassName?: string;
}

const KeyStatsCard = ({
    title,
    customizedTitle,
    value,
    isPercentage,
    children,
    titleChildren,
    cardHighlightColor,
    customFormat,
    showPlaceholder,
    valueId,
    cardClassName,
    titleClassName,
}: IProps) => {
    const defaultFormat = isPercentage ? '0,0.00%' : '$0,0.00';
    const format = customFormat || defaultFormat;
    const cardValue = isPercentage && typeof value === 'number' ? value / 100 : value;

    return (
        <Card className={`KeyStatsCard ${cardClassName}`} color={cardHighlightColor}>
            <CardBody className="w-100 d-flex flex-column justify-content-center align-items-start">
                <Row className="mx-0 d-flex justify-content-between" style={{ width: 'inherit' }}>
                    <div className={`d-flex align-items-center ${titleClassName}`}>
                        {title && <span className="mr-1">{title}</span>}
                        {!!customizedTitle && customizedTitle}
                        {titleChildren}
                    </div>
                </Row>
                <Row className="mx-0 mb-0">
                    <h3 className="text-dark font-weight-bold mt-1" style={{ marginBottom: 'unset' }} id={valueId}>
                        {typeof cardValue === 'number' && !showPlaceholder && numeralFormatterCurrency(cardValue, format)}
                        {typeof cardValue === 'string' && !showPlaceholder && cardValue}
                        {showPlaceholder === true && '-'}
                    </h3>
                </Row>
                {!!children && children}
            </CardBody>
        </Card>
    );
};

export default KeyStatsCard;
