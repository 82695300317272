/* eslint-disable no-unused-vars */

import React from 'react';
import { formatDate, formatUSDCurrency } from 'ns_libs/formatter';
import { URLS } from 'services/urls';
import { generatePath, Link } from 'react-router-dom';
import { Deal, IOptionStringValue } from './types';

export enum TABLE_KEYS {
    all = 'all',
    // General
    deal = 'dealName', // name
    address = 'address',
    city = 'city',
    county = 'county',
    state = 'state',
    zipCode = 'zipCode',
    market = 'market',
    submarket = 'subMarket',
    dateCreated = 'createdAt',
    description = 'description',

    // Deal info
    dealType = 'dealType.name',
    assetType = 'assetClass', // assetClass
    constructionType = 'constructionType',
    parkingType = 'parkingType',
    units = 'units', // ???
    phase = 'phase.name',
    leadSource = 'leadSource.name',
    leasingStrategy = 'leasingStrategy.name',
    rank = 'rank.name',

    // Deal metrics
    acquisitionPrice = 'acquisitionPrice',
    targetROC = 'targetReturnOnCost',
    targetROE = 'targetReturnOnEquity',
    targetPR = 'targetProfitReturn',
    irr = 'irr',
    equityMultiple = 'equityMultiple ',

    // Personnel
    createdBy = 'creatorName', // deal creator
    dealOwner = 'dealOwner.name',
    developmentManager = 'developmentManager.name',

    // Capital partners
    investor = 'investors.0.name',
    lender = 'lenders.0.name',

    // Vendors
    legalServices = 'legalServices.0.name',
    projectArchitect = 'projectArchitects.0.name',
    surveyor = 'surveyors.0.name',
    environmentalConsultant = 'environmentalConsultants.0.name',
}

export const COLUMN_OPTIONS: Array<
    IOptionStringValue & {
        sortable?: boolean;
        cellRenderer?: (row: Deal) => JSX.Element;
    }
> = [
    // General
    {
        label: 'Deal',
        value: TABLE_KEYS.deal,
        sortable: true,
        cellRenderer: row => <Link to={generatePath(URLS.DEAL_DASHBOARD.HOME, { dealId: row.id || '' })}>{row.dealName}</Link>,
    },
    { label: 'Address', value: TABLE_KEYS.address, sortable: true },
    { label: 'City', value: TABLE_KEYS.city, sortable: true },
    { label: 'County', value: TABLE_KEYS.county, sortable: true },
    { label: 'State', value: TABLE_KEYS.state, sortable: true },
    { label: 'Zip code', value: TABLE_KEYS.zipCode, sortable: true },
    { label: 'Market', value: TABLE_KEYS.market, sortable: true },
    { label: 'Submarket', value: TABLE_KEYS.submarket, sortable: true },
    { label: 'Date created', value: TABLE_KEYS.dateCreated, sortable: true, cellRenderer: row => <span>{formatDate(row.createdAt)}</span> },
    { label: 'Description', value: TABLE_KEYS.description, sortable: true },

    // Deal info
    { label: 'Deal type', value: TABLE_KEYS.dealType, sortable: true },
    { label: 'Asset type', value: TABLE_KEYS.assetType, sortable: true },
    {
        label: 'Construction type',
        value: TABLE_KEYS.constructionType,
        sortable: true,
    },
    { label: 'Parking type', value: TABLE_KEYS.parkingType, sortable: true },
    { label: 'Units', value: TABLE_KEYS.units, sortable: true },
    { label: 'Phase', value: TABLE_KEYS.phase, sortable: true },
    { label: 'Lead source', value: TABLE_KEYS.leadSource, sortable: true },
    {
        label: 'Leasing strategy',
        value: TABLE_KEYS.leasingStrategy,
        sortable: true,
    },
    { label: 'Rank', value: TABLE_KEYS.rank, sortable: true },

    // Personnel
    { label: 'Created by', value: TABLE_KEYS.createdBy, sortable: true },
    { label: 'Deal owner', value: TABLE_KEYS.dealOwner, sortable: true },
    {
        label: 'Development manager',
        value: TABLE_KEYS.developmentManager,
        sortable: true,
    },
    // Deal metrics
    {
        label: 'Acquisition price',
        value: TABLE_KEYS.acquisitionPrice,
        sortable: true,
        cellRenderer: row => <div style={{ textAlign: 'right' }}>{formatUSDCurrency(row.acquisitionPrice)}</div>,
    },
    { label: 'Target return on cost', value: TABLE_KEYS.targetROC, sortable: true },
    { label: 'Target return on equity', value: TABLE_KEYS.targetROE, sortable: true },
    { label: 'Target profit return', value: TABLE_KEYS.targetPR, sortable: true },
    { label: 'IRR', value: TABLE_KEYS.irr, sortable: true },
    {
        label: 'Equity multiple',
        value: TABLE_KEYS.equityMultiple,
        sortable: true,
    },

    // Capital partners
    { label: 'Investor', value: TABLE_KEYS.investor, sortable: true },
    { label: 'Lender', value: TABLE_KEYS.lender, sortable: true },

    // Vendors
    { label: 'Legal services', value: TABLE_KEYS.legalServices, sortable: true },
    {
        label: 'Project architect',
        value: TABLE_KEYS.projectArchitect,
        sortable: true,
    },
    { label: 'Surveyor', value: TABLE_KEYS.surveyor, sortable: true },
    {
        label: 'Environmental consultant',
        value: TABLE_KEYS.environmentalConsultant,
        sortable: true,
    },
];

const INITIAL_VISIBLE_COLUMNS: Partial<Record<TABLE_KEYS, boolean>> = {
    [TABLE_KEYS.deal]: true,
    [TABLE_KEYS.address]: true,
    [TABLE_KEYS.dateCreated]: true,
    [TABLE_KEYS.acquisitionPrice]: true,
    [TABLE_KEYS.createdBy]: true,
};

export const getInitialColumnVisibility = (): Record<string, boolean> => {
    const defaultVisibility: Record<string, boolean> = {};

    COLUMN_OPTIONS.forEach(column => {
        defaultVisibility[column.value] = INITIAL_VISIBLE_COLUMNS[column.value as TABLE_KEYS] ?? false;
    });

    return defaultVisibility;
};

export const mockedDeals: Deal[] = [
    {
        id: 2,
        createdAt: '2024-11-22T16:03:44.472Z',
        updatedAt: '2024-11-22T16:03:44.472Z',
        organizationId: 'd765c8e2-4e90-4f4c-b3b9-0bdf742eca6d',
        dealName: 'Bavaria Towers Development',
        description: 'Modern office spaces in the heart of Munich.',
        address: 'Bogenhauser Kirchplatz 1',
        address_center: { lng: 11.582, lat: 48.1351 },
        city: 'Munich',
        state: 'Bavaria',
        county: 'Germany',
        zipCode: '81675',
        latitude: 48.1351,
        longitude: 11.582,
        market: 'Commercial',
        subMarket: 'Office',
        dealType: {
            id: '99c2ff35-9ae2-4a14-b48c-7a6d52f6f97d',
            name: 'Acquisition',
        },
        assetClass: 'Office',
        constructionType: 'Steel Frame',
        parkingType: 'Underground',
        acres: 2.5,
        grossSf: 150000,
        rentableSf: 140000,
        residentialUnits: 0,
        lots: 0,
        beds: 0,
        megawatts: 0,
        phase: {
            id: '843a74e1-3a55-46f5-8223-fc2eb4c4f6bf',
            name: 'Planning',
        },
        leadSource: {
            id: '15e0ed5e-bc79-492e-8e7b-1f8b1a8db1ae',
            name: 'Local Agency',
        },
        leasingStrategy: {
            id: 'e1d564b5-89c5-4b56-bb5b-5f8f9f4e8ec1',
            name: 'Long-term lease',
        },
        rank: {
            id: '7a63b8f9-0133-4c74-b89f-318dc91b84c4',
            name: 'High Priority',
        },
        acquisitionPrice: 45000000,
        targetReturnOnCost: 0.12,
        targetReturnOnEquity: 0.15,
        targetProfitReturn: 0.2,
        irr: 0.18,
        equityMultiple: 2.0,
        creatorName: 'Maximilian Berger',
        dealOwner: {
            id: '914f2b0c-b1df-4a92-9851-2bd39a781fb6',
            type: 'user',
            name: 'Anneliese Schuster',
        },
        developmentManager: {
            id: 'c67deac1-e120-4f4b-9d2f-b7260e82b1b3',
            type: 'user',
            name: 'Johannes Krause',
        },
        investors: [
            {
                id: '1b24d9a5-82e2-4c0f-8d24-f61c3b2c1e5e',
                type: 'company',
                name: 'Bayerische Investment Group',
            },
        ],
        lenders: [
            {
                id: '913bfe92-53e8-4d61-b2f7-cc97b2b7197e',
                type: 'company',
                name: 'Deutsche Bank',
            },
        ],
        legalServices: [
            {
                id: 'cf4c8a7e-8548-496d-bc3b-7881ecf28f6e',
                type: 'company',
                name: 'Müller & Partner',
            },
        ],
        projectArchitects: [
            {
                id: '08e5f5d4-9f4f-44c2-a172-768b4ebd2c6a',
                type: 'company',
                name: 'Modern Design Architects',
            },
        ],
        surveyors: [],
        environmentalConsultants: [],
    },
    {
        id: 3,
        createdAt: '2024-11-22T16:03:44.472Z',
        updatedAt: '2024-11-22T16:03:44.472Z',
        organizationId: '92d25bba-bb75-4b1a-b30b-dcbd56b6f491',
        dealName: 'Dnipro Riverfront Plaza',
        description: 'Mixed-use development along the Dnipro river.',
        address: 'Shevchenka Boulevard 12',
        address_center: { lng: 35.0456, lat: 48.4647 },
        city: 'Dnipro',
        state: 'Dnipropetrovsk Oblast',
        county: 'Ukraine',
        zipCode: '49000',
        latitude: null,
        longitude: null,
        market: 'Mixed-Use',
        subMarket: 'Residential & Retail',
        dealType: {
            id: 'f32d4aa2-2f18-4d26-b994-c2a59a8d1c72',
            name: 'Development',
        },
        assetClass: 'Mixed-Use',
        constructionType: 'Concrete',
        parkingType: 'Open Surface',
        acres: 5.2,
        grossSf: 250000,
        rentableSf: 200000,
        residentialUnits: 150,
        lots: 10,
        beds: 300,
        megawatts: 0,
        phase: {
            id: 'eed5d90e-b35e-45bb-81ff-4c5cccd11c42',
            name: 'Under Construction',
        },
        leadSource: {
            id: 'a4569c98-b5b8-41f3-8e20-d6f89c8a1e19',
            name: 'Direct Inquiry',
        },
        leasingStrategy: {
            id: 'f2df7f57-f3b3-46ff-8f19-849f42e15e91',
            name: 'Flexible lease options',
        },
        rank: {
            id: '2a0b7d79-bb58-4958-b522-f1f4f2c5b36f',
            name: 'Standard Priority',
        },
        acquisitionPrice: 30000000,
        targetReturnOnCost: 0.14,
        targetReturnOnEquity: 0.18,
        targetProfitReturn: 0.25,
        irr: 0.2,
        equityMultiple: 2.5,
        creatorName: 'Oksana Bondar',
        dealOwner: {
            id: 'dbad3c5e-2a3b-48eb-9382-847c98f4f6d7',
            type: 'user',
            name: 'Yaroslav Ivanenko',
        },
        developmentManager: {
            id: 'cc842b7f-55c4-4774-8109-0fcf9e5d7f89',
            type: 'user',
            name: 'Iryna Shevchenko',
        },
        investors: [
            {
                id: '3e25d2c5-942f-4c2e-b4d5-3e245e872db8',
                type: 'company',
                name: 'Dnipro Urban Investment Fund',
            },
        ],
        lenders: [
            {
                id: 'e52fc87b-d3b8-4c96-b622-d54f8c5e7b19',
                type: 'company',
                name: 'PrivatBank',
            },
        ],
        legalServices: [
            {
                id: '4e3fb68c-f5e3-4e68-b3b4-c94f32b5d9b3',
                type: 'company',
                name: 'Kyiv Legal Partners',
            },
        ],
        projectArchitects: [
            {
                id: '3c92b574-3a92-4d3e-bd62-cd42f69c74f7',
                type: 'company',
                name: 'Dnipro Modern Architects',
            },
        ],
        surveyors: [],
        environmentalConsultants: [],
    },
    {
        id: 6,
        createdAt: '2024-11-22T16:03:44.472Z',
        updatedAt: '2024-11-22T16:03:44.472Z',
        organizationId: '7f24c85b-9a4e-49e3-a2b5-c83e5d942a3c',
        dealName: 'Lyon Central Residences',
        description: 'Luxury apartments in the heart of Lyon.',
        address: 'Rue de la République 20',
        address_center: { lng: 4.8357, lat: 45.764 },
        city: 'Lyon',
        state: 'Auvergne-Rhône-Alpes',
        county: 'France',
        zipCode: '69002',
        latitude: null,
        longitude: null,
        market: 'Residential',
        subMarket: 'Luxury Apartments',
        dealType: {
            id: '8a2c1b3f-5e62-4b39-bd25-87c3f98b6f89',
            name: 'Development',
        },
        assetClass: 'Residential',
        constructionType: 'Masonry',
        parkingType: 'Underground',
        acres: 3.8,
        grossSf: 180000,
        rentableSf: 170000,
        residentialUnits: 120,
        lots: 0,
        beds: 300,
        megawatts: 0,
        phase: {
            id: 'b5c4d8e3-4f9b-4f2c-83e5-bf42c96e8c72',
            name: 'Pre-Lease',
        },
        leadSource: {
            id: '3c24b8e3-b57c-4e49-bf2c-e3b4f98c72f6',
            name: 'Broker Referral',
        },
        leasingStrategy: {
            id: 'd82c95b4-4e3f-46c8-b5e3-5c82b94e6d7f',
            name: 'Short-term lease',
        },
        rank: {
            id: 'f92c6d8e-5b3e-49f8-a5e3-d8c5f94b7e62',
            name: 'High Priority',
        },
        acquisitionPrice: 60000000,
        targetReturnOnCost: 0.1,
        targetReturnOnEquity: 0.16,
        targetProfitReturn: 0.22,
        irr: 0.19,
        equityMultiple: 2.4,
        creatorName: 'Emilie Dupont',
        dealOwner: {
            id: '5c8f4e32-b3f9-4f8e-a5f3-c72d89e5c3b6',
            type: 'user',
            name: 'Jean-Luc Lefèvre',
        },
        developmentManager: {
            id: '7e3f92c5-4b3f-49d8-a5f3-e2d96b4c5e92',
            type: 'user',
            name: 'Camille Rousseau',
        },
        investors: [
            {
                id: '8b4c5f92-3d9b-4c5f-a3f6-2b92e5c4f6e8',
                type: 'company',
                name: 'Paris Real Estate Fund',
            },
        ],
        lenders: [
            {
                id: 'd6b8e3f5-4b9e-4f3b-a5d9-b4f82c5d7f96',
                type: 'company',
                name: 'BNP Paribas',
            },
        ],
        legalServices: [
            {
                id: 'c82b94e5-b3c8-4d5f-83f9-7b5f92e8d3f9',
                type: 'company',
                name: 'Lyon Legal Advisors',
            },
        ],
        projectArchitects: [
            {
                id: '4c5b9d3e-b7f9-4f82-a6b3-e94b5f82d7f5',
                type: 'company',
                name: 'Atelier Moderne',
            },
        ],
        surveyors: [],
        environmentalConsultants: [],
    },
    {
        id: 1,
        createdAt: '2024-11-22T16:03:44.472Z',
        updatedAt: '2024-11-22T16:03:44.472Z',
        organizationId: 'b5e7f92c-4f9b-4f8e-92c5-7f9d3b8e94c5',
        dealName: 'Milan Skyline Residences',
        description: "A premium residential project near Milan's city center.",
        address: 'Via Monte Napoleone 8',
        address_center: { lng: 9.1919, lat: 45.4642 },
        city: 'Milan',
        state: 'Lombardy',
        county: 'Italy',
        zipCode: '20121',
        latitude: null,
        longitude: null,
        market: 'Residential',
        subMarket: 'High-End Apartments',
        dealType: {
            id: 'a92f4b7c-9d3e-4f5b-9d8e-7f8c4b3f94c5',
            name: 'Development',
        },
        assetClass: 'Residential',
        constructionType: 'Reinforced Concrete',
        parkingType: 'Underground',
        acres: 2.8,
        grossSf: 220000,
        rentableSf: 210000,
        residentialUnits: 140,
        lots: 0,
        beds: 350,
        megawatts: 0,
        phase: {
            id: '7b4e8f92-3c7d-4e6b-a9f2-d7f3b8e9c4f6',
            name: 'Pre-Construction',
        },
        leadSource: {
            id: 'c5b4f9e8-3f2d-49b7-a5e8-3c7f4b8e92d5',
            name: 'Referral',
        },
        leasingStrategy: {
            id: 'f92b5c3e-4f7d-45e9-a3c8-7f3b9e8c5d2b',
            name: 'Luxury Lease Options',
        },
        rank: {
            id: '3b7f92e5-c5f8-4b9d-a7f6-9d3f2b8e94c6',
            name: 'Top Priority',
        },
        acquisitionPrice: 75000000,
        targetReturnOnCost: 0.11,
        targetReturnOnEquity: 0.17,
        targetProfitReturn: 0.23,
        irr: 0.2,
        equityMultiple: 2.6,
        creatorName: 'Giuseppe Rinaldi',
        dealOwner: {
            id: 'd5b4f92c-3e9b-49f7-a5f8-3b7e92d6c4f9',
            type: 'user',
            name: 'Lucia Bianchi',
        },
        developmentManager: {
            id: '9f3b4e92-7d5f-4b8e-a5c3-4f92d5e8b7c9',
            type: 'user',
            name: 'Alessandro Conti',
        },
        investors: [
            {
                id: '8b7c4f92-5e3f-4d9b-a3f2-4b9e7f8c5d92',
                type: 'company',
                name: 'Milano Capital Group',
            },
        ],
        lenders: [
            {
                id: 'd7f9b4e2-c5f8-4f7d-a9f3-4b8c9e5d3f92',
                type: 'company',
                name: 'UniCredit Bank',
            },
        ],
        legalServices: [
            {
                id: 'b4f9e8c5-d2b7-4f7e-a5c9-7b4e92f8d5c3',
                type: 'company',
                name: 'Studio Legale Verdi',
            },
        ],
        projectArchitects: [
            {
                id: '3b8f92d5-7c4e-49f8-a5b9-2c5f9e7d4b93',
                type: 'company',
                name: 'Architettura Moderna',
            },
        ],
        surveyors: [],
        environmentalConsultants: [],
    },
];
