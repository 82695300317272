import React from 'react';
import { NSTable } from 'bricks';
import { IGridConfig } from '../../types';
import { GroupLabelRow, HeaderRow, GroupRow } from '.';

export interface IProps {
    gridConfig: IGridConfig;
    maxHeight?: string | number;
}

export const ComparisonTable = ({ gridConfig, maxHeight }: IProps) => {
    const { data, groups, headerRowFormatter } = gridConfig;
    const numDataCols = data.length;

    return (
        <div className="NSTable--sticky-headers__table-container" style={{ maxHeight }}>
            <NSTable className="NSTable NSTable--sticky-headers ComparisonTable">
                <thead>
                    <HeaderRow data={data} headerRowFormatter={headerRowFormatter} />
                </thead>
                <tbody className="NSTable__tbody NSTable__tbody--show-column-borders">
                    {groups.map(group => (
                        <React.Fragment key={group.groupLabel}>
                            <GroupLabelRow groupLabel={group.groupLabel} numDataCols={numDataCols} />
                            {group.rows.map(row => (
                                <GroupRow key={row.label} row={row} data={data} />
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </NSTable>
        </div>
    );
};

export default ComparisonTable;
