import { useMutation, useQueryClient } from '@tanstack/react-query';
import API_ENDPOINTS from 'services/urls';
import axiosInstance from 'ns_libs/nsAxios';

interface IFormValues {
    landArea: number;
    landAreaUnitTypeId: number;
    floorAreaRatio: number;
    proFormaId: number;
}

interface IUpdateVariables {
    organizationId: string;
    proFormaId: number;
}

const deleteProForma = async ({ organizationId, proFormaId }: IUpdateVariables): Promise<IFormValues> => {
    const response = await axiosInstance.delete(`${API_ENDPOINTS.ORGANIZATION_PRO_FORMA(organizationId, proFormaId)}`);
    return response.data;
};

const useHardDeleteProForma = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: deleteProForma,
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['getProFormas'] });
        },
        onError: error => {
            console.error('Error deleting lot:', error);
        },
    });
};

export default useHardDeleteProForma;
