import { useQuery } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';
import { IDeal } from '../types';

const getOrgDealSummaries = async (orgId: string): Promise<Array<IDeal>> => {
    const response = await axiosInstance.get(API_ENDPOINTS.DEAL_SUMMARIES(orgId));
    return response.data;
};

export const useGetOrgDealSummaries = (orgId: string, options: { enabled: boolean }) => {
    return useQuery({
        queryKey: ['dealSummaries', orgId],
        queryFn: () => getOrgDealSummaries(orgId),
        staleTime: 5000,
        refetchOnMount: true,
        ...options,
    });
};

export default useGetOrgDealSummaries;
