import React from 'react';
import ProFormaReturnsTable from 'components/ProFormaReturnsTable/ProFormaReturnsTable';
import { IOptionAnyValue } from 'bricks/types';

export interface IProps {
    proFormaIds: number[];
    selectedPeriod: IOptionAnyValue;
}

export const AggregateProFormaReturnsTable = ({ proFormaIds, selectedPeriod }: IProps) => {
    const data = [
        {
            Property: 'Total development cost (before interest reserve)',
            Amount: 1000000,
            'Jun 2024': 5894341,
            'Jul 2024': 5894341,
            'Aug 2024': 5894341,
            'Sep 2024': 5894341,
            'Oct 2024': 5894341,
            'Nov 2024': 5894341,
        },
        {
            Property: 'Total interest reserve',
            Amount: 1000000,
            'Jun 2024': 5894341,
            'Jul 2024': 5894341,
            'Aug 2024': 5894341,
            'Sep 2024': 5894341,
            'Oct 2024': 5894341,
            'Nov 2024': 5894341,
        },
        {
            Property: 'Total construction loan draws',
            Amount: 1000000,
            'Jun 2024': 5894341,
            'Jul 2024': 5894341,
            'Aug 2024': 5894341,
            'Sep 2024': 5894341,
            'Oct 2024': 5894341,
            'Nov 2024': 5894341,
        },
        {
            Property: 'Permanent loan funding',
            Amount: 1000000,
            'Jun 2024': 5894341,
            'Jul 2024': 5894341,
            'Aug 2024': 5894341,
            'Sep 2024': 5894341,
            'Oct 2024': 5894341,
            'Nov 2024': 5894341,
        },
        {
            Property: 'Cash flow from operations',
            Amount: 1000000,
            'Jun 2024': 5894341,
            'Jul 2024': 5894341,
            'Aug 2024': 5894341,
            'Sep 2024': 5894341,
            'Oct 2024': 5894341,
            'Nov 2024': 5894341,
        },
        {
            Property: 'Permanent loan debt service',
            Amount: 1000000,
            'Jun 2024': 5894341,
            'Jul 2024': 5894341,
            'Aug 2024': 5894341,
            'Sep 2024': 5894341,
            'Oct 2024': 5894341,
            'Nov 2024': 5894341,
        },
        {
            Property: 'Net sale proceeds',
            Amount: 1000000,
            'Jun 2024': 5894341,
            'Jul 2024': 5894341,
            'Aug 2024': 5894341,
            'Sep 2024': 5894341,
            'Oct 2024': 5894341,
            'Nov 2024': 5894341,
        },
        {
            Property: 'Construction loan payoff',
            Amount: '-1000000',
            'Jun 2024': 5894341,
            'Jul 2024': 5894341,
            'Aug 2024': 5894341,
            'Sep 2024': 5894341,
            'Oct 2024': 5894341,
            'Nov 2024': 5894341,
        },
        {
            Property: 'Permanent loan payoff',
            Amount: '-1000000',
            'Jun 2024': 5894341,
            'Jul 2024': 5894341,
            'Aug 2024': 5894341,
            'Sep 2024': 5894341,
            'Oct 2024': 5894341,
            'Nov 2024': 5894341,
        },
        {
            Property: 'Equity proceeds from capital event(s)',
            Amount: 1000000,
            'Jun 2024': 5894341,
            'Jul 2024': 5894341,
            'Aug 2024': 5894341,
            'Sep 2024': 5894341,
            'Oct 2024': 5894341,
            'Nov 2024': 5894341,
        },
    ];

    const cashFlowData = [
        {
            Property: 'Net unlevered cash flow',
            EquityMultiple: 1.8,
            IRR: 7.17,
            NetProfit: 1000000,
            'Jun 2024': 5894341,
            'Jul 2024': 5894341,
            'Aug 2024': 5894341,
            'Sep 2024': 5894341,
            'Oct 2024': 5894341,
            'Nov 2024': 5894341,
        },
        {
            Property: 'Net levered cash flow',
            EquityMultiple: 1.8,
            IRR: 7.17,
            NetProfit: 1000000,
            'Jun 2024': 5894341,
            'Jul 2024': 5894341,
            'Aug 2024': 5894341,
            'Sep 2024': 5894341,
            'Oct 2024': 5894341,
            'Nov 2024': 5894341,
        },
    ];

    return (
        <div className="AggregateProFormaReturnsTable pt-2">
            <ProFormaReturnsTable data={data} cashFlowData={cashFlowData} selectedPeriod={selectedPeriod} />
        </div>
    );
};

export default AggregateProFormaReturnsTable;
