import React, { useState, useEffect } from 'react';
import { NSCard, NSInput, NSLabel, NSSelect } from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import { useQueryClient } from '@tanstack/react-query';
import ExpandableSection from 'components/ExpandableSection/ExpandableSection';
import RenderIf from 'components/RenderIf/RenderIf';
import { STATES_OPTIONS } from 'constants/statesList';
import { Col, Row } from 'reactstrap';
import {
    FORM_LABEL_CLASS_NAME,
    FORM_ROW_CLASS_NAME,
    GENERAL_INFORMATION_EXPANDABLE_SECTIONS,
    DEAL_TYPES,
    dealTypeProFormaFormFields,
    parkingOptions,
    mockOrgId,
} from 'views/ProFormaTable/constants';
import { IProFormaData } from 'views/ProFormaTable/types';
import Map from 'components/Map/Map';
import useDebounce from 'views/Deals/hooks/useDebounce';
import { useUpdateDeal } from 'components/DealForm/hooks/useUpdateDeal';
import useGetCoordinates from 'queries/Mapbox/useGetCoordinates';
import ApartmentSizeDetails from './ApartmentSizeDetails/ApartmentSizeDetails';
import { useProFormaSocketContext } from '../socketContext/ProFormaSocketProvider';

export interface IProFormaGeneralInformationProps {
    proForma: IProFormaData;
}

const ProFormaGeneralInformation = () => {
    const { proForma, handleUpdateProForma } = useProFormaSocketContext();

    const queryClient = useQueryClient();
    const deal = { assetType: { name: 'Apartment' } }; // TODO: this is a MOCK, update when we pro_forma_model_id populated

    const [values, setValues] = useState<{ [key: string]: any }>({});

    useEffect(() => {
        if (proForma) {
            const initialValues = {
                ...(dealTypeProFormaFormFields[deal.assetType.name] || {}),
                dealName: proForma.dealName,
                address: proForma.address,
                city: proForma.city,
                county: proForma.county,
                state: proForma.state,
                zipCode: proForma.zipCode,
                startDate: proForma.startDate,
                landArea: proForma.landArea,
                landAreaUnitTypeId: proForma.landAreaUnitTypeId,
                saleDate: proForma.saleDate,
                floorAreaRatio: proForma.floorAreaRatio ? Number(proForma.floorAreaRatio) : 0,
                grossBuildableArea: proForma.grossBuildableArea,
                buildings: proForma.buildings ? Number(proForma.buildings) : 0,
                averageStories: proForma.averageStories ? Number(proForma.averageStories) : 0,
                builtGrossSquareFootage: proForma.builtGrossSquareFootage,
                parking: [],
            };

            if (proForma.structuredParkingSpaces != null) {
                initialValues.parking.push(parkingOptions[0]);
                initialValues.structuredParkingSpaces = proForma.structuredParkingSpaces;
            }
            if (proForma.surfaceParkingSpaces != null) {
                initialValues.parking.push(parkingOptions[1]);
                initialValues.surfaceParkingSpaces = proForma.surfaceParkingSpaces;
            }
            setValues(initialValues);
        }
    }, [proForma]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setValues({ ...values, [name]: value });
    };

    const handleSelect = (name: string, option: string | IOptionAnyValue | IOptionAnyValue[]) => {
        setValues({ ...values, [name]: option });
    };

    const { mutate: updateDeal } = useUpdateDeal();

    const handleUpdateDeal = async (dealName: string) => {
        updateDeal(
            {
                orgId: mockOrgId,
                dealId: Number(proForma.dealId),
                postData: { dealName },
            },
            {
                onSuccess: () => queryClient.invalidateQueries({ queryKey: ['proFormaData'] }),
            },
        );
    };

    const fullAddressAsString = `${values.address}, ${values.city}, ${values.state}, ${values.zipCode}`;
    const debouncedAddress = useDebounce(fullAddressAsString, 500);
    const { data: coordinates = {}, isLoading: isLoadingMapCoordinates } = useGetCoordinates(debouncedAddress);

    return (
        <>
            <ExpandableSection
                number={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[0].number}
                title={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[0].title}
                subtitle={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[0].subtitle}
                isExpanded
                animate
            >
                <NSCard className="p-3 NSCard--level-2">
                    <Row>
                        <Col>
                            <div className={FORM_ROW_CLASS_NAME}>
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>Deal name</NSLabel>
                                <NSInput
                                    type="text"
                                    name="dealName"
                                    value={values.dealName}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                    onBlur={() => handleUpdateDeal(values.dealName)}
                                    placeholder="Enter deal name"
                                />
                            </div>
                            <div className={FORM_ROW_CLASS_NAME}>
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>Address</NSLabel>
                                <NSInput
                                    type="text"
                                    name="address"
                                    value={values.address}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                    onBlur={() => handleUpdateProForma('address', values.address)}
                                    placeholder="Enter address"
                                />
                            </div>
                            <div className={FORM_ROW_CLASS_NAME}>
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>City</NSLabel>
                                <NSInput
                                    type="text"
                                    name="city"
                                    value={values.city}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                    onBlur={() => handleUpdateProForma('city', values.city)}
                                    placeholder="Enter city"
                                />
                            </div>
                            <div className={FORM_ROW_CLASS_NAME}>
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>County</NSLabel>
                                <NSInput
                                    type="text"
                                    name="county"
                                    value={values.county}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                    onBlur={() => handleUpdateProForma('county', values.county)}
                                    placeholder="Enter county"
                                />
                            </div>
                            <div className={FORM_ROW_CLASS_NAME}>
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>State</NSLabel>
                                <div className="col-4 pl-0">
                                    <NSSelect
                                        name="state"
                                        options={STATES_OPTIONS}
                                        value={{ label: values.state, value: values.state }}
                                        onChange={option => handleSelect('state', option.value)}
                                        onBlur={() => handleUpdateProForma('state', values.state)}
                                        isClearable={false}
                                    />
                                </div>
                            </div>
                            <div className="d-flex">
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>Zip</NSLabel>
                                <div className="col-4 pl-0">
                                    <NSInput
                                        type="number"
                                        name="zipCode"
                                        value={values.zipCode}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                        onBlur={() => handleUpdateProForma('zipCode', values.zipCode)}
                                        placeholder="Enter zip code"
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col className="NSCard--level-3 mx-2 p-2">
                            <RenderIf isTrue={coordinates && coordinates?.lat && coordinates?.lng} isLoading={isLoadingMapCoordinates}>
                                <Map lat={coordinates.lat} lng={coordinates.lng} />
                            </RenderIf>
                        </Col>
                    </Row>
                </NSCard>
            </ExpandableSection>

            <ExpandableSection
                number={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[1].number}
                title={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[1].title}
                subtitle={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[1].subtitle}
                isExpanded
                animate
            >
                <NSCard className="p-3 NSCard--level-2">
                    <div className="d-flex">
                        <div className="d-flex w-50">
                            <div className="d-flex w-100 my-auto">
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>Start month</NSLabel>
                                <NSInput
                                    type="date"
                                    name="startDate"
                                    value={values.startDate}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                    onBlur={() => handleUpdateProForma('startDate', values.startDate)}
                                />
                            </div>
                        </div>
                        <div className="d-flex w-50 ml-3">
                            <div className="d-flex w-100 my-auto">
                                <NSLabel className={FORM_LABEL_CLASS_NAME}>Sale month</NSLabel>
                                <NSInput
                                    type="date"
                                    name="saleDate"
                                    value={values.saleDate}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleInputChange(event)}
                                    onBlur={() => handleUpdateProForma('saleDate', values.saleDate)}
                                />
                            </div>
                        </div>
                    </div>
                </NSCard>
            </ExpandableSection>

            <ExpandableSection
                number={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[2].number}
                title={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[2].title}
                subtitle={GENERAL_INFORMATION_EXPANDABLE_SECTIONS[2].subtitle}
                isExpanded
                animate
            >
                <NSCard className="p-3 NSCard--level-2">
                    <RenderIf isTrue={deal.assetType.name === DEAL_TYPES.APARTMENT && values}>
                        <ApartmentSizeDetails
                            values={values}
                            setValues={setValues}
                            deal={deal}
                            handleInputChange={handleInputChange}
                            handleSelect={handleSelect}
                            updateProForma={handleUpdateProForma}
                        />
                    </RenderIf>

                    <RenderIf isTrue={deal.assetType.name === DEAL_TYPES.INDUSTRIAL}>{/* <IndustrialSizeDetails /> */}</RenderIf>

                    <RenderIf isTrue={deal.assetType.name === DEAL_TYPES.RETAIL}>{/* <RetailSizeDetails /> */}</RenderIf>

                    <RenderIf isTrue={deal.assetType.name === DEAL_TYPES.OFFICE}>{/* <OfficeSizeDetails /> */}</RenderIf>
                </NSCard>
            </ExpandableSection>
        </>
    );
};

export default ProFormaGeneralInformation;
