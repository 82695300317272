import React, { useState } from 'react';
import { NSDropdown, NSLabel, NSModal } from 'bricks';
import { IOptionAnyValue } from 'bricks/types';
import { Deal } from 'views/Deals/types';
import { useGetDealGroups } from 'views/Deals/hooks/useGetDealGroups';
import { useAssociateDealsToDealGroup } from 'views/Deals/hooks/useAssociateToDealGroup';

export interface IAssociateDealsToGroupModalProps {
    toggle: () => void;
    isOpen: boolean;
    deals: Deal[];
    onAsociationSuccess?: () => void;
}

const AssociateDealsToGroupModal = ({ toggle, isOpen, deals, onAsociationSuccess }: IAssociateDealsToGroupModalProps) => {
    const [selectedGroup, setSelectedGroup] = useState<IOptionAnyValue | undefined>();

    // TODO Remove hardcoded ORG ID
    const ORG_ID = '123e4567-e89b-12d3-a456-426614174000';
    const { data: dealGroups, isLoading } = useGetDealGroups(ORG_ID);
    const { mutate: associateDealsToDealGroup, error: associateError } = useAssociateDealsToDealGroup();

    const cleanData = () => {
        setSelectedGroup(undefined);
    };

    const handleAssociateDeals = () => {
        if (!selectedGroup) {
            window.alert('Please select a group.');
            return;
        }

        associateDealsToDealGroup(
            {
                orgId: ORG_ID,
                postData: {
                    associations: deals.map(deal => ({
                        dealId: Number(deal.id),
                        dealGroupId: Number(selectedGroup.value),
                    })),
                },
            },
            {
                onSuccess: () => {
                    window.alert('Deals successfully associated with the group!');
                    toggle();
                    cleanData();
                    onAsociationSuccess?.();
                },
                onError: error => {
                    window.alert(associateError?.message || 'Failed to associate deals. Please try again.');
                },
            },
        );
    };

    const dealGroupOptions: IOptionAnyValue[] = dealGroups ? dealGroups.map(group => ({ label: group.name, value: group.id, id: group.id })) : [];

    return (
        <NSModal
            toggle={toggle}
            isOpen={isOpen}
            modalHeaderClassName="bg-primary modal-colored-header"
            modalHeaderText="Associate Deals to Group"
            modalFooterButtonText="Associate Deals"
            modalFooterIsButtonFunctionDisabled={!selectedGroup}
            modalFooterIsShown
            size="lg"
            modalFooterButtonFunction={handleAssociateDeals}
            modalFooterCancelButtonFunction={cleanData}
        >
            <NSLabel className="text-dark font-weight-normal pt-1">
                Select an existing group
                <span className="text-danger"> *</span>
            </NSLabel>
            <NSDropdown
                options={dealGroupOptions}
                selectedOption={selectedGroup}
                placeholder={isLoading ? 'Loading groups...' : 'Select a group'}
                handleSelectOption={option => setSelectedGroup(option)}
                isFullWidth
            />
        </NSModal>
    );
};

export default AssociateDealsToGroupModal;
