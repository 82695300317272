import React, { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';
import { IProFormaData, IUsesExpandedCategories, IUsesSelectedCategories } from 'views/ProFormaTable/types';

export interface IUsesTableContext {
    selectedCategories: IUsesSelectedCategories;
    setSelectedCategories: Dispatch<SetStateAction<IUsesSelectedCategories>>;
    expandedCategories: IUsesExpandedCategories;
    setExpandedCategories: Dispatch<SetStateAction<IUsesExpandedCategories>>;
    updateSelectedCategories: (highLevelId: number | null, subcategoryIds: number[] | null, budgetClassificationIds: number[]) => void;
    updateExpandedCategories: (highLevelId: number | null, subcategoryId: number | null) => void;
    deselectAllCategories: () => void;
    selectAllCategories: (updatedCategories: IUsesSelectedCategories) => void;
    hideAllCategories: () => void;
    showAllCategories: (updatedCategories: IUsesExpandedCategories) => void;
    proForma: IProFormaData;
    navigateToSection: (sideNavName: string, tabName?: string) => void;
}

export const defaultValues: IUsesTableContext = {
    selectedCategories: { highLevels: [], subcategories: [], budgetClassifications: [] },
    setSelectedCategories: () => {},
    expandedCategories: { highLevels: [], subcategories: [] },
    setExpandedCategories: () => {},
    updateSelectedCategories: () => {},
    updateExpandedCategories: () => {},
    deselectAllCategories: () => {},
    selectAllCategories: () => {},
    hideAllCategories: () => {},
    showAllCategories: () => {},
    proForma: {} as IProFormaData,
    navigateToSection: () => {},
};

const UsesTableContext = createContext<IUsesTableContext>(defaultValues);

interface IUsesTableProviderProps {
    children: React.ReactNode;
    proForma: IProFormaData;
    navigateToSection: (sideNavName: string, tabName?: string) => void;
}
const UsesTableProvider = ({ children, proForma, navigateToSection }: IUsesTableProviderProps) => {
    const [selectedCategories, setSelectedCategories] = useState<IUsesSelectedCategories>({
        highLevels: [],
        subcategories: [],
        budgetClassifications: [],
    });
    const [expandedCategories, setExpandedCategories] = useState<IUsesExpandedCategories>({ highLevels: [], subcategories: [] });

    const updateSelectedCategories = (highLevelId: number | null, subcategoryIds: number[] | null, budgetClassificationIds: number[]) => {
        /**
         * selecting any level also selects its children
         * deselecting any level also deselects it's children
         */
        const newCategories = { ...selectedCategories };

        if (highLevelId) {
            if (!newCategories.highLevels.includes(highLevelId)) {
                newCategories.highLevels.push(highLevelId);
                if (subcategoryIds) {
                    newCategories.subcategories = [...new Set([...newCategories.subcategories, ...subcategoryIds])];
                }
                if (budgetClassificationIds) {
                    newCategories.budgetClassifications = [...new Set([...newCategories.budgetClassifications, ...budgetClassificationIds])];
                }
            } else {
                newCategories.highLevels = newCategories.highLevels.filter(id => id !== highLevelId);
                if (subcategoryIds) {
                    newCategories.subcategories = newCategories.subcategories.filter(id => !subcategoryIds.includes(id));
                }
                if (budgetClassificationIds) {
                    newCategories.budgetClassifications = newCategories.budgetClassifications.filter(id => !budgetClassificationIds.includes(id));
                }
            }
        } else if (subcategoryIds && subcategoryIds.length > 0) {
            subcategoryIds.forEach(subcategoryId => {
                if (!newCategories.subcategories.includes(subcategoryId)) {
                    newCategories.subcategories.push(subcategoryId);
                    if (budgetClassificationIds) {
                        newCategories.budgetClassifications = [...new Set([...newCategories.budgetClassifications, ...budgetClassificationIds])];
                    }
                } else {
                    newCategories.subcategories = newCategories.subcategories.filter(id => id !== subcategoryId);
                    if (budgetClassificationIds) {
                        newCategories.budgetClassifications = newCategories.budgetClassifications.filter(id => !budgetClassificationIds.includes(id));
                    }
                }
            });
        } else if (budgetClassificationIds && budgetClassificationIds.length > 0) {
            budgetClassificationIds.forEach(budgetClassificationId => {
                if (!newCategories.budgetClassifications.includes(budgetClassificationId)) {
                    newCategories.budgetClassifications.push(budgetClassificationId);
                } else {
                    newCategories.budgetClassifications = newCategories.budgetClassifications.filter(id => id !== budgetClassificationId);
                }
            });
        }

        setSelectedCategories(newCategories);
    };

    const deselectAllCategories = () => {
        setSelectedCategories({ highLevels: [], subcategories: [], budgetClassifications: [] });
    };

    const selectAllCategories = (updatedCategories: IUsesSelectedCategories) => {
        setSelectedCategories(updatedCategories);
    };

    const updateExpandedCategories = (highLevelId: number | null, subcategoryId: number | null) => {
        if (highLevelId) {
            setExpandedCategories(prev => ({
                ...prev,
                highLevels: prev.highLevels.includes(highLevelId) ? prev.highLevels.filter(id => id !== highLevelId) : [...prev.highLevels, highLevelId],
            }));
        }

        if (subcategoryId) {
            setExpandedCategories(prev => ({
                ...prev,
                subcategories: prev.subcategories.includes(subcategoryId)
                    ? prev.subcategories.filter(id => id !== subcategoryId)
                    : [...prev.subcategories, subcategoryId],
            }));
        }
    };

    const hideAllCategories = () => {
        setExpandedCategories({ highLevels: [], subcategories: [] });
    };

    const showAllCategories = (updatedCategories: IUsesExpandedCategories) => {
        setExpandedCategories(updatedCategories);
    };

    return (
        <UsesTableContext.Provider
            value={{
                selectedCategories,
                setSelectedCategories,
                expandedCategories,
                setExpandedCategories,
                updateSelectedCategories,
                updateExpandedCategories,
                deselectAllCategories,
                selectAllCategories,
                hideAllCategories,
                showAllCategories,
                proForma,
                navigateToSection,
            }}
        >
            {children}
        </UsesTableContext.Provider>
    );
};

const useUsesTableContext = () => useContext(UsesTableContext);

export { UsesTableProvider, useUsesTableContext };
