import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useGetProForma } from 'queries/ProForma';
import ProFormaSideNav from './components/ProFormaSideNav/ProFormaSideNav';
import {
    PRO_FORMA_TABS,
    PRO_FORMA_TAB_NAMES,
    PRO_FORMA_TABLE_NAV,
    PRO_FORMA_TABLE_NAV_NAMES,
    RETURNS_AND_WATERFALL_TABS,
    RETURNS_AND_WATERFALL_TAB_NAMES,
} from './constants';
import RenderIf from '../../components/RenderIf/RenderIf';
import ProForma from './components/ProForma/ProForma';
import ReturnsAndWaterfall from './components/ReturnsAndWaterfall/ReturnsAndWaterfall';
import Sensitivity from './components/Sensitivity/Sensitivity';
import ExecutiveSummary from './components/ExecutiveSummary/ExecutiveSummary';
import { getProFormaUrl } from './helpers';
import { IProFormaData } from './types';

const ProFormaTable = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { dealId, proFormaId } = useParams();

    const section = searchParams.get('section') || '';
    const tab = searchParams.get('tab') || '';

    const [activeSideNav, setActiveSideNav] = useState<string>(section);

    const { data: proForma } = useGetProForma(Number(dealId), Number(proFormaId));

    const navigateToSection = useCallback(
        (sideNavName: string, tabName?: string) => {
            setActiveSideNav(sideNavName);

            const updatedSearchParams: Record<string, string> = {
                section: sideNavName,
                ...(tabName ? { tab: tabName } : {}),
            };
            setSearchParams(updatedSearchParams);

            const url = getProFormaUrl(sideNavName, tabName);
            navigate(url, { replace: true });
        },
        [setActiveSideNav, setSearchParams, navigate],
    );

    useEffect(() => {
        const currentSection = PRO_FORMA_TABLE_NAV_NAMES.includes(section) ? section : PRO_FORMA_TABLE_NAV.PRO_FORMA.name;
        let currentTab = '';
        if (currentSection === PRO_FORMA_TABLE_NAV.PRO_FORMA.name) {
            currentTab = tab && PRO_FORMA_TAB_NAMES.includes(tab) ? tab : PRO_FORMA_TABS.GENERAL_INFORMATION.name;
        } else if (currentSection === PRO_FORMA_TABLE_NAV.RETURNS_AND_WATERFALL.name) {
            currentTab = tab && RETURNS_AND_WATERFALL_TAB_NAMES.includes(tab) ? tab : RETURNS_AND_WATERFALL_TABS.RETURNS;
        }
        if (currentSection !== section || currentTab !== tab) {
            navigateToSection(currentSection, currentTab);
        }
    }, [section, tab, navigateToSection]);

    return (
        <div className="d-flex align-items-stretch">
            <ProFormaSideNav activeNav={activeSideNav} navigateToSection={navigateToSection} proForma={proForma as IProFormaData} />

            <div className="w-100 flex-shrink-1 ml-3">
                <RenderIf isTrue={activeSideNav === PRO_FORMA_TABLE_NAV.PRO_FORMA.name}>
                    <ProForma navigateToSection={navigateToSection} currentSection={section} currentTab={tab} proForma={proForma as IProFormaData} />
                </RenderIf>

                <RenderIf isTrue={activeSideNav === PRO_FORMA_TABLE_NAV.RETURNS_AND_WATERFALL.name}>
                    <ReturnsAndWaterfall navigateToSection={navigateToSection} currentSection={section} currentTab={tab} />
                </RenderIf>

                <RenderIf isTrue={activeSideNav === PRO_FORMA_TABLE_NAV.SENSITIVITY.name}>
                    <Sensitivity />
                </RenderIf>

                <RenderIf isTrue={activeSideNav === PRO_FORMA_TABLE_NAV.EXECUTIVE_SUMMARY.name}>
                    <ExecutiveSummary />
                </RenderIf>
            </div>
        </div>
    );
};

export default ProFormaTable;
