import { BASE_URL } from './constants';

export const V1 = `${BASE_URL}/api`;

export const URLS = {
    // Cloudfront url for images
    IMAGE_CLOUDFRONT_URL: (name: string) => `https://d24iv9sojvq5iq.cloudfront.net/images/${name}`,
    UTILITIES: '/utilities',
    TIFF: '/tiff',
    REPORTS: {
        HOME: '/reports',
        NEW: '/reports/new/:reportType',
        REPORT_PAGE: '/reports/:reportId',
        REPORT_PREVIEW: '/reports/:reportId/preview',
    },
    REPORT_BUILDER: '/deal/:dealId/report_builder',
    DEALS: {
        HOME: '/deals',
        VIEW: '/deals/:dealId',
        FORM: '/deals/form/:dealId?',
        COMPARISON: '/deals/comparison/:dealId?',
    },
    DEAL_DASHBOARD: {
        HOME: '/deal/:dealId',
    },
    PRO_FORMA: {
        HOME: `/deal/:dealId/pro_forma`,
        BASE_URL: `/deal/:dealId/pro_forma/:proFormaId`,
        WIZARD: `/deal/:dealId/pro_forma/wizard`,
        TABLE: `/deal/:dealId/pro_forma/:proFormaId/table`,
    },
};

const API_ENDPOINTS = {
    BASE_URL,
    AUTHENTICATED_USER: () => `${V1}/users/me`,
    DEALS: (organizationId: string) => `${V1}/${organizationId}/deals`,
    DEAL_SUMMARIES: (organizationId: string) => `${V1}/${organizationId}/deals/summaries`,
    DEALS_BY_ID: (organizationId: string, dealId: number) => `${V1}/${organizationId}/deals/${dealId}`,
    DEALS_GROUPS: (organizationId: string) => `${V1}/${organizationId}/deal-groups`,
    DEALS_GROUPS_BY_ID: (organizationId: string, dealGroupId: number) => `${V1}/${organizationId}/deal-groups/${dealGroupId}`,
    DEAL_OVERVIEW_PDF: (organizationId: string, dealId: number) => `${V1}/${organizationId}/deals/${dealId}/overview/pdf`,
    ASSOCIATE_DEALS_GROUPS: (organizationId: string) => `${V1}/${organizationId}/deal-groups/associate`,
    ORG_REPORTS: (orgId: string) => `${V1}/${orgId}/reports`,
    REPORTS: {
        REPORT: (reportId: number) => `${V1}/reports/${reportId}`,
        PDF: (reportId: number) => `${V1}/reports/${reportId}/pdf`,
        PREVIEW: (reportId: number) => `${V1}/reports/${reportId}/preview`,
    },
    REPORT_BUILDER_TEMPLATE: (dealId: number) => `${V1}/deals/${dealId}/report_builder_templates`,
    TIMELINES: (orgId: string, dealId: number) => `${V1}/${orgId}/deals/${dealId}/timelines`,
    TIMELINE_BY_ID: (orgId: string, dealId: number, timelineId: string) => `${V1}/${orgId}/deals/${dealId}/timelines/${timelineId}`,
    BACK_OF_ENVELOPE: (orgId: string) => `${V1}/${orgId}/back-of-envelope`,
    BACK_OF_ENVELOPE_DEALS: (orgId: string) => `${V1}/${orgId}/back-of-envelope/deals`,
    BACK_OF_ENVELOPE_SCENARIOS: (orgId: string) => `${V1}/${orgId}/back-of-envelope/scenarios`,
    BOE_SCENARIO_COMP_PDF: (orgId: string, dealId: number) => `${V1}/${orgId}/back-of-envelope/${dealId}/comparison/pdf`,
    BOE_SCENARIO_DASHBOARD_PDF: (orgId: string, scenarioId: number) => `${V1}/${orgId}/back-of-envelope/${scenarioId}/dashboard/pdf`,
    BACK_OF_ENVELOPE_BY_ID: (orgId: string, scenarioId: string) => `${V1}/${orgId}/back-of-envelope/${scenarioId}`,
    PRO_FORMA: (dealId: number, proFormaId?: number) => `${V1}/deals/${dealId}/pro_formas${proFormaId ? `/${proFormaId}` : ''}`,
    ORGANIZATION_PRO_FORMA: (organizationId: string, proFormaId: number) => `${V1}/organizations/${organizationId}/pro_formas/${proFormaId}`,
    DUPLICATE_PRO_FORMA: (organizationId: string, proFormaId: number) => `${V1}/organizations/${organizationId}/pro_formas/${proFormaId}/duplicate`,
    PRO_FORMA_SOURCE: (organizationId: string, proFormaId: string) => `${V1}/organization/${organizationId}/pro_forma/${proFormaId}/pro_forma_source`,
    PRO_FORMA_BUILDABLE_LOT: (dealId: number, proFormaId: number, lotId?: number) =>
        `${V1}/deals/${dealId}/pro_forma/${proFormaId}/gross_buildable_area_lot${lotId ? `/${lotId}` : ''}`,
    GENERATE_PDF: () => `${V1}/generate_pdf`,
    BUDGET_CLASSIFICATIONS: (organizationId: string, proFormaId: string) =>
        `${V1}/organization/${organizationId}/pro_forma/${proFormaId}/budget_classifications`,
    DEFAULT_BUDGET_CLASSIFICATIONS: (organizationId: string, proFormaId: string) =>
        `${V1}/organization/${organizationId}/pro_forma/${proFormaId}/budget_classifications/default`,
    BUDGET_CLASSIFICATION: (organizationId: string, proFormaId: string) =>
        `${V1}/organization/${organizationId}/pro_forma/${proFormaId}/budget_classification`,
    USES_TABLE: (organizationId: string, proFormaId: string) => `${V1}/organizations/${organizationId}/pro_formas/${proFormaId}/uses`,
    SOURCES_TABLE: (organizationId: string, proFormaId: string) => `${V1}/organizations/${organizationId}/pro_formas/${proFormaId}/sources`,
    MAPBOX: 'https://api.mapbox.com/geocoding/v5/mapbox.places-permanent/',
};

export default API_ENDPOINTS;
