import React, { useMemo, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { URLS } from 'services/urls';
import { NSLoadingScreen } from 'bricks';
import { PipelineReport, ComparisonReport, AggregateReport } from './ReportTypes';
import { ReportType, ReportTypeIdToReportTypeMap, ReportTypeUrlParamToTypeMap } from './types';
import { useGetReport } from './hooks';

type IRouterParams = {
    reportId: string;
    reportType: string;
};

export const ReportPageRouter = () => {
    const location = useLocation();
    const { reportId, reportType } = useParams<IRouterParams>();
    const numericReportId = reportId ? Number(reportId) : undefined;
    const newReportType = reportType ? ReportTypeUrlParamToTypeMap[reportType] : undefined;
    const isEditMode = location.state?.isEdit;

    const navigate = useNavigate();

    const { data: reportData, isLoading } = useGetReport(Number(numericReportId), {
        enabled: !newReportType && !!numericReportId, // Fetch only if no reportType is provided and a reportId exists
    });

    const targetReportType = useMemo(() => {
        if (newReportType) {
            return newReportType;
        }
        if (reportData) {
            return ReportTypeIdToReportTypeMap[reportData.type];
        }
        return undefined;
    }, [reportData, newReportType]);

    useEffect(() => {
        if (!targetReportType && !isLoading) {
            navigate(URLS.REPORTS.HOME);
        }
    }, [targetReportType]);

    const handleBack = () => {
        // only navigate back if the user clicked on create new report. prevents step back for manual url navigation.
        if ((reportId === undefined || newReportType) && window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate(URLS.REPORTS.HOME);
        }
    };

    return (
        <div>
            {isLoading && <NSLoadingScreen />}
            {!isLoading && (
                <>
                    {targetReportType === ReportType.Pipeline && <PipelineReport onClickBack={handleBack} report={reportData} isEditing={isEditMode} />}
                    {targetReportType === ReportType.Aggregate && <AggregateReport report={reportData} onClickBack={handleBack} isEditing={isEditMode} />}
                    {targetReportType === ReportType.Comparison && (
                        <ComparisonReport report={reportData} onClickBack={handleBack} isEditing={isEditMode} />
                    )}
                </>
            )}
        </div>
    );
};

export default ReportPageRouter;
