import React, { useState } from 'react';
import './ProFormaIncomeTable.scss';
import ProFormaGenericTable from 'views/ProFormaTable/components/ProFormaGenericTable/ProFormaGenericTable';
import { ProFormaGenericRow, ProFormaGenericColumn } from 'views/ProFormaTable/components/ProFormaGenericTable/types';
import { IOptionAnyValue } from 'bricks/types';
import {
    INITIAL_INCOME_ROWS,
    INITIAL_GROSS_RENT_ADJUSTMENT_ROWS,
    INITIAL_OTHER_INCOME_ROWS,
    calculationOptions,
    INCOME_COLUMNS,
    GROSS_RENT_ADJUSTMENT_COLUMNS,
    OTHER_INCOME_COLUMNS,
    TOTAL_POTENTIAL_COLUMNS,
    INITIAL_INCOME_ADJUSTMENTS_ROWS,
} from 'views/ProFormaTable/constants';

const ProFormaIncomeTable = () => {
    const [incomeRows, setIncomeRows] = useState(INITIAL_INCOME_ROWS);
    const [grossRentAdjustmentRows, setGrossRentAdjustmentRows] = useState(INITIAL_GROSS_RENT_ADJUSTMENT_ROWS);
    const [otherIncomeRows, setOtherIncomeRows] = useState(INITIAL_OTHER_INCOME_ROWS);
    const [incomeAdjustmentsRows, setIncomeAdjustmentsRows] = useState(INITIAL_INCOME_ADJUSTMENTS_ROWS);

    const handleSelectCalculation = (rowId: string) => (option: IOptionAnyValue | null) => {
        if (option) {
            setIncomeAdjustmentsRows(rows => rows.map(row => (row.id === rowId ? { ...row, selectedCalculation: option } : row)));
        }
    };

    const incomeAdjustmentsColumns: ProFormaGenericColumn[] = [
        {
            key: 'name',
            field: 'name',
            header: 'Income adjustments',
            format: 'none',
            type: 'text',
            isReadOnly: (row: ProFormaGenericRow) => ['vacancy', 'credit-loss'].includes(row.id),
            dropdown: {
                label: 'Calculated on:',
                options: calculationOptions,
                getSelectedOption: (row: ProFormaGenericRow) => row.selectedCalculation,
                handleSelectOption: handleSelectCalculation,
            },
        },
        {
            key: 'percentTotalRent',
            field: 'percentTotalRent',
            header: '% of total rent',
            format: 'percentage',
        },
        {
            key: 'costPerSF',
            field: 'costPerSF',
            header: '$/SF/Mo.',
            isReadOnly: (row: ProFormaGenericRow) => ['vacancy', 'credit-loss'].includes(row.id),
        },
        {
            key: 'costPerUnit',
            field: 'costPerUnit',
            header: '$/Unit/month',
            isReadOnly: (row: ProFormaGenericRow) => ['vacancy', 'credit-loss'].includes(row.id),
        },
        {
            key: 'totalRent',
            field: 'totalRent',
            header: 'Amount/year',
            isReadOnly: (row: ProFormaGenericRow) => ['vacancy', 'credit-loss'].includes(row.id),
        },
        {
            key: 'column1',
            field: 'column1',
            header: 'MON 20XX',
            isFixed: false,
            isReadOnly: (row: ProFormaGenericRow) => ['vacancy', 'credit-loss'].includes(row.id),
        },
    ];

    return (
        <div className="p-2">
            <ProFormaGenericTable columns={INCOME_COLUMNS} rows={incomeRows} setRows={setIncomeRows} />
            <div className="pb-1" />
            <ProFormaGenericTable columns={GROSS_RENT_ADJUSTMENT_COLUMNS} rows={grossRentAdjustmentRows} setRows={setGrossRentAdjustmentRows} />
            <div className="pb-1" />
            <ProFormaGenericTable columns={OTHER_INCOME_COLUMNS} rows={otherIncomeRows} setRows={setOtherIncomeRows} />
            <div className="pb-1" />
            <ProFormaGenericTable columns={TOTAL_POTENTIAL_COLUMNS} rows={[]} setRows={() => {}} hideTotalFooter hideAddButton />
            <div className="pb-1" />
            <ProFormaGenericTable columns={incomeAdjustmentsColumns} rows={incomeAdjustmentsRows} setRows={setIncomeAdjustmentsRows} />
        </div>
    );
};

export default ProFormaIncomeTable;
