/* eslint-disable no-unused-vars */
export enum ActiveSections {
    INITIAL = 'Initial',
    START_NEW_REPORT = 'Start New Report',
    SELECT_SAVED_TEMPLATE = 'Select Saved Template',
}

export enum ReportTypes {
    SAVED_REPORT = 'saved_report',
    BACK_OF_ENVELOPE_SCENARIO_COMPARISON = 'back_of_envelope_scenario_comparison',
    DEAL_BACK_OF_EVENLOPE = 'deal_back_of_envelope',
    DEAL_OVERVIEW = 'deal_overview',
    DOCUMENT_LIBRARY = 'document_library',
    PRO_FORMA_EXECUTIVE_SUMMARY = 'pro_forma_executive_summary',
}

export interface IReportBuilderReport {
    savedReportId?: number;
    savedReportName?: string;
    reportType: string;
}
