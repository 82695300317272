import { NSDropdown } from 'bricks';
import NSCellInput from 'bricks/NSCellInput/NSCellInput';
import { IOptionAnyValue } from 'bricks/types';
import RenderIf from 'components/RenderIf/RenderIf';
import { PRO_FORMA_UNIT_TO_FIELD_VALUES } from 'constants/unitTypes';
import { numeralFormatterCurrency, percentFormatter } from 'ns_libs/formatter';
import React from 'react';
import { DEBT_SOURCE_TYPES, PRO_FORMA_DEVELOPMENT_DEBT_RATE_OPTIONS } from 'views/ProFormaTable/constants';
import { DevelopmentUnitOfMeasureTypes, IProFormaSourceTableRow } from 'views/ProFormaTable/types';

export interface ISourceProps {
    source: IProFormaSourceTableRow;
    totalSourcesAmount: number;
    unitType: DevelopmentUnitOfMeasureTypes;
}

const Source = ({ source, totalSourcesAmount, unitType }: ISourceProps) => {
    // TODO: update when this info is available
    // TODO: also update RenderIfs true with source type being one of debt types
    const mockSourceTypeName = DEBT_SOURCE_TYPES.DEBT_PRIVATE;

    const traunchOptions: IOptionAnyValue[] = Array.from({ length: totalSourcesAmount }, (_, index) => ({
        label: `${index + 1}`,
        value: index + 1,
    }));

    const sourceUnitAmount = source[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType]] !== null ? source[PRO_FORMA_UNIT_TO_FIELD_VALUES[unitType]] : null;

    return (
        <tr className="NSTable__tbody__tr">
            <td className="NSTable__tbody__tr__td align-middle" colSpan={4}>
                <div className="ml-2">
                    <div className="d-flex">
                        {mockSourceTypeName}
                        <RenderIf isTrue>
                            <NSDropdown
                                options={PRO_FORMA_DEVELOPMENT_DEBT_RATE_OPTIONS}
                                selectedOption={PRO_FORMA_DEVELOPMENT_DEBT_RATE_OPTIONS[0]}
                                handleSelectOption={() => {}}
                                isBadgeToggle
                                badgeColor="none"
                                containerClassName="text-dark"
                            />
                        </RenderIf>
                    </div>
                    <NSCellInput
                        value={source.name}
                        onChange={() => {}}
                        onBlur={() => {}}
                        placeholder="Enter source name"
                        customClassName="mt-2"
                        onEnter={() => {}}
                    />
                </div>
            </td>
            <td className="NSTable__tbody__tr__td align-middle" colSpan={2}>
                <RenderIf isTrue>
                    <div className="d-flex justify-content-end text-primary">
                        {source.debtYield !== null ? percentFormatter(source.debtYield * 100) : '—'}
                    </div>
                </RenderIf>
            </td>
            <td className="NSTable__tbody__tr__td align-middle" colSpan={1}>
                <div className="d-flex justify-content-end">
                    <NSDropdown
                        options={traunchOptions}
                        selectedOption={traunchOptions?.find(traunch => traunch.value === source.traunch)}
                        handleSelectOption={() => {}}
                        isBadgeToggle
                        badgeColor="none"
                        containerClassName="text-primary"
                    />
                </div>
            </td>
            <td className="NSTable__tbody__tr__td align-middle" colSpan={1}>
                <div className="d-flex justify-content-end text-dark">{percentFormatter((source.sourcesPercentage || 0) * 100)}</div>
            </td>
            <td className="NSTable__tbody__tr__td align-middle" colSpan={2}>
                <div className="d-flex justify-content-end text-dark">{sourceUnitAmount !== null ? numeralFormatterCurrency(sourceUnitAmount) : '—'}</div>
            </td>
            <td className="NSTable__tbody__tr__td align-middle" colSpan={2}>
                <div className="d-flex justify-content-end text-primary">{!source.amount ? '—' : numeralFormatterCurrency(source.amount)}</div>
            </td>
        </tr>
    );
};

export default Source;
