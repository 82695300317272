import { useMutation } from '@tanstack/react-query';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';

export const updateTimeline = async ({ orgId, dealId, timelineId, patchData }: { orgId: string; dealId: number; timelineId: string; patchData: any }) => {
    const response = await axiosInstance.patch(API_ENDPOINTS.TIMELINE_BY_ID(orgId, dealId, timelineId), patchData);
    return response.data;
};

export const useUpdateTimeline = (onSuccess: (data: any, variables: any) => void) =>
    useMutation({
        mutationFn: updateTimeline,
        onSuccess,
    });
