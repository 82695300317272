import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { UUID } from 'crypto';
import axiosInstance from 'ns_libs/nsAxios';
import API_ENDPOINTS from 'services/urls';
import { IBudgetClassification } from 'types/db/BudgetClassification';

interface ICreateBudgetClassificationPostBody {
    nodeName: string;
    parentId: number;
}

interface IVariables {
    organizationId: UUID;
    proformaId: number;
    postBody: ICreateBudgetClassificationPostBody;
}

interface IResponse {
    success: boolean;
    data: IBudgetClassification;
}

const createBudgetClassification = async ({ organizationId, proformaId, postBody }: IVariables): Promise<IResponse> => {
    const response = await axiosInstance.post(API_ENDPOINTS.BUDGET_CLASSIFICATION(String(organizationId), String(proformaId)), postBody);
    return response.data;
};

export const useCreateBudgetClassification = <TError = unknown, TContext = unknown>(
    options?: UseMutationOptions<IResponse, TError, IVariables, TContext>,
) => {
    return useMutation<IResponse, TError, IVariables, TContext>({
        mutationFn: createBudgetClassification,
        ...options,
    });
};

export default useCreateBudgetClassification;
