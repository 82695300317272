import { io } from 'socket.io-client';
import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { NSButton } from 'bricks';
import { useAuth0TokenContext } from '../../contexts/Auth0TokenContext';
import AppTemplateWithAuthorization from '../../components/AppTemplateWithAuthorization';
import { config } from '../../config';
import { URLS } from '../../services/urls';

const roomId = 'f1423021-a183-473d-9282-4fd4097031f6/counter';

const joinRoom = (socket, roomId) => {
    return new Promise((resolve, reject) => {
        socket.emit('join_room', roomId, response => {
            if (response && response.error) {
                console.error('Error joining room:', response.error);
                reject(response.error);
            } else {
                console.log('Successfully joined room:', roomId);
                resolve(response);
            }
        });
    });
};

// Function to fetch the counter value for a specific room
const fetchCounterValue = (socket, roomId) => {
    return new Promise((resolve, reject) => {
        socket.emit('get_counter_value', { room: roomId });

        // Listen for the response event
        socket.on('counter_value', data => {
            console.log('Counter value response:', data);
            if (data && data.error) {
                console.error('Error fetching counter value:', data.error);
                reject(data.error);
            } else if (data.room === roomId) {
                console.log('Counter value fetched:', data.count);
                resolve(data.count);
            }
        });
    });
};

const Counter = () => {
    const [responseMessage, setResponseMessage] = useState('');
    const [counter, setCounter] = useState(null);
    const socketRef = useRef(null);

    const { isAuthenticated, accessToken } = useAuth0TokenContext();

    useEffect(() => {
        if (isAuthenticated && accessToken) {
            // Initialize WebSocket connection
            const socket = io(config.webSocketBaseUrl, {
                transports: ['websocket'],
                path: '/api/socket.io',
                auth: {
                    token: accessToken,
                },
                rejectUnauthorized: config.rejectUnauthorizedWebsocket,
            });

            // Event: Connection established
            socket.on('connect', async () => {
                console.log('Socket ID:', socket.id);
                console.log('Joining room:', roomId);

                try {
                    // Join the room
                    await joinRoom(socket, roomId);

                    // Fetch and set the counter value
                    console.log('Fetching counter value...');
                    const counterValue = await fetchCounterValue(socket, roomId);
                    setCounter(counterValue);
                } catch (error) {
                    console.error('Error during connection workflow:', error);
                }
            });

            socket.on('counter:current', data => {
                console.log('The current counter value was returned:', data);
                console.log('Counter value:', data.count);
                setCounter(data.count); // Update counter value displayed in the UI
            });

            socket.on('counter:updated', data => {
                console.log('The current counter value was returned:', data);
                console.log('Counter value:', data.count);
                setCounter(data.count); // Update counter value displayed in the UI
            });

            // Event: Error handling
            socket.on('connect_error', error => {
                console.error('WebSocket connection error:', error);
            });

            // Store the socket instance for potential use in other parts of the component
            socketRef.current = socket;

            // Return a cleanup function to close the WebSocket connection on unmount
            return () => {
                console.log('Cleaning up WebSocket connection');
                if (socket) {
                    socket.disconnect();
                    socketRef.current = null;
                }
            };
        }

        // Return a no-op cleanup function when the WebSocket is not initialized
        return () => {};
    }, [isAuthenticated, accessToken, config.webSocketBaseUrl]);

    const handleButtonClick = () => {
        if (socketRef.current) {
            // Emit the "update_counter" event
            socketRef.current.emit('update_counter', {
                room: roomId,
            });
        } else {
            setResponseMessage('WebSocket connection is not established');
        }
    };

    const handleRedirect = () => {
        window.location.href = config.northspyreProjectsAppUrl;
    };

    return (
        <AppTemplateWithAuthorization>
            <NSButton className="m-2" color="secondary" callback={handleButtonClick}>
                Increment Counter
            </NSButton>
            <p>Current Counter Value: {counter !== null ? counter : 'Click the button to increment the count!'}</p>
            <NSButton className="mx-2 NSButton--secondary-lighten" callback={handleRedirect}>
                Go to Northspyre Projects App
            </NSButton>
            <div className="my-3">
                <Link to={URLS.TIFF} className="d-block">
                    Tiff
                </Link>
            </div>
            {responseMessage && <p>{responseMessage}</p>}
        </AppTemplateWithAuthorization>
    );
};

export default Counter;
