export const getMonthNumber = (input: Date | string) => {
    const date = typeof input === 'string' ? new Date(input) : input;
    const monthNumber = date.getMonth() + 1;
    return `MONTH ${monthNumber.toString().padStart(2, '0')}`;
};

export const formatDate = (input: Date | string): string => {
    const date = typeof input === 'string' ? new Date(input) : input;
    const dayOfWeek = date.toLocaleDateString('en-US', { weekday: 'short' }).toUpperCase();
    const year = date.getFullYear();
    return `${dayOfWeek} ${year}`;
};
