import React from 'react';
import { Button } from 'reactstrap';
import { Deal } from 'views/Deals/types';
import Map from 'components/Map/Map';
import RenderIf from 'components/RenderIf/RenderIf';
import { generatePath, Link } from 'react-router-dom';
import { URLS } from 'services/urls';
import { NSCard } from 'bricks';
import { useMapCoordinates } from 'hooks/useMapCoordinates';
import DetailText from './components/DetailText';

interface IDetailItem {
    id: number;
    label: string;
    value: string | number;
}

interface IDetailsProps {
    deal: Deal;
    detailsArray: IDetailItem[];
    isCompact?: boolean;
}

const DealDetails = ({ deal, detailsArray, isCompact }: IDetailsProps) => {
    const { lat, lng } = useMapCoordinates(deal);

    return (
        <NSCard className="p-2">
            <RenderIf isTrue={!isCompact}>
                <div className="d-flex justify-content-between align-items-center mb-2 DealDashboard__separator">
                    <h5>Details</h5>
                    <Link to={generatePath(URLS.DEALS.FORM, { dealId: deal.id || '' })}>
                        <Button color="link" className="text-primary">
                            Edit details
                        </Button>
                    </Link>
                </div>
            </RenderIf>

            <RenderIf isTrue={isCompact}>
                <div className="d-flex justify-content-between align-items-center">
                    <h4 className="text-white">Deal Overview</h4>
                </div>
            </RenderIf>

            <div className="DealDashboard__hide-scrollbar d-flex flex-column flex-grow-1 overflow-auto">
                <div className="d-flex flex-column">
                    <RenderIf isTrue={deal.description}>
                        <p className="pb-1 DealDashboard__separator">
                            <span className="text-muted">Description:</span>
                            <br />
                            <span className="text-dark">{deal.description}</span>
                        </p>
                    </RenderIf>
                    {detailsArray.map(({ label, value, id }) => (
                        <DetailText key={id} label={label} value={value} />
                    ))}
                </div>

                <div className="mt-2 flex-grow-1 d-flex position-relative">
                    <Map lat={lat} lng={lng} hideMarker={false} zoom={10} />
                </div>
            </div>
        </NSCard>
    );
};

export default DealDetails;
